import React from "react";
import { useFormatPrice } from "hooks/useFormatPrice";
import { Apartment } from "./dataTransformer";
import { useNavigate } from "react-router";

interface propsType {
  data: Apartment;
}

const ChessCard = ({ data }: propsType) => {
  const { price, size, number, status, id } = data;
  const navigate = useNavigate();

  const formatedprice = useFormatPrice(price);

  const handleNavigateToDeatails = () => {
    navigate(`/apartments/card/${id}`);
  };

  return (
    <div
      className={`bg-white-default rounded-[8px] p-[10px] w-[200px] flex flex-col gap-[16px] cursor-pointer
      `}
      onClick={handleNavigateToDeatails}
    >
      <div className="flex justify-between">
        <div
          className={`w-[46px] h-[31px] rounded-[5px] flex 
      items-center justify-center text-white-default 
      text-[16px] font-medium bg-accent-default  `}
        >
          {number}
        </div>
        <span className="text-[16px] leading-[20px] font-normalS">
          №{number}
        </span>
      </div>
      <div className="text-[14px] text-accent-default font-normal leading-[18px] flex flex-col gap-[5px]">
        <p className={`"text-[#E7B400]"}`}>
          {status === "free" ? `${formatedprice}` : "Забронировано"}
        </p>
        <p>{size} m2</p>
      </div>
    </div>
  );
};

export default ChessCard;
