import React from "react";
import { useNavigate } from "react-router";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import ButtonForm from "components/Button/ButtonForm";
import TextField from "components/Form/TextField/TextField";
import EmailIcon from "components/constant/icons/EmailIcon";
import EyeIcon from "components/constant/icons/EyeIcon";

import { useSignInEmployeeMutation } from "data/api/auth";
import Loader from "components/Loader";

import { emplFormDataType, formDataKeyType } from "../../types";
import { schemaEmployee } from "utils/schemas";
import showToast from "utils/showToast";

export const EmployeeForm = () => {
  const navigate = useNavigate();
  const [mutate, { isLoading }] = useSignInEmployeeMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
  } = useForm<emplFormDataType | formDataKeyType>({
    resolver: yupResolver<emplFormDataType | formDataKeyType>(schemaEmployee),
  });

  const onSubmit: SubmitHandler<emplFormDataType | formDataKeyType> = async (
    data
  ) => {
    try {
      const { data: res, error } = await mutate({
        data: { email: data.email, password: data.password },
      });
      if (error?.message) {
        setError("password", {
          type: "manual",
          message: error?.message,
        });
        return;
      }

      if (res?.data.accessToken) {
        localStorage.setItem("token", res.data.accessToken);
        showToast("Вы успешно вошли в систему", "success");
        navigate("/");
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-[13px] relative"
    >
      {isLoading && <Loader />}
      <Controller
        name="email"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            value={value}
            className=""
            placeholder="Ваш email в Бруснике"
            label="Email"
            type="email"
            name="email"
            onChange={onChange}
            imgLeft={<EmailIcon />}
            errors={errors}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            value={value}
            className=""
            classNameInput={
              errors.password?.message
                ? "bg-red-disabled text-white-default placeholder:text-white-default "
                : ""
            }
            placeholder="Ваш пароль"
            label="Пароль"
            type="password"
            imgRight={<EyeIcon />}
            name="password"
            setValues={setValue}
            errors={errors}
            onChange={onChange}
          />
        )}
      />
      <ButtonForm
        className="bg-accent-default py-[12px] w-full
              text-white-default text-center flex justify-center hover:bg-accent-focus "
        text="Войти"
        type="submit"
      />
    </form>
  );
};
