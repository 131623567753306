import React, { Dispatch, SetStateAction, useRef } from "react";
import ButtonForm from "components/Button/ButtonForm";
import CloseWithBorderIcon from "components/constant/icons/CloseWithBorderIcon";
import Modal from "components/Modal";
import DateIcon from "components/constant/icons/DateIcon";
import Label from "components/Form/Label";
import Dropdown from "components/Form/Dropdown/Dropdown";
import DatePickerField from "components/Form/DatePicker/DatePickerField";
import TextField from "components/Form/TextField/TextField";
import RublIcon from "components/constant/icons/RublIcon";
import CopyIcon from "../../components/constant/icons/CopyIcon";
import showToast from "../../utils/showToast";

interface Props {
  isTaskViewModalOpen: boolean;
  setTaskViewModalOpen: Dispatch<SetStateAction<boolean>>;
  handleCompleteTheTask: () => void;
  taskResultOpen: boolean;
  setTaskResultOpen: Dispatch<SetStateAction<boolean>>;
  taskAddModalOpen: boolean;
  setTaskAddModalOpen: Dispatch<SetStateAction<boolean>>;
  mortgageModalOpen: boolean;
  setMortgageModalOpen: Dispatch<SetStateAction<boolean>>;
  handleApplyForMortgage: () => void;
  mortgageModal2Open: boolean;
  setMortgageModal2Open: Dispatch<SetStateAction<boolean>>;
  handleApplyForMortgage2: () => void;
  appliedModal: boolean;
  setAppliedModal: Dispatch<SetStateAction<boolean>>;
}

const TasksModals = ({
  isTaskViewModalOpen,
  taskResultOpen,
  taskAddModalOpen,
  mortgageModalOpen,
  mortgageModal2Open,
  appliedModal,
  handleApplyForMortgage,
  setTaskViewModalOpen,
  handleCompleteTheTask,
  setTaskResultOpen,
  setTaskAddModalOpen,
  setMortgageModalOpen,
  setMortgageModal2Open,
  handleApplyForMortgage2,
  setAppliedModal,
}: Props) => {
	const copyAreaRef = useRef<HTMLDivElement>(null);

  const taskData = [
    { id: 1, property: "Номер сделки:", value: "#fn5268" },
    { id: 2, property: "Мененджер:", value: "Алексей Паськов" },
    { id: 3, property: "Статус:", value: "Ожидание" },
    { id: 4, property: "Вид задачи:", value: "Показ", color: "text-[#3583FF]" },
    { id: 5, property: "Проект:", value: "ЖК Норпалова" },
    { id: 6, property: "Этап сделки:", value: "Переговоры" },
    {
      id: 7,
      property: "Комментарий:",
      value:
        "Все задачи передаются в CRM-систему (как заведение новых, так и закрытие старых).",
    },
  ];

  const clientData = [
    { id: 1, property: "ФИО клиента:", value: "Алексей" },
    { id: 2, property: "Телефон клиента:", value: "+7 909 205 56 58" },
    { id: 3, property: "Почта клиента:", value: "klient_brusniki@gmail.com" },
    {
      id: 4,
      property: "Начало и окончание задачи:",
      value: ["04.04.2024", "16.04.2024"],
    },
  ];

	const handleCopy = () => {
		if (copyAreaRef.current) {
			const textToCopy = copyAreaRef.current.textContent;
			if (textToCopy) {
				navigator.clipboard
					.writeText(textToCopy)
					.then(() => {
						showToast("Информация скопирована", "success", 1000);
					})
					.catch((err) => {
						showToast("Не удалось скопировать", "error", 1000);
					});
			}
		}
	};

  return (
    <>
      <Modal
        isOpen={isTaskViewModalOpen}
        className="mx-auto sm:w-[450px] lg:w-[500px] rounded-t-[30px] rounded-b-0 sm:rounded-[20px]"
        onClose={() => setTaskViewModalOpen(false)}
      >
        <div className={"flex justify-between mb-[20px]"}>
          <h1 className={"font-extrabold text-[25px] font-dewi"}>#123412</h1>

          <ButtonForm
            text={<CloseWithBorderIcon />}
            onClick={() => setTaskViewModalOpen(false)}
          />
        </div>
				<div>
					<h3 className="text-accent-default text-[16px]">Данные задачи</h3>
					<div className="flex flex-col gap-[10px] text-accent-default text-sm my-5">
						{taskData.map((item) => (
							<div className="flex gap-2" key={item.id}>
								<p className="font-semibold">{item.property}</p>
								<p className={`font-normal ${item?.color}`}>{item.value}</p>
							</div>
						))}
					</div>
					<div className={"flex items-center gap-[13px]"}>
						<h3 className="text-accent-default text-[16px]">Данные клиента</h3>
						<div onClick={handleCopy} className=" cursor-pointer">
							<CopyIcon color={"#8E95A1"}/>
						</div>
					</div>
					<div className="flex flex-col gap-[10px] text-accent-default text-sm my-5" ref={copyAreaRef}>
						{clientData.map((item) => (
							<div className="flex gap-2" key={item.id}>
								<p className="font-semibold">{item.property}</p>
								{typeof item.value === "string" ? (
									<p className={`font-normal`}>{item.value}</p>
								) : (
									<div className="flex items-center gap-2">
										<br/>
										<div
											className="py-[5px] flex items-center gap-[10px] rounded px-[6px] bg-bg-default text-[#000000]">
											<DateIcon color="#3583FF"/> <span>{item.value[0]}</span>
										</div>
										<div>-</div>
										<div
											className="py-[5px] flex items-center gap-[10px] rounded px-[6px] bg-bg-default text-[#000000]">
											<DateIcon color="#3583FF"/> <span>{item.value[0]}</span>
										</div>
									</div>
								)}
							</div>
						))}
					</div>
				</div>
				<div className="flex flex-col gap-[10px]">
					<ButtonForm
						className={
							" px-6 py-3 w-full justify-center  bg-bg-default hover:bg-accent-default text-accent-default hover:text-white-default text-ellipsis whitespace-nowrap"
            }
            text="Завершить"
            onClick={handleCompleteTheTask}
          />
          <ButtonForm
            className={
              " px-6 py-3 w-full justify-center hover:opacity-50  bg-white-default border border-red-default text-red-default  hover:bg-bg-defau text-ellipsis whitespace-nowrap"
            }
            text="Отменить"
            onClick={() => setTaskViewModalOpen(false)}
          />
        </div>
      </Modal>

      <Modal
        isOpen={taskResultOpen}
        className="mx-auto sm:w-[450px] lg:w-[500px] rounded-t-[30px] rounded-b-0 sm:rounded-[20px]"
        onClose={() => setTaskResultOpen(false)}
      >
        <div className={"flex justify-between mb-[20px]"}>
          <h1 className={"font-extrabold text-[25px] font-dewi"}>
            Завершение <br /> задачи
          </h1>

          <ButtonForm
            text={<CloseWithBorderIcon />}
            onClick={() => setTaskResultOpen(false)}
          />
        </div>
        <h3 className="text-sm font-semibold text-accent-default mb-2">
          Результат
        </h3>
        <textarea
          name=""
          rows={4}
          cols={5}
          className="border border-boder-default py-3 px-4 w-full rounded outline-none resize-none"
          id=""
        ></textarea>
        <ButtonForm
          className={
            " px-6 py-3 w-full mt-5 justify-center bg-accent-default text-white-default hover:bg-accent-disabled  hover:text-bg-default  hover:bg-bg-defau text-ellipsis whitespace-nowrap"
          }
          text="Отправить"
          onClick={() => setTaskResultOpen(false)}
        />
      </Modal>
      <Modal
        isOpen={taskAddModalOpen}
        className="mx-auto sm:w-[450px] lg:w-[500px] rounded-t-[30px] rounded-b-0 sm:rounded-[20px]"
      >
        <div className={"flex justify-between mb-[20px]"}>
          <h1 className={"font-extrabold text-[25px] font-dewi"}>
            Добавление задачи
          </h1>

          <ButtonForm
            text={<CloseWithBorderIcon />}
            onClick={() => setTaskAddModalOpen(false)}
          />
        </div>
        <div className="flex flex-col gap-5 h-[73vh] overflow-y-auto toolbar-scroll pr-3 mb-5">
          <Label label="Исполнитель">
            <Dropdown
              onChange={(e) => {}}
              options={[]}
              name="type"
              placeholder="Исполнитель не выбран"
            />
          </Label>
          <Label label="Клиент">
            <Dropdown
              onChange={(e) => {}}
              options={[]}
              name="type"
              placeholder="Клиент не выбран"
            />
          </Label>
          <Label label="Вид задачи">
            <Dropdown
              onChange={(e) => {}}
              options={[]}
              name="type"
              placeholder="Вид задачи не выбран"
            />
          </Label>
          <Label label="Тип задачи">
            <Dropdown
              onChange={(e) => {}}
              options={[]}
              name="type"
              placeholder="Тип задачи не выбран"
            />
          </Label>
          <Label label="Статус">
            <Dropdown
              onChange={(e) => {}}
              options={[]}
              name="type"
              placeholder="Статус не выбран"
            />
          </Label>
          <Label label="Проект">
            <Dropdown
              onChange={(e) => {}}
              options={[]}
              name="type"
              placeholder="Проект не выбран"
            />
          </Label>
          <Label label="Помещение">
            <Dropdown
              onChange={(e) => {}}
              options={[]}
              name="type"
              placeholder="Помещение не выбранно"
            />
          </Label>
          <Label label="Дедлайн" className={"w-full"}>
						<DatePickerField
							onChangeDate={() => {}}
							className=""
							placeholder="Выберите дату"
							isIcon
							value={""}
							name="startWorkDate"
						/>
          </Label>
          <Label label="Комментарий">
            <textarea
              name=""
              rows={3}
              cols={5}
              className="border border-boder-default py-3 px-4 w-full rounded outline-none resize-none"
              id=""
            ></textarea>
          </Label>
        </div>
        <div className="flex flex-col  gap-[10px]">
          <ButtonForm
            className={
              " px-6 py-3 w-full justify-center bg-accent-default text-white-default hover:bg-accent-disabled  hover:text-bg-default  hover:bg-bg-defau text-ellipsis whitespace-nowrap"
            }
            text="Добавить"
            onClick={() => setTaskAddModalOpen(false)}
          />
          <ButtonForm
            className={
              " px-6 py-3 w-full bg-bg-default text-accent-default justify-center hover:bg-accent-default text-white-defaul  hover:text-white-default  hover:bg-bg-defau text-ellipsis whitespace-nowrap"
            }
            text="Назад"
            onClick={() => setTaskAddModalOpen(false)}
          />
        </div>
      </Modal>

      <Modal
        isOpen={mortgageModalOpen}
        className="mx-auto sm:w-[450px] lg:w-[500px] rounded-t-[30px] rounded-b-0 sm:rounded-[20px]"
      >
        <div className={"flex justify-between mb-[20px]"}>
          <h1 className={"font-extrabold text-[25px] font-dewi"}>
            Заявка на ипотеку
          </h1>

          <ButtonForm
            text={<CloseWithBorderIcon />}
            onClick={() => setMortgageModalOpen(false)}
          />
        </div>
        <div className="flex flex-col gap-5">
          <Label label="Клиент">
            <Dropdown
              onChange={(e) => {}}
              options={[]}
              name="type"
              placeholder="Укажите клиента"
            />
          </Label>
          <Label label="Объект">
            <Dropdown
              onChange={(e) => {}}
              options={[]}
              name="type"
              placeholder="Выберите объект"
            />
          </Label>
          <Label label="Помещение">
            <Dropdown
              onChange={(e) => {}}
              options={[]}
              name="type"
              placeholder="Выберите помещение"
            />
          </Label>
          <ButtonForm
            className={
              " px-6 py-3 w-full mt-3 justify-center bg-accent-default text-white-default hover:bg-accent-disabled  hover:text-bg-default  hover:bg-bg-defau text-ellipsis whitespace-nowrap"
            }
            text="Отправить заявку"
            onClick={handleApplyForMortgage}
          />
        </div>
      </Modal>

      <Modal
        isOpen={mortgageModal2Open}
        className="mx-auto sm:w-[450px] lg:w-[500px] rounded-t-[30px] rounded-b-0 sm:rounded-[20px]"
      >
        <div className={"flex justify-between mb-[20px]"}>
          <h1 className={"font-extrabold text-[25px] font-dewi"}>
            Заявка на ипотеку
          </h1>

          <ButtonForm
            text={<CloseWithBorderIcon />}
            onClick={() => setMortgageModal2Open(false)}
          />
        </div>
        <div className="flex flex-col gap-5">
          <Label label="Клиент">
            <Dropdown
              onChange={(e) => {}}
              options={[]}
              name="type"
              placeholder="Укажите клиента"
            />
          </Label>
          <Label label="Объект">
            <Dropdown
              onChange={(e) => {}}
              options={[]}
              name="type"
              placeholder="ЖК Лобанова"
            />
          </Label>
          <Label label="Помещение">
            <Dropdown
              onChange={(e) => {}}
              options={[]}
              name="type"
              placeholder="Выберите помещение"
            />
          </Label>
          <TextField
            value=""
            label="Стоимость"
            placeholder="Стоимость"
            onChange={() => {}}
            imgRight={<RublIcon />}
          />
          <TextField
            value=""
            label="Первый взнос"
            placeholder="1 236 500"
            onChange={() => {}}
          />
          <Label label="Комментарий">
            <textarea
              name=""
              rows={3}
              placeholder="Введите свой комментарий"
              cols={5}
              className="border border-boder-default py-3 px-4 w-full rounded text-sm font-normal outline-none resize-none"
              id=""
            ></textarea>
          </Label>
        </div>
        <ButtonForm
          className={
            " px-6 py-3 w-full mt-5 justify-center bg-accent-default text-white-default hover:bg-accent-disabled  hover:text-bg-default  hover:bg-bg-defau text-ellipsis whitespace-nowrap"
          }
          text="Отправить заявку"
          onClick={handleApplyForMortgage2}
        />
      </Modal>
      <Modal
        isOpen={appliedModal}
        className="mx-auto sm:w-[450px] lg:w-[500px] rounded-t-[30px] rounded-b-0 sm:rounded-[20px]"
      >
        <h3 className="text-[25px] font-extrabold text-center mb-5">
          Заявка успешно <br /> отправлена
        </h3>
        <ButtonForm
          text={"Отлично"}
          onClick={() => setAppliedModal(false)}
          className="bg-accent-default py-[16px] w-full
             text-white-default text-center flex justify-center hover:bg-accent-focus "
        />
      </Modal>
    </>
  );
};

export default TasksModals;
