import React, { useState } from "react";
import ArrowDropdown from "components/constant/icons/ArrowDropdown";
import PageTitle from "components/PageTitle";
import Calendar from "components/constant/icons/Calendar";
import MinusIcon from "components/constant/icons/MinusIcon";
import { useWindowSize } from "hooks/useWindowSize";

const AnalyticsFilter = () => {
  const [selectedTab, setSelectedTab] = useState("Month");
  const { isLg } = useWindowSize();

  const tabs = [
    { id: 1, label: "Сегодня", value: "Today" },
    { id: 2, label: "Неделя", value: "Week" },
    { id: 3, label: "Месяц", value: "Month" },
    { id: 4, label: "Год", value: "Year" },
  ];

  return (
    <div className="flex justify-between">
      <div className="flex w-full flex-col gap-7 ">
        {isLg && <PageTitle text="Аналитика по платформе" />}
        <div className="flex  justify-between lg:justify-start  items-center gap-5">
          <h2 className="text-[18px] text-accent-default font-dewi font-extrabold">
            Апрель 2024
          </h2>
          <p className="flex cursor-pointer items-center text-accent-default">
            Москва
            <ArrowDropdown />
          </p>
        </div>
      </div>
      {isLg && (
        <div className="flex flex-col-reverse xl:flex-row gap-5 items-center self-end">
          <div className="flex bg-white-default p-[4px] rounded-full w-fit mx-auto">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setSelectedTab(tab.value)}
                className={`px-6 py-[8px] rounded-full w-[112px] text-sm transition-all duration-300 ${
                  selectedTab === tab.value
                    ? " bg-accent-default text-white-default"
                    : "text-accent-default"
                }`}
              >
                {tab.label}
              </button>
            ))}
          </div>
          <div className="w-[330px] px-[4px] flex text-sm items-center bg-white-default justify-between rounded-full py-[10px] text-accent-default">
            <div className="flex-1 flex items-center justify-center cursor-pointer gap-3">
              <Calendar /> <p>12.10.2023</p>
            </div>
            <MinusIcon />
            <div className="flex-1 flex items-center justify-center cursor-pointer gap-3 ">
              <Calendar /> <p>12.10.2023</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnalyticsFilter;
