import React, { ReactNode, useRef, useState } from "react";
import ButtonForm from "../../../components/Button/ButtonForm";
import { Link } from "react-router-dom";
import ArrowDropdown from "../../../components/constant/icons/ArrowDropdown";
import PlanLayoutIcon from "../../../components/constant/icons/Apartment/PlanLayoutIcon";
import PlanSectionIcon from "../../../components/constant/icons/Apartment/PlanSectionIcon";
import PlanFloorIcon from "../../../components/constant/icons/Apartment/PlanFloorIcon";
import PlanLayoutGridIcon from "../../../components/constant/icons/Apartment/PlanLayoutGridIcon";
import CompassIcon from "../../../components/constant/icons/Apartment/CompassIcon";

import { images } from "../../../utils/resource";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Close from "../../../components/constant/icons/Close";
import Modal from "../../../components/Modal";
import SearchIcon from "../../../components/constant/icons/SearchIcon";
import { useNavigate, useParams } from "react-router";
import { useGetOnePremiseQuery } from "data/api/premises";
import { useFormatPrice } from "hooks/useFormatPrice";
import BookingFrom from "../../../components/BookingAndVisits/BookingFrom";
import AdvertisementForm, { State } from "./AdvertisementForm";
import CircleXIcon from "components/constant/icons/CircleXIcon";
import DownloadIcon from "components/constant/icons/DownloadIcon";
import Logo from "components/constant/icons/Logo";
import html2canvas from "html2canvas";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { toggleBookingModal } from "store/BaseClientsReducer";
import ApartmentCardRightBlock from "./ApartmentCardRightBlock";

interface btnListImageData {
  text: string;
  key: string;
  icon: ReactNode;
}

const ApartmentCardPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, refetch } = useGetOnePremiseQuery(id);
  const selector = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  console.log(data?.data);

  const slicedDate = data?.data?.season?.date;

  const selection = {
    title: data?.data?.name,
    titleInfo:
      "Квартира с объединенной кухней-гостиной в Бруснике в Академическом",
    moreUrl: "https://moskva.brusnika.ru",
    locationImage: images.compass,
    imageLayout:
      process.env.REACT_APP_FILES_URL +
        "/" +
        data?.data?.schema?.schema_image || "",
    viewWindow: [
      {
        src: images.apartmentCardImg,
        width: 1920,
        height: 1080,
      },
    ],
    schema: data?.data?.schema,
    project: data?.data?.project,
    imageFloor: "",
    imageSection: "",
    compilation: true,
    types: [
      {
        image: images.apartmentCardImg,
        text: "Брусника в Академическом",
        url: "https://moskva.brusnika.ru/",
      },
    ],
    tags: [
      {
        text: "Холодная лоджия",
        desc: "ООО «Брусника» ОГРН 1116671018958 Юридический адрес: 620075, г. Екатеринбург, ул. Малышева, д.51, офис 37/05 ООО «Брусника. Строительство и девелопмент» раскрывает информацию в сети Интернет на странице",
      },
      {
        text: "Балкон",
        desc: "ООО «Брусника» ОГРН 1116671018958 Юридический адрес: 620075, г. Екатеринбург, ул. Малышева, д.51, офис 37/05 ООО «Брусника. Строительство и девелопмент» раскрывает информацию в сети Интернет на странице",
      },
    ],
    buys: ["Холодная лоджия", "Балкон"],
    info: {
      Дом: data?.data?.building?.name,
      Секция: data?.data.section?.name,
      Площадь: data?.data.size,
      Этаж: data?.data.floor,
      Стоимость: useFormatPrice(data?.data.price),
      Санузел: "2",
      "Срок сдачи": slicedDate,
    },
    galleryList: [
      {
        src: images.apartmentCardImg,
        width: 304,
        height: 177,
      },
      {
        src: images.apartmentCardImg4,
        width: 304,
        height: 435,
      },
      {
        src: images.apartmentCardImg2,
        width: 304,
        height: 217,
      },
      {
        src: images.apartmentCardImg2,
        width: 304,
        height: 217,
      },
      {
        src: images.apartmentCardImg5,
        width: 304,
        height: 270,
      },
      {
        src: images.apartmentCardImg3,
        width: 304,
        height: 517,
      },
      {
        src: images.apartmentCardImg3,
        width: 304,
        height: 517,
      },
      {
        src: images.apartmentCardImg2,
        width: 304,
        height: 217,
      },
      {
        src: images.apartmentCardImg,
        width: 304,
        height: 177,
      },
    ],
    is_booked: !data?.data.is_booked,
    ...data?.data,
  };

  const btnListImage = [
    {
      text: "Планировка",
      key: "layout",
      icon: <PlanLayoutIcon />,
    },
    {
      text: "Вид из окон",
      key: "grid",
      icon: <PlanLayoutGridIcon />,
    },
    {
      text: "На этаже",
      key: "floor",
      icon: <PlanFloorIcon />,
    },
    {
      text: "План секции",
      key: "section",
      icon: <PlanSectionIcon />,
    },
  ];

  const [isOpenAdvertisingModal, setIsOpenAdvertisingModal] = useState(false);
  const [generateModal, setGenirateModal] = useState(false);
  const formattedPrice = useFormatPrice(data?.data?.price ?? "");

  const [stateAddForm, setStateAddForm] = useState<State>({
    tags: [],
    selectedImg: "",
    purchaseoptions: "",
    logo: "",
    radioBtnData: [
      { id: 1, label: "Проект", value: false },
      { id: 2, label: "Дом/секция", value: false },
      { id: 3, label: "Этаж", value: false },
      { id: 4, label: "Номер помещения", value: false },
      { id: 5, label: "Количество комнат", value: false },
      { id: 6, label: "Площадь", value: false },
      { id: 7, label: "Стоимость", value: false },
      { id: 8, label: "Срок сдачи дома", value: false },
      { id: 9, label: "Информация по варианту покупки", value: false },
    ],
  });

  const [selectOpenWindow, setSelectOpenWindow] = useState(-1);

  const [selectItemImage, setSelectItemImage] = useState<btnListImageData>(
    btnListImage[0]
  );

  const [isOpenModalImageLayout, setIsOpenModalImageLayout] = useState(false);
  const captureRef = useRef(null);

  function onChangeImage(item: any) {
    setSelectItemImage(item);

    if (item.key === "grid") {
      setSelectOpenWindow(0);
    }
  }

  function handleModalImageLayout() {
    setIsOpenModalImageLayout(true);
  }

  function handleResetAddsFrom() {
    setStateAddForm((prev) => ({
      ...prev,
      tags: [],
      selectedImg: "",
      purchaseoptions: "",
      logo: "",
      radioBtnData: [
        { id: 1, label: "Проект", value: false },
        { id: 2, label: "Дом/секция", value: false },
        { id: 3, label: "Этаж", value: false },
        { id: 4, label: "Номер помещения", value: false },
        { id: 5, label: "Количество комнат", value: false },
        { id: 6, label: "Площадь", value: false },
        { id: 7, label: "Стоимость", value: false },
        { id: 8, label: "Срок сдачи дома", value: false },
        { id: 9, label: "Информация по варианту покупки", value: false },
      ],
    }));
  }

  const handleDownload = () => {
    if (captureRef?.current)
      html2canvas(captureRef.current, {
        allowTaint: false,
        useCORS: true,
        scale: 2,
      }).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "screenshot.png";
        link.click();
        setGenirateModal(false);
        handleResetAddsFrom();
      });
  };

  const handleToggleBookingModal = () => {
    dispatch(toggleBookingModal());
  };

  return (
    <div className="bg-bg-default">
      <div className="br-container py-[30px]">
        <div className="flex flex-col-reverse lg:flex-row gap-[30px]">
          <div className="w-[100%] lg:w-[69%]">
            <div className={"bg-white-default p-[30px] rounded-lg"}>
              <div className={"flex justify-between mb-[10px]"}>
                <div className={"flex items-center gap-[10px]"}>
                  <h2 className={"text-2xl font-semibold text-accent-default"}>
                    {selection.title}
                  </h2>

                  {/*<a*/}
                  {/*  href={selection.moreUrl}*/}
                  {/*  className={"text-blue-default text-sm hidden md:block"}*/}
                  {/*>*/}
                  {/*  Подробнее*/}
                  {/*</a>*/}
                </div>

                <div className={"hidden md:block"}>
                  <ButtonForm
                    leftIcon={
                      <span
                        className={
                          "border border-[#0000001c] rounded-full rotate-90 flex py-[4.5px] px-1"
                        }
                      >
                        <ArrowDropdown color={"black"} />
                      </span>
                    }
                    text="Вернуться к выбору"
                    className={
                      "flex items-center text-accent-default opacity-50"
                    }
                    onClick={() => navigate("/apartments")}
                  />
                </div>
              </div>

              <p className={"text-accent-default font-normal"}>
                {selection.titleInfo}
              </p>

              {selection.schema?.schema_image && (
                <div className={"flex justify-end my-[10px]"}>
                  {/*<img*/}
                  {/*	crossOrigin="anonymous"*/}
                  {/*	src={selection.locationImage}*/}
                  {/*	width="79"*/}
                  {/*	height="79"*/}
                  {/*	alt={""}*/}
                  {/*	// style={{*/}
                  {/*	// 	transform: `rotate(${selection.schema?.sunrise_angle || 0}deg)`*/}
                  {/*	// }}*/}
                  {/*/>*/}

                  <div
                    className={
                      "flex items-center justify-center shrink-0 w-[68px] h-[68px]"
                    }
                  >
                    <CompassIcon
                      rotate={
                        selection.schema?.sunrise_angle
                          ? 180 + selection.schema?.sunrise_angle
                          : 180
                      }
                    />
                  </div>
                </div>
              )}

              <div
                className={
                  "relative flex items-center justify-center mb-[20px] md:mb-[45px]"
                }
              >
                <div className={"image-layout flex"}>
                  <img
                    crossOrigin="anonymous"
                    src={selection.imageLayout}
                    alt={""}
                    className={"max-h-[410px]"}
                  />

                  <div
                    className={
                      "flex justify-center items-center " +
                      "absolute gap-2 bg-bg-default border py-1 " +
                      "px-2 opacity-0 cursor-pointer transition z-10"
                    }
                    style={{
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    onClick={handleModalImageLayout}
                  >
                    <SearchIcon color={"black"} /> Увеличить
                  </div>
                </div>
              </div>

              <div
                className={
                  "flex flex-wrap justify-center gap-[20px] md:gap-[2px] mt-[10px]"
                }
              >
                {/*{btnListImage.map((item, idx) => (*/}
                {/*  <ButtonForm*/}
                {/*    key={`btn-image-item-${idx}`}*/}
                {/*    leftIcon={item.icon}*/}
                {/*    text={item.text}*/}
                {/*    className={`flex items-center text-accent-default gap-[7px] ${*/}
                {/*      selectItemImage && selectItemImage.key === item.key*/}
                {/*        ? "underline"*/}
                {/*        : ""*/}
                {/*    }`}*/}
                {/*    onClick={() => onChangeImage(item)}*/}
                {/*  />*/}
                {/*))}*/}

                <Link
                  to={(selection.project?.link as string) || ""}
                  className={`flex items-center text-accent-default gap-[7px] py-3 px-6 border rounded text-sm font-medium max-w-[266px] w-full justify-center`}
                >
                  Подробная информация
                </Link>
              </div>
            </div>

            {/*<div className={"gap-[20px] py-[30px] min-h-[954px]"}>*/}
            {/*  <PhotoAlbum*/}
            {/*    layout="columns"*/}
            {/*    photos={selection.galleryList}*/}
            {/*    columns={(containerWidth) => {*/}
            {/*      if (containerWidth < 400) return 1;*/}
            {/*      if (containerWidth < 500) return 2;*/}
            {/*      return 3;*/}
            {/*    }}*/}
            {/*    rowConstraints={{*/}
            {/*      minPhotos: 3,*/}
            {/*      maxPhotos: 3,*/}
            {/*    }}*/}
            {/*    targetRowHeight={(containerWidth) => {*/}
            {/*      if (containerWidth < 400) return 1;*/}
            {/*      if (containerWidth < 500) return 2;*/}
            {/*      return 3;*/}
            {/*    }}*/}
            {/*    onClick={({ index }) => setSelectOpenGallery(index)}*/}
            {/*  />*/}
            {/*</div>*/}

            {/*<Lightbox*/}
            {/*  styles={{ container: { backgroundColor: "rgba(20,25,26,.6)" } }}*/}
            {/*  slides={selection.galleryList}*/}
            {/*  open={selectOpenGallery >= 0}*/}
            {/*  index={selectOpenGallery}*/}
            {/*  close={() => setSelectOpenGallery(-1)}*/}
            {/*  plugins={[Fullscreen, Slideshow, Zoom]}*/}
            {/*/>*/}
          </div>

          <ApartmentCardRightBlock
            selection={selection}
            setIsOpenAdvertisingModal={setIsOpenAdvertisingModal}
          />
        </div>
      </div>

      <Modal
        isOpen={isOpenModalImageLayout}
        className="rounded-lg relative"
        onClose={() => setIsOpenModalImageLayout(false)}
      >
        <div className={"absolute right-3 top-4 flex justify-end"}>
          <ButtonForm
            text={<Close color={"black"} strokeWidth={2} />}
            onClick={() => setIsOpenModalImageLayout(false)}
          />
        </div>

        <div className={"flex items-center justify-center p-[20px]"}>
          <img crossOrigin="anonymous" src={selection.imageLayout} alt={""} />
        </div>
      </Modal>

      <Lightbox
        styles={{ container: { backgroundColor: "rgba(20,25,26,.6)" } }}
        slides={selection.viewWindow}
        open={selectOpenWindow >= 0}
        index={selectOpenWindow}
        close={() => {
          setSelectItemImage(btnListImage[0]);
          setSelectOpenWindow(-1);
        }}
        plugins={[Fullscreen, Slideshow, Zoom]}
      />
      <BookingFrom
        refetch={refetch}
        primise_id={data?.data.id}
        isOpen={selector.baseClients.isOpenBookingModal}
        handleToggle={handleToggleBookingModal}
      />
      <AdvertisementForm
        setGenirateModal={setGenirateModal}
        imgList={selection.galleryList}
        isOpen={isOpenAdvertisingModal}
        setIsOpen={setIsOpenAdvertisingModal}
        stateAddForm={stateAddForm}
        setStateAddForm={setStateAddForm}
      />
      <Modal
        className="w-[500px] rounded-t-[30px] md:rounded-[8px]"
        isOpen={generateModal}
      >
        <div className=" text-accent-default mb-[30px] flex items-center justify-between">
          <h1 className="font-dewi text-[25px] font-extrabold leading-[30px]">
            Рекламный макет
          </h1>
          <button
            onClick={() => {
              setGenirateModal(false);
              handleResetAddsFrom();
            }}
          >
            <CircleXIcon />
          </button>
        </div>
        <div ref={captureRef}>
          <div className=" bg-red-default h-[36px] rounded-t-[2px] flex items-center justify-center">
            <Logo width="114" color="#FFFFFF" />
          </div>
          <div className="relative h-[326.7px]">
            <img
              crossOrigin="anonymous"
              src={stateAddForm.selectedImg || images.cardImg}
              className="w-full h-full
            "
              alt="maket bg image"
            />
            <div className=" absolute z-[2] top-0 bottom-0  left-0 right-0 bg-accent-default opacity-80"></div>
            <div className="flex gap-2 z-[3] justify-between absolute top-0 bottom-0  left-0 right-0 p-4 sm:p-6">
              <div className="flex flex-col justify-between  text-white-default">
                {stateAddForm.logo == "true" && (
                  <div className="flex items-center">
                    <img
                      crossOrigin="anonymous"
                      src={images.brusnika_logo}
                      height={36}
                      width={96}
                      className="w-[50px] h-[50px]"
                      alt="SemantecLogo"
                    />
                    <Logo width="120px" />
                  </div>
                )}
                <div className="">
                  {stateAddForm.radioBtnData?.[8].value &&
                    stateAddForm.purchaseoptions && (
                      <div className="py-1 px-[10px] w-fit rounded-3xl flex items-center justify-center bg-accent-default font-dewi text-white-default text-[8px] font-extrabold">
                        {stateAddForm.purchaseoptions}
                      </div>
                    )}

                  {stateAddForm.radioBtnData?.[0].value && (
                    <p className="text-[16px] font-dewi">
                      {data?.data?.project?.name}
                    </p>
                  )}

                  {stateAddForm.radioBtnData?.[6].value && (
                    <p className=" text-[22px] sm:text-[26px] font-dewi">
                      {formattedPrice}
                    </p>
                  )}

                  <div className="flex gap-[6px] sm:gap-[13px] text-[11px] font-dewi  sm:text-[13px]">
                    {stateAddForm.radioBtnData?.[2].value && (
                      <span>{data?.data.floor} этаж</span>
                    )}
                    {stateAddForm.radioBtnData?.[4].value && (
                      <span>{data?.data.rooms} комнаты</span>
                    )}

                    {stateAddForm.radioBtnData?.[5].value && (
                      <span>{data?.data.size} м2</span>
                    )}
                  </div>
                  <div className="flex gap-[7px] mt-[5px]">
                    {stateAddForm.tags.map((tag, i) => (
                      <div
                        key={i}
                        className="px-[9px] bg-red-default py-[5px]  text-[9px] font-semibold rounded-[25px]"
                      >
                        <p> {tag}</p>
                      </div>
                    ))}
                  </div>
                </div>
                {stateAddForm.radioBtnData?.[1].value && (
                  <span className="text-white-default text-[8px] font-extrabold font-dewi">
                    {stateAddForm.radioBtnData[3]?.value &&
                      data?.data.building.name + `, ${data?.data.number}`}
                  </span>
                )}
              </div>
              <div className="self-end flex gap-[20px] flex-col">
                <img
                  crossOrigin="anonymous"
                  src={images.layoutLocation}
                  className="bg-white-default w-[110px] sm:w-[125px]"
                  width={125}
                  height={200}
                  alt=""
                />
                <span className="text-white-default text-[8px] self-end font-extrabold font-dewi">
                  {stateAddForm.radioBtnData?.[7]?.value &&
                    `Срок сдачи:` + " " + slicedDate}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-[10px] mt-[20px]">
          <ButtonForm
            onClick={() => {
              setGenirateModal(false);
              setIsOpenAdvertisingModal(true);
            }}
            text="Назад"
            type="button"
            className=" bg-bg-default py-3 px-6 flex-1
              hover:bg-accent-default flex justify-center
               hover:text-white-default"
          />
          <ButtonForm
            text="Скачать макет"
            leftIcon={<DownloadIcon color="#FFFFFF" />}
            onClick={handleDownload}
            className=" py-3 px-6 
             bg-red-default flex-1
            text-white-default flex justify-center
            hover:bg-red-focus
            "
          />
        </div>
      </Modal>
    </div>
  );
};

export default ApartmentCardPage;
