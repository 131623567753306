import React from "react";

const GenerateIcons = () => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.40508 20.6H3.60507C2.27958 20.6 1.20507 19.5254 1.20508 18.2L1.20517 3.80001C1.20518 2.47453 2.27969 1.40002 3.60517 1.40002H14.4054C15.7309 1.40002 16.8054 2.47454 16.8054 3.80002V10.4M15.3992 20.1882V16.7941M15.3992 16.7941V13.4M15.3992 16.7941L12.0051 16.7941M15.3992 16.7941L18.7933 16.7941"
        stroke="#37465B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GenerateIcons;
