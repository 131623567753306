import { Icons } from "./interface";

const PlusIconBig = (
	{
		color = "#313945",
		strokeWidth = "1.5",
		width = 24,
		height = 24
	}: Icons
) => {
  return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.0008 4.7998L12.0008 19.1998M19.2008 11.9998L4.80078 11.9998"
				stroke={color}
				strokeWidth={strokeWidth}
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default PlusIconBig;
