import React from "react";
interface porpsType {
  color?: string;
  width?: string;
}
const Logo = ({ color = "white", width = "259" }: porpsType) => {
  return (
    <svg
      width={width}
      height="18"
      viewBox="0 0 259 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1354_53412)">
        <path
          d="M0.918457 0.328003H27.6726V4.5107H6.69331V6.83441H22.4557C26.6682 6.83441 29.179 8.83005 29.179 12.1926C29.179 15.5552 26.6961 17.5508 22.4557 17.5508H0.918457V0.328003ZM6.69331 10.9077V13.5322H21.3954C22.6789 13.5322 23.2646 13.1494 23.2646 12.2199C23.2646 11.3178 22.6789 10.9077 21.3954 10.9077H6.69331Z"
          fill={color}
        />
        <path
          d="M32.5547 0.328003H52.0552C57.4396 0.328003 60.6477 2.7884 60.6477 6.94376C60.6477 11.0991 57.4674 13.5595 52.0552 13.5595H38.5806V17.5782H32.5547V0.328003ZM38.6085 4.78406V9.15812H51.1345C53.506 9.15812 54.5381 8.502 54.5381 6.99842C54.5381 5.44016 53.506 4.78406 51.1345 4.78406H38.6085Z"
          fill={color}
        />
        <path
          d="M72.6723 12.5207L60.1182 0.328003H67.3996L76.2432 8.80273L84.1104 0.328003H91.2244L75.2109 17.5782H67.9297L72.6723 12.5207Z"
          fill={color}
        />
        <path
          d="M105.982 0C114.63 0 119.763 2.023 121.074 5.87763L114.574 7.13516C113.821 5.2762 111.171 4.42872 106.009 4.42872C98.9513 4.42872 95.8267 5.7956 95.8267 8.96679C95.8267 12.1107 98.9233 13.5049 106.009 13.5049C111.31 13.5049 113.96 12.6301 114.602 10.6891L121.018 11.892C119.623 15.9106 114.658 17.9336 106.009 17.9336C95.4639 17.9336 89.6612 14.7351 89.6612 8.96679C89.6333 3.17119 95.436 0 105.982 0Z"
          fill={color}
        />
        <path
          d="M123.837 0.328003H129.863V6.56102H148.275V0.328003H154.329V17.5782H148.275V11.0171H129.863V17.5782H123.837V0.328003Z"
          fill={color}
        />
        <path
          d="M158.765 0.328003H164.791V12.0833L182.59 0.328003H188.644V17.5782H182.59V5.8229L164.791 17.5782H158.765V0.328003Z"
          fill={color}
        />
        <path
          d="M192.883 0.328003H198.91V7.51785L213.306 0.328003H223.126L206.498 8.44733L223.544 17.5782H213.027L198.91 10.0056V17.5782H192.883V0.328003Z"
          fill={color}
        />
        <path
          d="M240.534 4.59271L245.222 10.4977H235.848L240.534 4.59271ZM237.521 0.328003L223.238 17.5782H230.24L232.639 14.571H248.456L250.857 17.5782H258.082L243.798 0.328003H237.521Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1354_53412">
          <rect width="259" height="18" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
