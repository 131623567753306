import React from "react";

const AddUserIcons = ({ color = "#37465B" }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.650391 20.5999C0.650344 21.0142 0.986093 21.35 1.40031 21.35C1.81452 21.3501 2.15034 21.0143 2.15039 20.6001L0.650391 20.5999ZM1.4008 16.9996L2.1508 16.9997L1.4008 16.9996ZM11.6004 14.15C12.0146 14.15 12.3504 13.8142 12.3504 13.4C12.3504 12.9858 12.0146 12.65 11.6004 12.65V14.15ZM20.6004 15.35C21.0146 15.35 21.3504 15.0142 21.3504 14.6C21.3504 14.1858 21.0146 13.85 20.6004 13.85V15.35ZM15.2004 13.85C14.7862 13.85 14.4504 14.1858 14.4504 14.6C14.4504 15.0142 14.7862 15.35 15.2004 15.35V13.85ZM17.1504 17.2999C17.1504 17.7141 17.4862 18.0499 17.9004 18.0499C18.3146 18.0499 18.6504 17.7141 18.6504 17.2999H17.1504ZM18.6504 11.8999C18.6504 11.4857 18.3146 11.1499 17.9004 11.1499C17.4862 11.1499 17.1504 11.4857 17.1504 11.8999H18.6504ZM12.6504 5.00002C12.6504 6.57404 11.3744 7.85002 9.80039 7.85002V9.35002C12.2028 9.35002 14.1504 7.40246 14.1504 5.00002H12.6504ZM9.80039 7.85002C8.22638 7.85002 6.95039 6.57404 6.95039 5.00002H5.45039C5.45039 7.40246 7.39795 9.35002 9.80039 9.35002V7.85002ZM6.95039 5.00002C6.95039 3.42601 8.22638 2.15002 9.80039 2.15002V0.650024C7.39795 0.650024 5.45039 2.59759 5.45039 5.00002H6.95039ZM9.80039 2.15002C11.3744 2.15002 12.6504 3.42601 12.6504 5.00002H14.1504C14.1504 2.59759 12.2028 0.650024 9.80039 0.650024V2.15002ZM2.15039 20.6001L2.1508 16.9997L0.650795 16.9995L0.650391 20.5999L2.15039 20.6001ZM5.00079 12.65C2.59855 12.65 0.651065 14.5973 0.650795 16.9995L2.1508 16.9997C2.15097 15.4258 3.42691 14.15 5.00079 14.15V12.65ZM5.00079 14.15H11.6004V12.65H5.00079V14.15ZM20.6004 13.85H17.9004V15.35H20.6004V13.85ZM17.9004 13.85H15.2004V15.35H17.9004V13.85ZM18.6504 17.2999V14.6H17.1504V17.2999H18.6504ZM18.6504 14.6V11.8999H17.1504V14.6H18.6504Z"
        fill={color}
      />
    </svg>
  );
};

export default AddUserIcons;
