import React, { useState } from "react";

import RadioButton from "components/RadioSelectionButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { useNavigate } from "react-router";
import { useFormatPrice } from "hooks/useFormatPrice";
import { Apartment } from "./dataTransformer";
import BookingFrom from "../../../components/BookingAndVisits/BookingFrom";
import moment from "moment";
import { useGetPremisesQuery } from "data/api/premises";
import { objectToQueryString } from "utils/objectToQuery";
import RecordForShow from "components/BookingAndVisits/RecordForShowForm";
import {
  toggleBookingModal,
  toggleRecordForShowModal,
} from "store/ApartmentReducer";

interface propsType {
  isButton?: boolean;
  handleSelectItem?: (id: number) => void;
  item: Apartment;
}
const ApartmentCard = ({ handleSelectItem, item }: propsType) => {
  const {
    id,
    end_date,
    name,
    number,
    price,
    is_booked,
    photo,
    similiarapartmentcount,
  } = item;
  const filters = useSelector((state: RootState) => state.apartment);
  const { refetch } = useGetPremisesQuery<{
    data: { data: Apartment[] };
    isLoading: boolean;
  }>(objectToQueryString(filters.filters));
  const navigate = useNavigate();

  const selection = useSelector((state: RootState) => state.apartment);
  const dispatch = useDispatch();

  const itemExists = (id: number) => {
    return selection.storedData.includes(id);
  };

  const handleNavigateToDeatails = () => {
    navigate(`/apartments/card/${item.id}`);
  };

  const handleToggleRecordForShowModal = () => {
    dispatch(toggleRecordForShowModal(item.id));
  };

  const handleToggleBookingForm = () => {
    dispatch(toggleBookingModal(item.id));
  };

  const hanldeSelectItem = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    handleSelectItem?.(item.id);
  };

  return (
    <div className="bg-white-default rounded-[16px] sm:rounded-lg text-accent-default min-w-[295px] 2xl:max-w-[320px] flex-1 cursor-pointer hover:shadow-lg overflow-hidden">
      <div
        onClick={handleNavigateToDeatails}
        className="p-[20px] flex flex-col gap-[18px]"
      >
        <div className="flex justify-between items-start">
          <div>
            <h3 className="text-[16px] font-medium leading-[18.65px] mb-[5px]">
              {name}
            </h3>
            <p className="text-[12px] font-medium leading-[16px]">
              {useFormatPrice(price)}
            </p>
          </div>
          {selection.generateSelection && (
            <RadioButton
              onChange={hanldeSelectItem}
              checked={itemExists(item.id)}
            />
          )}
        </div>

        <div className="flex justify-center">
          <img
            crossOrigin="anonymous"
            src={process.env.REACT_APP_FILES_URL + "/" + photo}
            width={270}
            height={177}
            alt=""
          />
        </div>
        <div className="text-[12px] leading-[16px] font-medium">
          <p className="opacity-50">{name}</p>
          <p className="opacity-50">Дом {number}</p>
          <p className="opacity-50">
            Срок сдачи {moment(end_date).format("YYYY.MM.DD")}
          </p>
          {selection.generateSelection && (
            <p className="text-[12px] cursor-pointer mt-[10px] text-blue-default hover:text-blue-focus leading-[16px] font-medium ">
              Посмотреть
            </p>
          )}
        </div>

        <div
          className="text-[12px] w-[73px] h-[28px] font-normal 
       rounded-[20px] border border-light-blue-10 flex items-center justify-center"
        >
          Лоджия
        </div>

        <div className="flex justify-between flex-col sm:flex-row gap-[10px] mt-[10px]">
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleToggleRecordForShowModal();
            }}
            className="border border-accent-default mt-[1px] py-3 
              hover:bg-accent-default flex-1 flex justify-center text-[12px]
               hover:text-white-default rounded transition-all 
                duration-400
                "
          >
            Запись на показ
          </button>
          {!is_booked && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleToggleBookingForm();
              }}
              className="border border-accent-default mt-[1px] py-3 
            hover:bg-accent-default text-[12px] flex-1 flex justify-center
             hover:text-white-default rounded transition-all
                duration-400
                "
            >
              Забронировать
            </button>
          )}
        </div>
      </div>
      <hr className="opacity-10" />
      <span className="text-[12px] font-medium inline-block py-[8px] pl-[20px]">
        Таких квартир: {similiarapartmentcount}
      </span>
      <RecordForShow
        project_id={item.project_id}
        handleToggle={handleToggleRecordForShowModal}
        isOpenModal={selection.recordForShowModalById === item.id}
      />
      <BookingFrom
        isOpen={selection.recordBookingById === item.id}
        primise_id={id}
        handleToggle={handleToggleBookingForm}
        refetch={refetch}
      />
    </div>
  );
};

export default ApartmentCard;
