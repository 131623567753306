import React from "react";
import loaderImg from "../../assets/images/Loader.png";

const Loader = () => {
  return (
    <div
      className="flex justify-center items-center h-[150px] absolute left-[50%] 
    top-[50%] -translate-x-[50%] -translate-y-[50%] z-20"
    >
      <img src={loaderImg} alt="loading img" className="animate-spin-slow" />
    </div>
  );
};

export default Loader;
