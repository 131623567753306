import { Icons } from "./interface";

const MarkerIcon = (
	{
		width = 21,
		height = 20,
		color = '#37465B',
		strokeWidth = 1.5
	}: Icons
) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.2492 18C10.2492 18 16.51 12.4348 16.51 8.26087C16.51 4.80309 13.7069 2 10.2492 2C6.79137 2 3.98828 4.80309 3.98828 8.26087C3.98828 12.4348 10.2492 18 10.2492 18Z"
				stroke={color}
				strokeWidth={strokeWidth}
			/>
			<path
				d="M12.2494 8.00013C12.2494 9.1047 11.354 10.0001 10.2494 10.0001C9.14484 10.0001 8.24941 9.1047 8.24941 8.00013C8.24941 6.89556 9.14484 6.00013 10.2494 6.00013C11.354 6.00013 12.2494 6.89556 12.2494 8.00013Z"
				stroke={color}
				strokeWidth={strokeWidth}
			/>
		</svg>
	);
};

export default MarkerIcon;
