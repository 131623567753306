import React from "react";
import AnalyticsFilter from "./FilterAnalytics";
import BarChartComponent from "./charts/BarChart";
import { data, dealsInfoSteps, infoCartData, infoCartData1 } from "./data";
import AnaliticsInfoCart from "./Card/AnaliticsInfoCart";
import PieChartComponent from "./charts/PieChart";
import EventsAnaliticsCart from "./Card/EventsAnaliticsCart";

const Analitics = () => {
  return (
    <section id="analytics" className="min-h-[50vh]">
      <div className="br-container py-6 flex flex-col gap-6">
        <AnalyticsFilter />
        <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[10px] order-2">
          <BarChartComponent
            data={data.registeredAgentChart.data}
            title={data.registeredAgentChart.title}
          />
          <BarChartComponent
            data={data.activeAgentChart.data}
            title={data.activeAgentChart.title}
          />
          <BarChartComponent
            data={data.cityChart.data}
            title={data.cityChart.title}
          />
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-[10px] -order-1 lg:order-2">
          {infoCartData1.map((item) => (
            <AnaliticsInfoCart key={item.id} item={item} isrewardInfo={true} />
          ))}
          <div className="rounded-lg col-span-2 bg-white-default flex flex-col items-center sm:items-start sm:flex-row  sm:gap-[30px] p-[15px] ">
            <h3 className="text-accent-default block sm:hidden text-[16px] font-medium">
              Статус сделок
            </h3>
            <div>
              <PieChartComponent />
            </div>
            <div className="flex flex-col mt-[10px]  sm:mt-0 gap-[10px] ">
              <h3 className="text-accent-default hidden sm:block text-[16px] font-medium">
                Статус сделок
              </h3>
              <div className="flex gap-[10px] justify-center sm:justify-start flex-wrap">
                {dealsInfoSteps.map((item) => (
                  <div
                    key={item.id}
                    className="flex items-center justify-center gap-2"
                  >
                    <div
                      className={`w-[18px] h-[18px] rounded-full ${item.color} `}
                    ></div>
                    <p className=" text-sm text-accent-default">{item.label}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {infoCartData.map((item, i) => (
            <AnaliticsInfoCart key={item.id} item={item} />
          ))}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[10px] order-4">
          <EventsAnaliticsCart title="Топ новостей" />
          <EventsAnaliticsCart title="Топ обучения" />
          <EventsAnaliticsCart title="Топ мероприятий" />
        </div>
      </div> 
    </section>
  );
};

export default Analitics;
