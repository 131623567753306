import React from "react";

const LocationIcon = () => {
  return (
    <svg
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99953 15.1999C6.99953 15.1999 12.6343 10.1912 12.6343 6.43471C12.6343 3.3227 10.1115 0.799927 6.99953 0.799927C3.88752 0.799927 1.36475 3.3227 1.36475 6.43471C1.36475 10.1912 6.99953 15.1999 6.99953 15.1999Z"
        stroke="#37465B"
        strokeWidth="1.5"
      />
      <path
        d="M8.79976 6.20004C8.79976 7.19415 7.99387 8.00004 6.99976 8.00004C6.00565 8.00004 5.19976 7.19415 5.19976 6.20004C5.19976 5.20593 6.00565 4.40004 6.99976 4.40004C7.99387 4.40004 8.79976 5.20593 8.79976 6.20004Z"
        stroke="#37465B"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default LocationIcon;
