import React, { ChangeEvent, useMemo, useRef, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import ImageUploadIcon from "components/constant/icons/ImageUploadIcon";
import TextField from "components/Form/TextField/TextField";
import Dropdown from "components/Form/Dropdown/Dropdown";
import ButtonForm from "components/Button/ButtonForm";
import PencilIcon from "components/constant/icons/PencilIcon";
import Modal from "components/Modal";
import Loader from "components/Loader";
import Label from "components/Form/Label";
import PhoneIcon from "components/constant/icons/PhoneIcon";
import { UseSMSCodeSender } from "hooks/useSMSCodeSender";
import showToast from "utils/showToast";
import { images } from "utils/resource";

import { useGetCitiesQuery } from "data/api/city";
import { useFormatForDropDown } from "hooks/useDropDownOption";
import { useGetAllAgenciesQuery } from "data/api/agency";
import { useUploadFileMutation } from "data/api/file";
import {
  profileApiHooks,
  useGetMeQuery,
  useUpdateMeMutation,
  useUpdatePhoneMutation,
  useVerifyUserPhoneMutation,
} from "data/api/profile";

import { Agency } from "data/api/agency/type";
import { City } from "data/api/city/type";
import { mamberLocalName, roleOptions } from "enums/users";
import { userProfileFormDataType } from "./type";
import { ResponseStatusType } from "data/api/exception";
import { useRoles } from "hooks/useRoles";
import MaskComponent from "components/MaskInput/MaskComponent";

const Profile = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isEditable, setIsEditable] = useState(false);
  const [isSMSCodeVisible, setIsSMSCodeVisible] = useState(false);
  const [formStatus, setFormStatus] = useState(0);
  const navigate = useNavigate();

  const { data } = useGetMeQuery();
  const { data: agencies } = useGetAllAgenciesQuery();
  const [smsVerify, setSmsVerify] = useState({
    phone: data?.data.phone.slice(1),
    code: 0,
  });

  const { counter, handleResent, updateCounter } = UseSMSCodeSender({});

  const [mutate, { isLoading }] = useUpdateMeMutation();
  const [phoneMutate] = useUpdatePhoneMutation();
  const [phoneVerify] = useVerifyUserPhoneMutation();
  const [uploadFile] = useUploadFileMutation();
  const { data: cities } = useGetCitiesQuery();
  const dispatch = useDispatch();

  const roles = useRoles(null);

  const optionsCity = useFormatForDropDown<City>(cities?.data ?? []);
  const optionsAgencies = useFormatForDropDown<Agency>(
    agencies?.data ?? [],
    "legalName"
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<userProfileFormDataType>({
    values: {
      role: data?.data.role,
      firstName: data?.data.firstName,
      lastName: data?.data.lastName,
      email: data?.data.email,
      avatar: data?.data.avatar,
      city_id: data?.data.city?.id,
      agency_id: data?.data.agency?.id,
    },
  });

  async function handleVerifyPhone() {
    const res = await phoneVerify({
      data: { code: smsVerify.code },
    });
    if (res?.data?.meta?.type === ResponseStatusType.SUCCESS) {
      setIsSMSCodeVisible(false);
      return res;
    }
    if (res.error?.message) {
      showToast(res.error.message, "error", 2000);
      return;
    }
  }

  const onSubmit: SubmitHandler<userProfileFormDataType> = async (formData) => {
    if (isSMSCodeVisible) {
      const verifyRes = await handleVerifyPhone();
      if (verifyRes?.data?.meta?.type === ResponseStatusType.SUCCESS) {
        updateUserData(formData);
        setSmsVerify((prev) => ({ ...prev, code: 0 }));
        return;
      }
      if (verifyRes?.error?.message) {
        showToast(verifyRes?.error.message, "error", 2000);
        return;
      }
    } else {
      updateUserData(formData);
    }
  };

  async function updateUserData(formData: userProfileFormDataType) {
    const { data: res, error } = await mutate({ data: formData });
    if (res?.meta?.type === ResponseStatusType.SUCCESS) {
      setFormStatus(200);
      return;
    }
    if (error?.message) {
      showToast(error?.message, "error", 2000);
      return;
    }
  }

  const handleFileInputClick = () => {
    fileInputRef.current?.click();
  };

  const handleModalClose = () => {
    setFormStatus(0);
    setIsEditable(false);
    setIsSMSCodeVisible(false);
  };

  const handleEditClick = () => {
    setIsEditable(true);
  };

  const handleLogOut = () => {
    navigate("/sign-in");
    dispatch(profileApiHooks.util.resetApiState());
    localStorage.removeItem("token");
    localStorage.removeItem("phone");
    localStorage.removeItem("userId");
    localStorage.removeItem("old_token");
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("image", file);
      try {
        const response = await uploadFile(formData).unwrap();
        const fileName = response.data.filename;
        setValue("avatar", fileName);
      } catch (error) {
        console.error("File upload failed", error);
      }
    }
  };

  const isPhoneNumberSameWithUsers = useMemo(() => {
    return smsVerify.phone && smsVerify.phone === data?.data.phone;
  }, [smsVerify.phone, data?.data.phone]);

  const handlePhoneSubmit = async () => {
    if (smsVerify.phone && !isSMSCodeVisible)
      try {
        const { data: res, error } = await phoneMutate({
          data: { phone: smsVerify.phone },
        });

        if (res?.data.user_id) {
          setIsSMSCodeVisible(true);
          updateCounter();
          localStorage.setItem("phone", smsVerify.phone);
        }
        if (error?.message) {
          showToast(error.message, "error", 2000);
        }
      } catch (error) {
        console.log("err", error);
      }
  };
  const handleResentCode = () => {
    handleResent();
    setSmsVerify((prev) => ({ ...prev, code: 0 }));
  };
  const getValue = (
    opts: { label: string | number; value: string | number }[],
    val: number | string | undefined
  ) => {
    if (val) {
      return opts.filter((o) => o.value == val);
    }
    return opts?.[0];
  };

  const imgUrl = process.env.REACT_APP_FILES_URL;

  return (
    <div className="bg-bg-default">
      <div className="br-container py-8 flex justify-center">
        <div className="w-[440px] p-8 bg-white-default rounded-lg">
          <div className="flex flex-col items-center gap-4">
            <Controller
              name="avatar"
              control={control}
              render={({ field: { value } }) => (
                <div className="relative">
                  <img
                    src={value ? `${imgUrl}/${value}` : images.defaultImg}
                    className="w-[100px] h-[100px] rounded-full"
                    alt="profile"
                    crossOrigin="anonymous"
                  />
                  {isEditable && (
                    <div
                      onClick={handleFileInputClick}
                      className="w-[34px] h-[34px] flex items-center border border-boder-default cursor-pointer justify-center bg-white-default absolute right-0 bottom-0 rounded-lg"
                    >
                      <input
                        type="file"
                        className="hidden"
                        onChange={handleFileChange}
                        ref={fileInputRef}
                        disabled={!isEditable}
                      />

                      <ImageUploadIcon />
                    </div>
                  )}
                </div>
              )}
            />
            <div className="text-center">
              <h2 className="text-[24px] font-semibold leading-[28px]">
                {data?.data.fullName}
              </h2>
              <p className="text-[14px] font-medium leading-[18px] text-accent-disabled text-center mt-[2px]">
                {/* @ts-ignore */}
                {mamberLocalName[data?.data?.role]}
              </p>
              <p className="text-[14px] font-medium leading-[18px] text-red-default text-center mt-[10px]">
                Осталось бронирований:
                <span className="ml-1">
                  {data?.meta?.data?.remaining_user_creation_limit}
                </span>
              </p>
            </div>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-5 mt-10 relative"
          >
            {isLoading && <Loader />}
            <Controller
              name="firstName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  className={isEditable ? "" : "opacity-50"}
                  placeholder="Введите Ваше имя"
                  label="Имя"
                  disabled={!isEditable}
                  errors={errors}
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  className={isEditable ? "" : "opacity-50"}
                  placeholder="Ваша фамилия"
                  label="Фамилия"
                  disabled={!isEditable}
                  errors={errors}
                />
              )}
            />

            <Label
              label="Номер телефона"
              className={isEditable ? "" : "opacity-50"}
            >
              <MaskComponent
                name="phone"
                errors={errors}
                imgLeft={<PhoneIcon />}
                disabled={!isEditable}
                imgRight={
                  !isPhoneNumberSameWithUsers && (
                    <button
                      onClick={handlePhoneSubmit}
                      disabled={smsVerify.phone?.length !== 11}
                      type="button"
                      className={`text-blue-default ${
                        smsVerify.phone?.length !== 11 && "opacity-50"
                      } text-[12px] font-medium`}
                    >
                      Отправить код
                    </button>
                  )
                }
                value={data?.data.phone || ""}
                onChange={(value) =>
                  setSmsVerify((prev) => ({ ...prev, phone: value }))
                }
              />
            </Label>

            {/* <TextField
              className={isEditable ? "" : "opacity-50"}
              placeholder="(909)"
              label="Номер телефона"
              type="tel"
              countryCode="+7"
              maxLength={15}
              onChangeValue={(text) =>
                setSmsVerify((prev) => ({ ...prev, phone: text }))
              }
              value={formatPhoneNumber(
                smsVerify.phone ? smsVerify.phone : data?.data.phone.slice(1)
              )}
              imgLeft={<PhoneIcon />}
              imgRight={
                !isPhoneNumberSameWithUsers && (
                  <button
                    onClick={handlePhoneSubmit}
                    disabled={smsVerify.phone?.length !== 15}
                    type="button"
                    className={`text-blue-default ${
                      smsVerify.phone?.length !== 15 && "opacity-50"
                    } text-[12px] font-medium`}
                  >
                    Отправить код
                  </button>
                )
              }
              disabled={!isEditable}
              errors={errors}
            /> */}

            {isSMSCodeVisible && (
              <TextField
                value={smsVerify.code}
                placeholder="Введите код"
                label="Код из смс"
                type="text"
                onChangeValue={(value) =>
                  setSmsVerify((prev) => ({ ...prev, code: Number(value) }))
                }
                maxLength={6}
                imgRight={
                  <>
                    {counter === 0 ? (
                      <span
                        onClick={handleResentCode}
                        className="leading-[18px] text-blue-default text-[14px] flex items-center h-full justify-center cursor-pointer font-medium"
                      >
                        Отправить повторно
                      </span>
                    ) : (
                      <span className="text-[14px] flex items-center justify-center text-accent-default font-medium">
                        00:{counter >= 10 ? counter : `0${counter}`}
                      </span>
                    )}
                  </>
                }
                errors={errors}
              />
            )}
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  className={isEditable ? "" : "opacity-50"}
                  placeholder="Ваша почта"
                  label="Почта"
                  type="email"
                  disabled={!isEditable}
                  errors={errors}
                />
              )}
            />
            <Label label="Роль" className={isEditable ? "" : "opacity-50"}>
              <Controller
                name="role"
                control={control}
                render={({ field: { onChange } }) => (
                  <Dropdown
                    onChange={onChange}
                    options={roleOptions}
                    placeholder="Должность"
                    disabled
                    errors={errors}
                    name="role"
                    value={{
                      // @ts-ignore
                      label: mamberLocalName[data?.data?.role],
                      value: data?.data.role,
                    }}
                  />
                )}
              />
            </Label>
            <Label label="Ваш город" className={isEditable ? "" : "opacity-50"}>
              <Controller
                name="city_id"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    onChange={(e) => onChange(e.value)}
                    options={optionsCity}
                    placeholder="Город не выбран"
                    interactive={isEditable}
                    errors={errors}
                    name="city"
                    value={getValue(optionsCity, value)}
                  />
                )}
              />
            </Label>
            <Label
              label="Агенство недвижемости"
              className={isEditable ? "" : "opacity-50"}
            >
              <Controller
                name="agency_id"
                control={control}
                disabled={roles.isHead_of_agency}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    onChange={(e) => onChange(e.value)}
                    options={optionsAgencies}
                    placeholder="Агенство не выбран"
                    value={getValue(optionsAgencies, value)}
                    errors={errors}
                  />
                )}
              />
            </Label>
            {isEditable && (
              <ButtonForm
                type="submit"
                className={`py-[12px] w-full
             text-center flex justify-center mt-6
                bg-accent-default text-white-default
              `}
                text="Сохранить"
              />
            )}
          </form>
          {!isEditable && (
            <ButtonForm
              type="button"
              leftIcon={<PencilIcon />}
              onClick={handleEditClick}
              className={`border-[1px] border-accent-default py-[12px] w-full
            hover:bg-accent-default hover:text-white-default text-center flex  mt-[44px] justify-center  bg-white-default text-accent-default
            `}
              text={"Редактировать"}
            />
          )}
          <ButtonForm
            type="button"
            onClick={handleLogOut}
            className={`border-[1px] border-accent-default py-[12px] w-full
            hover:bg-accent-default hover:text-white-default text-center flex justify-center mt-[15px] bg-white-default text-accent-default
            `}
            text={"Выйти"}
          />
        </div>
      </div>
      <Modal
        isOpen={formStatus === 200}
        className="md:w-[419px] rounded-lg"
        containerClassName="items-center justify-center"
      >
        <h2 className="text-[25px] leading-[30px] text-center text-accent-default font-extrabold mb-[10px] ">
          Успешно
        </h2>
        <p className="mb-[20px] text-[14px] leading-[18px] text-center text-accent-default font-normal">
          Ваши персональные данные обновляются
        </p>
        <ButtonForm
          className="bg-accent-default py-[16px] w-full
             text-white-default text-center flex justify-center hover:bg-accent-focus "
          text={"Закрыть"}
          onClick={handleModalClose}
        />
      </Modal>
    </div>
  );
};

export default Profile;
