import ButtonForm from "../../components/Button/ButtonForm";
import CloseWithBorderIcon from "../../components/constant/icons/CloseWithBorderIcon";
import Label from "../../components/Form/Label";
import Dropdown from "../../components/Form/Dropdown/Dropdown";
import Modal from "../../components/Modal";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { typeItem } from "../BaseClients";

interface formData {
	client: typeItem;
	object: typeItem;
	room: typeItem;
}

interface MortgageModalProps {
	isOpen: boolean;
	setIsOpen: (value: boolean) => void;
	handleApply: (value: formData) => void;
}

const MortgageModal = (
	{
		isOpen,
		setIsOpen,
		handleApply
	}: MortgageModalProps
) => {

	const {
		handleSubmit,
		control,
		formState: { errors, isValid },
	} = useForm<formData>();


	return (
		<Modal
			isOpen={isOpen}
			className="mx-auto sm:w-[450px] lg:w-[500px] rounded-t-[30px] rounded-b-0 sm:rounded-[20px]"
		>
			<div className={"flex justify-between mb-[20px]"}>
				<h1 className={"font-extrabold text-[25px] font-dewi"}>
					Заявка на ипотеку
				</h1>

				<ButtonForm
					text={<CloseWithBorderIcon />}
					onClick={() => setIsOpen(false)}
				/>
			</div>
			<form className="flex flex-col gap-5" onSubmit={handleSubmit(handleApply)}>
				<Label label="Клиент">
					<Controller
						name="client"
						control={control}
						rules={{
							required: {
								value: true,
								message: "Клиент обязателен для заполнения",
							},
						}}
						render={({ field: { onChange, value } }) => (
							<Dropdown
								name="client"
								options={[]}
								onChange={onChange}
								placeholder="Укажите клиента"
								errors={errors}
							/>
						)}
					/>
				</Label>
				<Label label="Объект">
					<Controller
						name="object"
						control={control}
						rules={{
							required: {
								value: true,
								message: "Объект обязателен для заполнения",
							},
						}}
						render={({ field: { onChange, value } }) => (
							<Dropdown
								name="object"
								options={[]}
								onChange={onChange}
								placeholder="Выберите объект"
								errors={errors}
							/>
						)}
					/>
				</Label>
				<Label label="Помещение">
					<Controller
						name="room"
						control={control}
						rules={{
							required: {
								value: true,
								message: "Помещение обязателен для заполнения",
							},
						}}
						render={({ field: { onChange, value } }) => (
							<Dropdown
								name="room"
								options={[]}
								onChange={onChange}
								placeholder="Выберите помещение"
								errors={errors}
							/>
						)}
					/>
				</Label>
				<ButtonForm
					type={"submit"}
					className={
						" px-6 py-3 w-full mt-3 justify-center bg-accent-default text-white-default hover:bg-accent-disabled  hover:text-bg-default  hover:bg-bg-defau text-ellipsis whitespace-nowrap"
					}
					text="Отправить заявку"
				/>
			</form>
		</Modal>
	)
}

export default MortgageModal;