export enum EventsCategory {
  ALL = " all",
  PRESENTATION = "presentation",
  EXCURSION = "excursion",
  TRAINING = "training",
  TESTING = "testing",
}

export enum EventFormat {
  ONLINE = "online",
  OFFLINE = "offline",
}

export const eventsCategoryOptions = [
  { label: "Презентация", value: EventsCategory.PRESENTATION },
  { label: "Экскурсия", value: EventsCategory.EXCURSION },
  { label: "Обучение", value: EventsCategory.TRAINING },
  { label: "Тестирование", value: EventsCategory.TESTING },
];

export const eventFormatOptions = [
  { label: "Online", value: EventFormat.ONLINE },
  { label: "Offline", value: EventFormat.OFFLINE },
];
