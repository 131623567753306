export const debounce = (func: any, wait: number, immediate: boolean) => {
	let timeout: ReturnType<typeof setTimeout> | null = null;

	return (...rest: any) => {
		const context: any = this;


		const later = function () {
			timeout = null;
			if (!immediate) func.apply(context, rest);
		};

		const callNow = immediate && !timeout;

		if (timeout) {
			clearTimeout(timeout);
		}

		timeout = setTimeout(later, wait);

		if (callNow) func.apply(context, rest);
	};
};

export const withDebounce = debounce((action: any) => {
	action();
}, 1000, false);