import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Filters } from "./type";
import { Apartment } from "modules/Apartment/ApartmentList/dataTransformer";
import { Primise } from "enums/primisesType";

export interface SelectionState {
  type: Primise;
  chess: boolean;
  generateSelection: boolean;
  storedData: number[];
  count: { [key in Primise]: number };
  filters: Filters;
  filteredData: Apartment[];
  recordForShowModalById: number | null;
  recordBookingById: number | null;
}

const initialState: SelectionState = {
  type: Primise.APARTMENT,
  chess: false,
  generateSelection: false,
  storedData: [],
  recordForShowModalById: null,
  recordBookingById: null,
  filteredData: [],
  count: {
    [Primise.APARTMENT]: 0,
    [Primise.PARKING]: 0,
    [Primise.STOREROOM]: 0,
    [Primise.COMMERCIAL]: 0,
  },
  filters: {
    type: Primise.APARTMENT,
  },
};

const selectionSlice = createSlice({
  name: "apartment",
  initialState,
  reducers: {
    selectType: (state, action: PayloadAction<Primise>) => {
      state.type = action.payload;
    },
    chessAppearance: (state) => {
      if (state.generateSelection) {
        state.chess = false;
        return;
      }
      state.chess = !state.chess;
    },
    toggleGenerateSelection: (state) => {
      state.generateSelection = !state.generateSelection;
    },
    toggleItemInStore: (state, action: PayloadAction<{ id: number }>) => {
      const { id } = action.payload;
      const itemType = state.filters.type;

      const itemIndex = state.storedData.indexOf(id);

      if (itemIndex !== -1) {
        state.storedData.splice(itemIndex, 1);
        state.count[itemType] -= 1;
      } else {
        state.storedData.push(id);
        state.count[itemType] += 1;
      }
    },
    resetState: () => {
      return initialState;
    },
    resetFilter: (state) => {
      state.filters = initialState.filters;
    },
    handleDeteleteItemInStore: (
      state,
      action: PayloadAction<{ type: Primise; id: number }>
    ) => {
      const { type, id } = action.payload;
      const itemIndex = state.storedData.indexOf(id);
      if (itemIndex !== -1) {
        state.storedData.splice(itemIndex, 1);
        state.count[type] -= 1;
      }
    },
    handleFilterData: (state, action: PayloadAction<Filters>) => {
      state.filters = action.payload;
    },

    handleStoreFilteredData: (state, action: PayloadAction<Apartment[]>) => {
      state.filteredData = action.payload;
    },
    toggleRecordForShowModal: (state, action: PayloadAction<number | null>) => {
      const cardId = action.payload;
      state.recordForShowModalById =
        state.recordForShowModalById === cardId ? null : cardId;
    },
    toggleBookingModal: (state, action: PayloadAction<number | null>) => {
      const cardId = action.payload;
      state.recordBookingById =
        state.recordBookingById === cardId ? null : cardId;
    },
  },
});

export const {
  selectType,
  chessAppearance,
  toggleGenerateSelection,
  toggleItemInStore,
  resetState,
  handleDeteleteItemInStore,
  handleFilterData,
  handleStoreFilteredData,
  resetFilter,
  toggleRecordForShowModal,
  toggleBookingModal,
} = selectionSlice.actions;
export default selectionSlice.reducer;
