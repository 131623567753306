import React, { useEffect, useState } from "react";
import { images } from "../../utils/resource";
import Dropdown from "../../components/Form/Dropdown/Dropdown";
import PencilIcon from "../../components/constant/icons/PencilIcon";
import { useNavigate } from "react-router-dom";
import { NewsItemData } from "./index";
import { CloseIcon } from "yet-another-react-lightbox";
import NewsPageCard from "../../components/Cards/NewsPageCard";
import ButtonForm from "../../components/Button/ButtonForm";
import PlusIcon from "../../components/constant/icons/PlusIcon";
import NewsCreateEditModal from "./NewsCreateEditModal";
import NewsPreviewModal from "./NewsPreviewModal";
import NewsCategoryModal from "./NewsCategoryModal";
import { useWindowSize } from "../../hooks/useWindowSize";
import { getIsOpenCreateNews, handleOpenCreateNews } from "../../store/NewsReducer";
import { useDispatch, useSelector } from "react-redux";

const NewsEditPage = () => {
	const dispatch = useDispatch();
  const navigate = useNavigate();
	const isCreateNewsItem = useSelector(getIsOpenCreateNews);

	const { isMd } = useWindowSize();

  const defaultSelected: NewsItemData = {
    img: "",
    title: "",
    text: "",
    tags: [],
    views: 0,
    likes: 0,
    date: "",
    id: 1,
    key: "События Брусники",
  };

  const optionsCity = [
    {
      label: "Москва",
      value: "Москва",
    },
  ];

  const [activeCategory, setActiveCategory] = useState("Все");
  const [categoryList, setCategoryList] = useState([
    "События Брусники",
    "Риелторам",
    "Клиентам",
    "Мероприятия",
  ]);

  const [newsList, setNewsList] = useState<NewsItemData[]>([
    {
      id: 1,
      key: "События Брусники",
      img: images.newsImage,
      title: "Скидка на квартиры сотрудникам",
      text: "10 самых ожидаемых российских сериалов 2024 года",
      tags: ["Брусника", "Мероприятие"],
      city: "moscow",
      views: 1000,
      likes: 42,
      date: "12.12.2024",
      is_show: true,
      is_banner: true,
      is_draft: true,
    },
    {
      id: 2,
      key: "События Брусники",
      img: images.newsImage,
      title: "Скидка на квартиры сотрудникам",
      text: "10 самых ожидаемых российских сериалов 2024 года",
      tags: ["Брусника", "Мероприятие"],
      city: "moscow",
      views: 1000,
      likes: 42,
      date: "12.12.2024",
      is_show: false,
      is_banner: false,
      is_draft: false,
    },
    {
      id: 3,
      key: "Риелторам",
      img: images.newsImage,
      title: "Скидка на квартиры сотрудникам",
      text: "10 самых ожидаемых российских сериалов 2024 года",
      tags: ["Брусника", "Мероприятие"],
      city: "moscow",
      views: 1000,
      likes: 42,
      date: "12.12.2024",
      is_show: false,
      is_banner: false,
      is_draft: false,
    },
  ]);
  const [filterList, setFilterList] = useState<NewsItemData[]>([]);

  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false);
  const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false);

  const [selected, setSelected] = useState<NewsItemData | null>(null);

  const [selectCity, setSelectCity] = useState({
    label: "Москва",
    value: "Москва",
  });

  const editBlock = (item: NewsItemData) => {
    return {
      toggle: {
        isToggle: item.is_show,
        setIsToggle: (value: boolean) => handleToggleCard(item, value),
      },
      editNews: () => {
        setSelected(item);
				dispatch(handleOpenCreateNews(true));
      },
      deleteNews: () => {
        const updateFilterList = filterList.filter((i) => i.id !== item.id);

        setFilterList(updateFilterList);
      },
    };
  };

  useEffect(() => {
    if (newsList) {
      const updateList = [...newsList].map((item) => ({
        ...item,
        isToggle: item.is_show || false,
      }));

      newsList && setFilterList(updateList);
    }
  }, [newsList]);

  function handleCategory(item: string) {
    setActiveCategory(item);

    if (item === "Все") {
      setFilterList([...newsList]);
    } else {
      const updateList = [...newsList].filter((i) => item === i.key);

      setFilterList(updateList);
    }
  }

  function handleToggleCard(item: any, value: boolean) {
    const updateList = [...newsList].map((i) => {
      if (i.key === item.key) {
        return {
          ...i,
          is_show: value,
        };
      }

      return i;
    });

    setNewsList(updateList);
  }

  return (
    <>
      <div className={"bg-bg-default"}>
        <div className="br-container py-[20px]">
          <div className={"hidden lg:flex items-center justify-between mb-[15px]"}>
            <h1 className={"text-[25px] font-dewi font-extrabold"}>
              Редактор новостей
            </h1>

            <ButtonForm
              leftIcon={<PlusIcon />}
              text={"Создать"}
              onClick={() => dispatch(handleOpenCreateNews(true))}
              className={
                "p-3 bg-accent-default rounded text-white-default max-w-[259px] w-full flex justify-center gap-[12px!important]"
              }
            />
          </div>

          <div className={"lg:mt-[18px]"}>
            <div className={"flex justify-between items-center"}>
              <div className={"hidden lg:flex"}>
                <Dropdown
                  placeholder={"Город"}
                  value={selectCity}
                  options={optionsCity}
                  className={"w-full select-bg-transparent"}
                  onChange={setSelectCity}
                />
              </div>

              <div className={"flex items-center gap-[10px] flex-nowrap whitespace-nowrap overflow-auto"}>
                <ButtonForm
                  text={<PencilIcon color={"#FFFFFF"} size={isMd ? 18 : 24}/>}
                  onClick={() => setIsOpenCategoryModal(true)}
                  className={"md:p-[8px!important] p-[6px!important] bg-accent-default rounded-full"}
                />

                {["Все", ...categoryList].map((item, idx) => (
                  <div
                    key={`category-item-${idx}`}
                    onClick={() => handleCategory(item)}
                    className={`
										md:py-[11px] py-[7px] 
										md:px-[24px] px-[15px] 
										rounded-full text-sm font-normal 
										md:leading-[18px] leading-[16px] 
										cursor-pointer
										border md:border-white-default border-[#D7DCE4]
										
										${
                      activeCategory === item
                        ? "bg-accent-default text-white-default"
                        : "bg-white-default"
                    }
										`}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>

            <div
              className={
                "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[15px] md:mt-[20px] mt-[15px] xl:mt-[30px]"
              }
            >
              {(filterList.length &&
                filterList.map((item, index) => (
                  <NewsPageCard
                    key={`news-block-item-${index}`}
                    imageSrc={item.img || ""}
                    title={item.title}
                    watched={item.views}
                    likes={item.likes}
                    date={item.date}
                    category="Категория"
                    onClick={(event) => {
                      if (["button", "checkbox"].includes(event.target.type))
                        return;

                      navigate(`/news/view/${item.id}`);
                    }}
                    edit={editBlock(item)}
                  />
                ))) ||
                null}
            </div>
          </div>

          <div className="hidden fixed right-[30px] bottom-[30px] lg:flex items-center justify-center">
            <div
              className={
                "rounded-full bg-red-default flex items-center justify-center p-3 cursor-pointer"
              }
              onClick={() => navigate("/news")}
            >
              <CloseIcon color={"#FFFFFF"} />
            </div>
          </div>
        </div>
      </div>

      <NewsCreateEditModal
        isOpen={isCreateNewsItem}
        selected={selected}
        setIsOpen={(isDelete) => {
          isDelete && setSelected(null);
					dispatch(handleOpenCreateNews(false))
        }}
        setSelect={(value) => {
          setSelected(value);
          setIsOpenPreviewModal(!isOpenPreviewModal);
        }}
      />

      <NewsPreviewModal
        isOpen={isOpenPreviewModal}
        selected={selected}
        setIsOpen={(value) => {
          setIsOpenPreviewModal(false);

          value ? setSelected(defaultSelected) : dispatch(handleOpenCreateNews(true));
        }}
        setSelect={setSelected}
      />

      <NewsCategoryModal
        isOpen={isOpenCategoryModal}
        setIsOpen={() => setIsOpenCategoryModal(false)}
        propsList={categoryList}
      />
    </>
  );
};

export default NewsEditPage;
