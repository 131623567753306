import React from "react";
import { Link } from "react-router-dom";

import Logo from "../constant/icons/Logo";

type propsType = {
  children: React.ReactNode;
  isRegisterPage?: boolean;
};

const AuthPageWraper = ({ children, isRegisterPage }: propsType) => {
  return (
    <div className="bg-login-bg bg-center bg-no-repeat bg-cover min-h-[100vh] h-[100%] flex items-center justify-between flex-col pb-[10px]">
      <div className=" -translate-y-[50px] mt-[19vh] flex justify-center items-center flex-col gap-[120px]">
        <Logo />
        <div className="p-5  rounded-2xl sm:p-[30px] sm:rounded-[8px] w-[360px]  extrasm:w-[390px] sm:w-[419px]  bg-white-default">
          {children}
          {!isRegisterPage && (
            <div className="text-center text-[14px] text-accent-disabled leading-[18px] mt-[20px] ">
              У вас нету аккаунта?
              <Link to="/sign-up">
                <span className="text-blue-default hover:text-blue-focus ml-[4px] cursor-pointer">
                  Зарегистрироваться
                </span>
              </Link>
            </div>
          )}
        </div>
      </div>

      <span className=" text-white-default text-[14px] mt-[100px] leading-[16.41px]">
        Все права защищены
      </span>
    </div>
  );
};

export default AuthPageWraper;
