import React from "react";
import { Link } from "react-router-dom";

interface propsType {
  children: React.ReactNode;
  label: string | React.ReactNode;
  className?: string;
  labelLink?: { to: string; label: string };
}

const Label = ({ children, label, className, labelLink }: propsType) => {
  return (
    <div className={`flex flex-col gap-[6px] ${className}`}>
      <label
        htmlFor=""
        className="text-[14px] flex gap-[15px] font-medium items-center text-accent-default leading-[16.41px]"
      >
        <span>{label}</span>
        {labelLink?.to && (
          <Link
            className=" text-sm text-blue-default font-normal"
            to={labelLink?.to}
          >
            {labelLink?.label}
          </Link>
        )}
      </label>
      {children}
    </div>
  );
};

export default Label;
