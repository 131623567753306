import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";

export interface State {
  city_id: number;
  recordForShowModal: boolean;
  isSearchModalOpen: boolean;
  isNotificationOpen: boolean;
  isCheckingTheFasteningOpenModal: boolean;
  isFasteningFormOpenModal: boolean;
}

const initialState: State = {
  city_id: 0,
  recordForShowModal: false,
  isSearchModalOpen: false,
  isNotificationOpen: false,
  isCheckingTheFasteningOpenModal: false,
  isFasteningFormOpenModal: false,
};

const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    handleSelectCity: (state, action: PayloadAction<number>) => {
      state.city_id = action.payload;
    },
    toggleRecordForShowModal: (state) => {
      state.recordForShowModal = !state.recordForShowModal;
    },
    toggleSearchModal: (state) => {
      state.isSearchModalOpen = !state.isSearchModalOpen;
    },
    toggleNotificationModal: (state, action: PayloadAction<boolean>) => {
      state.isNotificationOpen = action.payload;
    },
    toggleCheckingFasteningModal: (state, action: PayloadAction<boolean>) => {
      state.isCheckingTheFasteningOpenModal = action.payload;
    },
    toggleFasteningFormModal: (state, action: PayloadAction<boolean>) => {
      state.isFasteningFormOpenModal = action.payload;
    },
  },
});

export const {
  handleSelectCity,
  toggleRecordForShowModal,
  toggleSearchModal,
  toggleNotificationModal,
  toggleCheckingFasteningModal,
  toggleFasteningFormModal,
} = headerSlice.actions;

export const getSelectionCity = (state: RootState) => state.header;

export default headerSlice.reducer;
