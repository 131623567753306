import React, { useEffect, useState } from "react";
import ApartmentCard from "./ApartmentCard";
import PageTitle from "components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { toggleItemInStore } from "store/ApartmentReducer";
import ChessList from "./ChessList";
import { RootState } from "store";
import {
  Apartment,
  groupByProjectName,
  transformData,
} from "./dataTransformer";
import Loader from "components/Loader";
import { motion } from "framer-motion";
import AnimationFrame from "components/AnimationFrame.tsx";

interface porpsType {
  data: Apartment[];
  isLoading: boolean;
}

const ApartmentList: React.FC<porpsType> = ({ data, isLoading }) => {
  const selection = useSelector((state: RootState) => state.apartment);
  const [activeSection, setActiveSection] = useState<string>("");
  const [sectionSelect, setSectionSelect] = useState<Apartment[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const transformedData = transformData(data, "name");
    const firstSection = Object.keys(transformedData)?.[0];
    if (firstSection) {
      setActiveSection(firstSection);
      setSectionSelect(transformedData[firstSection]);
    }
  }, [data]);

  const handleSelectItem = (id: number) => {
    dispatch(toggleItemInStore({ id }));
  };

  const handleSectionClick = (sectionName: string, apartments: Apartment[]) => {
    setActiveSection(sectionName);
    setSectionSelect(apartments);
  };

  return (
    <div className="py-[20px] overflow-x-hidden relative min-h-[50vh]">
      {isLoading && <Loader />}
      {selection.chess ? (
        <>
          <PageTitle text="Зюзино, Дом 1" />
          <div className="flex gap-[20px] mt-[20px] mb-[15px]">
            {data ? (
              Object.entries(transformData(data, "name") || {})?.map(
                ([sectionName, apartments]) => (
                  <li
                    onClick={() => handleSectionClick(sectionName, apartments)}
                    key={sectionName}
                    className={`text-accent-default cursor-pointer list-none underline font-medium text-[16px] ${
                      activeSection === sectionName ? "text-blue-default" : ""
                    }`}
                  >
                    {sectionName}
                  </li>
                )
              )
            ) : (
              <h2 className="text-center text-accent-default">
                Ничего не найдено
              </h2>
            )}
          </div>

          <ChessList data={sectionSelect} />
          <div className="absolute w-[120px] right-0 bottom-[30px] top-0 opacity-70 bg-custom-gradient"></div>
        </>
      ) : (
        <>
          {data?.length
            ? Object.entries(groupByProjectName(data) || {})?.map(
                ([projectName, apartments]) => (
                  <div key={projectName}>
                    <PageTitle text={projectName} />
                    <AnimationFrame>
                      <div className=" mt-[20px] mb-[30px] grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 gap-5">
                        {apartments?.map((item: Apartment) => (
                          <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{ duration: 1 }}
                          >
                            <ApartmentCard
                              handleSelectItem={handleSelectItem}
                              item={item}
                              key={item.id}
                            />
                          </motion.div>
                        ))}
                      </div>
                    </AnimationFrame>
                  </div>
                )
              )
            : !isLoading && (
                <h2 className="text-center text-accent-default">
                  Ничего не найдено
                </h2>
              )}
        </>
      )}
    </div>
  );
};

export default ApartmentList;
