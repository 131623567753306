import { api } from "../data/api";

class MyUploadAdapter {
	private loader: any;

	constructor(loader: any) {
		this.loader = loader;
	}

	upload() {
		return this.loader.file
			.then((file: File) => new Promise(async(resolve, reject) => {

				const formData = new FormData();
				formData.append("image", file);
				try {
					this.loader.uploaded = true;

					const result = await api.fetch({
						path: "/api/file-upload/image",
						method: "POST",
						body: formData,
					});

					if (result) {
						resolve({
							default: result // need url image
						});
					} else {
						reject(`не удалось загрузить файл: ${ file.name }.`)
					}
				} catch (error) {
					console.error("не удалось загрузить файл:", error);
				}

			}));
	}
}

export default function MyCustomUploadAdapterPlugin(editor: any) {
	editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
		return new MyUploadAdapter(loader);
	};
}
