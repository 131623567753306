import React from "react";
import {Icons} from "./interface";

const Calendar = (
    {
        width = 25,
        height = 24,
        color = '#3583FF'
    }: Icons
) => {
  return (
      <svg
          width={width}
          height={height}
          viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"
      >
          <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill={color}
              d="M7.40211 2C7.81633 2 8.15211 2.33579 8.15211 2.75V3.63928H17.4795V2.75C17.4795 2.33579 17.8153 2 18.2295 2C18.6437 2 18.9795 2.33579 18.9795 2.75V3.68118C20.7837 3.95258 22.167 5.50939 22.167 7.38928L22.167 18.875C22.167 20.9461 20.4881 22.625 18.417 22.625H7.41699C5.34592 22.625 3.66699 20.9461 3.66699 18.875V7.38928C3.66699 5.58032 4.94785 4.07052 6.65211 3.71734V2.75C6.65211 2.33579 6.9879 2 7.40211 2ZM7.39209 5.13941C6.16092 5.15276 5.16699 6.15495 5.16699 7.38928V18.875C5.16699 20.1176 6.17435 21.125 7.41699 21.125H18.417C19.6596 21.125 20.667 20.1176 20.667 18.875L20.667 7.38928C20.667 6.14664 19.6596 5.13928 18.417 5.13928H7.41963C7.4138 5.13941 7.40797 5.13948 7.40211 5.13948C7.39877 5.13948 7.39543 5.13946 7.39209 5.13941ZM7.30985 8.21426C7.30985 7.80004 7.64564 7.46426 8.05985 7.46426H17.6982C18.1125 7.46426 18.4482 7.80004 18.4482 8.21426C18.4482 8.62847 18.1125 8.96426 17.6982 8.96426H8.05985C7.64564 8.96426 7.30985 8.62847 7.30985 8.21426Z"
          />
      </svg>
  );
};

export default Calendar;
