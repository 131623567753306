import React, { Dispatch, SetStateAction } from "react";
import DOMPurify from "dompurify";
import ButtonForm from "components/Button/ButtonForm";
import CloseWithBorderIcon from "components/constant/icons/CloseWithBorderIcon";
import Modal from "components/Modal";
import { TrainingType } from "data/api/training/type";

interface Props {
  isAnswerModalOpen: boolean;
  setIsAnswerModalOpen: Dispatch<SetStateAction<boolean>>;
  selectedQuestion?: TrainingType;
}

const AnswerModal = ({
  isAnswerModalOpen,
  setIsAnswerModalOpen,
  selectedQuestion,
}: Props) => {
  return (
    <Modal
      isOpen={isAnswerModalOpen}
      className="mx-auto w-full  sm:w-[910px]   rounded-t-[30px] rounded-b-0 sm:rounded-[30px] relative"
      onClose={() => setIsAnswerModalOpen(false)}
    >
      <div className={"flex justify-between items-start  mb-3"}>
        <div className="flex-col gap-[5px]">
          <p className="hidden sm:block text-sm font-normal opacity-50">
            {selectedQuestion?.category?.name}
          </p>
          <h1 className={"font-extrabold text-[20px] font-dewi"}>
            {selectedQuestion?.title}
          </h1>
        </div>

        <ButtonForm
          text={<CloseWithBorderIcon />}
          onClick={() => setIsAnswerModalOpen(false)}
        />
      </div>
      {selectedQuestion?.content ? (
        <div
          className={` max-h-[80vh] overflow-auto toolbar-scroll pr-3 ${
            !selectedQuestion?.is_copy_enabled && "prevent-select"
          }`}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(selectedQuestion?.content),
          }}
        ></div>
      ) : null}
    </Modal>
  );
};

export default AnswerModal;
