import { Icons } from "./interface";

const CloseWithBorderIcon = (
	{
		size = 30,
		color = '#37465B',
	}: Icons
) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15 4.6875C9.30456 4.6875 4.6875 9.30456 4.6875 15C4.6875 20.6954 9.30456 25.3125 15 25.3125C20.6954 25.3125 25.3125 20.6954 25.3125 15C25.3125 9.30456 20.6954 4.6875 15 4.6875ZM2.8125 15C2.8125 8.26903 8.26903 2.8125 15 2.8125C21.731 2.8125 27.1875 8.26903 27.1875 15C27.1875 21.731 21.731 27.1875 15 27.1875C8.26903 27.1875 2.8125 21.731 2.8125 15ZM10.3596 10.3596C10.7257 9.99349 11.3193 9.99349 11.6854 10.3596L15 13.6742L18.3146 10.3596C18.6807 9.9935 19.2743 9.9935 19.6404 10.3596C20.0065 10.7257 20.0065 11.3193 19.6404 11.6854L16.3258 15L19.6404 18.3146C20.0065 18.6807 20.0065 19.2743 19.6404 19.6404C19.2743 20.0065 18.6807 20.0065 18.3146 19.6404L15 16.3258L11.6854 19.6404C11.3193 20.0065 10.7257 20.0065 10.3596 19.6404C9.99349 19.2743 9.99349 18.6807 10.3596 18.3146L13.6742 15L10.3596 11.6854C9.99349 11.3193 9.99349 10.7257 10.3596 10.3596Z"
				fill={color}
			/>
		</svg>
	);
};

export default CloseWithBorderIcon;
