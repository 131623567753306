import React from "react";

const LikedIcon = () => {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.80772 2.20635C3.70785 1.30649 4.92853 0.800975 6.20132 0.800975C7.47411 0.800975 8.69478 1.30649 9.59492 2.20635L11.0013 3.61155L12.4077 2.20635C12.8505 1.7479 13.3802 1.38223 13.9658 1.13066C14.5514 0.8791 15.1812 0.746686 15.8186 0.741148C16.4559 0.73561 17.088 0.857058 17.6779 1.09841C18.2678 1.33975 18.8037 1.69617 19.2544 2.14685C19.7051 2.59754 20.0615 3.13347 20.3029 3.72337C20.5442 4.31327 20.6657 4.94533 20.6601 5.58268C20.6546 6.22002 20.5222 6.84987 20.2706 7.43549C20.019 8.02111 19.6534 8.55077 19.1949 8.99355L11.0013 17.1883L2.80772 8.99355C1.90786 8.09342 1.40234 6.87274 1.40234 5.59995C1.40234 4.32716 1.90786 3.10648 2.80772 2.20635Z"
        fill="#37465B"
        stroke="#37465B"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LikedIcon;
