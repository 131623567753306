import { UserRole } from "enums/users";

export enum UserStatus {
  ACTIVE = "active",
  BLOCKED = "blocked",
}

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  fullName: string;
  avatar: string;
  role: UserRole;
  status: UserStatus;
  city: {
    name: string;
    id: number;
  };
  agency: {
    legalName: string;
    id: number;
  };
  created_at: string;
  is_blocked: boolean;
  is_verified: boolean;
}

export interface ProfileRequestType {
  id?: number;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  avatar?: string;
  city_id?: number;
  role?: UserRole;
  is_blocked?: boolean;
  status?: UserStatus;
  is_verified?: boolean;
}

export interface SettingRequestType {
  booking_limit: number;
  training_show_date_limit: number;
}

export interface CommentsType {
  id: number;
  text: string;
}

export interface UsersMeta {
  new_user_count: number;
  total_user_count: number;
}

export interface UserMeta {
  user_created_count: number;
  max_user_creation_limit: number;
  remaining_user_creation_limit: number;
}
