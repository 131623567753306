import React from "react";
import {Icons} from "../interface";

const WarningIcon = (
    {
        width = 24,
        height = 24,
        color = '#C93B29',
        strokeWidth = 1.5
    }: Icons
) => {
  return (
      <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path
              d="M12 8.99996V13M20.043 21H3.95695C2.41895 21 1.45695 19.336 2.22295 18.003L10.266 4.01496C11.036 2.67796 12.965 2.67796 13.734 4.01496L21.777 18.003C22.543 19.336 21.58 21 20.043 21Z"
              stroke={color}
              strokeWidth={strokeWidth}
              strokeLinecap="round"
          />
          <path
              d="M12 17.0122L12.012 16.999"
              stroke={color}
              strokeWidth={strokeWidth}
              strokeLinecap="round"
              strokeLinejoin="round"
          />
      </svg>
  );
};

export default WarningIcon;
