import BreadCrump from "components/BreadCrump";
import BellIcon from "components/constant/icons/BellIcon";

const notificationData = [
  { title: "Окончание закрепления", sender: "ЖК Ломоносова", time: "10 сек" },
  { title: "Изменение закрепления", sender: "ЖК Бодкина", time: "15 ч." },
  { title: "Изменения по сделке", sender: "ЖК Додова", time: "21.02" },
  { title: "Новый проект", sender: "ЖК Додова", time: "10.02" },
  { title: "Новый проект", sender: "ЖК Додова", time: "10.02" },
  { title: "Новый проект", sender: "ЖК Додова", time: "10.02" },
  { title: "Новый проект", sender: "ЖК Додова", time: "10.02" },
  { title: "Новый проект", sender: "ЖК Додова", time: "10.02" },
  { title: "Новый проект", sender: "ЖК Додова", time: "10.02" },
  { title: "Новый проект", sender: "ЖК Додова", time: "10.02" },
];

const Notifications = () => {
  return (
    <div className=" p-[15px] xl:p-[20px] rounded-[8px] bg-white-default">
      <BreadCrump
        title="Уведомления"
        className="text-[16px] font-medium"
        link=""
        leftIcon={<BellIcon color="#3583FF" />}
      />

      <div className="flex flex-col gap-[10px] mt-[25px] ">
        {notificationData.map((item, i) => (
          <div key={i}>
            <div className="flex items-center justify-between">
              <div className="flex flex-col justify-between gap-[5px] text-accent-default">
                <h3 className="text-[13px] xl:text-[14px] leading-[18px] m-0 p-0 font-medium">
                  {item.title}
                </h3>
                <p className=" text-[11px] xl:text-[12px] m-0 p-0 leading-[16px] font-medium">
                  {item.sender}
                </p>
              </div>
              <span className=" text-[11px] xl:text-[12px] leading-[16px] font-medium opacity-50">
                {item.time}
              </span>
            </div>
            {!(notificationData.length === i + 1) && (
              <hr className="mt-[5px] border-[1px] text-boder-default" />
            )}
          </div>
        ))}
      </div>
      <button
        className="text-[13px] xl:text-[14px] leading-[24px] border-none 
      outline-none bg-white-default text-blue-default w-full mt-[25px]"
      >
        Еще
      </button>
    </div>
  );
};

export default Notifications;
