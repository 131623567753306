import React, { ChangeEvent, useState } from "react";

import FileUploadIcon from "../../constant/icons/FileUploadIcon";
import FileUploadedIcon from "../../constant/icons/FileUploadedIcon";
import CancelIcon from "../../constant/icons/CancelIcon";
import { FieldErrors, UseFormSetError, UseFormSetValue } from "react-hook-form";
import {
  emplFormDataType,
  userAgreementDataType,
} from "../../../modules/Auth/types";
import { useUploadFileMutation } from "data/api/file";
import { ApiException } from "data/api/exception";

type propsType = {
  label: string;
  subLabel?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  name: string;
  setValue?: UseFormSetValue<userAgreementDataType>;
  errors?: FieldErrors<emplFormDataType>;
  setError: UseFormSetError<userAgreementDataType>;
};

const FileInput = ({
  label,
  subLabel,
  name,
  value,
  setValue,
  errors,
  setError,
}: propsType) => {
  const handleClearFile = () => {
    setValue?.(name as any, null);
  };
  const [uploadFile] = useUploadFileMutation();

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("image", file);
      try {
        const response = await uploadFile(formData).unwrap();
        const fileName = response.data.filename;
        setValue?.(name as any, fileName);
      } catch (err) {
        const error = err as ApiException;
        setError(name as any, {
          type: "manual",
          message: "Пожалуйста, введите только форматы jpg, pdf и docs.",
        });
      }
    }
  };

  return (
    <div className="flex flex-col items-start mb-[10px]">
      <div className="flex flex-col mb-[7px] ">
        <span className="text-[14px] font-medium leading-[16.41px]">
          {label}
        </span>
        <span className=" text-accent-disabled text-[12px] leading-[16px]">
          {subLabel}
        </span>
      </div>
      <div
        className=" flex flex-col items-start 
      w-full 
      border py-[12px] 
      rounded-[4px] border-red-default"
      >
        <input
          type="file"
          id={name}
          className="hidden h-full"
          onChange={handleFileChange}
          placeholder="loader"
          name={name}
        />
        <label
          htmlFor={name}
          className=" flex gap-[15px] bg-blue-500 m-auto text-white 
          rounded cursor-pointer hover:bg-blue-600 
          transition-colors duration-300 w-full items-center justify-center text-red-default text-[14px] font-medium"
        >
          <FileUploadIcon />
          Загрузить
        </label>
      </div>
      {value && (
        <div className="flex items-center justify-between w-full mt-[10px] ">
          <div className="flex items-center gap-[10px]">
            <FileUploadedIcon />
            {value && (
              <p className=" text-[14px] text-accent-default truncate max-w-[270px]">
                {value}
              </p>
            )}
          </div>
          <button onClick={handleClearFile}>
            <CancelIcon />
          </button>
        </div>
      )}
      <div className="text-[12px] leading-[14.06px] mt-[5px] flex flex-col">
        {errors?.[name as keyof emplFormDataType] && !value && (
          <p className="text-red-default mb-[5px]">
            {errors?.[name as keyof emplFormDataType]?.message}
          </p>
        )}
      </div>
    </div>
  );
};

export default FileInput;
