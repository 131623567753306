import React, { useState } from "react";

import AuthPageWraper from "../../../components/AuthPageWraper";
import { AgentForm } from "./AgentForm";
import { EmployeeForm } from "./EmployeeForm";
import SelectionToggle from "../../../components/SelectionToggle";
import ButtonForm from "components/Button/ButtonForm";
import { LoginRoles } from "enums/authEnums";

const Login = () => {
  // @TODO change SelectionToggle and remove loginProcess state make loginRole nullable
  // it helps to use one useState instead of two
  const [loginRole, setLoginRole] = useState(LoginRoles.AGENT);
  const [loginProcess, setLoginProcess] = useState("role");

  const handleLoginRoleChange = (role: LoginRoles) => {
    setLoginRole(role);
  };
  const handleLoginProcessChange = () => {
    setLoginProcess("role");
  };

  return (
    <AuthPageWraper>
      <h3 className="text-center text-accent-default font-extrabold text-[25px] font-dewi m-0 mb-[15px]">
        Авторизация
      </h3>
      {loginProcess === "role" ? (
        <SelectionToggle
          hasButton={true}
          leftLabel="Агент"
          rightLabel="Сотрудник"
          options={{ LEFT: LoginRoles.AGENT, RIGHT: LoginRoles.EMPLOYEE }}
          selectedOption={loginRole}
          onSubmit={setLoginProcess}
          handleChange={handleLoginRoleChange}
          buttonText="Войти"
          title="Введите свою роль"
        />
      ) : (
        <LoginForm goBack={handleLoginProcessChange} loginRole={loginRole} />
      )}
    </AuthPageWraper>
  );
};

function LoginForm({
  loginRole,
  goBack,
}: {
  loginRole: LoginRoles;
  goBack: () => void;
}) {
  return (
    <>
      {loginRole === LoginRoles.AGENT ? <AgentForm /> : <EmployeeForm />}
      <div className="flex flex-col gap-[10px] mt-[10px]">
        <ButtonForm
          className="py-[12px] w-full
              text-accent-default text-center flex justify-center bg-bg-default
               hover:bg-accent-default
               hover:text-white-default"
          text="Изменить роль"
          onClick={goBack}
        />
      </div>
    </>
  );
}

export default Login;
