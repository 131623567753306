import { Primise } from "enums/primisesType";

export const header = [
  {
    key: "floor",
    title: "Этаж",
    sortable: true,
  },
  {
    key: "section",
    title: "Секция",
    sortable: true,
  },
  {
    key: "number",
    title: "Номер",
  },
  {
    key: "building",
    title: "Дом",
    sortable: true,
  },
  {
    key: "price",
    title: "Стоимость",
    sortable: true,
  },
  {
    key: "size",
    title: "Площадь",
    sortable: true,
  },
  {
    key: "status",
    title: "Статус",
    sortable: true,
  },
];

export const rangeData = [
  {
    label: "Площадь",
    min: 5,
    max: 150,
    step: 1,
    filterKeys: ["min_size", "max_size"],
  },
  {
    label: "Стоимость",
    min: 0,
    max: 43000000,
    step: 1,
    filterKeys: ["min_price", "max_price"],
  },
  {
    label: "Этаж",
    min: 1,
    max: 10,
    step: 1,
    filterKeys: ["min_floor", "max_floor"],
  },
];

export const primisesTypeData = [
  { label: "Квартиры", value: Primise.APARTMENT },
  { label: "Паркинги", value: Primise.PARKING },
  { label: "Кладовые", value: Primise.STOREROOM },
  { label: "Коммерческий", value: Primise.COMMERCIAL },
];
