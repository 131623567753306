export function truncateHtmlWords(htmlString: string, wordLimit: number) {
  // Parse the HTML string into a DOM structure
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  let wordCount = 0;

  function traverse(node: HTMLElement) {
    if (node.nodeType === Node.TEXT_NODE && node.textContent) {
      const words = node.textContent.split(/\s+/);
      if (wordCount + words.length > wordLimit) {
        node.textContent =
          words.slice(0, wordLimit - wordCount).join(" ") + "...";
        wordCount = wordLimit;
      } else {
        wordCount += words.length;
      }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      for (let i = 0; i < node.childNodes.length; i++) {
        // @ts-ignore
        traverse(node.childNodes[i]);
        if (wordCount >= wordLimit) {
          while (i + 1 < node.childNodes.length) {
            node.removeChild(node.childNodes[i + 1]);
          }
          break;
        }
      }
    }
  }

  traverse(doc.body);
  console.log(doc.body.innerHTML);

  return doc.body.innerHTML;
}
