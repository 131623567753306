import React from "react";

const RublIcon = () => {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.04767 7.18414H0V5.90967H6.04767C6.63755 5.90967 7.12191 5.81496 7.50073 5.62555C7.87956 5.43614 8.15826 5.16825 8.33685 4.8219C8.52085 4.47555 8.61285 4.06696 8.61285 3.59613C8.61285 3.21189 8.52627 2.84389 8.35309 2.49213C8.18532 2.14036 7.91203 1.85083 7.5332 1.62354C7.15979 1.39624 6.66461 1.28259 6.04767 1.28259H3.23895V11.8193H1.68036V0H6.04767C6.94061 0 7.69285 0.154236 8.30438 0.462708C8.91591 0.765767 9.37862 1.18789 9.6925 1.72906C10.0118 2.26483 10.1714 2.88177 10.1714 3.5799C10.1714 4.33754 10.0118 4.98696 9.6925 5.52814C9.37862 6.0639 8.91591 6.4752 8.30438 6.76202C7.69285 7.04344 6.94061 7.18414 6.04767 7.18414ZM5.95026 8.29626V9.57886H0V8.29626H5.95026Z"
        fill="#3583FF"
      />
    </svg>
  );
};

export default RublIcon;
