import React from "react";
import { Icons } from "../interface";

const PlanLayoutGridIcon = ({
  width = 20,
  height = 20,
  color = "black",
}: Icons) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 2.5L10 17.5M2 10H17.5M2 5L2 15C2 16.6569 3.34315 18 5 18H15C16.6569 18 18 16.6569 18 15V5C18 3.34315 16.6569 2 15 2L5 2C3.34315 2 2 3.34315 2 5Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default PlanLayoutGridIcon;
