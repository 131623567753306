import React from "react";

const ThreeDotLoader: React.FC = () => {
  return (
    <div className="flex justify-center items-center space-x-4 w-full h-[80vh]">
      <div className="w-[10px] h-[10px] bg-accent-default rounded-full animate-scale-up"></div>
      <div className="w-[10px] h-[10px] bg-accent-default rounded-full animate-scale-up animation-delay-200"></div>
      <div className="w-[10px] h-[10px] bg-accent-default rounded-full animate-scale-up animation-delay-400"></div>
    </div>
  );
};

export default ThreeDotLoader;
