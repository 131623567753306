import React, { FC, ReactNode, useState } from "react";
import ArrowTableIcon from "../../constant/icons/Table/ArrowTableIcon";
import SpinnerIcon from "../../constant/icons/SpinnerIcon";
import RadioButton from "components/RadioSelectionButton";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { formatPhoneNumber } from "hooks/useFormatInput";
import { motion } from "framer-motion";
import { checkExpirationStatus } from "utils/checkdateExpirationDate";
import ExpireIcon from "components/constant/icons/ExpireIcon";
import WarningIcon from "components/constant/icons/WarningIcon";

export interface DataItem {
  [key: string]: any;
}

export interface TableColumn {
  key: keyof DataItem;
  title: string;
  width?: string;
  sortable?: boolean;
}

interface TableContainerProps {
  header: TableColumn[];
  content: DataItem[];
  setData?: (list: DataItem[]) => void;
  sortable?: boolean;
  textContentEmpty?: string | ReactNode;
  onRightClickTrContent?: (e: MouseEvent, item: DataItem) => void;
  onLeftClickTrContent?: (e: DataItem) => void;
  isLastRightText?: boolean;
  loading?: boolean;
  tableBodyUnderBlock?: any;
  doSelectData?: boolean;
  handleSelectTableData?: (id: number) => void;
  handleClickTableItem?: () => void;
}

const TableContainer: FC<TableContainerProps> = ({
  header,
  content,
  setData,
  sortable = true,
  textContentEmpty = "Пусто",
  onRightClickTrContent,
  onLeftClickTrContent,
  isLastRightText = true,
  loading = false,
  tableBodyUnderBlock,
  doSelectData,
  handleSelectTableData,
  handleClickTableItem,
}) => {
  const [sortConfig, setSortConfig] = useState<{
    key: keyof DataItem;
    direction: string;
  } | null>(null);
  const selection = useSelector((state: RootState) => state.apartment);

  const sortedItem = (item: any, key: string | number) => {
    return typeof item[key] === "object" ? item[key]?.key : item[key];
  };

  const handleSort = (column: TableColumn) => {
    if (!column.sortable && !sortable) return;

    let direction = "asc";
    if (
      sortConfig &&
      sortConfig.key === column.key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key: column.key, direction });

    const sortedData = [...content].sort((a: any, b: any) => {
      if (sortedItem(a, column.key) < sortedItem(b, column.key))
        return direction === "asc" ? -1 : 1;
      if (sortedItem(a, column.key) > sortedItem(b, column.key))
        return direction === "asc" ? 1 : -1;
      return 0;
    });

    setData && setData(sortedData);
  };

  const itemExists = (id: number) => {
    return selection.storedData.includes(id);
  };

  const expireDateColumn = (expiration_date: string) => {
    const status = checkExpirationStatus(expiration_date);
    switch (status) {
      case "expired":
        return (
          <span className="flex items-center gap-2 text-red-default">
            <ExpireIcon /> Закончился
          </span>
        );

      case "gonna_expire":
        return (
          <span className="flex items-center gap-2 text-[#DAAA00]">
            <WarningIcon /> {expiration_date}
          </span>
        );

      case "success":
        return expiration_date;

      default:
        return expiration_date;
    }
  };

  return (
    <table className="table-auto border-collapse w-full bg-white-default rounded-lg">
      <thead className="table__header">
        <tr className={""}>
          {doSelectData && <th className=""></th>}
          {header.map((column, idx) => {
            const isDesc =
              sortConfig?.key === column.key && sortConfig.direction === "desc";

            return (
              <th
                key={column.key}
                style={{ width: column.width }}
                className={
                  idx === header.length - 1 && isLastRightText
                    ? "py-4 px-[15px] 2xl:px-5"
                    : "py-4 px-[15px] 2xl:px-5"
                }
              >
                <div className={"table__header__th flex"}>
                  <p className={"mr-2 text-[14px] 2xl:text-base font-normal"}>
                    {column.title}
                  </p>

                  {(column.sortable || sortable) && (
                    <button
                      onClick={() => handleSort(column)}
                      className={`transition-all ${isDesc ? "rotate-180" : ""}`}
                    >
                      {sortConfig?.direction &&
                      sortConfig.direction === "asc" ? (
                        <ArrowTableIcon />
                      ) : (
                        <ArrowTableIcon />
                      )}
                    </button>
                  )}
                </div>
              </th>
            );
          })}
        </tr>
      </thead>

      <tbody
        className={`table__content ${loading ? "table__content-loading" : ""}`}
      >
        {content.length ? (
          content.map((item, index) => (
            <React.Fragment key={`table-content-item-${index}`}>
              <tr
                key={index}
                onContextMenu={(e: any) => {
                  onRightClickTrContent && onRightClickTrContent(e, item);
                }}
                onClick={() => {
                  onLeftClickTrContent && onLeftClickTrContent(item);
                }}
                className={
                  "cursor-pointer w-full p-1 border-t-[1px] border-boder-default hover:bg-bg-default"
                }
              >
                {doSelectData && (
                  <td className="py-6 pl-[20px]">
                    <div className="pt-[6px]">
                      <RadioButton
                        onChange={() => handleSelectTableData?.(item.id)}
                        checked={itemExists(item.id)}
                      />
                    </div>
                  </td>
                )}
                {header.map((column, idx) => {
                  return (
                    <td
                      onClick={handleClickTableItem}
                      key={column.key}
                      colSpan={idx === header.length - 1 ? header.length : 1}
                      className={`py-6 px-3 text-[14px] 2xl:text-[16px] 2xl:px-7 font-normal ${
                        idx === header.length - 1 && isLastRightText
                          ? "last"
                          : ""
                      } ${
                        column.title === "Закрепление" && " text-blue-default"
                      }`}
                    >
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 20 }}
                        transition={{ duration: 0.6 }}
                      >
                        {typeof item[column.key] === "object"
                          ? column.key == "datePin"
                            ? expireDateColumn(item[column.key]?.text)
                            : item[column.key]?.text
                          : column.title === "Номер телефона"
                          ? "+7 " + formatPhoneNumber(item[column.key].slice(1))
                          : item[column.key]}
                      </motion.div>
                    </td>
                  );
                })}
              </tr>
              {tableBodyUnderBlock && tableBodyUnderBlock(item)}
            </React.Fragment>
          ))
        ) : (
          <tr>
            <td colSpan={header.length}>
              <div className={"flex justify-center items-center"}>
                {loading ? (
                  <>
                    <div
                      className="flex justify-center items-center h-full px-[20px] py-[10px]"
                      style={{ position: "relative" }}
                    >
                      <SpinnerIcon />
                    </div>
                  </>
                ) : (
                  <p className={"m-6"}>{textContentEmpty}</p>
                )}
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default TableContainer;
