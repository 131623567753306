import React from "react";

const LogoutIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6471 5.79961V3.69961C12.6471 3.14265 12.424 2.60851 12.0268 2.21469C11.6297 1.82086 11.091 1.59961 10.5294 1.59961H3.11765C2.55601 1.59961 2.01738 1.82086 1.62024 2.21469C1.22311 2.60851 1 3.14265 1 3.69961V16.2996C1 16.8566 1.22311 17.3907 1.62024 17.7845C2.01738 18.1784 2.55601 18.3996 3.11765 18.3996H10.5294C11.091 18.3996 11.6297 18.1784 12.0268 17.7845C12.424 17.3907 12.6471 16.8566 12.6471 16.2996V14.1996M6.29412 9.99961H19M19 9.99961L15.8235 6.84961M19 9.99961L15.8235 13.1496"
        stroke="#EF3B24"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default LogoutIcon;
