import moment from "moment-timezone";

export function timeSince(date: string): string {
  const now = moment.utc();
  const createdDate = moment.utc(date);
  const seconds = now.diff(createdDate, "seconds");

  let interval = Math.floor(seconds / 31536000);

  if (interval >= 1) {
    return (
      interval + (interval === 1 ? " год" : interval <= 4 ? " года" : " лет")
    );
  }
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return (
      interval +
      (interval === 1 ? " месяц" : interval <= 4 ? " месяца" : " месяцев")
    );
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return (
      interval + (interval === 1 ? " день" : interval <= 4 ? " дня" : " дней")
    );
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return (
      interval + (interval === 1 ? " час" : interval <= 4 ? " часа" : " часов")
    );
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return (
      interval +
      (interval === 1 ? " минута" : interval <= 4 ? " минуты" : " минут")
    );
  }
  return (
    Math.floor(seconds) +
    (seconds === 1 ? " секунда" : seconds <= 4 ? " секунды" : " секунд")
  );
}
