import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { UseSMSCodeSender } from "hooks/useSMSCodeSender";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  useSignInAgentMutation,
  useSignInAgentVerifyMutation,
} from "data/api/auth";
import { ApiException } from "data/api/exception";

import ButtonForm from "components/Button/ButtonForm";
import Loader from "components/Loader";
import {
  agentFormDataType,
  formDataKeyType,
  verificationDataType,
} from "modules/Auth/types";
import TextField from "components/Form/TextField/TextField";
import PhoneIcon from "components/constant/icons/PhoneIcon";
import { schemaAgent, schemaSMSCode } from "utils/schemas";
import showToast from "utils/showToast";
import MaskComponent from "components/MaskInput/MaskComponent";
import Label from "components/Form/Label";

export const AgentForm = () => {
  const [showVerification, setShowVerification] = useState(false);
  const [mutate, { isLoading }] = useSignInAgentMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    getValues,
  } = useForm<agentFormDataType | formDataKeyType>({
    resolver: yupResolver<agentFormDataType | formDataKeyType>(schemaAgent),
  });

  const onSubmit: SubmitHandler<agentFormDataType | formDataKeyType> = async (
    data
  ) => {
    try {
      const { data: res, error } = await mutate({
        data: { phone: data.phone },
      });

      if (error?.message) {
        setError?.("phone", {
          type: "manual",
          message: error?.message,
        });
        return;
      }

      const { user_id } = res?.data || {};
      if (user_id) {
        localStorage.setItem("userId", String(user_id));
        localStorage.setItem("phone", data.phone);
        setShowVerification(true);
        return;
      }
    } catch (err) {
      const error = err as ApiException;
      console.log("err", error);
    }
  };

  if (showVerification) {
    return <SMSCodeVerification />;
  }
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-[30px] relative"
    >
      {isLoading && <Loader />}

      <Label label="Номер телефона">
        <Controller
          name="phone"
          control={control}
          render={({ field: { onChange, value } }) => (
            <MaskComponent
              name="phone"
              errors={errors}
              imgLeft={<PhoneIcon />}
              value={getValues("phone")}
              onChange={(value) => onChange(value)}
            />
          )}
        />
      </Label>
      <ButtonForm
        className="bg-accent-default py-[16px] w-full text-white-default text-center flex justify-center hover:bg-accent-focus"
        text="Войти"
        type="submit"
      />
    </form>
  );
};

export const SMSCodeVerification = () => {
  const [mutate, { isLoading }] = useSignInAgentVerifyMutation();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    reset,
  } = useForm<verificationDataType>({
    resolver: yupResolver<verificationDataType>(schemaSMSCode),
  });

  const {
    counter,
    handleResent,
    isLoading: loading,
    updateCounter,
  } = UseSMSCodeSender({ reset });
  useEffect(() => updateCounter(), []);

  const onSubmit: SubmitHandler<verificationDataType> = async (data) => {
    const user_id = Number(localStorage.getItem("userId"));
    try {
      const { data: res, error } = await mutate({
        data: { code: +data.code, user_id },
      });
      if (error?.message) {
        showToast(error.message, "error");
        return;
      }

      if (res?.data.accessToken) {
        localStorage.setItem("token", res.data.accessToken);
        localStorage.removeItem("register_status");
        showToast("Вы успешно вошли в систему", "success");
        navigate?.("/");
      } else if (res?.data.user_id) {
        localStorage.setItem(
          "register_status",
          String(res.data.register_status)
        );
        navigate?.(`/sign-up`);
      }
    } catch (err) {
      const error = err as ApiException;
      console.log("err", error);
    }
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-[30px] relative"
    >
      <p className="text-center text-accent-default text-sm font-normal">
        На ваш номер телефона был отправлен код для авторизации
      </p>
      {loading && <Loader />}
      {isLoading && <Loader />}
      <Controller
        name="code"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            value={value}
            className=""
            placeholder="Введите код"
            label="Код из смс"
            type="text"
            maxLength={6}
            onChange={onChange}
            name="code"
            errors={errors}
            imgRight={
              <>
                {counter === 0 ? (
                  <span
                    onClick={handleResent}
                    className="leading-[18px] text-blue-default text-[14px] flex items-center h-full justify-center cursor-pointer font-medium"
                  >
                    Отправить повторно
                  </span>
                ) : (
                  <span className="text-[14px] flex items-center justify-center text-accent-default font-medium">
                    00:{counter >= 10 ? counter : `0${counter}`}
                  </span>
                )}
              </>
            }
          />
        )}
      />
      <ButtonForm
        className="bg-accent-default py-[12px] w-full
              text-white-default text-center flex justify-center hover:bg-accent-focus"
        text="Войти"
        type="submit"
        disabled={!isValid}
      />
    </form>
  );
};
