import Label from "components/Form/Label";
import { UserData } from "components/Form/SearchField";
import MaskComponent from "components/MaskInput/MaskComponent";
import {
  useCheckingThefasteningMutation,
  useClipThefasteningMutation,
} from "data/api/base-clients";
import { ResponseStatusType } from "data/api/exception";
import { useEffect, useState } from "react";
import { checkExpirationStatus } from "utils/checkdateExpirationDate";
import showToast from "utils/showToast";
import ButtonForm from "../../components/Button/ButtonForm";
import TextField from "../../components/Form/TextField/TextField";
import Modal from "../../components/Modal";
import CloseWithBorderIcon from "../../components/constant/icons/CloseWithBorderIcon";
import PhoneIcon from "../../components/constant/icons/PhoneIcon";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useDispatch } from "react-redux";
import { toggleFasteningFormModal } from "store/HeaderReducer";
import { useLocation } from "react-router";

interface NotFoundItemProps {
  nameValue: UserData | null;
  phoneValue: string;
  handleClipClient: () => void;
}
type StatusMessageItem = {
  key: string;
  content: (item: {
    name: string;
    phone: string;
    handle: () => void;
  }) => JSX.Element;
};

const NotFoundItem = ({
  nameValue,
  phoneValue,
  handleClipClient,
}: NotFoundItemProps) => {
  return (
    <div className={"pt-[10px]"}>
      <p className="text-center mb-[20px] text-red-default font-medium text-sm">
        Клиент не закреплен
      </p>

      <ButtonForm
        text="Закрепить клиента"
        disabled={!nameValue || !phoneValue}
        className="hover:bg-bg-default py-3 px-6
                     bg-accent-default xl:flex-1 flex justify-center
                      text-white-default hover:text-accent-default w-full"
        onClick={handleClipClient}
      />
    </div>
  );
};

interface SuccessClipClientProps {
  nameValue: UserData | null;
  date?: string;
}

const SuccessClipClient = ({ nameValue, date }: SuccessClipClientProps) => {
  return (
    <div className={"pt-[10px]"}>
      <p className="text-center mb-[12px] font-normal text-sm">
        По клиенту <span className={"font-semibold"}>{nameValue?.name}</span>{" "}
        установлено закрепление до
        <span className={"font-semibold ml-1"}>{date}</span>
      </p>

      <p className={"text-center mb-[20px] font-medium text-sm text-[#0FBB00]"}>
        Успешное закрепление
      </p>
    </div>
  );
};

interface ExpireClipClientProps
  extends SuccessClipClientProps,
    NotFoundItemProps {}

const ExpireClipClient = ({
  date,
  nameValue,
  phoneValue,
  handleClipClient,
}: ExpireClipClientProps) => {
  return (
    <div className={"pt-[10px]"}>
      <p className="text-center mb-[12px] font-normal text-sm">
        Срок закрепления за клиентом{" "}
        <span className={"font-semibold"}>{nameValue?.name}</span> истекает{" "}
        <span className={"font-semibold"}>{date}</span>
      </p>

      <p className={"text-center mb-[12px] font-normal text-sm"}>
        Если покупка для клиента актуальна, то направьте повторную форму
        уведомления на клиента или забронируйте помещение.
      </p>

      <p className={"text-center mb-[20px] font-medium text-sm text-[#DAAA00]"}>
        Истечение срока закрепления
      </p>

      <ButtonForm
        text="Закрепить клиента"
        disabled={!nameValue || !phoneValue}
        className="hover:bg-bg-default py-3 px-6
                     bg-accent-default xl:flex-1 flex justify-center
                      text-white-default hover:text-accent-default w-full"
        onClick={handleClipClient}
      />
    </div>
  );
};

const CannotClipClient = ({ nameValue }: SuccessClipClientProps) => {
  return (
    <div className={"pt-[10px]"}>
      <p className="text-center mb-[12px] font-normal text-sm">
        Клиент <span className={"font-semibold"}>{nameValue?.name}</span> уже
        обращался в Бруснику.
      </p>

      <p
        className={"text-center mb-[12px] font-normal text-sm text-red-default"}
      >
        Закрепление за вами невозможно.
      </p>

      <p className={"text-center mb-[20px] font-medium text-sm text-[#0FBB00]"}>
        Сильное закрепление
      </p>
    </div>
  );
};

interface PropsComponent {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  client?: UserData;
  handleToggle?: () => void;
}

const BaseClientsAnchoringModal = ({
  isOpen,
  setIsOpen,
  client,
  handleToggle,
}: PropsComponent) => {
  const { isSm } = useWindowSize();

  const [nameValue, setNameValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [statusMessageSelect, setStatusMessageSelect] = useState<any>(null);

  const statusMessageList: StatusMessageItem[] = [
    {
      key: "not_found",
      content: (item: any) => (
        <NotFoundItem
          nameValue={item.name}
          phoneValue={item.phone}
          handleClipClient={item.handle}
        />
      ),
    },
    {
      key: "success",
      content: (item: any) => (
        <SuccessClipClient nameValue={item.name} date={item.date} />
      ),
    },
    {
      key: "gonna_expire",
      content: (item: any) => (
        <ExpireClipClient
          date={item.date}
          nameValue={item.name}
          phoneValue={item.phone}
          handleClipClient={item.handle}
        />
      ),
    },
    {
      key: "expire",
      content: (item: any) => (
        <ExpireClipClient
          date={item.date}
          nameValue={item.name}
          phoneValue={item.phone}
          handleClipClient={item.handle}
        />
      ),
    },
    {
      key: "cannot",
      content: (item: any) => <CannotClipClient nameValue={item.name} />,
    },
  ];

  const [fasteningMutate] = useCheckingThefasteningMutation();
  const [clipFasteningMutate] = useClipThefasteningMutation();
  const dispatch = useDispatch();
  const route = useLocation();

  useEffect(() => {
    init();
  }, [client]);

  function init() {
    if (client?.id) {
      setNameValue(client.name);
      client.phone && setPhoneValue(client.phone);
    }
  }

  const handleOpenFasteningModal = () => {
    if (route.pathname === "/base-clients") {
      handleToggle?.();
    } else {
      dispatch(toggleFasteningFormModal(true));
    }
    handleModalClose();
  };

  async function handleCheckClient() {
    const query = `fullname=${nameValue}&phone_number=${phoneValue}`;

    const { data: res, error } = await fasteningMutate(query);

    if (error?.message) {
      showToast(error?.message, "error", 2000);
      return;
    }

    if (res?.meta?.type === ResponseStatusType.SUCCESS) {
      let findItem: StatusMessageItem | undefined;

      if (!res.data) {
        findItem = statusMessageList.find((item) => item.key === "not_found");
      }
      if (res.data?.expiration_date) {
        const status = checkExpirationStatus(res.data.expiration_date);
        console.log("status", status);
        switch (status) {
          case "expired":
            findItem = statusMessageList.find((item) => item.key === "expire");
            break;

          case "gonna_expire":
            findItem = statusMessageList.find(
              (item) => item.key === "gonna_expire"
            );
            break;

          case "success":
            findItem = statusMessageList.find((item) => item.key === "success");
            break;

          default:
            findItem = undefined;
        }
      }

      const props: any = {
        name: nameValue,
        phone: phoneValue,
        date: "05.09.2024", // TODO: res?.data?.date
        handle: () => handleOpenFasteningModal(),
      };

      // Set the status message if findItem is found
      if (findItem) {
        setStatusMessageSelect(findItem.content(props));
      }
    }
  }

  // async function handleClipClient() {
  //   const query = `fullname=${nameValue}&phone_number=${phoneValue}`;

  //   const { data: res, error } = await clipFasteningMutate(query);
  //   if (error?.message) {
  //     showToast(error?.message, "error", 2000);
  //     return;
  //   }

  //   const findItem = statusMessageList.find((item) => res?.data?.status);

  //   const props: any = {
  //     name: nameValue,
  //     phone: phoneValue,
  //     date: "28.06.2024", // TODO: res?.data?.date
  //     handle: () => handleClipClient(),
  //   };

  //   findItem && setStatusMessageSelect(findItem.content(props));
  // }

  function clearFields() {
    setNameValue("");
    setPhoneValue("");
    setStatusMessageSelect(null);
  }

  function handleModalClose() {
    clearFields();
    setIsOpen(false);
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        className="md:w-[500px] w-full rounded-t-[30px] sm:rounded-lg"
        onClose={handleModalClose}
      >
        <div className={"flex md:justify-between justify-center mb-3"}>
          <h1 className={"font-extrabold text-[25px] font-dewi"}>
            Проверка <br className="hidden md:block" /> закрепления
          </h1>

          {!isSm && (
            <ButtonForm
              text={<CloseWithBorderIcon />}
              onClick={handleModalClose}
            />
          )}
        </div>

        <div className={"py-[10px]"}>
          <TextField
            value={nameValue}
            placeholder="Введите ФИО"
            label="ФИО"
            type="text"
            name="firstName"
            onChange={(e) => setNameValue(e.target.value)}
            className={
              "min-w-[175px] xl:min-w-[238px] md:rounded-[4px!important]"
            }
          />

          <Label className={"mt-[20px]"} label="Номер телефона">
            <MaskComponent
              name="phone"
              imgLeft={<PhoneIcon />}
              value={phoneValue}
              onChange={setPhoneValue}
            />
          </Label>
        </div>

        {statusMessageSelect && statusMessageSelect}

        <div className={"pt-[10px]"}>
          <ButtonForm
            text="Проверить"
            className="hover:bg-bg-default py-3 px-6
                     bg-accent-default xl:flex-1 flex justify-center
                      text-white-default hover:text-accent-default w-full"
            onClick={handleCheckClient}
          />
        </div>
      </Modal>
    </>
  );
};

export default BaseClientsAnchoringModal;
