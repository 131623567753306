import ButtonForm from "../../components/Button/ButtonForm";
import Modal from "../../components/Modal";
import CloseWithBorderIcon from "../../components/constant/icons/CloseWithBorderIcon";
import React, { useEffect, useState } from "react";
import PlusIcon from "../../components/constant/icons/PlusIcon";
import NewsCategoryItem from "./NewsCategoryItem";
import { useWindowSize } from "../../hooks/useWindowSize";

interface NewsCategoryModalData {
	isOpen: boolean;
	setIsOpen: (value: boolean) => void;
	propsList: string[];
}

const NewsCategoryModal = (
	{
		isOpen,
		setIsOpen,
		propsList
	}: NewsCategoryModalData
) => {
	const { isSm } = useWindowSize();

	const [categoryList, setCategoryList] = useState(propsList || []);

	const onChangeCategory = (value: string, idx: number) => {
		const updateList = [...categoryList].map((key, index) => {
			if (idx === index) return value

			return key
		})

		setCategoryList(updateList)
	}

	const handleDeleteCategoryBlock = (idx: number) => {
		const updateList = [...categoryList].filter((key, index) => idx !== index)

		setCategoryList(updateList)
	}

	useEffect(() => {
		setCategoryList(propsList)
	}, [propsList]);

	function handleAddNewBlock() {
		setCategoryList((list) => {
			return [...list, '']
		})
	}

	function handleSave() {
		// $save(selected).then(res => {
			setIsOpen(true);
		// })
	}

	return (
		<Modal
			isOpen={isOpen}
			className="md:w-[440px] w-full rounded-lg overflow-auto max-h-[90vh]"
			onClose={() => setIsOpen(true)}
		>
			<div className={'flex md:justify-start justify-center relative'}>
				<h1 className={'font-extrabold text-[20px] font-dewi md:max-w-[80%]'}>Категории</h1>

				{!isSm && (
					<div className={'absolute right-0'}>
						<ButtonForm
							text={<CloseWithBorderIcon color={'black'}/>}
							className={'p-0 px-[0px!important]'}
							onClick={() => setIsOpen(true)}
						/>
					</div>
				)}
			</div>

			<section className="my-[15px]">
				{categoryList.map((key, idx) => (
					<div key={`category-edit-item-${idx}`}>
						<NewsCategoryItem
							text={key}
							index={idx}
							onChangeValue={onChangeCategory}
							handleDeleteBlock={() => handleDeleteCategoryBlock(idx)}
						/>

						{idx !== (categoryList.length - 1) && <div className={"border-t border-[#D7DCE4]"}></div>}
					</div>
				))}

				<div>
					<ButtonForm
						text={<PlusIcon color={"#3583FF"} width={37} height={37}/>}
						className={'bg-white-default border border-blue-default border-dashed rounded justify-center min-w-[48px] w-full p-[4px]'}
						onClick={() => handleAddNewBlock()}
					/>
				</div>
			</section>

			<div className={'flex py-[15px]'}>
				<ButtonForm
					text={'Готово'}
					className={'p-3 bg-accent-default text-white-default justify-center font-medium w-full'}
					onClick={handleSave}
				/>
			</div>
		</Modal>
	)
}

export default NewsCategoryModal;