import { ReactNode } from "react";

interface Props {
  className?: string;
  text: string | ReactNode;
}

const TooltipTags = ({ text = "", className }: Props) => {
  return (
    <div
      className={`absolute py-3 px-4 min-w-[60px] 
      w-full z-10 top-[20px] left-[20px] bg-white-default 
      text-accent-default shadow-lg border 
      border-boder-default rounded ${className}`}
			style={{
				maxWidth: "calc(100% - 20px)"
			}}
    >
			{text}
    </div>
  );
};

export default TooltipTags;
