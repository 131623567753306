import { FC, ReactElement, ReactNode } from "react";
import Select, { components, Props, SingleValueProps } from "react-select";
import Close from "../../constant/icons/Close";
import ArrowDropdown from "../../constant/icons/ArrowDropdown";
import ButtonForm from "../../Button/ButtonForm";
import { clsx } from "yet-another-react-lightbox";

export interface selectOption {
  label?: string | null | ReactNode;
  value?: string | null | number | boolean;
  __isNew__?: boolean;
}
interface DropdownProps extends Props {
  options: selectOption[] | any[];
  onChange: (value: any) => void;
  onChangeInput?: (value: any) => void;
  deleteItem?: () => void;
  placeholder?: string;
  isMulti?: boolean;
  rest?: any;
  value?: any;
  imgLeft?: ReactElement | null;
  imgRight?: ReactElement | null;
  className?: string;
  isShowBtnDelete?: boolean;
  disabled?: boolean;
  defaultValue?: any;
  required?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  errors?: any;
  name?: string;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
}

const customStyles = {
  control: (provided: object) => ({
    ...provided,
    borderColor: "",
  }),
  placeholder: (provided: object) => ({
    ...provided,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  }),
  dropdownIndicator: (provided: object) => ({
    ...provided,
    padding: 0,
    transition: "all 150ms",
  }),
  indicatorSeparator: (provided: object) => ({
    ...provided,
    display: "none",
  }),
  menu: (provided: object) => ({
    ...provided,
    margin: 0,
    padding: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow: "none",
    borderLeft: "1px solid #D7DCE4",
    borderRight: "1px solid #D7DCE4",
    borderBottom: "1px solid #D7DCE4",
    overflow: "hidden",
  }),
  menuList: (provided: object) => ({
    ...provided,
    padding: 0,
  }),
  option: (provided: object) => ({
    ...provided,
    padding: "12px 16px",
    fontSize: "14px",
    fontWeight: 400,
    color: "",
    borderBottom: "1px solid #D7DCE4",
    backgroundColor: "",
  }),
};

const DropdownIndicator = (props: any) => {
  const { isFocused } = props;

  const caretClass = isFocused ? "rotate-180" : "caret-down";

  return (
    <components.DropdownIndicator {...props} className={caretClass}>
      <ArrowDropdown />
    </components.DropdownIndicator>
  );
};

const SingleValue = ({ children, ...props }: SingleValueProps<any>) => {
  return <components.SingleValue {...props}>{children}</components.SingleValue>;
};

const Control = ({ children, ...props }: any) => {
  return (
    <components.Control
      {...props}
      className={
        "py-[10.5px] px-[8px] placeholder:text-accent-disabled border-[1px] border-boder-default outline-none text-[14px] rounded-[4px]"
      }
    >
      {children}
    </components.Control>
  );
};

const Option = ({ children, ...props }: any) => {
  const { isFocused } = props;

  const bgClass = isFocused
    ? "bg-accent-default text-white-default"
    : "text-accent-default";

  return (
    <components.Option {...props} className={bgClass}>
      {children}
    </components.Option>
  );
};

const Dropdown: FC<DropdownProps & { interactive?: boolean }> = ({
  options,
  onChange,
  imgLeft,
  name,
  imgRight,
  className = "",
  isShowBtnDelete = false,
  deleteItem,
  disabled,
  defaultValue,
  required,
  isSearchable = false,
  onChangeInput,
  isClearable,
  errors,
  onMenuOpen,
  onMenuClose,
  interactive = true,
  ...props
}) => {
  return (
    <div
      className={clsx(
        "relative",
        !interactive && "pointer-events-none",
        className,
        Boolean(imgLeft) && "left",
        Boolean(imgRight) && "right",
        disabled && "disabled"
      )}
    >
      {imgLeft && (
        <div className="absolute bottom-0 h-full flex items-center justify-center w-[60px] z-10 left">
          {imgLeft}
        </div>
      )}

      <Select
        styles={customStyles}
        className={"react-select-prefix font-normal"}
        classNamePrefix={"react-select-prefix"}
        components={{ DropdownIndicator, SingleValue, Control, Option }}
        options={options}
        isSearchable={isSearchable}
        placeholder={"Статус"}
        onChange={(val) => onChange(val)}
        onInputChange={(val) => onChangeInput && onChangeInput(val)}
        noOptionsMessage={() => <div>Данных нет</div>}
        isDisabled={disabled}
        isClearable={isClearable}
        value={props.value}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        {...props}
      />
      {errors?.[name as keyof {}] && (
        <div className="text-[12px] leading-[14.06px] mt-[5px]">
          <p className="text-red-default mb-[5px]">
            {errors?.[name as keyof {}]?.message}
          </p>
        </div>
      )}

      {isShowBtnDelete && (
        <div
          className={
            "absolute inset-y-0 right-[2px] flex items-center justify-center z-10"
          }
        >
          <ButtonForm
            text={<Close color={"#FF0000"} />}
            onClick={() => deleteItem && deleteItem()}
            className={"bg-transparent"}
          />
        </div>
      )}

      {imgRight && (
        <div
          className={
            "absolute bottom-0 h-full flex items-center justify-center w-[60px] z-10 right-0"
          }
        >
          {imgRight}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
