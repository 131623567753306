import { useEffect, useState } from "react";
import { images } from "../../utils/resource";
import Dropdown from "../../components/Form/Dropdown/Dropdown";
import PencilIcon from "../../components/constant/icons/PencilIcon";
import { useNavigate } from "react-router-dom";
import NewsBanner from "../../components/Carousel/NewsBanner";
import NewsPageCard from "../../components/Cards/NewsPageCard";
import { useGetNewsQuery } from "data/api/news";
import moment from "moment";

export interface NewsItemData {
  id: number;
  key: string;
  img?: string;
  images?: string[];
  title: string;
  text?: string;
  tags: string[];
  views: number;
  likes: number;
  date: string;
  city?: string;
  is_banner?: boolean;
  is_draft?: boolean;
  is_show?: boolean;
}

interface categoryItem {
  key: string;
  text: string;
}

const NewsPage = () => {
  const navigate = useNavigate();
  const { data: news } = useGetNewsQuery();

  const optionsCity = [
    {
      label: "Москва",
      value: "Москва",
    },
  ];

  const [activeCategory, setActiveCategory] = useState("all");
  const [categoryList, setCategoryList] = useState([
    {
      key: "all",
      text: "Все",
    },
    {
      key: "events",
      text: "События Брусники",
    },
    {
      key: "realtors",
      text: "Риелторам",
    },
    {
      key: "clients",
      text: "Клиентам",
    },
    {
      key: "activity",
      text: "Мероприятия",
    },
  ]);

  const [newsList, setNewsList] = useState<NewsItemData[]>([
    {
      id: 123,
      key: "events",
      img: images.newsImage,
      title: "Скидка на квартиры сотрудникам",
      tags: ["Брусника", "Мероприятие"],
      views: 1000,
      likes: 42,
      date: "12.12.2024",
    },
    {
      id: 123,
      key: "realtors",
      img: images.newsImage,
      title: "Скидка на квартиры сотрудникам",
      tags: ["Брусника", "Мероприятие"],
      views: 1000,
      likes: 42,
      date: "12.12.2024",
    },
    {
      id: 123,
      key: "events",
      img: images.newsImage,
      title: "Скидка на квартиры сотрудникам",
      tags: ["Брусника", "Мероприятие"],
      views: 1000,
      likes: 42,
      date: "12.12.2024",
    },
    {
      id: 123,
      key: "activity",
      img: images.newsImage,
      title: "Скидка на квартиры сотрудникам",
      tags: ["Брусника", "Мероприятие"],
      views: 1000,
      likes: 42,
      date: "12.12.2024",
    },
    {
      id: 123,
      key: "activity",
      img: images.newsImage,
      title: "Скидка на квартиры сотрудникам",
      tags: ["Брусника", "Мероприятие"],
      views: 1000,
      likes: 42,
      date: "12.12.2024",
    },
    {
      id: 123,
      key: "activity",
      img: images.newsImage,
      title: "Скидка на квартиры сотрудникам",
      tags: ["Брусника", "Мероприятие"],
      views: 1000,
      likes: 42,
      date: "12.12.2024",
    },
  ]);

  const [filterList, setFilterList] = useState<NewsItemData[]>([]);

  const [sliderList, setSliderList] = useState<NewsItemData[]>([
    {
      id: 123,
      key: "events",
      images: [images.newsImage, images.newsImage, images.newsImage],
      title: "Скидка на квартиры сотрудникам",
      tags: ["Брусника", "Мероприятие"],
      views: 1000,
      likes: 42,
      date: "12.12.2024",
    },
    {
      id: 123,
      key: "events",
      images: [images.newsImage, images.newsImage, images.newsImage],
      title: "Скидка на квартиры сотрудникам",
      tags: ["Брусника", "Мероприятие"],
      views: 1000,
      likes: 42,
      date: "12.12.2024",
    },
    {
      id: 123,
      key: "events",
      images: [images.newsImage, images.newsImage, images.newsImage],
      title: "Скидка на квартиры сотрудникам",
      tags: ["Брусника", "Мероприятие"],
      views: 1000,
      likes: 42,
      date: "12.12.2024",
    },
    {
      id: 123,
      key: "events",
      images: [images.newsImage, images.newsImage, images.newsImage],
      title: "Скидка на квартиры сотрудникам",
      tags: ["Брусника", "Мероприятие"],
      views: 1000,
      likes: 42,
      date: "12.12.2024",
    },
    {
      id: 123,
      key: "events",
      images: [images.newsImage, images.newsImage, images.newsImage],
      title: "Скидка на квартиры сотрудникам",
      tags: ["Брусника", "Мероприятие"],
      views: 1000,
      likes: 42,
      date: "12.12.2024",
    },
    {
      id: 123,
      key: "events",
      images: [images.newsImage, images.newsImage, images.newsImage],
      title: "Скидка на квартиры сотрудникам",
      tags: ["Брусника", "Мероприятие"],
      views: 1000,
      likes: 42,
      date: "12.12.2024",
    },
  ]);

  const [selectCity, setSelectCity] = useState({
    label: "Москва",
    value: "Москва",
  });

  useEffect(() => {
    newsList && setFilterList([...newsList]);
  }, [newsList]);

  function handleCategory(item: categoryItem) {
    setActiveCategory(item.key);

    if (item.key === "all") {
      setFilterList([...newsList]);
    } else {
      const updateList = [...newsList].filter((i) => item.key === i.key);

      setFilterList(updateList);
    }
  }

  return (
    <>
      <div className={"bg-bg-default min-h-[50vh]"}>
        <div className="br-container py-[20px]">
          <h1
            className={
              "hidden md:flex text-[25px] font-dewi font-extrabold mb-[15px]"
            }
          >
            Новости
          </h1>

          <NewsBanner list={[]} />

          <div className={""}>
            <div className={"flex justify-between items-center"}>
              <div className={"hidden lg:flex"}>
                <Dropdown
                  placeholder={"Город"}
                  value={selectCity}
                  options={optionsCity}
                  className={"w-full select-bg-transparent"}
                  onChange={setSelectCity}
                />
              </div>

              <div
                className={
                  "flex items-center gap-[10px] flex-nowrap whitespace-nowrap overflow-auto"
                }
              >
                {categoryList.map((item, idx) => (
                  <div
                    key={`category-item-${idx}`}
                    onClick={() => handleCategory(item)}
                    className={`
										md:py-[11px] py-[7px] 
										md:px-[24px] px-[15px] 
										rounded-full text-sm font-normal 
										md:leading-[18px] leading-[16px] 
										cursor-pointer
										border md:border-white-default border-[#D7DCE4]
										${
                      activeCategory === item.key
                        ? "bg-accent-default text-white-default"
                        : "bg-white-default"
                    }
										`}
                  >
                    {item.text}
                  </div>
                ))}
              </div>
            </div>

            <div
              className={
                "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[15px] md:mt-[20px] mt-[15px] xl:mt-[30px]"
              }
            >
              {(news?.data?.length &&
                news.data.map((item, index) => (
                  <NewsPageCard
                    category="Категория"
                    key={`news-block-item-${item.id}`}
                    imageSrc={
                      process.env.REACT_APP_FILES_URL +
                        "/" +
                        item?.cover_image || ""
                    }
                    title={item.title}
                    watched={item.views_count}
                    likes={item.likes_count}
                    date={`${
                      item.published_at
                        ? moment(item.published_at).format("DD.MM.YYYY")
                        : ""
                    }`}
                    onClick={() => navigate(`view/${item.id}`)}
                  />
                ))) ||
                null}
            </div>
          </div>

          <div className="fixed right-[30px] bottom-[30px] flex items-center justify-center">
            <div
              className={
                "rounded-full bg-accent-default flex items-center justify-center p-3 cursor-pointer"
              }
              onClick={() => navigate("edit")}
            >
              <PencilIcon color={"#FFFFFF"} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsPage;
