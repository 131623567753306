import React from "react";

const DownloadIcon = ({ color = "#3B4658" }) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 13.2044L1 16.8925C1 17.4514 1.21071 17.9875 1.58579 18.3827C1.96086 18.778 2.46957 19 3 19H15C15.5304 19 16.0391 18.778 16.4142 18.3827C16.7893 17.9875 17 17.4514 17 16.8925V13.2044M9.00112 1V12.9425M9.00112 12.9425L13.5725 8.3793M9.00112 12.9425L4.42969 8.3793"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownloadIcon;
