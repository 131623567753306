import React, { useEffect, useState } from "react";
import CalculatorFilter from "./CalculatorFilter";
import {
  calculateBankItemData,
  calculateFormData,
} from "../../store/CalculatorReducer/type";
import { useWindowSize } from "../../hooks/useWindowSize";

const MortgageCalculator = () => {
  const { isMd } = useWindowSize();

  const [optionsProject, setOptionsProject] = useState([
    {
      value: "1",
      label: "Проект",
    },
  ]);
  const [optionsCurrency, setOptionsCurrency] = useState([
    {
      value: "rub",
      label: "Рубль",
    },
  ]);
  const [minInitial, setMinInitial] = useState(0);
  const [maxInitial, setMaxInitial] = useState(2000000);
  const [minMonth, setMinMonth] = useState(1);
  const [maxMonth, setMaxMonth] = useState(240);

  const [bankList, setBankList] = useState<calculateBankItemData[]>([
    {
      project: "Тинькофф",
      price_apart: 100000,
      currency_apart: "rub",
      percent: 20,
      initial: 200000,
      period: 10,
      price_month: 500000,
    },
    {
      project: "Тинькофф",
      price_apart: 200000,
      currency_apart: "rub",
      percent: 10,
      initial: 300000,
      period: 15,
      price_month: 600000,
    },
  ]);
  const [filteredBankList, setFilteredBankList] = useState<
    calculateBankItemData[]
  >([]);

  const [calculate, setCalculate] = useState<calculateFormData | null>(null);

  const applyFilter = (
    list: calculateBankItemData[],
    filter: calculateFormData | null
  ) => {
    if (!filter) return [];

    return list.filter((item) => {
      const isProjectMatch = item.project === filter.project;
      const isCurrencyMatch = item.currency_apart === filter.currency_apart;
      const isPercentMatch = item.percent === filter.percent;
      const isInitialMatch =
        item.initial >= filter.initial.min &&
        item.initial <= filter.initial.max;
      const isPeriodMatch =
        item.period >= filter.period.min && item.period <= filter.period.max;

      return (
        isProjectMatch &&
        isCurrencyMatch &&
        isPercentMatch &&
        isInitialMatch &&
        isPeriodMatch
      );
    });
  };

  useEffect(() => {
    if (bankList) {
      setFilteredBankList(bankList);
    }
  }, [bankList]);

  return (
    <div className={"bg-bg-default"}>
      <div className={`${!isMd ? "br-container " : ""} lg:py-[20px] pt-[65px]`}>
        <div className={"flex flex-wrap lg:flex-nowrap lg:gap-[10px]"}>
          <CalculatorFilter
            filterData={{
              optionsProject,
              optionsCurrency,
              minInitial,
              maxInitial,
              minMonth,
              maxMonth,
              calculate,
            }}
            setFiltered={(data) => {
              setCalculate({
                ...data,
                price_all:
                  data.price_apart * (data.percent / 100) + data.price_apart,
                price_month: data.period.max,
              });
              applyFilter(bankList, data);
            }}
          />

          <div
            className={
              "lg:max-w-[62%] w-full gap-[10px] flex flex-col lg:p-0 p-[15px]"
            }
          >
            {filteredBankList &&
              filteredBankList.map((item, idx) => (
                <div
                  key={`filter-bank-item-${idx}`}
                  className={
                    "bg-white-default rounded-md flex items-center justify-between p-[20px] cursor-pointer"
                  }
                  onClick={() => {}}
                >
                  <p className={"font-medium text-accent-default"}>
                    {item.project}
                  </p>

                  <p className={"text-sm font-medium text-accent-default"}>
                    {item.price_month} руб/мес
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MortgageCalculator;
