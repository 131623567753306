import React, { useEffect, useState } from "react";

import ButtonForm from "../../components/Button/ButtonForm";
import Close from "../../components/constant/icons/Close";
import Modal from "../../components/Modal";
import ImgUpload from "../../components/Form/ImgUpload";
import Dropdown from "../../components/Form/Dropdown/Dropdown";
import Label from "../../components/Form/Label";
import TextField from "../../components/Form/TextField/TextField";
import ToggleSwitcher from "../../components/ToggleSwitcher";
import { Editor } from "../../components/Form/Editor";
import { useWindowSize } from "../../hooks/useWindowSize";
import CloseWithBorderIcon from "../../components/constant/icons/CloseWithBorderIcon";

interface NewsCreateEditModalData {
  isOpen: boolean;
  setIsOpen: (isDelete: boolean) => void;
  selected?: any;
  setSelect?: (value: any) => void;
}

interface SelectData {
  label: string;
  value: string;
}

const NewsCreateEditModal = ({
  isOpen,
  setIsOpen,
  selected,
  setSelect,
}: NewsCreateEditModalData) => {
  const { isSm } = useWindowSize();

  const [selectedImg, setSelectedImg] = useState<string>("");

  const [titleValue, setTitleValue] = useState("");
  const [textValue, setTextValue] = useState("");

  const [optionsCity, setOptionsCity] = useState([
    {
      label: "Москва",
      value: "moscow",
    },
  ]);
  const [optionsCategory, setOptionsCategory] = useState<string[]>([]);

  const [selectCity, setSelectCity] = useState<SelectData | null>(null);
  const [selectCategory, setSelectCategory] = useState<string>("");
  const [isToggle, setIsToggle] = useState(false);

  const getRequestData = () => ({
    img: selectedImg,
    title: titleValue,
    text: textValue,
    city: selectCity,
    hashtag: selectCategory,
    is_banner: isToggle,
  });

  const isDisabledNext = () => {
    return !(
      selectedImg &&
      titleValue &&
      textValue &&
      selectCity !== null &&
      !!optionsCategory?.length
    );
  };

  useEffect(() => {
    if (selected) {
      setSelectedImg(selected.img);
      setTitleValue(selected.title);
      setTextValue(selected.text);
      setOptionsCategory(selected.tags);
      setIsToggle(selected.is_banner);

      const findCity = optionsCity.find((item) => item.value === selected.city);
      findCity && setSelectCity(findCity);
    }
  }, [selected]);

  function handleAddedCategory(key: string) {
    if (key === "Enter") {
      setSelectCategory("");

      const findDuplicate = optionsCategory.find(
        (item) => item === selectCategory
      );

      if (!findDuplicate) {
        setOptionsCategory((list: string[]) => {
          return [...list, selectCategory];
        });
      }
    }
  }

  function handleRemoveCategory(key: string) {
    const filterList = optionsCategory.filter((text) => text !== key);

    setOptionsCategory(filterList);
  }

  function handleSave() {
    if (selected) handleUpdate();

    // $save(getRequestData()).then(res => {
    resetForm();

    setIsOpen(true);
    // })
  }

  function handleUpdate() {
    // $update(getRequestData()).then(res => {
    resetForm();

    setIsOpen(true);
    // })
  }

  function handlePreview() {
    const select = {
      img: selectedImg,
      title: titleValue,
      text: textValue,
      hashtag: selectCategory,
      watched: 0,
      likes: 0,
      date: "12.12.2024",
      city: selectCity?.value,
      is_banner: isToggle,
    };

    setSelect && setSelect(select);

    setIsOpen(false);
  }

  function resetForm() {
    setSelectedImg("");
    setTitleValue("");
    setTextValue("");
    setSelectCity(null);
    setSelectCategory("");
    setIsToggle(false);
  }

  return (
    <Modal
      isOpen={isOpen}
      className="md:w-[672px] w-full rounded-lg overflow-auto max-h-[90vh]"
      onClose={() => setIsOpen(true)}
    >
      <div className={"flex md:justify-start justify-center relative"}>
        <div className={"flex md:justify-start justify-center items-center gap-[10px] w-full"}>
          <h1 className={"font-extrabold text-[20px] font-dewi md:max-w-[80%]"}>
            {selected ? "Редактирование" : "Создание"} новости
          </h1>

          {selected?.is_draft && (
            <p className={"text-sm font-medium text-[#DB9E00] font-roboto"}>
              Черновик
            </p>
          )}
        </div>

        {!isSm && (
          <div className={"absolute right-0"}>
            <ButtonForm
              text={<CloseWithBorderIcon color={"black"} />}
              className={"p-0 px-[0px!important]"}
              onClick={() => setIsOpen(true)}
            />
          </div>
        )}
      </div>

      <div className={"py-[20px]"}>
        <ImgUpload
          title={"Обложка"}
          requirementText={
            "Добавьте изображение с разрешением минимум 800х377 px"
          }
          value={selectedImg}
          setValue={setSelectedImg}
        />

        <div className={"pt-[30px] pb-[20px]"}>
          <TextField
            value={titleValue}
            className="mb-[20px]"
            onChangeValue={setTitleValue}
            label={"Заголовок"}
            placeholder="Напишите заголовок новости"
            type="text"
            name="teg"
          />

          <Label label="Описание" className={"w-full"}>
            <Editor value={textValue} onChangeValue={setTextValue} />
          </Label>
        </div>

        <div className={"gap-[10px]"}>
          <Label label="Город" className={"w-full"}>
            <Dropdown
              value={selectCity}
              onChange={setSelectCity}
              options={[{ label: "Все", value: "" }, ...optionsCity]}
              placeholder="Выберите город"
              className="cursor-pointer"
            />
          </Label>

          <div className={"pt-[20px]"}>
            <Label label="Категория" className={"w-full"}>
              <TextField
                value={selectCategory}
                onChangeValue={setSelectCategory}
                onKeyDown={handleAddedCategory}
                placeholder="Введите категорию"
                className="cursor-pointer max-h-[48px]"
              />
            </Label>

            <div className={"flex flex-wrap gap-[10px] pt-[10px]"}>
              {optionsCategory?.map((text, idx) => (
                <div
                  key={`category-item-${idx}`}
                  className={
                    "flex items-center bg-accent-default rounded-[14px] text-white-default p-[6px] pl-[16px] gap-[2px]"
                  }
                >
                  {text}

                  <div onClick={() => handleRemoveCategory(text)}>
                    <Close />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className={"pt-[28px]"}>
          <ToggleSwitcher
            label="Отображать в баннере"
            isToggle={isToggle}
            setIsToggle={(value) => setIsToggle(!value)}
          />
        </div>
      </div>

      <div className={"flex gap-[10px] pt-[10px]"}>
        <ButtonForm
          text={"Предпросмотр"}
          className={
            "p-3 bg-bg-default text-accent-default justify-center font-medium w-full"
          }
          onClick={handlePreview}
          disabled={isDisabledNext()}
        />

        <ButtonForm
          text={selected ? "Сохранить" : "Создать"}
          className={
            "p-3 bg-accent-default text-white-default justify-center font-medium w-full"
          }
          onClick={handleSave}
          disabled={isDisabledNext()}
        />
      </div>
    </Modal>
  );
};

export default NewsCreateEditModal;
