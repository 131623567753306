import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SelectionState {
  isOpenFilterModal: boolean;
}
const initialState: SelectionState = {
  isOpenFilterModal: false,
};

const adminsSlice = createSlice({
  name: "adminstration",
  initialState,
  reducers: {
    handleOpenAdminsFilter: (state, action: PayloadAction<boolean>) => {
      state.isOpenFilterModal = action.payload;
    },
  },
});

export const { handleOpenAdminsFilter } = adminsSlice.actions;

export default adminsSlice.reducer;
