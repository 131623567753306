import { createBrowserRouter } from "react-router-dom";

import App from "../App";
import Login from "../modules/Auth/Login";
import SignUp from "../modules/Auth/Register";
import BaseClients from "../modules/BaseClients";
import Layout from "../components/Layout";
import HomePage from "../modules/Home";
import Profile from "modules/Profile";
import Projects from "modules/Project";
import Apartment from "modules/Apartment";
import ApartmentInfo from "modules/Apartment/ApartmentInfo";
import CalendarPage from "../modules/CalendarPage";
import PrivateRoute from "components/PrivateRoute";
import ApartmentCardPage from "../modules/Apartment/ApartmentCardPage";
import Deals from "modules/Deal";
import Events from "modules/Events";
import NewsPage from "../modules/News";
import NewsViewPage from "../modules/News/NewsViewPage";
import NewsEditPage from "../modules/News/NewsEditPage";
import EventEditPage from "modules/Events/EventEditPage";
import Tasks from "modules/Tasks";
import Adminstartion from "modules/Adminstration";
import MortgageCalculator from "../modules/Calculator";
import EventViewPage from "modules/Events/EventViewPage";
import Contacts from "modules/Contacts";
import ContactsEditPage from "modules/Contacts/ContactEditPage";
import Training from "modules/Training";
import TrainingEditPage from "modules/Training/TrainingEditPage";
import { UserRole } from "enums/users";
import Analitics from "modules/Analitics";

interface IRoutes {
  path: string;
  element: JSX.Element;
  roles: UserRole[];
}

const routes: IRoutes[] = [
  { path: "", element: <HomePage />, roles: [] },
  { path: "base-clients", element: <BaseClients />, roles: [] },
  { path: "profile", element: <Profile />, roles: [] },
  { path: "projects", element: <Projects />, roles: [] },
  { path: "apartments", element: <Apartment />, roles: [] },
  { path: "apartments/:id", element: <Apartment />, roles: [] },
  {
    path: "apartments/info",
    element: <ApartmentInfo actions={true} />,
    roles: [],
  },
  { path: "apartments/card/:id", element: <ApartmentCardPage />, roles: [] },
  { path: "calendar", element: <CalendarPage />, roles: [] },
  { path: "deals", element: <Deals />, roles: [] },
  { path: "events", element: <Events />, roles: [] },
  { path: "events/view/:id", element: <EventViewPage />, roles: [] },
  {
    path: "events/edit",
    element: <EventEditPage />,
    roles: [UserRole.ADMIN, UserRole.AFFILIATE_MANAGER],
  },
  { path: "news", element: <NewsPage />, roles: [] },
  { path: "news/view/:id", element: <NewsViewPage />, roles: [] },
  {
    path: "news/edit",
    element: <NewsEditPage />,
    roles: [UserRole.ADMIN, UserRole.AFFILIATE_MANAGER],
  },
  {
    path: "tasks",
    element: <Tasks />,
    roles: [UserRole.MANAGER, UserRole.OZK_MANAGER],
  },
  {
    path: "administration",
    element: <Adminstartion />,
    roles: [
      UserRole.AFFILIATE_MANAGER,
      UserRole.OZK_MANAGER,
      UserRole.ADMIN,
      UserRole.HEAD_OF_AGENCY,
    ],
  },
  {
    path: "mortgage-calculator",
    element: <MortgageCalculator />,
    roles: [
      UserRole.AGENT,
      UserRole.HEAD_OF_AGENCY,
      UserRole.MANAGER,
      UserRole.OZK_MANAGER,
    ],
  },
  { path: "contacts", element: <Contacts />, roles: [] },
  {
    path: "contacts/edit",
    element: <ContactsEditPage />,
    roles: [UserRole.ADMIN, UserRole.AFFILIATE_MANAGER],
  },
  { path: "training", element: <Training />, roles: [] },
  { path: "training/edit", element: <TrainingEditPage />, roles: [] },
  { path: "analytics", element: <Analitics />, roles: [] },
];

const publicRoutes = [
  { path: "sign-in", element: <Login /> },
  { path: "sign-up", element: <SignUp /> },
  { path: "buildings/info", element: <ApartmentInfo actions={false} /> },
  {
    path: "*",
    element: (
      <h1 className="flex items-center justify-center h-[50vh]">
        Страница не найдена
      </h1>
    ),
  },
];

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <App />
      </Layout>
    ),
    children: routes.map((route) => ({
      ...route,
      element: <PrivateRoute element={route.element} roles={route.roles} />,
    })),
  },
  ...publicRoutes,
]);
