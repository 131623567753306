import React from "react";
import ArrowDropdown from "../constant/icons/ArrowDropdown";
import { Link } from "react-router-dom";

interface PropsType {
  title: string;
  link: string;
  hasRightIcon?: boolean;
  className?: string;
  leftIcon?: React.ReactNode;
}

const BreadCrump = ({
  title,
  link,
  hasRightIcon = true,
  className = " text-[20px] xl:text-[24px] text-accent-default font-semibold",
  leftIcon = false,
}: PropsType) => {
  return (
    <div className="flex gap-[5px] mb-[6px] items-center">
      {leftIcon && (
        <button className="w-[24px] cursor-default h-[24px] flex items-center justify-center">
          {leftIcon}
        </button>
      )}
      <h2 className={` ${className}`}>{title}</h2>
      {hasRightIcon && (
        <Link to={link}>
          <button className="-rotate-[90deg] mt-[3px] w-[24px] h-[24px] flex items-center justify-center">
            <ArrowDropdown color="#3583FF" />
          </button>
        </Link>
      )}
    </div>
  );
};

export default BreadCrump;
