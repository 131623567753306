import React from "react";
import { useNavigate } from "react-router";

import NewsPageCard from "components/Cards/NewsPageCard";
import { Event } from "data/api/event/type";
import { formatDate } from "@fullcalendar/core";
import moment from "moment";

interface Props {
  data: Event[];
}

const EventsList = ({ data }: Props) => {
  const navigate = useNavigate();

  return (
    <div
      className={
        "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-[15px] md:mt-[20px] mt-[15px] xl:mt-[30px]"
      }
    >
      {(data?.length &&
        data.map((item) => (
					<NewsPageCard
						key={`news-block-item-${item.id}`}
						imageSrc={process.env.REACT_APP_FILES_URL + "/" + item?.photo || ""}
						title={item.title}
						watched={item.views_count}
						likes={item.likes_count}
						date={`${item.date ? moment(item.date).format("DD.MM.YYYY") : ""} ${item.start_time || ""}`}
						onClick={() => navigate(`view/${item.id}`)}
					/>
				))) ||
        null}
    </div>
  );
};

export default EventsList;
