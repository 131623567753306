import React, { useState } from "react";
import Carousel from "../../components/Carousel";
import BreadCrump from "../../components/BreadCrump";
import TableContainer from "../../components/Form/Table";

import HomeCalendar from "../../components/Calendar/HomeCalendar";
import Notifications from "components/Notifications";
import ChatIcon from "components/constant/icons/ChatIcon";
import HomePageCard from "../../components/Cards/HomePageCard";
import { images } from "utils/resource";
import { useWindowSize } from "../../hooks/useWindowSize";
import HomePageMobileCard from "../../components/Cards/HomePageMobileCard";
import { TrainingSider } from "../../components/Carousel/TrainingSider";
import { useRoles } from "hooks/useRoles";
import { useGetEventsQuery } from "data/api/event";
import { useSelector } from "react-redux";
import { RootState } from "store";
import moment from "moment";
import { useNavigate } from "react-router";

const HomePage = () => {
  const { isMd } = useWindowSize();
  const roles = useRoles(null);
  const selection = useSelector((state: RootState) => state);
  const navigate = useNavigate();
  const { data: events } = useGetEventsQuery(
    `city_id=${selection.header.city_id}`
  );

  const count = [1, 2, 3];

  const [contentTable, setContentTable] = useState<any[]>([
    {
      name: "Показ",
      task: "Все задачи передаются в CRM-систему ....",
      fullname: "Артур Спиридонов",
      date: "16.05.24  19:00",
      project: "ЖК Норпалова",
    },
    {
      name: "Показ",
      task: "Все задачи передаются в CRM-систему ....",
      fullname: "Артур Спиридонов",
      date: "16.05.24  19:00",
      project: "ЖК Норпалова",
    },
    {
      name: "Показ",
      task: "Все задачи передаются в CRM-систему ....",
      fullname: "Артур Спиридонов",
      date: "16.05.24  19:00",
      project: "ЖК Норпалова",
    },
    {
      name: "Показ",
      task: "Все задачи передаются в CRM-систему ....",
      fullname: "Артур Спиридонов",
      date: "16.05.24  19:00",
      project: "ЖК Норпалова",
    },
  ]);

  const [headerTable, setHeaderTable] = useState([
    {
      key: "task",
      title: "Зачачи",
      sortable: true,
    },
    {
      key: "fullname",
      title: "Клиент",
    },
    {
      key: "date",
      title: "Дедлайн",
      sortable: true,
    },
    {
      key: "project",
      title: "Проект",
      sortable: true,
    },
  ]);

  const [newsList, setNewsList] = useState([
    {
      img: images.newsImage,
      title: "Скидка на квартиры сотрудникам",
      tags: ["Брусника", "Мероприятие"],
      views: 1000,
      likes: 42,
      date: "12.12.2024",
    },
    {
      img: images.newsImage,
      title: "Скидка на квартиры сотрудникам",
      tags: ["Брусника", "Мероприятие"],
      views: 1000,
      likes: 42,
      date: "12.12.2024",
    },
    {
      img: images.newsImage,
      title: "Скидка на квартиры сотрудникам",
      tags: ["Брусника", "Мероприятие"],
      views: 1000,
      likes: 42,
      date: "12.12.2024",
    },
    {
      img: images.newsImage,
      title: "Скидка на квартиры сотрудникам",
      tags: ["Брусника", "Мероприятие"],
      views: 1000,
      likes: 42,
      date: "12.12.2024",
    },
    {
      img: images.newsImage,
      title: "Скидка на квартиры сотрудникам",
      tags: ["Брусника", "Мероприятие"],
      views: 1000,
      likes: 42,
      date: "12.12.2024",
    },
    {
      img: images.newsImage,
      title: "Скидка на квартиры сотрудникам",
      tags: ["Брусника", "Мероприятие"],
      views: 1000,
      likes: 42,
      date: "12.12.2024",
    },
  ]);

  return (
    <section id="homepage" className=" bg-bg-default relative">
      <div className="md:py-[30px] py-[10px] br-container flex gap-[20px] flex-col lg:flex-row">
        <div className="xl:w-[76%] lg:w-[70%] w-full">
          <div className="md:mb-[20px] mb-[15px] 2xl:mb-[30px] xl:h-[320px] md:h-[270px] h-[140px] w-full">
            <Carousel>
              {count.map((item, i) => (
                <div className="rounded-[8px]" key={i}>
                  <img
                    src={images.carouselImg}
                    alt="img of building"
                    className="w-full xl:h-[320px] md:h-[270px] h-[140px]"
                  />
                </div>
              ))}
            </Carousel>
          </div>

          {isMd && <HomeCalendar />}

          {roles.isOzk_manager || roles.isManager ? (
            <div className="md:mt-[20px] mt-[15px] xl:mt-[30px]">
              <BreadCrump link="" title="Задачи" hasRightIcon={true} />

              {isMd ? (
                <div className={"bg-white-default rounded-lg"}>
                  {contentTable.map((item: any, index) => (
                    <div
                      key={`table-content-item-${index}`}
                      onClick={() => {}}
                      className={`cursor-pointer w-full p-1 ${
                        index ? "border-t" : ""
                      } border-boder-default px-[15px] py-[12px]`}
                    >
                      <div className={"flex items-center mb-[5px]"}>
                        <p
                          className={
                            "text-sm font-medium mr-[12px] text-blue-default"
                          }
                        >
                          {item.name}
                        </p>

                        <p className={"text-xs text-accent-default opacity-50"}>
                          {item.date}
                        </p>
                      </div>

                      <div className={"mb-[15px]"}>
                        <p className={"text-xs"}>{item.task}</p>
                      </div>

                      <div
                        className={"flex justify-between text-sm font-medium"}
                      >
                        <p>{item.project}</p>

                        <p>{item.fullname}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <TableContainer
                  header={headerTable}
                  content={contentTable}
                  setData={setContentTable}
                />
              )}
            </div>
          ) : null}

          {!isMd && (
            <div className="md:mt-[20px] mt-[15px] xl:mt-[30px]">
              <BreadCrump link="/news" title="Новости" hasRightIcon={true} />

              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[15px]">
                {isMd
                  ? // <HomePageMobileCard list={newsList} />
                    null
                  : newsList.map((item, i) => (
                      <HomePageCard
                        imageSrc={item.img}
                        key={i}
                        title={item.title}
                        hashtag={item.tags}
                        watched={item.views}
                        likes={item.likes}
                        date={item.date}
                      />
                    ))}
              </div>
            </div>
          )}

          {isMd && <TrainingSider className={isMd ? "mt-[15px]" : ""} />}
          {events?.data.length ? (
            <div className="md:mt-[20px] mt-[15px] xl:mt-[30px]">
              <BreadCrump
                link="/events"
                title="Мероприятия"
                hasRightIcon={true}
              />

              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[15px]">
                {isMd ? (
                  <HomePageMobileCard list={events?.data} />
                ) : (
                  events?.data.map((item, i) => (
                    <HomePageCard
                      imageSrc={
                        process.env.REACT_APP_FILES_URL + "/" + item?.photo ||
                        ""
                      }
                      key={i}
                      title={item.title}
                      hashtag={item.tags}
                      watched={item.views_count}
                      likes={item.likes_count}
                      date={`${
                        item.date ? moment(item.date).format("DD.MM.YYYY") : ""
                      }`}
                      onClick={() => navigate(`events/view/${item.id}`)}
                    />
                  ))
                )}
              </div>
            </div>
          ) : null}
        </div>

        <div className="xl:w-[24%] lg:w-[30%] w-full flex flex-col gap-[10px] self-start">
          {!isMd && (
            <>
              <TrainingSider />
              <HomeCalendar />
            </>
          )}
          <Notifications />
        </div>
      </div>
      {!isMd && (
        <button
          className="w-[48px] h-[48px] flex items-center justify-center
       bg-accent-default fixed bottom-[30px] right-[30px] rounded-full outline-none"
        >
          <ChatIcon />
        </button>
      )}
    </section>
  );
};

export default HomePage;
