import React, { useEffect, useState } from "react";
import PageTitle from "components/PageTitle";
import ArrowDropdown from "components/constant/icons/ArrowDropdown";
import TextField from "components/Form/TextField/TextField";
import SearchIcon from "components/constant/icons/SearchIcon";
import AnimationFrame from "components/AnimationFrame.tsx";
import { useRoles } from "hooks/useRoles";
import PencilIcon from "components/constant/icons/PencilIcon";
import { useNavigate } from "react-router";
import AnswerModal from "./AnswerModal";
import {
  useGetTrainingCategoriesQuery,
  useGetTrainingsByIdQuery,
} from "data/api/training";
import Loader from "components/Loader";
import { TrainingCategoryType, TrainingType } from "data/api/training/type";
import TrainingCategories from "./TrainingCategories";

const Training = () => {
  const [categoriesList, setCategoriesList] =
    useState<TrainingCategoryType[]>();
  const [category, setCategory] = useState<number>(0);
  const [isAnswerModalOpen, setIsAnswerModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState<TrainingType>();

  const roles = useRoles(null);
  const navigate = useNavigate();
  useGetTrainingsByIdQuery(category);
  const { data: categories, isLoading } = useGetTrainingCategoriesQuery();

  useEffect(() => {
    init();
  }, [categories?.data]);

  function init() {
    if (categories?.data.length) {
      setCategoriesList(categories?.data);
    }
  }

  const handleSelectCategory = (id: number) => {
    setCategory(id);
    if (id) {
      setCategoriesList((prev) => prev?.filter((item) => item.id === id));
      return;
    }
    init();
  };

  const handleOpenAnswer = (quest: TrainingType) => {
    setSelectedQuestion(quest);
    setIsAnswerModalOpen(true);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (search.length) {
      setCategoriesList(
        categories?.data.filter((item) => item.name.startsWith(value))
      );
    }

    setSearch(value);
  };

  return (
    <section id="#training" className="br-container min-h-[50vh] py-5">
      <div className="hidden lg:mb-5 lg:flex justify-between ">
        <PageTitle text="Обучение" />
        <TextField
          value={search}
          onChange={handleSearch}
          imgLeft={<SearchIcon />}
          className="w-[332px] outline-none"
          placeholder="Поиск"
        />
      </div>
      <TrainingCategories
        categories={categories?.data}
        handleSelectCategory={handleSelectCategory}
        category={category}
      />
      {isLoading && <Loader />}

      <AnimationFrame>
        <div className="w-full p-[20px] sm:p-[30px] rounded-[8px] bg-white-default grid  lg:grid-cols-2 gap-6 relative min-h-[100px]">
          {categoriesList?.length
            ? categoriesList.map((categ) => (
                <div
                  className=" text-accent-default font-semibold"
                  key={categ.id}
                >
                  <h2 className="text-[20px] sm:text-2xl mb-3">
                    {categ?.name}
                  </h2>
                  <div className="flex flex-col gap-[10px]">
                    {categ.training.map((quest) => (
                      <div
                        onClick={() => handleOpenAnswer(quest)}
                        className="p-[10px] pl-[15px] rounded-[12px] border border-boder-default cursor-pointer flex items-center sm:items-start justify-between gap-[10px]"
                        key={quest.id}
                      >
                        <div className="flex flex-col">
                          <p className=" text-sm sm:text-[16px] font-normal">
                            {quest.title}
                          </p>
                        </div>

                        <div className="-rotate-90 sm:self-start">
                          <ArrowDropdown />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            : !isLoading && (
                <div className="text-accent-default absolute left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] opacity-70 ">
                  Обучение не найдено
                </div>
              )}
        </div>
      </AnimationFrame>

      {(roles.isAdmin || roles.isAffiliate_manager) && (
        <div className=" hidden lg:fixed right-[30px]  bottom-[30px] lg:flex items-center justify-center">
          <div
            className={
              "rounded-full bg-accent-default flex items-center shadow-md border border-bg-default justify-center p-3 cursor-pointer"
            }
            onClick={() => navigate("edit")}
          >
            <PencilIcon color={"#FFFFFF"} />
          </div>
        </div>
      )}
      <AnswerModal
        selectedQuestion={selectedQuestion}
        isAnswerModalOpen={isAnswerModalOpen}
        setIsAnswerModalOpen={setIsAnswerModalOpen}
      />
    </section>
  );
};

export default Training;
