import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import ButtonForm from "components/Button/ButtonForm";
import Modal from "components/Modal";
import PlusIcon from "components/constant/icons/PlusIcon";
import CheckIcon from "components/constant/icons/CheckIcon";
import CancelIcon from "components/constant/icons/CancelIcon";
import PencilIcon from "components/constant/icons/PencilIcon";
import TrashIcon from "components/constant/icons/TrashIcon";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { useGetTrainingCategoriesQuery } from "data/api/training";
import {
  handleCreateTrainingCategory,
  handleDeleteTrainingCategories,
  handleUpdateTrainingCategories,
} from "store/TrainingReducer";
import { TrainingCategoryType } from "data/api/training/type";

interface Props {
  isCreateCategoryModalOpen: boolean;
  setIsCreateCategoryModalOpen: Dispatch<SetStateAction<boolean>>;
}

const CreateCategoryModal: React.FC<Props> = ({
  isCreateCategoryModalOpen,
  setIsCreateCategoryModalOpen,
}) => {
  const [topics, setTopics] = useState<TrainingCategoryType[] | undefined>([]);
  const selector = useSelector((state: RootState) => state.training);
  const { data: categories } = useGetTrainingCategoriesQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    initInLocalState();
  }, [selector.categories, dispatch]);

  function initInLocalState() {
    setTopics(selector.categories);
  }

  const handleAddTopic = () => {
    const newTopic: TrainingCategoryType = {
      id: Date.now(),
      name: "",
      isEditing: true,
      training: [],
      sequnce_id: categories?.data.length ? categories?.data.length + 2 : 1,
    };
    setTopics([...(topics || []), newTopic]);
  };

  const handleEditTopic = (item: TrainingCategoryType) => {
    setTopics(
      topics?.map((topic) =>
        topic.id === item.id ? { ...topic, isEditing: true } : topic
      )
    );
  };

  const handleSaveTopic = (item: TrainingCategoryType) => {
    if (item.name.trim()) {
      const isUpdate = selector.categories.some((cat) => cat.id === item.id);
      if (isUpdate) {
        dispatch(
          handleUpdateTrainingCategories({
            ...item,
            name: item.name,
            isEditing: false,
          })
        );
      } else {
        dispatch(
          handleCreateTrainingCategory({
            ...item,
            name: item.name,
            isEditing: false,
            training: [],
          })
        );
      }
    } else {
      handleDeleteTopic(item);
    }
  };

  const handleCancelEdit = (item: TrainingCategoryType) => {
    const topicToCancel = topics?.find((topic) => topic.id === item.id);
    if (topicToCancel?.name.trim() === "") {
      handleDeleteTopic(item);
    } else {
      setTopics(
        selector.categories.map((topic) =>
          topic.id === item.id ? { ...topic, isEditing: false } : topic
        )
      );
    }
  };

  const handleDeleteTopic = (item: TrainingCategoryType) => {
    dispatch(handleDeleteTrainingCategories(item));
  };

  return (
    <Modal
      isOpen={isCreateCategoryModalOpen}
      className="mx-auto w-full sm:w-[440px] rounded-t-[30px] rounded-b-0 sm:rounded-lg relative"
      onClose={() => setIsCreateCategoryModalOpen(false)}
    >
      <h1 className="font-extrabold text-[20px] font-dewi mr-auto mb-5">
        Темы
      </h1>
      <div className="flex flex-col max-h-[50vh] overflow-y-auto toolbar-scroll pr-3">
        {topics?.map((topic) => (
          <React.Fragment key={topic.id}>
            <div className="flex items-center gap-[10px]">
              {topic.isEditing ? (
                <input
                  type="text"
                  className="flex-1 border border-boder-default rounded py-3 px-6 outline-none pr-3"
                  defaultValue={topic.name}
                  onChange={(e) =>
                    setTopics(
                      topics?.map((t) =>
                        t.id === topic.id ? { ...t, name: e.target.value } : t
                      )
                    )
                  }
                />
              ) : (
                <span className="flex-1 py-3 text-center px-6 border border-accent-default">
                  {topic.name}
                </span>
              )}
              <div className="flex">
                {topic.isEditing ? (
                  <>
                    <button
                      className="w-[48px] h-[48px] flex items-center justify-center text-white-default bg-accent-default mr-1 rounded"
                      onClick={() => handleSaveTopic(topic)}
                    >
                      <CheckIcon color="#FFFFFF" />
                    </button>
                    <button
                      className="w-[48px] h-[48px] flex items-center justify-center text-white-default border border-red-default rounded"
                      onClick={() => handleCancelEdit(topic)}
                    >
                      <CancelIcon color="#EF3B24" />
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="w-[48px] h-[48px] flex items-center justify-center text-white-default border border-accent-default mr-1 rounded"
                      onClick={() => handleEditTopic(topic)}
                    >
                      <PencilIcon color="#37465B" />
                    </button>
                    <button
                      className="w-[48px] h-[48px] flex items-center justify-center text-white-default border border-red-default rounded"
                      onClick={() => handleDeleteTopic(topic)}
                    >
                      <TrashIcon color="#EF3B24" />
                    </button>
                  </>
                )}
              </div>
            </div>
            <div className="w-full h-[1px] my-3 bg-boder-default"></div>
          </React.Fragment>
        ))}

        <div className="flex flex-col mb-5">
          <button
            onClick={handleAddTopic}
            className="h-[48px] w-full rounded-lg flex items-center justify-center border border-dashed border-blue-default outline-none"
          >
            <PlusIcon width={28} height={28} color="#3583FF" />
          </button>
        </div>

        <ButtonForm
          onClick={() => setIsCreateCategoryModalOpen(false)}
          type="submit"
          text="Готово"
          className="py-3 px-6 border bg-accent-default xl:flex-1 flex justify-center text-white-default w-full hover:bg-accent-disabled hover:text-bg-default"
        />
      </div>
    </Modal>
  );
};

export default CreateCategoryModal;
