import React, { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { clsx } from "yet-another-react-lightbox";

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  className?: string;
  containerClassName?: string;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  children,
  className = "",
  containerClassName = "",
  onClose,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";

      return () => {
        document.body.style.overflow = "auto";
      };
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <div
        className={clsx(
          "fixed inset-0 flex items-end md:items-center md:justify-center bg-[#DCDCDC] bg-opacity-40 z-50",
          containerClassName,
        )}
        onClick={(event: any) => {
          if (event.target.id === "bg-modal") onClose && onClose();
        }}
        id={"bg-modal"}
      >
        <motion.div
          className={`${className}`}
          initial={{ y: "100vh", opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: "100vh", opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <div
            className={`bg-white-default w-full md:p-[30px] p-[20px] text-accent-default shadow-sm  ${className}`}
          >
            {isOpen && children}
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

export default Modal;
