import React, { useEffect, useState } from "react";
import { User } from "data/api/profile/type";
import { images } from "utils/resource";
import { formatPhoneNumber } from "hooks/useFormatInput";
import PencilIcon from "components/constant/icons/PencilIcon";
import { formatDate } from "@fullcalendar/core";
import ArrowTableIcon from "components/constant/icons/Table/ArrowTableIcon";
import { UserRole } from "enums/users";

interface Props {
  user?: User[];
  handleEditItem: (data: User) => void;
  handleClickItem: (data: User) => void;
  handleChangeFields: (
    fullname?: string,
    role?: UserRole | null,
    city_id?: number | null,
    registered_at?: string,
    order_by?: string,
    sort_by?: string
  ) => void;
}
enum ORDERBY {
  ASC = "ASC",
  DESC = "DESC",
}

const AdminMobile = ({
  user,
  handleEditItem,
  handleClickItem,
  handleChangeFields,
}: Props) => {
  const [sortIsActive, setSortIsActive] = useState("");
  const [orderBy, setOrderBy] = useState<ORDERBY>(ORDERBY.ASC);

  useEffect(() => {
    handleChangeFields("", null, null, "", orderBy, sortIsActive);
  }, [orderBy]);

  const handleClickCard = (e: React.MouseEvent, item: User) => {
    e.stopPropagation();
    handleClickItem(item);
  };

  const handleEditCard = (e: React.MouseEvent, item: User) => {
    e.stopPropagation();
    handleEditItem(item);
  };
  const handleSort = (value: string) => {
    if (orderBy === ORDERBY.DESC) {
      setOrderBy(ORDERBY.ASC);
    } else {
      setOrderBy(ORDERBY.DESC);
    }

    setSortIsActive(value);
  };

  const sortBy = [
    { id: 1, value: "fullname", label: "ФИО" },
    { id: 2, value: "agency_name", label: "Агентство" },
    { id: 3, value: "role", label: "Роль" },
    { id: 4, value: "city_name", label: "Город" },
    { id: 5, value: "status", label: "Статус" },
    { id: 6, value: "registered_at", label: "Регистрация" },
  ];
  const imgUrl = process.env.REACT_APP_FILES_URL;

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-[10px]">
      <div className="flex items-center gap-[10px] no-scrollbar overflow-x-auto ">
        {sortBy &&
          sortBy.map((item) => (
            <div
              key={item.id}
              onClick={() => handleSort(item.value)}
              className={`py-[3px] px-[12px] sm:px-[24px] lg:py-[11px] lg:px-6 flex items-center gap-2 rounded-[32px] text-[12px] font-normal flex-shrink-0  cursor-pointer ${
                sortIsActive === item.value
                  ? "bg-accent-default text-white-default"
                  : "bg-white-default text-accent-default"
              }`}
            >
              <p>{item.label}</p>

              <div
                className={`${
                  orderBy === ORDERBY.DESC && sortIsActive === item.value
                    ? "rotate-180"
                    : "rotate-0"
                }`}
              >
                <ArrowTableIcon
                  color={sortIsActive === item.value ? "#FFFFFF" : "#37465B"}
                />
              </div>
            </div>
          ))}
      </div>
      {user?.map((item) => (
        <div
          key={item.id}
          className="flex flex-col gap-3 bg-white-default p-[15px] rounded-lg"
          onClick={(e) => handleClickCard(e, item)}
        >
          <div className="flex gap-3 items-center">
            <img
              src={item.avatar ? `${imgUrl}/${item.avatar}` : images.defaultImg}
              alt="user image"
              width={50}
              height={50}
              className="rounded-full w-[50px] h-[50px]"
              crossOrigin="anonymous"
            />
            <div className="flex flex-col ">
              <p className="text-sm text-accent-default font-semibold">
                {item?.fullName}
              </p>
              <p className="text-[12px] text-accent-disabled font-normal">
                {item.role}
              </p>
            </div>
            <div className=" ml-auto self-start">
              <div
                onClick={(e) => handleEditCard(e, item)}
                className="h-[34px] w-[34px] rounded flex items-center justify-center border border-accent-default"
              >
                <PencilIcon />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <p className="text-sm text-accent-default font-medium">
              +7 {formatPhoneNumber(item.phone)}
            </p>
            <p className="text-sm text-accent-default font-medium">
              {item?.city?.name}
            </p>
            <p className="text-sm text-accent-default font-medium">
              {item?.agency?.legalName}
            </p>
            <p className="text-sm text-accent-default font-medium">
              {formatDate(item?.created_at)}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AdminMobile;
