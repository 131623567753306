import React from "react";
import { Icons } from "./interface";

const LikeIcon = (
	{
		color = "#8E95A1"
	}: Icons
) => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.85481 2.65479C2.52991 1.9799 3.44542 1.60076 4.40001 1.60076C5.3546 1.60076 6.27011 1.9799 6.94521 2.65479L8.00001 3.70869L9.05481 2.65479C9.3869 2.31096 9.78414 2.0367 10.2234 1.84803C10.6626 1.65936 11.135 1.56005 11.613 1.55589C12.091 1.55174 12.565 1.64282 13.0074 1.82384C13.4499 2.00485 13.8518 2.27216 14.1898 2.61017C14.5278 2.94818 14.7952 3.35013 14.9762 3.79256C15.1572 4.23499 15.2483 4.70903 15.2441 5.18704C15.24 5.66504 15.1406 6.13743 14.952 6.57665C14.7633 7.01586 14.489 7.4131 14.1452 7.74519L8.00001 13.8913L1.85481 7.74519C1.17992 7.07009 0.800781 6.15458 0.800781 5.19999C0.800781 4.2454 1.17992 3.32989 1.85481 2.65479V2.65479Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LikeIcon;
