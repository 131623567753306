import { apiService } from "..";
import { MetaData } from "../exception";
import { Agency } from "./type";

export const addTagTypes = ["agencies"] as const;

export const newsApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      searchAgencies: build.query<MetaData<Agency[]>, string>({
        query: (param) => ({
          path: `/agencies?name=${param}`,
        }),
      }),
      getAllAgencies: build.query<MetaData<Agency[]>, void>({
        query: () => ({
          path: `/agencies`,
        }),
      }),
    }),
  });

export const { useSearchAgenciesQuery, useGetAllAgenciesQuery } = newsApiHooks;
