import React from "react";

import { useDispatch, useSelector } from "react-redux";

import ContentLayout from "./ContentLayout";
import CheckBox from "components/Form/CheckBox";
import ButtonForm from "components/Button/ButtonForm";
import { RootState } from "store";
import SelectedObjectBar from "./SelectedObjectsBar";
import Filter from "./Filter";
import {
  chessAppearance,
  resetState,
  toggleGenerateSelection,
} from "store/ApartmentReducer";
import PageTitle from "components/PageTitle";
import { useWindowSize } from "../../hooks/useWindowSize";
import Modal from "components/Modal";
import { getIsOpenFilters } from "store/BaseClientsReducer";
import { Primise } from "enums/primisesType";

const Apartment = () => {
  const { isMd, isLg } = useWindowSize();

  const selection = useSelector((state: RootState) => state.apartment);
  const storeIsOpenFilters = useSelector(getIsOpenFilters);
  const dispatch = useDispatch();

  const handleAppearanceChange = () => {
    dispatch(chessAppearance());
  };

  const generateSelection = () => {
    if (selection.generateSelection) {
      dispatch(toggleGenerateSelection());
      dispatch(resetState());
    } else {
      dispatch(toggleGenerateSelection());
      dispatch(chessAppearance());
    }
  };

  const getTitle = () => {
    switch (selection.filters.type) {
      case Primise.STOREROOM:
        return ["Выбор кладовой", "Кладовые"];
      case Primise.PARKING:
        return ["Выбор паркинга", "Паркинг"];
      case Primise.COMMERCIAL:
        return ["Выбор коммерческий", "Коммерческий"];
      default:
        return ["Выбор квартир", "Квартиры"];
    }
  };

  return (
    <div className="bg-bg-default">
      <div className="br-container">
        <div className="flex">
          {isLg && <Filter title={getTitle()[0]} />}
          {isMd && storeIsOpenFilters && (
            <Modal
              className="overflow-y-scroll rounded-t-[30px] h-[90vh] w-full toolbar-scroll"
              isOpen={storeIsOpenFilters}
            >
              <Filter title={getTitle()[0]} />
            </Modal>
          )}
          <div className="relative h-full w-full overflow-hidden">
            <div className="lg:pl-[20px] pt-[20px] xl:pl-[30px] xl:pt-[30px]">
              <ContentLayout>
                <div className="flex flex-col xl:flex-row gap-[20px] sm:justify-between  mb-[10px]">
                  <PageTitle text={getTitle()[1]} />
                  <div className=" self-start sm:self-end  hidden lg:flex  flex-col-reverse sm:flex-row sm:items-center justify-between gap-[30px]">
                    {selection.filters.type === Primise.APARTMENT &&
                      !selection.generateSelection && (
                        <CheckBox
                          onChange={handleAppearanceChange}
                          value={selection.chess}
                          label="Шахматка"
                        />
                      )}
                    <ButtonForm
                      onClick={generateSelection}
                      text={selection.generateSelection ? "Вернуться к списку" : "Сгенерировать подборку"}
                      className={`border mt-[1px] ml-auto 2xl:w-[255px] py-3 px-6 flex justify-center
              text-white-default  ${
                selection.generateSelection
                  ? " bg-red-default hover:bg-red-disabled"
                  : "bg-accent-default hover:bg-accent-disabled "
              }`}
                    />
                  </div>
                </div>
              </ContentLayout>
            </div>
          </div>
        </div>
      </div>
      {selection.generateSelection && <SelectedObjectBar />}
    </div>
  );
};

export default Apartment;
