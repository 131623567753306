import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import { ApiException } from "./exception";
import { router } from "routes";

interface FetchParams {
  path: string;
  method?: "GET" | "POST" | "PUT" | "DELETE";
  body?: any;
}

export const apiService = createApi({
  baseQuery: baseQueryFn(),
  endpoints: () => ({}),
  reducerPath: "apiService",
  keepUnusedDataFor: 30,
});

export const api = {
  async fetch<T>({ path, method = "GET", body }: FetchParams): Promise<T> {
    const token = localStorage.getItem("token");
    const isFormData = body instanceof FormData;

    const headers: HeadersInit = {
      Authorization: `Bearer ${token}`,
      ...(!isFormData && { "Content-Type": "application/json" }),
    };

    const response = await fetch(`${process.env.REACT_APP_BASE_URL}${path}`, {
      headers,
      method,
      body: isFormData ? body : JSON.stringify(body),
    });

    const data = await response.json();

    const status = data?.meta?.prompt?.status ?? response.status;

    if (!response.ok) {
      throw {
        message: data?.meta?.prompt?.labels.ru ?? "Something went wrong",
        status,
      };
    }

    return data as T;
  },
};

function baseQueryFn(): BaseQueryFn<FetchParams, unknown, ApiException> {
  return async ({ path, method, body }, { dispatch }) => {
    try {
      const result = await api.fetch({
        path,
        method,
        body,
      });

      return { data: result };
    } catch (error) {
      const apiError = error as ApiException;

      if (apiError.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("phone");
        localStorage.removeItem("userId");
        localStorage.removeItem("old_token");
        dispatch(apiService.util.resetApiState());
        router.navigate("/sign-in");
      }
      return { error: apiError };
    }
  };
}
