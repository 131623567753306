import React, { useEffect, useState } from "react";
import TableContainer from "components/Form/Table";
import PageTitle from "components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { toggleItemInStore } from "store/ApartmentReducer";
import {
  Apartment,
  groupByProjectName,
} from "../ApartmentList/dataTransformer";
import { header } from "../data";

interface porpsType {
  data: any;
  isLoading: boolean;
}
interface TranfsdormedDataType {
  id: string;
  floor: number;
  section: string;
  number: string;
  building: string;
  price: string;
  size: string;
  status: string;
}
interface dataType {
  [projectName: string]: TranfsdormedDataType[];
}

const ParkingOrStoreroom = ({ data, isLoading }: porpsType) => {
  const [apartmentData, setApartmentData] = useState<dataType[] | any>([]);

  const selection = useSelector((state: RootState) => state.apartment);

  const dispatch = useDispatch();

  const handleSelectItem = (id: number) => {
    dispatch(toggleItemInStore({ id }));
  };

  const formatedPrice = (price: number) => {
    return new Intl.NumberFormat("ru-Ru", {
      style: "currency",
      currency: "RUB",
    }).format(price);
  };

  useEffect(() => {
    const transformedData = Object.entries(groupByProjectName(data))?.map(
      ([projectName, tableData]) => {
        return {
          projectName,
          data: tableData?.map((item: Apartment) => ({
            id: item?.id,
            floor: item?.floor,
            section: item?.section?.name,
            number: item?.number,
            building: item?.building?.name,
            price: formatedPrice(Number(item?.price)),
            size: item?.size,
            status: item?.status,
          })),
        };
      }
    );

    setApartmentData(transformedData);
  }, [data]);

  return (
    <>
      {apartmentData.length ? (
        apartmentData.map(
          (item: { projectName: string; data: Apartment[] }, i: number) => (
            <div key={i}>
              <PageTitle
                text={item?.projectName}
                className="mb-[20px] mt-[30px]"
              />
              <TableContainer
                header={header}
                content={item?.data}
                doSelectData={selection.generateSelection}
                handleSelectTableData={handleSelectItem}
              />
            </div>
          )
        )
      ) : (
        <h3 className="text-center text-accent-default">Ничего не найдено</h3>
      )}
    </>
  );
};

export default ParkingOrStoreroom;
