import React from "react";
import EyeIcon from "../constant/icons/EyeIcon";
import LikeIcon from "../constant/icons/LikeIcon";
import Slider from "react-slick";
import moment from "moment";
import { useNavigate } from "react-router";

interface CardProps {
  list: any[];
}

const HomePageMobileCard = ({ list }: CardProps) => {
	const navigate = useNavigate();

  return (
    <Slider
      className="w-full"
      infinite
      speed={500}
      slidesToShow={1}
      slidesToScroll={1}
      arrows={false}
      adaptiveHeight
    >
      {list.map((item, i) => (
        <div key={`news-item-${i}`} className={""} onClick={()=> navigate(`events/view/${item.id}`)}>
          <div className="flex items-center bg-white-default rounded-lg p-[5px]">
            <div className="min-w-[120px] h-[120px]">
              <img
                src={process.env.REACT_APP_FILES_URL + "/" + item?.photo || ""}
                crossOrigin="anonymous"
                alt={item.title}
                className="w-full h-full object-cover rounded-[5px]"
              />
            </div>
            <div className="flex flex-col justify-between px-[10px]">
              <div className={"pb-[15px]"}>
                <div className="flex gap-[10px] text-[14px] leading-[18px] font-normal text-blue-default mb-2">
                  {item.tags.map((_item: string, idx: number) => (
                    <div
                      key={idx}
                      className="px-[10px] py-[5px] rounded-[100px] bg-light-blue text-[10px]"
                    >
                      #{_item}
                    </div>
                  ))}
                </div>

                <h3 className="text-sm leading-[18.75px] text-accent-default font-medium">
                  {item.title}
                </h3>
              </div>

              <div className="flex gap-[15px] items-center  text-[12px] text-accent-disabled font-medium leading-[16px]">
                <div className="flex items-center justify-center gap-[8px] cursor-pointer">
                  <EyeIcon color="#8E95A1" width="18" />
                  <span>{item.views_count}</span>
                </div>

                <div className="flex items-center justify-center gap-[8px] cursor-pointer">
                  <LikeIcon />
                  <span>{item.likes_count}</span>
                </div>

                <span className="ml-auto">
                  {item.date ? moment(item.date).format("DD.MM.YYYY") : ""}
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default HomePageMobileCard;
