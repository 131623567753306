import { createSlice } from "@reduxjs/toolkit";
import { User } from "data/api/profile/type";
import { getUserAction } from "./actions";

interface State {
  data: User | null;
  meta: {
    loading: boolean;
    error: string | null;
  };
}

const initialState: State = {
  data: null,
  meta: { loading: false, error: null },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers(builder) {
    builder.addCase(getUserAction.pending, (state) => {
      state.meta.loading = true;
      state.meta.error = null;
    });
    builder.addCase(getUserAction.fulfilled, (state, { payload }) => {
      state.meta.loading = false;
      state.data = payload;
    });
    builder.addCase(getUserAction.rejected, (state, { payload }) => {
      state.meta.loading = false;
      state.meta.error = payload ?? "что-то пошло не так";
    });
  },
  reducers: {},
});

export default userSlice.reducer;
