import React from "react";

const CoinsIcon = ({ color = "#3583FF" }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.60639 6.63639C7.38145 3.62508 10.115 1.40002 13.3683 1.40002C17.2242 1.40002 20.3501 4.52589 20.3501 8.38184C20.3501 11.456 18.3632 14.0662 15.6035 14.9982M15.1121 13.6182C15.1121 17.4742 11.9862 20.6 8.13025 20.6C4.2743 20.6 1.14844 17.4742 1.14844 13.6182C1.14844 9.76225 4.2743 6.63639 8.13025 6.63639C11.9862 6.63639 15.1121 9.76225 15.1121 13.6182Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CoinsIcon;
