import React, { useLayoutEffect, useRef } from "react";
import { images } from "utils/resource";
import { Office } from ".";

interface Props {
  contactsList?: Office[];
}

const ContactsMap = ({ contactsList }: Props) => {
  const mapRef = useRef<any>(null);

  useLayoutEffect(() => {
    if (contactsList?.length !== 0) {
      handleInit();
    }
  }, [contactsList]);

  const handleInit = () => {
    window.ymaps.ready(init);
    function init() {
      if (!mapRef.current) {
        mapRef.current = new window.ymaps.Map("map", {
          center: [contactsList?.[0].city.lat, contactsList?.[0].city.long],
          zoom: 9,
          controls: [],
        });
      }

      mapRef.current.geoObjects.removeAll();

      if (contactsList?.length !== 0) {
        contactsList?.forEach((ofis) => {
          const MyIconContentLayout =
            window.ymaps.templateLayoutFactory.createClass(
              `<div class="w-14 h-14 p-1 rounded-full bg-white-default relative after:absolute after:bottom-[-7px] after:left-4  after:h-0  after:w-0 after:border-r-[10px] after:border-t-[10px]
                after:border-l-[10px] after:border-r-transparent after:border-l-transparent after:border-t-white-default">
                <img src="${images.cardImg}" class="w-full h-full rounded-full" />
              </div>`
            );

          const myPlacemark = new window.ymaps.Placemark(
            [Number(ofis.lat), Number(ofis.long)],
            {},
            { iconLayout: MyIconContentLayout }
          );
          mapRef.current.geoObjects.add(myPlacemark);
        });

        mapRef.current.setCenter(
          [contactsList?.[0].city.lat, contactsList?.[0].city.long],
          9,
          { duration: 1500 }
        );
      }
    }
  };
  return <div id="map" className="flex-1 h-[437px] w-full overflow-hidden" />;
};

export default ContactsMap;
