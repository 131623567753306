export interface FilterOptions {
  projects: { label: string; value: string | number }[];
  buildings: { label: string; value: string | number }[];
  sections: { label: string; value: string | number }[];
  selectedId?: string;
  id?: number;
}

export interface dropDownOptionsType {
  label: string;
  value: string | number;
}

export const initialFilterOptions: FilterOptions = {
  projects: [],
  buildings: [],
  sections: [],
};

export type FilterKeys = "project_id" | "building_id" | "section_id";

export const filterKeyLabels: Record<FilterKeys, string> = {
  project_id: "Проект",
  building_id: "Дом",
  section_id: "Секция",
};

export interface optionsType {}
