import { apiService } from "..";
import { MetaData } from "../exception";
import { buildingResponseType } from "./type";

export const addTagTypes = ["buildings"] as const;

export const buildingsApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      getBuildings: build.query<MetaData<[]>, { projectId: number | null }>({
        query: (data) => ({
          path: data.projectId
            ? `/buildings?project_id=${data.projectId}`
            : "/buildings",
        }),
        providesTags: ["buildings"],
      }),
    }),
  });

export const { useGetBuildingsQuery } = buildingsApiHooks;
