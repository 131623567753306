import React, { useEffect, useState } from "react";
import ButtonForm from "components/Button/ButtonForm";
import AddUserIcons from "components/constant/icons/AddUserIcons";
import PeopleIcon from "components/constant/PeopleIcon";

import PageTitle from "components/PageTitle";
import AdminisFilter from "./AdminsFilter";
import AdminTableContainer from "components/Form/Table/AdministrationTable";
import AdminsModals from "./AdminsModals";
import { useRoles } from "hooks/useRoles";
import AdminSittings from "./AdminSittings";
import { useGetAllUsersQuery } from "data/api/profile";
import { User } from "data/api/profile/type";
import { PaginationType } from "modules/BaseClients";
import { objectToQueryString } from "utils/objectToQuery";
import Loader from "components/Loader";
import { useWindowSize } from "hooks/useWindowSize";
import AdminMobile from "./AdminMobile";
import { UserRole } from "enums/users";
import AnimationFrame from "components/AnimationFrame.tsx";

enum Tab {
  USERS = "users",
  SETTINGS = "settings",
}

export interface iFilter {
  page: number;
  limit: number;
  text?: string;
  role?: UserRole | null;
  city_id?: number | null;
  registered_at?: string;
  sort_by?: string;
  order_by?: string;
}

const Adminstartion = () => {
  const roles = useRoles(null);
  const [isVerifyModal, setIsVerifyModal] = useState(false);
  const [user, setUser] = useState<User>();
  const [isEditModal, setIsEditModal] = useState(false);
  const [tab, setTab] = useState(Tab.USERS);
  const [paginationInfo, setPaginationInfo] = useState<
    PaginationType | undefined
  >({
    currPage: 0,
    limit: 0,
    total: 0,
    totalPage: 0,
  });
  const [filter, setFilter] = useState<iFilter>({
    page: 1,
    limit: 10,
  });

  const { data, isLoading, refetch } = useGetAllUsersQuery(
    objectToQueryString(filter)
  );

  const [contentTable, setContentTable] = useState<User[] | undefined>([]);

  const [headerTable, setHeaderTable] = useState<
    { key: keyof User; title: string; sortable: boolean }[]
  >([
    {
      key: "fullName",
      title: "ФИО",
      sortable: true,
    },
    {
      key: "agency",
      title: "Агенство",
      sortable: true,
    },
    {
      key: "role",
      title: "Роль",
      sortable: true,
    },
    {
      key: "city",
      title: "Город",
      sortable: true,
    },
    {
      key: "status",
      title: "Статус",
      sortable: true,
    },
    {
      key: "created_at",
      title: "Регистрация",
      sortable: true,
    },
  ]);

  const windowSize = useWindowSize();
  useEffect(() => {
    init();
  }, [data?.data]);

  function init() {
    setContentTable((prevData) => {
      return prevData ? [...prevData, ...(data?.data || [])] : data?.data;
    });
    setPaginationInfo(data?.meta?.links);
  }

  const handleClickTableItem = (user: User) => {
    setUser(user);
    if (!user.is_verified) {
      setIsVerifyModal(true);
    }
  };

  const handleEditTableItem = (user: User) => {
    setIsEditModal(true);
    setUser(user);
  };

  function handleUpdateList() {
    const filterUpdate = {
      ...filter,
      page: filter.page + 1,
    };

    setFilter(filterUpdate);
  }

  function handleChangeFilter(
    text?: string,
    role?: UserRole | null,
    city_id?: number | null,
    registered_at?: string,
    order_by?: string,
    sort_by?: string
  ) {
    setFilter({
      ...filter,
      page: 1,
      text,
      role,
      city_id,
      registered_at,
      order_by,
      sort_by,
    });
  }

  return (
    <section className=" bg-bg-default">
      <div className="br-container py-4 min-h-[50vh]">
        {roles.isAdmin ? (
          <div className=" flex gap-[30px] border-b mb-4 border-boder-default ">
            <button
              onClick={() => setTab(Tab.USERS)}
              className={`text-accent-default text-sm font-normal outline-none py-3 ${
                tab === Tab.USERS && "border-b-2 border-accent-default"
              }`}
            >
              Пользователи
            </button>
            <button
              onClick={() => setTab(Tab.SETTINGS)}
              className={`text-accent-default text-sm font-normal outline-none py-3 ${
                tab === Tab.SETTINGS && "border-b-2 border-accent-default"
              }`}
            >
              Настройки
            </button>
          </div>
        ) : null}

        {tab === Tab.USERS ? (
          <>
            <div className="flex items-center justify-between">
              <div className="flex flex-col md:flex-row gap-1 md:gap-5 md:items-center">
                <PageTitle className=" text-[18px]" text="ООО Ломонова" />
                <div className="flex items-center gap-2">
                  <PeopleIcon />
                  <p className="text-sm text-accent-default font-medium">
                    {data?.meta?.data.total_user_count} пользователей
                  </p>
                </div>
              </div>
              {!roles.isAffiliate_manager ? (
                <ButtonForm
                  text={
                    windowSize.isLg
                      ? `${data?.meta?.data.new_user_count} новых пользователей`
                      : ""
                  }
                  leftIcon={
                    <AddUserIcons
                      color={windowSize.isLg ? "#ffffff" : "#37465B"}
                    />
                  }
                  className={`py-3 px-3 sm:px-6 ${
                    windowSize.isLg
                      ? "bg-accent-default hover:bg-accent-disabled"
                      : "border border-accent-default"
                  }  lg:w-[245px] text-white-default flex justify-center `}
                />
              ) : null}
            </div>
            <AdminisFilter handleChangeFields={handleChangeFilter} />
            {isLoading && <Loader />}
            <div className="py-5">
              {windowSize.isLg ? (
                <AnimationFrame>
                  <AdminTableContainer
                    header={headerTable}
                    content={contentTable}
                    setData={setContentTable}
                    onLeftClickTrContent={handleClickTableItem}
                    handleEditTableItem={handleEditTableItem}
                  />
                </AnimationFrame>
              ) : (
                <AdminMobile
                  user={data?.data}
                  handleEditItem={handleEditTableItem}
                  handleClickItem={handleClickTableItem}
                  handleChangeFields={handleChangeFilter}
                />
              )}

              {paginationInfo
                ? !(paginationInfo?.currPage >= paginationInfo?.totalPage) && (
                    <button
                      type={"button"}
                      className={
                        "py-3 px-6 mx-auto text-blue-default text-sm font-medium flex mt-[15px]"
                      }
                      onClick={() => handleUpdateList()}
                    >
                      Загрузить еще
                    </button>
                  )
                : null}
            </div>
            <AdminsModals
              refetch={refetch}
              user={user}
              isVerifyModal={isVerifyModal}
              setIsVerifyModal={setIsVerifyModal}
              isEditModal={isEditModal}
              setIsEditModal={setIsEditModal}
            />
          </>
        ) : (
          <AnimationFrame>
            <AdminSittings />
          </AnimationFrame>
        )}
      </div>
    </section>
  );
};

export default Adminstartion;
