import { useState, useEffect, useMemo } from "react";
import { useGetBuildingsQuery } from "data/api/building";
import { useGetProjectsQuery } from "data/api/project";
import { useGetSectionsQuery } from "data/api/sections";
import {
  dropDownOptionsType,
  FilterOptions,
  initialFilterOptions,
} from "modules/Apartment/Filter/type";
import { useSelector } from "react-redux";
import { RootState } from "store";

type optionsType = {
  id: number;
  name: string;
  fullname?: string;
}[];

type ItemType = {
  [key: string]: any;
};

export const useFormatForDropDownOptions = (
  data: optionsType,
  formatOption?: string
) => {
  const options = useMemo(() => {
    const formatOptions = (
      list: ItemType[],
      labelKey: string,
      valueKey: string | number
    ): dropDownOptionsType[] => {
      return list.map((item) => ({
        label: item[labelKey],
        value: item[valueKey],
      }));
    };

    const optionsArray = formatOptions(data, formatOption || "name", "id");
    return optionsArray;
  }, [data, formatOption]);

  return options;
};
export const useFilterOptions = (
  selectedProjectId: number,
  selectedBuildingId: number
): FilterOptions => {
  const selection = useSelector((state: RootState) => state.header);

  const { data: projectData } = useGetProjectsQuery(selection.city_id);
  const { data: buildingData } = useGetBuildingsQuery({
    projectId: selectedProjectId,
  });
  const { data: sectionData } = useGetSectionsQuery({
    buildingId: selectedBuildingId,
  });

  const [filterOptions, setFilterOptions] =
    useState<FilterOptions>(initialFilterOptions);

  useEffect(() => {
    const formatOptions = (data: any[], labelKey: string, valueKey: string) =>
      data?.map((item) => ({ label: item[labelKey], value: item[valueKey] })) ||
      [];

    const projects = formatOptions(projectData?.data || [], "name", "id");
    const buildings = formatOptions(buildingData?.data || [], "name", "id");
    const sections = formatOptions(sectionData?.data || [], "name", "id");

    setFilterOptions({ projects, buildings, sections });
  }, [projectData, buildingData, sectionData]);

  return filterOptions;
};

// For Test ====
type dropDownOptions = {
  label: string | number;
  value: string | number;
};

export const useFormatForDropDown = <T extends Record<string, any>>(
  data: T[],
  formatOption?: string
) => {
  const options = useMemo(() => {
    const formatOptions = (
      list: T[],
      labelKey: keyof T,
      valueKey: keyof T
    ): dropDownOptions[] => {
      return list.map((item) => ({
        label: item[labelKey] as string | number,
        value: item[valueKey] as string | number,
      }));
    };

    const optionsArray = formatOptions(data, formatOption || "name", "id");
    return optionsArray;
  }, [data, formatOption]);

  return options;
};
