export enum LeadOpStatus {
    ACTIVE = "активные",
    PAUSE = "на паузе",
    WIN = "выиграна",
    LOST = "проиграна",
    OTHER = "other",
  }
  
  export enum DealStatus {
    ACTIVE = "Активные",
    PAUSE = "На паузе",
    WIN = "Выиграна",
    LOST = "Проиграна",
  }
  export enum DealSteps {
    OPEN = "открыта",
    INTEREST_IN_PURCHASING = "интерес к покупке",
    PRESENTATION = "презентация",
    BOOKED = "бронь",
    REQUEST_FOR_CONTRACT = "заявка на договор",
    CONTRACT_IS_REGISTERED = "договор зарегистрирован",
    WON = "выиграна",
    BOOK_CANCELED = "отмененная бронь",
    LOST_BOOK = "слетевшая бронь",
    ON_PAUSE = "на паузе",
    CHECK_LEAD = "проверка лида",
    FAILED = "проиграна",
  }
  
  