import { useSignInAgentResendMutation } from "data/api/auth";
import { useResendSMSCodeMutation } from "data/api/profile";
import { verificationDataType } from "modules/Auth/types";
import { useEffect, useState } from "react";
import { UseFormReset } from "react-hook-form";
import { useLocation } from "react-router";

interface propsType {
  reset?: UseFormReset<verificationDataType>;
}

export const UseSMSCodeSender = ({ reset }: propsType) => {
  const location = useLocation();
  const [counter, setCounter] = useState(0);
  const [mutate, { isLoading }] = useSignInAgentResendMutation();
  const [resentSMSMutate] = useResendSMSCodeMutation();

  const handleResent = async () => {
    const phone = localStorage.getItem("phone");
    if (phone) {
      if (location.pathname === "/profile") {
        await resentSMSMutate({ data: { phone } });
        setCounter(59);
      } else {
        await mutate({ data: { phone: phone } });
        setCounter(59);
      }
    }
    reset?.();
  };

  const updateCounter = () => {
    setCounter(59);
  };

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [counter]);

  return {
    counter,
    handleResent,
    isLoading,
    updateCounter,
  };
};
