import React from "react";

const YoutubeIcon = () => {
  return (
    <svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.235 1.5675C16.7467 0.699 16.2169 0.53925 15.138 0.4785C14.0603 0.405375 11.3501 0.375 9.00225 0.375C6.64987 0.375 3.93862 0.405375 2.862 0.477375C1.78537 0.53925 1.25437 0.697875 0.761625 1.5675C0.25875 2.43488 0 3.92887 0 6.55912V6.56813C0 9.18713 0.25875 10.6924 0.761625 11.5507C1.25437 12.4193 1.78425 12.5768 2.86087 12.6499C3.93863 12.7129 6.64987 12.75 9.00225 12.75C11.3501 12.75 14.0602 12.7129 15.1391 12.651C16.218 12.5779 16.7479 12.4204 17.2361 11.5519C17.7435 10.6935 18 9.18825 18 6.56925V6.56025C18 3.92888 17.7435 2.43488 17.235 1.5675ZM6.75 9.9375V3.1875L12.375 6.5625L6.75 9.9375Z"
        fill="#3B4658"
      />
    </svg>
  );
};

export default YoutubeIcon;
