import React, { useState } from "react";
import PageTitle from "components/PageTitle";
import ToggleSwitcher from "components/ToggleSwitcher";
import ButtonForm from "components/Button/ButtonForm";
import AddIconInCircle from "components/constant/icons/AddIconInCircle";
import { useWindowSize } from "hooks/useWindowSize";
import TableContainer from "components/Form/Table";
import TasksModals from "./TasksModals";

const Tasks = () => {
  const { isMd } = useWindowSize();
  const [isTaskViewModalOpen, setTaskViewModalOpen] = useState(false);
  const [taskResultOpen, setTaskResultOpen] = useState(false);
  const [taskAddModalOpen, setTaskAddModalOpen] = useState(false);
  const [mortgageModalOpen, setMortgageModalOpen] = useState(false);
  const [mortgageModal2Open, setMortgageModal2Open] = useState(false);
  const [appliedModal, setAppliedModal] = useState(false);

  const [contentTable, setContentTable] = useState<any[]>([
    {
      name: "Показ",
      task: "Все задачи передаются в CRM-систему ....",
      fullname: "Артур Спиридонов",
      date: "16.05.24  19:00",
      project: "ЖК Норпалова",
    },
    {
      name: "Показ",
      task: "Все задачи передаются в CRM-систему ....",
      fullname: "Артур Спиридонов",
      date: "16.05.24  19:00",
      project: "ЖК Норпалова",
    },
    {
      name: "Показ",
      task: "Все задачи передаются в CRM-систему ....",
      fullname: "Артур Спиридонов",
      date: "16.05.24  19:00",
      project: "ЖК Норпалова",
    },
    {
      name: "Показ",
      task: "Все задачи передаются в CRM-систему ....",
      fullname: "Артур Спиридонов",
      date: "16.05.24  19:00",
      project: "ЖК Норпалова",
    },
  ]);

  const [headerTable, setHeaderTable] = useState([
    {
      key: "task",
      title: "Вид задачи",
      sortable: true,
    },
    {
      key: "fullname",
      title: "Клиент",
    },
    {
      key: "date",
      title: "Дедлайн",
      sortable: true,
    },
    {
      key: "project",
      title: "Проект",
      sortable: true,
    },
  ]);

  const handleClickTableItem = () => {
    setTaskViewModalOpen(true);
  };

  const handleCompleteTheTask = () => {
    setTaskViewModalOpen(false);
    setTaskResultOpen(true);
  };
  const handleApplyForMortgage = () => {
    setMortgageModalOpen(false);
    setMortgageModal2Open(true);
  };

  const handleApplyForMortgage2 = () => {
    setMortgageModal2Open(false);
    setAppliedModal(true);
  };

  return (
    <div className="bg-bg-default">
      <div className="br-container  py-5 min-h-[50vh]">
        <div className="flex justify-between items-center">
          <PageTitle text="Задачи" />
          <div className="flex gap-[10px]">
            <ToggleSwitcher
              label="Показать архив"
              className="min-w-[160px] mr-[20px]"
            />
            <ButtonForm
              className={
                "bg-white-default hidden lg:flex px-6 py-3 w-full hover:bg-accent-default hover:text-white-default justify-center text-accent-default text-ellipsis whitespace-nowrap"
              }
              text="Заявка на ипотеку"
              onClick={() => setMortgageModalOpen(true)}
            />
            <ButtonForm
              className={
                " px-6 py-3 w-full hidden lg:flex justify-center hover:opacity-50  bg-white-default  hover:bg-bg-defau text-ellipsis whitespace-nowrap"
              }
              leftIcon={<AddIconInCircle />}
              text="Добавить задачу"
              onClick={() => setTaskAddModalOpen(true)}
            />
          </div>
        </div>
        <div>
          <div className="md:mt-[20px] mt-[15px] xl:mt-[30px]">
            {isMd ? (
              <div className={"bg-white-default rounded-lg"}>
                {contentTable.map((item: any, index) => (
                  <div
                    key={`table-content-item-${index}`}
                    onClick={() => {}}
                    className={`cursor-pointer w-full p-1 ${
                      index ? "border-t" : ""
                    } border-boder-default px-[15px] py-[12px]`}
                  >
                    <div className={"flex items-center mb-[5px]"}>
                      <p
                        className={
                          "text-sm font-medium mr-[12px] text-blue-default"
                        }
                      >
                        {item.name}
                      </p>

                      <p className={"text-xs text-accent-default opacity-50"}>
                        {item.date}
                      </p>
                    </div>

                    <div className={"mb-[15px]"}>
                      <p className={"text-xs"}>{item.task}</p>
                    </div>

                    <div className={"flex justify-between text-sm font-medium"}>
                      <p>{item.project}</p>

                      <p>{item.fullname}</p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <TableContainer
                handleClickTableItem={handleClickTableItem}
                header={headerTable}
                content={contentTable}
                setData={setContentTable}
              />
            )}
          </div>
        </div>
      </div>
      <TasksModals
        isTaskViewModalOpen={isTaskViewModalOpen}
        taskResultOpen={taskResultOpen}
        taskAddModalOpen={taskAddModalOpen}
        mortgageModalOpen={mortgageModalOpen}
        mortgageModal2Open={mortgageModal2Open}
        appliedModal={appliedModal}
        handleApplyForMortgage={handleApplyForMortgage}
        setTaskViewModalOpen={setTaskViewModalOpen}
        handleCompleteTheTask={handleCompleteTheTask}
        setTaskResultOpen={setTaskResultOpen}
        setTaskAddModalOpen={setTaskAddModalOpen}
        setMortgageModalOpen={setMortgageModalOpen}
        setMortgageModal2Open={setMortgageModal2Open}
        handleApplyForMortgage2={handleApplyForMortgage2}
        setAppliedModal={setAppliedModal}
      />
    </div>
  );
};

export default Tasks;
