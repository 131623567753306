import React from "react";

interface Props {
  className?: string;
  text: string;
}

const Tooltip = ({ text = "Tooltip", className }: Props) => {
  return (
    <ul
      className={`absolute text-[10px] text-center py-1 px-2 min-w-[60px] left-[50%] -translate-x-[50%] z-10  mt-[30px] top-[10px] bg-white-default text-accent-default shadow-lg border border-boder-default rounded before:content-[''] before:w-[8px] before:h-[8px] before:absolute before:-top-[5px] before:border-t before:border-l before:z-10 before:border-boder-default before:left-[50%] before:-translate-x-[50%] before:bg-white-default before:rotate-45 ${className}`}
      style={{ display: "inline-block", whiteSpace: "nowrap" }}
    >
      <p>{text}</p>
    </ul>
  );
};

export default Tooltip;
