export enum LoginRoles {
    AGENT = "agent",
    EMPLOYEE = "employee",
  }
  export enum RegisterType {
    AGENCY = "AGENCY",
    NEW_AGENCY = "NEW_AGENCY",
    NONE = "",
  }
  
  export enum Step {
    PHONE_NUMBER_FORM = "PHONE_NUMBER_FORM",
    SMS_VERIFICATION_FORM = "SMS_VERIFICATION_FORM",
    SELECT_REGISTER_TYPE = "SELECT_REGISTER_TYPE",
    USER_INFO_FORM = "USER_INFO_FORM",
    NEW_AGENCY_AGREEMENT_FORM = "NEW_AGENCY_AGREEMENT_FORM",
    SUCCESSFUL_REGISTRATION = "SUCCESSFUL_REGISTRATION",
    ATTACH_TO_AGENCY_FORM = "ATTACH_TO_AGENCY_FORM",
    ATTACHMENT_ISNOTEXIST_FORM = "ATTACHMENT_ISNOTEXIST_FORM",
    SUCCESS_MESSAGE = "SUCCESS_MESSAGE",
  }