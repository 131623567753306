import { Icons } from "./interface";

const PlusIcon = (
	{
		color = "white",
		strokeWidth = "1.5",
		width = 25,
		height = 24
	}: Icons
) => {
  return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.4992 7.19995L12.4992 16.8M17.2992 12L7.69922 12"
				stroke={color}
				strokeWidth={strokeWidth}
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default PlusIcon;
