import React from "react";

const FileUploadIcon = () => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.40593 20.6001H3.60592C2.28044 20.6001 1.20592 19.5256 1.20593 18.2001L1.20603 3.80013C1.20603 2.47466 2.28055 1.40015 3.60603 1.40015H14.4063C15.7318 1.40015 16.8063 2.47466 16.8063 3.80015V10.4001M15.4001 20.1884V16.7943M15.4001 16.7943V13.4001M15.4001 16.7943L12.0059 16.7943M15.4001 16.7943L18.7942 16.7943"
        stroke="#EF3B24"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FileUploadIcon;
