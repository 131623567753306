import React from "react";

const FileUploadedIcon = ({
  width = "20",
  height = "22",
  color = "#3B4658",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.29985 20.6001H3.49984C2.17436 20.6001 1.09984 19.5256 1.09985 18.2001L1.09995 3.80013C1.09995 2.47466 2.17447 1.40015 3.49995 1.40015H14.3002C15.6257 1.40015 16.7002 2.47466 16.7002 3.80015V10.4001M11.9002 17.2001L14.1002 19.4001L18.9002 14.6M5.30023 6.20015H12.5002M5.30023 9.80015H12.5002M5.30023 13.4001H8.90023"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FileUploadedIcon;
