import { Deal } from "modules/Deal/type";
import { apiService } from "..";
import { MetaData } from "../exception";

export const addTagTypes = ["leads"] as const;

export const citiesApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      getLeads: build.query<MetaData<Deal[]>, string>({
        query: (query) => ({
          path: `/leads?${query}`,
        }),
        providesTags: ["leads"],
      }),
    }),
  });

export const { useGetLeadsQuery } = citiesApiHooks;
