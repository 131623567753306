import React from "react";

const PaperPenIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.55742 20.5574H3.75741C2.43193 20.5574 1.35741 19.4828 1.35742 18.1574L1.35751 3.75741C1.35752 2.43193 2.43204 1.35742 3.75751 1.35742H14.5578C15.8833 1.35742 16.9578 2.43194 16.9578 3.75742V8.55742M5.5578 6.15742H12.7578M5.5578 9.75742H12.7578M5.5578 13.3574H9.1578M12.1576 17.2484L17.2488 12.1573L20.6429 15.5514L15.5517 20.6426H12.1576V17.2484Z"
        stroke="#37465B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PaperPenIcon;
