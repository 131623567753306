import ButtonForm from "../../components/Button/ButtonForm";
import Modal from "../../components/Modal";
import LikeIcon from "../../components/constant/icons/LikeIcon";
import EyeIcon from "../../components/constant/icons/EyeIcon";
import CloseWithBorderIcon from "../../components/constant/icons/CloseWithBorderIcon";
import React from "react";
import { NewsItemData } from "./index";
import { useWindowSize } from "../../hooks/useWindowSize";

interface NewsPreviewModalData {
	isOpen: boolean;
	setIsOpen: (value: boolean) => void;
	selected: NewsItemData | null;
	setSelect: (value: NewsItemData) => void;
}

const NewsPreviewModal = (
	{
		isOpen,
		setIsOpen,
		selected,
		setSelect
	}: NewsPreviewModalData
) => {
	const { isSm } = useWindowSize();

	function handleReturn() {
		setIsOpen(false)
	}

	function handleSave() {
		// $save(selected).then(res => {
			setIsOpen(true);
		// })
	}

	return (
		<Modal
			isOpen={isOpen}
			className="md:w-[910px] w-full rounded-lg p-[0px!important] overflow-auto max-h-[90vh]"
			onClose={() => setIsOpen(true)}
		>
			<div className={'flex md:justify-start justify-center relative pt-[20px] px-[20px]'}>
				<h1 className={'font-extrabold text-[20px] font-dewi md:max-w-[80%]'}>Предпросмотр</h1>

				{!isSm && (
					<div className={'absolute right-0'}>
						<ButtonForm
							text={<CloseWithBorderIcon color={'black'}/>}
							className={'p-0 px-[0px!important]'}
							onClick={() => setIsOpen(true)}
						/>
					</div>
				)}
			</div>

			<section className="my-[15px] px-[20px]">
				<div>
					<img src={selected?.img} width={"100%"} className={"rounded max-h-[463px] object-cover"}/>
				</div>

				<div className={"mt-[30px]"}>
					<div className="flex gap-[10px] text-[14px] leading-[18px] font-normal text-blue-default">
						{selected?.tags?.map((item, i) => (
							<div
								key={i}
								className="px-[20px] py-[6px] rounded-[100px] bg-light-blue"
							>
								{item}
							</div>
						))}
					</div>

					<div className={"flex justify-between pt-[15px]"}>
						<h3 className="text-[24px] leading-[28px] text-accent-default font-semibold">
							{selected?.title}
						</h3>

						<p className={"text-sm text-accent-default"}>{selected?.date}</p>
					</div>

					{selected?.text && (
						<p className={"my-[30px] text-accent-default text-sm"} dangerouslySetInnerHTML={{__html: selected.text}}/>
					)}

					<div className={"flex justify-between items-end"}>
						<ButtonForm
							leftIcon={<LikeIcon color={"#FFFFFF"}/>}
							text="Нравится"
							onClick={() => {
							}}
							className={
								"max-h-[48px] h-full max-w-[147px] px-6 py-3 w-full justify-center bg-accent-default text-white-default text-ellipsis whitespace-nowrap"
							}
						/>

						<div
							className="flex gap-[15px] items-center  text-[12px] text-accent-disabled font-medium leading-[16px]">
							{(selected?.views || selected?.views === 0) &&
                  <div className="flex items-center justify-center gap-[8px] cursor-pointer">
                      <EyeIcon color="#8E95A1" width="18"/>
                      <span>{selected.views}</span>
                  </div>
							}
							{(selected?.likes || selected?.likes === 0) &&
                  <div className="flex items-center justify-center gap-[8px] cursor-pointer">
                      <LikeIcon/>
                      <span>{selected.likes}</span>
                  </div>
							}
						</div>
					</div>
				</div>
			</section>

			<div className={'flex justify-end gap-[10px] py-[15px] px-[30px] border-t border-[#D7DCE4]'}>
				<ButtonForm
					text={'Вернуться к редактированию'}
					className={'p-3 bg-bg-default text-accent-default justify-center font-medium w-full max-w-[301px]'}
					onClick={handleReturn}
				/>

				<ButtonForm
					text={'Опубликовать'}
					className={'p-3 bg-accent-default text-white-default justify-center font-medium w-full max-w-[301px]'}
					onClick={handleSave}
				/>
			</div>
		</Modal>
	)
}

export default NewsPreviewModal;