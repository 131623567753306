import Keycloak from "keycloak-js";

const keycloakConfig = {
  url: "https://login.brusnika.ru",
  realm: "Staging",
  clientId: "brusnika-agent",
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;
