import React, { useRef, useState } from "react";
import ToggleSwitcher from "../../../components/ToggleSwitcher";
import ButtonForm from "../../../components/Button/ButtonForm";
import { Link } from "react-router-dom";
import { SearchField } from "../../../components/Form/SearchField";
import Label from "../../../components/Form/Label";
import ArrowDropdown from "../../../components/constant/icons/ArrowDropdown";

import showToast from "utils/showToast";
import { useRecordForShowMutation } from "data/api/calendar";
import { useDispatch } from "react-redux";
import { toggleBookingModal } from "store/BaseClientsReducer";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Dropdown from "../../../components/Form/Dropdown/Dropdown";
import { ApiException, ResponseStatusType } from "../../../data/api/exception";
import Tooltip from "../../../components/Tooltip";
import TooltipTags from "../../../components/Tooltip/TooltipTags";

interface RecordForShow {
  project: { label: string; value: number };
  date: { label: string; value: Date };
  time: string;
  client: number;
}

interface DateOption {
  label: string;
  value: Date;
}

interface ApartmentCardRightBlockProps {
  selection: any;
  setIsOpenAdvertisingModal: (value: boolean) => void;
}

const ApartmentCardRightBlock = ({
  selection,
  setIsOpenAdvertisingModal,
}: ApartmentCardRightBlockProps) => {
  const [mutate] = useRecordForShowMutation();
  const dispatch = useDispatch();

  const [hoveredIconId, setHoveredIconId] = useState<number | null>(null);

  const timeOptions = [
    {
      label: "09:30",
      value: "09:30",
    },
    {
      label: "10:00",
      value: "10:00",
    },
    {
      label: "10:30",
      value: "10:30",
    },
    {
      label: "11:00",
      value: "11:00",
    },
    {
      label: "11:30",
      value: "11:30",
    },
    {
      label: "12:00",
      value: "12:00",
    },
    {
      label: "12:30",
      value: "12:30",
    },
    {
      label: "13:00",
      value: "13:00",
    },
    {
      label: "13:30",
      value: "13:30",
    },
    {
      label: "14:00",
      value: "14:00",
    },
    {
      label: "14:30",
      value: "14:30",
    },
    {
      label: "15:00",
      value: "15:00",
    },
    {
      label: "15:30",
      value: "15:30",
    },
    {
      label: "16:00",
      value: "16:00",
    },
    {
      label: "16:30",
      value: "16:30",
    },
  ];

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<RecordForShow>();

  const dateOptions = (): DateOption[] => {
    const today = new Date();
    const dateOptions: DateOption[] = [];

    for (let i = 0; i < 7; i++) {
      const futureDate = new Date(today);
      futureDate.setDate(today.getDate() + i);
      const day = String(futureDate.getDate()).padStart(2, "0");
      const month = String(futureDate.getMonth() + 1).padStart(2, "0");
      const year = futureDate.getFullYear();
      const formattedDate = `${day}.${month}.${year}`;

      dateOptions.push({
        label: formattedDate,
        value: futureDate,
      });
    }

    return dateOptions;
  };

  const handleMouseEnter = (id: number) => {
    setHoveredIconId(id);
  };

  const handleMouseLeave = () => {
    setHoveredIconId(null);
  };

  const handleToggleBookingModal = () => {
    dispatch(toggleBookingModal());
  };

  const onSubmit: SubmitHandler<RecordForShow> = async (data) => {
    try {
      const { data: res, error } = await mutate({
        data: {
          project_id: data.project.value,
          date: data.date.value,
          time: data.time,
          client_id: data.client,
        },
      });
      if (res?.meta?.type === ResponseStatusType.SUCCESS) {
        reset();
        showToast("Подтверждение заявки на показ!", "success", 1000);
        return;
      }

      if (error?.message) {
        showToast(error.message, "error");
        return;
      }
    } catch (error) {
      const err = error as ApiException;
      console.log(err);
    }
  };

  return (
    <div className="w-[100%] lg:w-[31%]">
      <div className={"bg-white-default rounded-lg p-[20px] mb-[10px]"}>
        <ToggleSwitcher
          label="Добавить в подборку"
          isToggle={selection.compilation}
          setIsToggle={(value) => {
            console.log(value, "setIsToggle");
          }}
        />
      </div>

      <div
        className={"bg-white-default rounded-lg p-[20px] gap-[30px] mb-[10px]"}
      >
        <div className={"gap-[10px]"}>
          <div className={"flex gap-[10px] flex-wrap mb-[10px]"}>
            {selection.types.map((item: any, i: number) => (
              <a
                key={`selection-type-item-${i}`}
                className={
                  "border border-[#D7DCE4] rounded-3xl p-[5px] flex items-center"
                }
                href={item.url}
              >
                <img
                  crossOrigin="anonymous"
                  src={item.image}
                  className={"w-8 h-8 rounded-full"}
                />
                <p key={`tag-item-${item}`} className={"mx-[8px] text-xs"}>
                  {item.text}
                </p>
                <span className={"flex -rotate-90"}>
                  <ArrowDropdown color={"#37465B"} />
                </span>
              </a>
            ))}
          </div>

          <div className={"flex gap-[10px] flex-wrap mb-[30px]"}>
            {Object.keys(selection.info).map((item: any, idx: number) => (
              <p className={"text-sm"} key={`text-info-item-${idx}`}>
                <span className={"text-[#0000004d]"}>{item}:</span>{" "}
                {(selection.info as any)[item]}
              </p>
            ))}
          </div>

          <div className={"flex flex-col sm:flex-row gap-[10px]"}>
            <ButtonForm
              text="Выбрать кладовую"
              className="border border-red-default py-3 px-6 text-red-default
                     hover:bg-red-default xl:flex-1 flex justify-center
                     hover:text-white-default w-full"
            />
            <ButtonForm
              text="Выбрать паркинг"
              className="border border-red-default py-3 px-6 text-red-default
                     hover:bg-red-default xl:flex-1 flex justify-center
                     hover:text-white-default w-full"
            />
          </div>
        </div>
      </div>

      <div
        className={
          "bg-white-default rounded-lg p-[20px] mb-[10px] flex flex-wrap gap-[10px] relative"
        }
      >
        {selection.tags.map((item: any, idx: number) => (
          <>
            <p
              key={`tag-item-${idx}`}
              className={
                "border border-bg-focus py-[5px] px-[14px] text-xs rounded-3xl text-accent-default"
              }
              onMouseEnter={() => handleMouseEnter(item.desc)}
              onMouseLeave={handleMouseLeave}
            >
              {item.text}

              {hoveredIconId === item.desc && <TooltipTags text={item.desc} />}
            </p>
          </>
        ))}
      </div>

      <div
        className={
          "bg-white-default rounded-lg p-[20px] mb-[10px] flex flex-wrap gap-[15px]"
        }
      >
        <p>
          Вариант покупки
          <Link
            to={"/calculate"}
            className={"text-sm text-blue-default underline ml-[15px]"}
          >
            Ипотечный калькулятор
          </Link>
        </p>

        <div className={"flex flex-wrap gap-[10px]"}>
          {selection.buys.map((item: any) => (
            <p
              key={`tag-item-${item}`}
              className={
                "border border-bg-focus py-[5px] px-[14px] text-xs rounded-3xl text-accent-default"
              }
            >
              {item}
            </p>
          ))}
        </div>
      </div>

      <div
        className={
          "flex flex-col bg-white-default rounded-lg p-[20px] gap-[10px] mb-[10px]"
        }
      >
        {!selection.is_booked && (
          <ButtonForm
            onClick={handleToggleBookingModal}
            text="Забронировать"
            className="border border-accent-default py-3 px-6 text-accent-default
                     hover:bg-accent-default xl:flex-1 flex justify-center
                     hover:text-white-default w-full"
          />
        )}

        <ButtonForm
          onClick={() => setIsOpenAdvertisingModal(true)}
          text="Сформировать рекламный макет"
          className="border border-accent-default py-3 px-6 text-accent-default
                     hover:bg-accent-default xl:flex-1 flex justify-center
                     hover:text-white-default w-full"
        />
      </div>

      <div
        className={"bg-white-default rounded-lg p-[20px] gap-[30px] mb-[10px]"}
      >
        <form className={"gap-[10px]"} onSubmit={handleSubmit(onSubmit)}>
          <p className={"mb-[20px]"}>Запись на показ</p>

          <div className={"mb-[30px]"}>
            <Label label="Клиент">
              <Controller
                name="client"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Проект обязателен для заполнения",
                  },
                }}
                render={({ field: { onChange } }) => (
                  <SearchField
                    placeholder={"ФИО клиента"}
                    onChange={(value) => onChange(value.id)}
                    className="rounded-[4px!important]"
                    isShowBtnDelete={false}
                    btnListItem={
                      <div className={"p-2"}>
                        <ButtonForm
                          text="Добавить клиента"
                          className="bg-bg-default py-3 px-6 w-full
                           hover:bg-accent-default xl:flex-1 flex justify-center
                           hover:text-white-default md:rounded-[4px!important]"
                        />
                      </div>
                    }
                  />
                )}
              />
            </Label>

            <div className={"flex flex-col sm:flex-row gap-[10px] mt-[20px]"}>
              <Label label="Дата" className={"w-full"}>
                <Controller
                  name="date"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Дата обязателен для заполнения",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Dropdown
                      name="date"
                      options={dateOptions()}
                      errors={errors}
                      value={value}
                      defaultValue={value}
                      onChange={onChange}
                      placeholder="Выберите день"
                    />
                  )}
                />
              </Label>

              <Label label="Время">
                <Controller
                  name="time"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Время обязателен для заполнения",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Dropdown
                      name="time"
                      options={timeOptions}
                      errors={errors}
                      defaultValue={value}
                      onChange={(data) => onChange(data.value)}
                      placeholder="Выберите время"
                    />
                  )}
                />
              </Label>
            </div>
          </div>

          <div className={"flex flex-col sm:flex-row gap-[10px]"}>
            <ButtonForm
              type={"submit"}
              text="Запись на показ"
              className="bg-bg-default py-3 px-6
                     hover:bg-accent-default xl:flex-1 flex justify-center
                      hover:text-white-default w-full"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ApartmentCardRightBlock;
