import React, { FC, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { ru } from "date-fns/locale/ru";

import "react-datepicker/dist/react-datepicker.css";
import Calendar from "../constant/icons/Calendar";

type DateInterface = Date | null;

interface DatePickerProps {
  startDate?: DateInterface;
  onChangeDate: (date: Date) => void;
  isTimeSelect?: boolean;
  isMonthSelect?: boolean;
  className?: string;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  value?: string;
	isMobile?: boolean
}

const CalendarDatePickerField: FC<DatePickerProps> = ({
  startDate,
  onChangeDate,
  isTimeSelect = false,
  isMonthSelect = false,
  className = "",
  disabled,
  minDate,
  maxDate,
  value,
	isMobile
}) => {
  const dateStart = (date: any): DateInterface => {
    if (typeof date === "string") return new Date(date);

    return startDate || null;
  };

  const [startDateLocal, setStartDate] = useState<DateInterface>(
    dateStart(startDate)
  );
  const [endDate, setEndDate] = useState<DateInterface>(null);

  useEffect(() => {
    if (dateStart(startDate) !== startDateLocal)
      setStartDate(dateStart(startDate));
  }, [startDate]);

  const onDateRange = (value: DateInterface) => {
    onChangeDate(value || new Date());
  };

  const getDateFormatText = (): string => {
    if (isTimeSelect) return "dd.MM.yyyy HH:mm";

    if (isMonthSelect) return "MM/yyyy";

    return "dd.MM.yyyy";
  };

  const renderMonthContent = (
    monthIndex: number,
    shortMonthText: string,
    fullMonthText: string
  ) => {
    const tooltipText = `Подсказка: ${fullMonthText}`;

    return <span title={tooltipText}>{shortMonthText}</span>;
  };

  return (
    <React.Fragment>
      <div className={`relative z-[20] ${className}`}>
				<div className={"border-[1px] border-boder-default rounded h-full md:flex hidden items-center justify-center w-[48px] z-10 right-0"}>
					<Calendar color={"#37465B"}/>
				</div>

        <div className={"calendar-block__date-picker"}>
          <DatePicker
            locale={ru}
            selected={startDateLocal}
            value={value}
            dateFormat={getDateFormatText()}
            wrapperClassName=""
            onChange={(date: DateInterface) => {
              onDateRange(date);

              setStartDate(date);
            }}
            selectsStart
            startDate={startDateLocal}
            endDate={endDate}
            minDate={minDate}
            maxDate={maxDate}
            showTimeSelect={isTimeSelect}
            timeCaption="Время"
            timeFormat="HH:mm"
            className={`absolute top-0 w-full h-full opacity-0 cursor-pointer`}
            renderMonthContent={renderMonthContent}
            showMonthYearPicker={isMonthSelect}
            disabled={disabled}
						inline={isMobile}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default CalendarDatePickerField;
