import React from "react";
import ButtonForm from "../Button/ButtonForm";

interface SelectionToggleProps<T> {
  handleChange: (option: T) => void;
  options: { LEFT: T; RIGHT: T };
  selectedOption: T;
  leftLabel?: string;
  rightLabel?: string;
  onSubmit?: (selectedOption: T) => void;
  hasButton?: boolean;
  buttonText?: string;
  title?: string;
}

const SelectionToggle = <T,>({
  handleChange,
  options,
  selectedOption,
  leftLabel,
  rightLabel,
  onSubmit,
  hasButton = false,
  buttonText = "Submit",
  title,
}: SelectionToggleProps<T>) => {
  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(selectedOption);
    }
  };

  return (
    <React.Fragment>
      {title && (
        <div className="text-center text-[14px] text-accent-default font-medium leading-[16.41px] mb-[10px]">
          {title}
        </div>
      )}

      <div className="p-[3.5px] bg-bg-default flex rounded-[32px]  relative">
        <div
          onClick={() => handleChange(options.LEFT)}
          className={`flex-1 text-[14px] py-[11px] z-10 transition ease-in-out delay-100 cursor-pointer ${
            selectedOption === options.LEFT && "bg-white-default"
          } rounded-[32px] text-center `}
        >
          {leftLabel}
        </div>
        <div
          onClick={() => handleChange(options.RIGHT)}
          className={`flex-1 text-[14px] z-10 py-[11px] transition-bg ease delay-100 cursor-pointer ${
            selectedOption === options.RIGHT && "bg-white-default"
          } rounded-[32px] text-center `}
        >
          {rightLabel}
        </div>
        <div
          className={`absolute bottom-1 z-0 left-1 rounded-[32px] bg-white-default h-[43px] w-1/2 transition-transform duration-150 transform ${
            selectedOption === options.LEFT
              ? "translate-x-0"
              : "translate-x-[90%]"
          }`}
        ></div>
      </div>
      {hasButton && (
        <ButtonForm
          onClick={handleSubmit}
          className="bg-accent-default py-[16px] w-full mt-[30px] text-white-default text-center flex justify-center hover:bg-accent-focus"
          text={buttonText}
        />
      )}
    </React.Fragment>
  );
};

export default SelectionToggle;
