import React from "react";
import {Icons} from "../interface";

const ArrowTableIcon = (
    {
        size = 24,
        color = '#3B4658',
        strokeWidth = 1.5
    }: Icons
) => {
  return (
      <svg
          width={size}
          height={size}
          viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M16.375 12.8333L12 17M12 17L7.625 12.8333M12 17L12 7"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
      </svg>
  );
};

export default ArrowTableIcon;
