import React from "react";
import { Icons } from "./interface";

const ArrowDropdown = ({
  width = 25,
  height = 24,
  color = "#37465B",
}: Icons) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M6.7809 9.49346C7.06066 9.18799 7.53507 9.16716 7.84054 9.44691L12.3347 13.5629L16.8274 9.447C17.1328 9.16719 17.6072 9.18795 17.887 9.49337C18.1668 9.79878 18.146 10.2732 17.8406 10.553L12.8414 15.133C12.5548 15.3956 12.115 15.3957 11.8283 15.1331L6.82744 10.5531C6.52198 10.2733 6.50114 9.79892 6.7809 9.49346Z"
      />
    </svg>
  );
};

export default ArrowDropdown;
