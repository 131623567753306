import { configureStore } from "@reduxjs/toolkit";
import selectionReducer from "./ApartmentReducer";
import baseClientsReducer from "./BaseClientsReducer";
import headerSlice from "./HeaderReducer";
import { apiService } from "data/api";
import CalendarReducer from "./CalendarReducer";
import TrainingReducer from "./TrainingReducer";
import ContactReducer from "./ContactReducer";
import AdminstrationReducer from "./AdminstrationReducer";
import NewsReducer from "./NewsReducer";
import CalculatorReducer from "./CalculatorReducer";
import userSlice from "./UserReducer";

const store = configureStore({
  reducer: {
    apartment: selectionReducer,
    baseClients: baseClientsReducer,
    header: headerSlice,
    calculator: CalculatorReducer,
    news: NewsReducer,
    calendar: CalendarReducer,
    adminstration: AdminstrationReducer,
    training: TrainingReducer,
    contact: ContactReducer,
    user: userSlice,
    [apiService.reducerPath]: apiService.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiService.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
