import React from "react";

interface propsType {
  text: string;
  className?: string;
}

const PageTitle = ({ text, className }: propsType) => {
  return (
    <h1
      className={`sm:text-[24px] font-dewi leading-[30px] font-extrabold text-accent-default ${className}`}
    >
      {text}
    </h1>
  );
};

export default PageTitle;
