import React from "react";

const TriangelIcon = () => {
  return (
    <svg
      width="8"
      height="7"
      viewBox="0 0 8 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.84784 0.500001C3.23274 -0.166666 4.19499 -0.166667 4.57989 0.5L7.17797 5C7.56287 5.66667 7.08174 6.5 6.31194 6.5H1.11579C0.345991 6.5 -0.135135 5.66667 0.249765 5L2.84784 0.500001Z"
        fill="#3583FF"
      />
    </svg>
  );
};

export default TriangelIcon;
