import React, { useEffect, useRef } from "react";

import CancelIcon from "components/constant/icons/CancelIcon";
import CheckIcon from "components/constant/icons/CheckIcon";
import { images } from "utils/resource";
import { useGetAllNotificationsQuery } from "data/api/notification";
import { useDispatch } from "react-redux";
import { toggleNotificationModal } from "store/HeaderReducer";
import { useAcceptInvitationMutation } from "data/api/event";
import showToast from "utils/showToast";
import { timeSince } from "./notificationTimeCalculate";

const NotificationsModal = () => {
  const { data, refetch } = useGetAllNotificationsQuery();
  const dispatch = useDispatch();
  const modalRef = useRef<HTMLDivElement>(null);
  const [acceptInvitationMutation] = useAcceptInvitationMutation();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        dispatch(toggleNotificationModal(false));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  const handleAcceptOrRejectInvitation = async (
    event_id: number,
    is_accepted: boolean
  ) => {
    const { data } = await acceptInvitationMutation({
      data: { event_id: event_id, is_accepted: is_accepted },
    });
    refetch();
    if (data?.data.is_accepted) {
      showToast("Приглашение принято", "success", 1000);
      return;
    }
    showToast("Приглашение отклонено", "warning", 1000);
  };

  const hanldeCloseToggleNotificationModal = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    dispatch(toggleNotificationModal(false));
  };

  return (
    <div className="fixed inset-0 flex items-end md:items-center md:justify-center bg-opacity-40 z-50">
      <div
        ref={modalRef}
        className=" bg-white-default rounded-[20px] absolute right-5 bottom-5 w-[438px] shadow-sm border border-boder-default"
      >
        <div className="text-center relative pt-5 ">
          <h3 className="text-[20px] text-accent-default font-dewi font-extrabold">
            Уведомления
          </h3>
          <div
            onClick={hanldeCloseToggleNotificationModal}
            className=" cursor-pointer absolute right-[20px] top-[50%] -translate-y-[50%]"
          >
            <CancelIcon />
          </div>
          <div className="h-[1px] relative left-0 right-0 bg-boder-default my-3"></div>
        </div>

        {/* list */}
        <div className="flex flex-col gap-2 px-5 max-h-[80vh] overflow-auto toolbar-scroll">
          {data?.data?.length ? (
            data.data.map((item, i) => (
              <div key={item.id} className="flex flex-col pt-1">
                <div className="flex gap-3 items-center mb-[10px]">
                  {!item.is_read ? (
                    <div className="w-[5px] h-[5px] bg-red-default rounded-full"></div>
                  ) : null}

                  <div className="flex items-center gap-3 w-full">
                    <img
                      src={images.eventsImg}
                      width={50}
                      height={40}
                      className="w-[50px] h-[40px]"
                      alt="event"
                    />
                    <div className="flex flex-col gap-1 text-accent-default">
                      <p className="text-sm font-medium ">
                        {item.type === "event" && "Запись на мероприятие"}
                      </p>
                      <p className="text-[12px] font-medium">
                        Вы были занисаны на
                        <span className=" text-blue-default underline ml-1">
                          мероприятие
                        </span>
                      </p>
                    </div>
                    <p className="text-[12px] text-accent-default opacity-60 ml-auto">
                      {timeSince(item.created_at)}
                    </p>
                  </div>
                </div>
                {!item.is_read ? (
                  <div className="flex gap-2">
                    <button
                      onClick={() =>
                        handleAcceptOrRejectInvitation(item.object_id, false)
                      }
                      className="py-[10px] flex-1 border border-red-default rounded flex items-center justify-center"
                    >
                      <CancelIcon color="#EF3B24" />
                    </button>
                    <button
                      onClick={() =>
                        handleAcceptOrRejectInvitation(item.object_id, true)
                      }
                      className="py-[10px] flex-1 border border-[#4BCC36] rounded flex items-center justify-center"
                    >
                      <CheckIcon />
                    </button>
                  </div>
                ) : null}
                {data?.data?.length - 1 !== i ? (
                  <div className="h-[1px] relative left-0 right-0 bg-boder-default mt-3"></div>
                ) : null}
              </div>
            ))
          ) : (
            <div className="text-accent-default text-center mb-4 opacity-70">
              Пока нет уведомлений
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationsModal;
