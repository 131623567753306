import { apiService } from "..";
import { MetaData } from "../exception";

export const addTagTypes = ["sections"] as const;

export const sectionsApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      getSections: build.query<MetaData<[]>, { buildingId: number | null }>({
        query: (param) => ({
          path: param.buildingId
            ? `/sections?building_id=${param.buildingId}`
            : "/sections",
        }),
        providesTags: ["sections"],
      }),
    }),
  });

export const { useGetSectionsQuery } = sectionsApiHooks;
