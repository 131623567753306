import React, { useEffect, useRef, useState } from "react";
import ArrowDropdown from "../../constant/icons/ArrowDropdown";

interface ContentData {
  title: string;
  text: string;
}

interface propsType {
  label?: string;
  className?: string;
  content?: ContentData[];
  lead: any;
  handleOpenDealsInfoModal: (arg: any) => void;
}

const Collapse = ({
  label = "",
  content = [],
  className = "",
  lead,
  handleOpenDealsInfoModal,
}: propsType) => {
  const [isFocused, setIsFocused] = useState(false);
  const [height, setHeight] = useState("0px");
  const contentRef = useRef<HTMLDivElement>(null);

  const handleOpen = () => {
    setIsFocused(!isFocused);
  };

  useEffect(() => {
    if (contentRef.current) {
      setHeight(isFocused ? `${contentRef.current.scrollHeight}px` : "0px");
    }
  }, [isFocused]);

  const caretClass = isFocused ? "rotate-180" : "caret-down";

  return (
    <div className={`p-[10px] border border-[#D7DCE4] rounded-md ${className}`}>
      <div
        className={"cursor-pointer flex justify-between"}
        onClick={handleOpen}
      >
        <p>{label}</p>
        <span className={caretClass}>
          <ArrowDropdown />
        </span>
      </div>

      <div
        ref={contentRef}
        style={{ maxHeight: height }}
        className={`transition-max-height duration-300 ease-in-out overflow-hidden`}
      >
        {content?.length > 0 ? (
          content?.slice(1).map((item: any, idx: number) => (
            <div
              className={"text-sm flex gap-[10px] mt-[10px]"}
              key={`text-info-key-${idx}`}
            >
              <p className={"text-accent-default font-medium"}>
                {item?.title}:
              </p>
              {item?.title === "Этап сделки" ? (
                <p
                  className={"font-normal text-blue-default cursor-pointer"}
                  onClick={() => handleOpenDealsInfoModal(lead)}
                >
                  {item?.text}
                </p>
              ) : (
                <p className={"font-normal"}>{item?.text}</p>
              )}
            </div>
          ))
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <p>Список пуст</p>
          </div>
        )}
      </div>
      {/* <DealsInfoModal
        data={{
          ...lead,
       
        }}
        isModalOpen={selection.isDealsInfoModalOpen === lead.id}
        handleClose={handleCloseDealsInfoModal}
      /> */}
    </div>
  );
};

export default Collapse;
