import React from "react";
import ChessCard from "./ChessCard";
import { Apartment, transformData } from "./dataTransformer";
interface propsType {
  data: Apartment[];
}

const ChessList = ({ data }: propsType) => {
  return (
    <div className="w-full pb-[15px] overflow-x-auto appartment-scroll">
      <div className="flex flex-col gap-[20px]">
        {Object.entries(transformData(data, "floor"))
          .reverse()
          ?.map(([groupKey, apartments], i) => (
            <div className="flex gap-[15px] items-center " key={groupKey}>
              <div
                className="w-[20px] h-[20px] rounded-md
       text-white-default bg-accent-default 
       flex font-medium items-center text-[12px] justify-center flex-none"
              >
                {groupKey}
              </div>
              <div className="flex gap-[10px]">
                {apartments?.map((item, i) => (
                  <ChessCard data={item} key={i} />
                ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ChessList;
