import React from "react";

const CircleXIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 2.6875C7.30456 2.6875 2.6875 7.30456 2.6875 13C2.6875 18.6954 7.30456 23.3125 13 23.3125C18.6954 23.3125 23.3125 18.6954 23.3125 13C23.3125 7.30456 18.6954 2.6875 13 2.6875ZM0.8125 13C0.8125 6.26903 6.26903 0.8125 13 0.8125C19.731 0.8125 25.1875 6.26903 25.1875 13C25.1875 19.731 19.731 25.1875 13 25.1875C6.26903 25.1875 0.8125 19.731 0.8125 13ZM8.35961 8.35961C8.72573 7.99349 9.31932 7.99349 9.68544 8.35961L13 11.6742L16.3146 8.35961C16.6807 7.9935 17.2743 7.9935 17.6404 8.35961C18.0065 8.72573 18.0065 9.31932 17.6404 9.68544L14.3258 13L17.6404 16.3146C18.0065 16.6807 18.0065 17.2743 17.6404 17.6404C17.2743 18.0065 16.6807 18.0065 16.3146 17.6404L13 14.3258L9.68544 17.6404C9.31932 18.0065 8.72573 18.0065 8.35961 17.6404C7.99349 17.2743 7.99349 16.6807 8.35961 16.3146L11.6742 13L8.35961 9.68544C7.99349 9.31932 7.99349 8.72573 8.35961 8.35961Z"
        fill="#37465B"
      />
    </svg>
  );
};

export default CircleXIcon;
