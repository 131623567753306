import React from "react";

const PhoneIcon = ({ color = "#3583FF" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6638 16.771C18.6638 16.771 17.5052 17.909 17.2212 18.2426C16.7587 18.7362 16.2137 18.9693 15.4992 18.9693C15.4305 18.9693 15.3573 18.9693 15.2886 18.9647C13.9284 18.8779 12.6644 18.3477 11.7164 17.8953C9.12431 16.643 6.84819 14.8651 4.95678 12.6119C3.3951 10.7335 2.35093 8.99681 1.6594 7.13211C1.23349 5.9941 1.07778 5.10745 1.14647 4.27108C1.19227 3.73635 1.39835 3.29303 1.77847 2.91369L3.34015 1.3552C3.56455 1.14497 3.8027 1.03071 4.03626 1.03071C4.32478 1.03071 4.55835 1.20438 4.7049 1.35063C4.70948 1.3552 4.71406 1.35977 4.71864 1.36434C4.998 1.62485 5.26362 1.8945 5.54298 2.18243C5.68495 2.32869 5.8315 2.47494 5.97805 2.62576L7.22831 3.87346C7.71376 4.35792 7.71376 4.80581 7.22831 5.29027C7.0955 5.42281 6.96727 5.55534 6.83446 5.68331C6.44976 6.07636 6.75196 5.77478 6.35353 6.13127C6.34437 6.14041 6.33521 6.14498 6.33063 6.15412C5.93677 6.54717 6.01005 6.93108 6.09248 7.19159C6.09706 7.2053 6.10164 7.21901 6.10622 7.23272C6.43138 8.01882 6.88935 8.75921 7.58547 9.64129L7.59005 9.64586C8.85404 11.1998 10.1867 12.4109 11.6568 13.3387C11.8446 13.4575 12.0369 13.5535 12.2201 13.6449C12.385 13.7272 12.5407 13.8049 12.6735 13.8871C12.6918 13.8963 12.7101 13.91 12.7285 13.9191C12.8842 13.9968 13.0307 14.0334 13.1819 14.0334C13.562 14.0334 13.8001 13.7957 13.878 13.718L14.7757 12.8222C14.9314 12.6668 15.1787 12.4794 15.4672 12.4794C15.7511 12.4794 15.9847 12.6577 16.1267 12.813C16.1312 12.8176 16.1312 12.8176 16.1358 12.8222L18.6592 15.3404C19.1309 15.8066 18.6638 16.771 18.6638 16.771Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PhoneIcon;
