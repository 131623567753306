import React from "react";

const FilteEyeIcon = () => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.4 20.6H3.39999C2.0745 20.6 0.999991 19.5254 1 18.2L1.00009 3.80001C1.0001 2.47453 2.07462 1.40002 3.40009 1.40002H14.2004C15.5258 1.40002 16.6004 2.47454 16.6004 3.80002V8.60002M14.8004 15.14V15.0769M19.0004 15.191C19.0004 15.191 18.0042 18.1307 14.8004 18.0794C11.5965 18.028 10.6004 15.191 10.6004 15.191C10.6004 15.191 11.5561 12.2 14.8004 12.2C18.0446 12.2 19.0004 15.191 19.0004 15.191Z"
        stroke="#37465B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FilteEyeIcon;
