import { apiService } from "..";
import { MetaData } from "../exception";
import { SearchType } from "./type";

export const addTagTypes = ["cities"] as const;

export const searchApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      globalSearch: build.query<MetaData<SearchType<any>[]>, string>({
        query: (query) => ({
          path: `/search?text=${query}`,
        }),
      }),
    }),
  });

export const { useGlobalSearchQuery } = searchApiHooks;
