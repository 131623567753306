import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Dropdown from "../../components/Form/Dropdown/Dropdown";
import Label from "../../components/Form/Label";
import ButtonForm from "../../components/Button/ButtonForm";
import TextField from "../../components/Form/TextField/TextField";
import PercentIcon from "../../components/constant/icons/PercentIcon";
import MultiRangeSlider from "../../components/Form/MultiRangeSlider";
import { useWindowSize } from "../../hooks/useWindowSize";
import { calculateFormData } from "../../store/CalculatorReducer/type";
import { getFilterData, handleSetFilters } from "../../store/CalculatorReducer";
import { useDispatch, useSelector } from "react-redux";
import MortgageModal from "./MortgageModal";
import { useState } from "react";

interface CalculatorFilterData {
  filterData: any;
  setFiltered: (data: calculateFormData) => void;
}

const CalculatorFilter = ({
  filterData,
  setFiltered,
}: CalculatorFilterData) => {
  const { isMd } = useWindowSize();

  const dispatch = useDispatch();
  const getFilter = useSelector(getFilterData);

	const [isOpenMortgageModal, setIsOpenMortgageModal] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<calculateFormData>({
    defaultValues: {
      price_month: getFilter?.price_month,
      project: getFilter?.project,
      price_apart: getFilter?.price_apart,
      currency_apart: getFilter?.currency_apart,
      percent: getFilter?.percent || 30,
      price_all: getFilter?.price_all,
    },
  });

  const onSubmit: SubmitHandler<calculateFormData> = async (data) => {
    const updateData = {
      ...data,
      initial: {
        max: data.initial?.max || filterData.maxInitial,
        min: data.initial?.min || filterData.minInitial,
      },
      period: {
        max: data.period?.max || filterData.maxMonth,
        min: data.period?.min || filterData.minMonth,
      },
    };

    setFiltered(updateData);
    dispatch(handleSetFilters(updateData));
  };

  return (
    <div
      className={
        "bg-white-default rounded lg:max-w-[38%] w-full lg:p-[30px] py-[20px] px-[15px]"
      }
    >
      {!isMd && (
        <h1
          className={
            "text-[25px] font-dewi font-extrabold mb-[30px] text-accent-default"
          }
        >
          Ипотечный калькулятор
        </h1>
      )}

      <form
        onSubmit={handleSubmit(onSubmit)}
        className={"flex flex-col gap-[20px] pr-[10px]"}
      >
        <Label label="Проект">
          <Controller
            name="project"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Объект обязателен для заполнения",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                name="project"
                options={filterData.optionsProject}
                onChange={(value) => onChange(value.value)}
                placeholder="Объект не выбран"
                errors={errors}
              />
            )}
          />
        </Label>

        <Label label="Стоимость квартиры">
          <div className={"flex gap-[10px]"}>
            <Controller
              name="price_apart"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Стоимость обязателен для заполнения",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  className="w-full"
                  placeholder="Введите стоимость"
                  type="number"
                  name="price_apart"
                  onChange={onChange}
                />
              )}
            />
            <Controller
              name="currency_apart"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Валюта обязателена для заполнения",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  name="currency_apart"
                  options={filterData.optionsCurrency}
                  onChange={(value) => onChange(value.value)}
                  placeholder="Валюта не выбрана"
                  errors={errors}
                  className={"md:min-w-[37%] min-w-[45%]"}
                />
              )}
            />
          </div>
        </Label>

        <Label label="Процентная ставка">
          <Controller
            name="percent"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Процентная ставка обязателена для заполнения",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value}
                className="w-full"
                placeholder="Введите процент"
                type="number"
                name="percent"
                onChange={onChange}
                imgRight={<PercentIcon />}
              />
            )}
          />
        </Label>

        {/* <Label label={"Первоначальный взнос"}>
          <Controller
            name="initial"
            control={control}
            rules={{
              required: {
                value: false,
                message: "Взнос обязателен для заполнения",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <MultiRangeSlider
                width={"w-[32%]"}
                min={filterData.minInitial}
                max={filterData.maxInitial}
                onChange={onChange}
              />
            )}
          />
        </Label> */}

        {/* <Label label={"Срок кредита"}>
          <Controller
            name="period"
            control={control}
            rules={{
              required: {
                value: false,
                message: "Срок обязателен для заполнения",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <MultiRangeSlider
                width={"w-[16%]"}
                min={filterData.minMonth}
                max={filterData.maxMonth}
                onChange={onChange}
              />
            )}
          />
        </Label> */}

        {filterData.calculate && (
          <div className={"p-[20px] bg-bg-default flex"}>
            {filterData.calculate.price_all && (
              <div className={"w-full"}>
                <p
                  className={
                    "text-sm font-normal text-accent-default mb-[10px]"
                  }
                >
                  Общая стоимость
                </p>

                <p className={"font-medium text-accent-default"}>
                  {filterData.calculate.price_all} руб
                </p>
              </div>
            )}

            {filterData.calculate.price_month && (
              <div className={"w-full"}>
                <p
                  className={
                    "text-sm font-normal text-accent-default mb-[10px]"
                  }
                >
                  Оплата в месяц
                </p>

                <p className={"font-medium text-accent-default"}>
                  {filterData.calculate.price_month} руб
                </p>
              </div>
            )}
          </div>
        )}

        <div className="flex flex-col gap-[10px] mt-[10px]">
          <ButtonForm
            disabled={!isValid}
            text="Рассчитать ипотеку"
            type="submit"
            className={`py-3 px-6 bg-white-default 
						flex-1 flex justify-center 
						border rounded 
						${
              !isValid
                ? "border-red-disabled text-red-disabled"
                : "text-red-default border-red-default"
            }`}
          />

          <ButtonForm
            text="Заявка на ипотеку"
            type="button"
            className="py-3 px-6
							bg-white-default flex-1
							text-accent-default flex justify-center
							border border-accent-default rounded
							"
            onClick={() => setIsOpenMortgageModal(true)}
          />
        </div>
      </form>

			<MortgageModal
				isOpen={isOpenMortgageModal}
				setIsOpen={setIsOpenMortgageModal}
				handleApply={() => {}}
			/>
    </div>
  );
};

export default CalculatorFilter;
