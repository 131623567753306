import React from "react";

const ChatIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.99983 9C2.99983 4.58172 6.58125 1 10.9992 1C15.4171 1 18.9985 4.58172 18.9985 9C18.9985 10.15 18.7558 11.2434 18.319 12.2316L19 16.9992L14.9146 15.9778C13.7572 16.6287 12.4215 17 10.9992 17M1.00095 14C1.00095 14.7188 1.15258 15.4021 1.4256 16.0198L1 18.9995L3.55315 18.3611C4.27643 18.768 5.11115 19 6.00005 19C8.76098 19 10.9992 16.7614 10.9992 14C10.9992 11.2386 8.76098 9 6.00005 9C3.23912 9 1.00095 11.2386 1.00095 14Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChatIcon;
