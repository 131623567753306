import React from "react";

const WhatsAppIcon = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4677_72009)">
        <path
          d="M9.50226 0H9.49776C4.53539 0 0.500012 4.0365 0.500012 9C0.49693 10.8956 1.09699 12.7431 2.21339 14.2751L1.09176 17.6186L4.55114 16.5128C6.01855 17.4863 7.74127 18.0038 9.50226 18C14.4646 18 18.5 13.9624 18.5 9C18.5 4.03763 14.4646 0 9.50226 0ZM14.7391 12.7091C14.522 13.3223 13.6603 13.8307 12.9729 13.9792C12.5026 14.0794 11.8884 14.1593 9.82064 13.302C7.17576 12.2063 5.47251 9.51862 5.33976 9.34425C5.21264 9.16988 4.27101 7.92112 4.27101 6.62963C4.27101 5.33813 4.92689 4.70925 5.19126 4.43925C5.40839 4.21763 5.76726 4.11637 6.11151 4.11637C6.22289 4.11637 6.32301 4.122 6.41301 4.1265C6.67739 4.13775 6.81014 4.1535 6.98451 4.57088C7.20164 5.094 7.73039 6.3855 7.79339 6.51825C7.85751 6.651 7.92164 6.831 7.83164 7.00538C7.74726 7.18538 7.67301 7.26525 7.54026 7.41825C7.40751 7.57125 7.28151 7.68825 7.14876 7.8525C7.02726 7.99538 6.89001 8.14838 7.04301 8.41275C7.19601 8.6715 7.72476 9.53437 8.50326 10.2274C9.50789 11.1217 10.3224 11.4075 10.6138 11.529C10.8309 11.619 11.0896 11.5976 11.2483 11.4289C11.4496 11.2118 11.6983 10.8518 11.9514 10.4974C12.1314 10.2431 12.3586 10.2116 12.5971 10.3016C12.8401 10.386 14.126 11.0216 14.3904 11.1532C14.6548 11.286 14.8291 11.349 14.8933 11.4604C14.9563 11.5718 14.9563 12.0949 14.7391 12.7091Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4677_72009">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WhatsAppIcon;
