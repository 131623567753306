import React from "react";
import { images } from "utils/resource";

interface Props {
  title: string;
}

const EventsAnaliticsCart = ({ title }: Props) => {
  return (
    <div className="bg-white-default rounded-lg">
      <div className="py-[15px] px-5">
        <h2 className="font-dewi text-[18px] sm:text-[20px] text-accent-default">
          {title}
        </h2>
      </div>
      <hr className=" border-boder-default opacity-60" />
      <div className="py-[15px]  flex flex-col">
        {[...new Array(5)].map((_, i) => (
          <div key={i}>
            <div className="flex justify-between px-[15px] sm:px-5">
              <div className="flex gap-3">
                <div className="img relative w-[48px] h-[48px]">
                  <img
                    src={images.eventsImgAnalitics1}
                    alt="analitcs img"
                    className="w-[48px] h-[48px] rounded-lg"
                  />
                  <div className="w-[20px] absolute h-[20px] rounded-full flex items-center justify-center text-[12px] text-accent-default font-medium bg-[#FFB950] -bottom-[5px] -right-[5px]">
                    {i + 1}
                  </div>
                </div>
                <div className="text-accent-default leading-[18px] flex flex-col sm:gap-[5px]">
                  <p className=" text-sm sm:text-[16px] font-medium ">
                    Лучшее предложение весны!
                  </p>
                  <p className="text-sm">#брусника #горячее</p>
                </div>
              </div>
              <div className="text-accent-default leading-[18px] flex flex-col gap-[5px]">
                <p className="text-sm opacity-50">Просмотры </p>
                <p className=" text-sm sm:text-[16px] font-medium ">100 252</p>
              </div>
            </div>
            {i + 1 !== 5 && (
              <hr className=" border-boder-default my-[10px] opacity-60" />
            )}
          </div>
        ))}
      </div>
      <hr className=" border-boder-default opacity-60" />
      <button className=" outline-none text-center cursor-pointer text-[#0085FF] text-[16px] font-medium py-3 w-full">
        Посмотреть все
      </button>
    </div>
  );
};

export default EventsAnaliticsCart;
