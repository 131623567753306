import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../../index.css";
import { useLocation } from "react-router-dom";
import { useWindowSize } from "../../hooks/useWindowSize";
import LogOutAsUser from "./LogOutAsUser";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const { pathname } = useLocation();
  const { isMd } = useWindowSize();

  const isShowMobileCap = ["/base-clients"].includes(pathname) && isMd;

  const old_token = localStorage.getItem("old_token");

  return (
    <React.Fragment>
      {isShowMobileCap ? (
        <>
          <Header />
          <main className="min-h-[50vh]">
            {children} 
          </main>
          <Footer />
        </>
      ) : (
        <>
          <Header />
          <main className="min-h-[50vh]">{children}</main>
          <Footer />
        </>
      )}
      {old_token ? <LogOutAsUser /> : null}
    </React.Fragment>
  );
};

export default Layout;
