import ThreeDotLoader from "components/Loader/ThreeDotLoader";
import { UserRole } from "enums/users";
import { useRoles } from "hooks/useRoles";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

interface PrivateRouteProps {
  element: JSX.Element;
  roles: UserRole[];
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, roles = [] }) => {
  const token = localStorage.getItem("token");
  const { currentRole } = useRoles(null);
  const [displayLoader, setDisplayLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setDisplayLoader(false);
    }, 2000);
  }, []);

  if (!token) {
    return <Navigate to="/sign-in" replace />;
  }

  if (displayLoader) return <ThreeDotLoader />;

  if (currentRole) {
    if (roles.length === 0 || roles.includes(currentRole)) {
      return element;
    }
  }

  return <Navigate to="/" replace />;
};

export default PrivateRoute;
