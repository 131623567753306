import { apiService } from "..";
import { MetaData } from "../exception";
import { Notification } from "./type";

export const addTagTypes = ["notification"] as const;

export const notificationApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      getAllNotifications: build.query<MetaData<Notification[]>, void>({
        query: () => ({
          path: "/notification",
        }),
        providesTags: ["notification"],
      }),
    }),
  });

export const { useGetAllNotificationsQuery } = notificationApiHooks;
