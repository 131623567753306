import { ApiResponse } from "data/interface/api_responce";
import { apiService } from "..";
import { MetaData } from "../exception";

export const addTagTypes = ["image"] as const;

export const fileApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      uploadFile: build.mutation<MetaData<{ filename: string }>, FormData>({
        query: (fileData) => ({
          path: "/api/file-upload/image",
          method: "POST",
          body: fileData,
          fileData: true,
        }),
        invalidatesTags: ["image"],
      }),
    }),
  });

export const { useUploadFileMutation } = fileApiHooks;
