import React from "react";

const FileIcon = () => {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.70077 8.91767V12.6059C5.70077 14.4284 7.17823 15.9059 9.00077 15.9059C10.8233 15.9059 12.3008 14.4284 12.3008 12.6059V7.94708C12.3008 6.98221 11.5186 6.20002 10.5537 6.20002C9.58884 6.20002 8.80665 6.98221 8.80665 7.94708V12.4118M3.5998 1.40002H14.4C15.7255 1.40002 16.8 2.47457 16.8 3.80007L16.7998 18.2001C16.7997 19.5255 15.7252 20.6 14.3997 20.6L3.5997 20.6C2.27421 20.6 1.1997 19.5254 1.19971 18.2L1.1998 3.80001C1.19981 2.47453 2.27432 1.40002 3.5998 1.40002Z"
        stroke="#37465B"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default FileIcon;
