import React from "react";

const ImageUploadIcon = ({
  width = "21",
  height = "20",
  color = "#37465B",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9857 0.25C17.3999 0.25 17.7357 0.585786 17.7357 1L17.7357 3.26429L20 3.26429C20.4142 3.26429 20.75 3.60008 20.75 4.01429C20.75 4.42851 20.4142 4.76429 20 4.76429L17.7357 4.76429L17.7357 7.02859C17.7357 7.4428 17.3999 7.77859 16.9857 7.77859C16.5715 7.77859 16.2357 7.4428 16.2357 7.02859L16.2357 4.76429L13.9714 4.76429C13.5572 4.76429 13.2214 4.42851 13.2214 4.01429C13.2214 3.60008 13.5572 3.26429 13.9714 3.26429L16.2357 3.26429L16.2357 1C16.2357 0.585787 16.5715 0.25 16.9857 0.25ZM0.25 5.08001C0.25 2.90006 2.0172 1.13287 4.19714 1.13287H11.1243C11.5385 1.13287 11.8743 1.46865 11.8743 1.88287C11.8743 2.29708 11.5385 2.63287 11.1243 2.63287H4.19714C2.84562 2.63287 1.75 3.72849 1.75 5.08001V15.7371C1.75 16.9938 2.69721 18.0292 3.91661 18.1684L12.7417 9.86243C13.0369 9.5846 13.4994 9.59161 13.786 9.87825L17.3014 13.3936V10.4086C17.3014 9.99436 17.6372 9.65858 18.0514 9.65858C18.4656 9.65858 18.8014 9.99436 18.8014 10.4086V15.7371C18.8014 17.9171 17.0342 19.6843 14.8543 19.6843H4.19714C2.0172 19.6843 0.25 17.9171 0.25 15.7371V5.08001ZM17.3014 15.3895C17.1868 15.3549 17.0788 15.2923 16.9882 15.2018L13.2399 11.4534L6.08832 18.1843H14.8543C16.2058 18.1843 17.3014 17.0887 17.3014 15.7371V15.3895ZM5.79571 5.83001C5.32706 5.83001 4.94714 6.20993 4.94714 6.67858C4.94714 7.14723 5.32706 7.52715 5.79571 7.52715C6.26436 7.52715 6.64428 7.14723 6.64428 6.67858C6.64428 6.20993 6.26436 5.83001 5.79571 5.83001ZM3.44714 6.67858C3.44714 5.3815 4.49863 4.33001 5.79571 4.33001C7.09279 4.33001 8.14428 5.3815 8.14428 6.67858C8.14428 7.97566 7.09279 9.02715 5.79571 9.02715C4.49863 9.02715 3.44714 7.97566 3.44714 6.67858Z"
        fill={color}
      />
    </svg>
  );
};

export default ImageUploadIcon;
