import React, { useState } from "react";
import TextField from "../TextField/TextField";
import CancelIcon from "components/constant/icons/CancelIcon";
import {
  State,
  Tags,
} from "modules/Apartment/ApartmentCardPage/AdvertisementForm";

interface Props {
  tegsStateForm: Tags;
  setTegsStateForm: React.Dispatch<React.SetStateAction<State>>;
  label?: string;
  placeholder?: string;
  boldLabel?: string;
}

const TegField = ({
  tegsStateForm,
  setTegsStateForm,
  placeholder,
  boldLabel,
  label,
}: Props) => {
  const [tagInputValue, setTagInputValue] = useState<string>("");

  const handleClick = () => {
    if (tagInputValue.trim() && !tegsStateForm.includes(tagInputValue.trim())) {
      setTegsStateForm((prevState: State) => ({
        ...prevState,
        tags: [...prevState.tags, tagInputValue.trim()],
      }));

      setTagInputValue("");
    }
  };

  const removeTag = (indexToRemove: number) => {
    setTegsStateForm((prevState: State) => ({
      ...prevState,
      tags: prevState.tags.filter((_, index) => index !== indexToRemove),
    }));
  };
  return (
    <div className=" flex flex-col gap-[10px]">
      <h3 className=" text-accent-default text-sm font-semibold">
        {boldLabel}
      </h3>
      <TextField
        value={tagInputValue}
        className=""
        label={label}
        onChange={(e) => setTagInputValue(e.target.value)}
        placeholder={placeholder}
        imgRight={
          <button
            disabled={!tagInputValue.length}
            className={` text-blue-default text-sm font-medium outline-none ${
              !tagInputValue.length && "opacity-50"
            }`}
            onClick={handleClick}
          >
            добавлять
          </button>
        }
        type="text"
        name="teg"
      />
      <div className="flex gap-[10px] items-center justify-start flex-wrap">
        {tegsStateForm?.map((teg: string, i: number) => (
          <div
            key={i}
            className="flex items-center justify-between gap-[10px] px-[14px] py-[6px] bg-accent-default text-white-default text-sm font-normal rounded-xl"
          >
            <span>{teg}</span>
            <div className="cursor-pointer" onClick={() => removeTag(i)}>
              <CancelIcon color="#FFFFFF" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TegField;
