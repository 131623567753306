import React from "react";

const EventRegisterIcon = ({ color = "#3583FF" }) => {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7842 16.2431L11.5265 15.4112C12.4372 14.3904 14.0713 14.5231 14.8054 15.6774C15.4893 16.7527 16.9751 16.9566 17.9233 16.1052L19.2688 14.8972M1.22656 16.4701L5.59255 15.5904C5.82432 15.5437 6.03714 15.4296 6.20428 15.2624L15.978 5.48332C16.4466 5.01446 16.4462 4.25447 15.9772 3.78601L13.9068 1.71794C13.438 1.24967 12.6784 1.24999 12.21 1.71865L2.43533 11.4987C2.26851 11.6656 2.15461 11.878 2.10787 12.1093L1.22656 16.4701Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default EventRegisterIcon;
