import React from "react";
import { Icons } from "./interface";

const TickIcon = ({ color = "white", width = "10", height = "8" }: Icons) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.77093 0.231509C9.70478 0.158151 9.62608 0.099926 9.53938 0.0601915C9.45267 0.0204571 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204571 8.99208 0.0601915C8.90538 0.099926 8.82668 0.158151 8.76053 0.231509L3.45949 6.07013L1.23235 3.61259C1.16367 3.53961 1.08259 3.48223 0.993752 3.44372C0.904911 3.40521 0.810045 3.38633 0.714569 3.38814C0.619093 3.38996 0.524877 3.41244 0.437301 3.45431C0.349725 3.49618 0.270503 3.55661 0.204159 3.63215C0.137815 3.7077 0.085649 3.79687 0.050638 3.89459C0.015627 3.99231 -0.00154303 4.09666 0.000108809 4.20167C0.00176064 4.30669 0.0222019 4.41032 0.0602651 4.50665C0.0983283 4.60298 0.153268 4.69012 0.221948 4.76309L2.95429 7.76849C3.02044 7.84185 3.09914 7.90007 3.18585 7.93981C3.27256 7.97954 3.36556 8 3.45949 8C3.55343 8 3.64643 7.97954 3.73314 7.93981C3.81985 7.90007 3.89855 7.84185 3.96469 7.76849L9.77093 1.38201C9.84315 1.30872 9.9008 1.21977 9.94022 1.12077C9.97965 1.02176 10 0.914848 10 0.806761C10 0.698675 9.97965 0.59176 9.94022 0.492754C9.9008 0.393749 9.84315 0.304798 9.77093 0.231509Z"
        fill={color}
      />
    </svg>
  );
};

export default TickIcon;
