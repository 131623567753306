import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

function App() {
  const { pathname } = useLocation();
  const { keycloak } = useKeycloak();
  useEffect(() => {
    // keycloak.login();
    // console.log("login");

    window.scrollTo(0, 0);
  }, [pathname, keycloak]);

  return (
    <div className="pt-[67px] lg:pt-0 bg-bg-default">
      <Outlet />
    </div>
  );
}

export default App;
