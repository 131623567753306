import React, { useLayoutEffect, useRef } from "react";
import { Project } from "modules/Deal/type";
import { images } from "utils/resource";

interface YMapProps {
  projectList: Project[];
}

declare global {
  interface Window {
    ymaps: any;
  }
}

const YMap: React.FC<YMapProps> = ({ projectList }) => {
  const mapRef = useRef<any>(null);

  useLayoutEffect(() => {
    if (projectList.length !== 0) {
      handleInit();
    }
  }, [projectList]);

  const handleInit = () => {
    window.ymaps.ready(init);
    function init() {
      if (!mapRef.current) {
        mapRef.current = new window.ymaps.Map("map", {
          center: [projectList[0].city.lat, projectList[0].city.long],
          zoom: 9,
          controls: [],
        });
      }

      mapRef.current.geoObjects.removeAll();

      if (projectList.length !== 0) {
        projectList.forEach((proj) => {
          const MyIconContentLayout =
            window.ymaps.templateLayoutFactory.createClass(
              `<div class="w-14 h-14 p-1 rounded-full bg-white-default relative after:absolute after:bottom-[-7px] after:left-4  after:h-0  after:w-0 after:border-r-[10px] after:border-t-[10px] 
            after:border-l-[10px] after:border-r-transparent after:border-l-transparent after:border-t-white-default">
            <img src="${images.cardImg}" class="w-full h-full rounded-full" />
          </div>`
            );

          const myPlacemark = new window.ymaps.Placemark(
            [Number(proj.lat), Number(proj.long)],
            {},
            { iconLayout: MyIconContentLayout }
          );
          mapRef.current.geoObjects.add(myPlacemark);
        });

        mapRef.current.setCenter(
          [projectList[0].city.lat, projectList[0].city.long],
          9,
          { duration: 1500 }
        );
      }
    }
  };

  return (
    <div id="map" className="flex-1 h-[680px] max-w-[700px] overflow-hidden" />
  );
};

export default YMap;
