import { toast, Bounce, ToastPosition, TypeOptions } from "react-toastify";

type ToastStatus = "success" | "error" | "warning" | "info";
type ToastConfig = {
  position: ToastPosition;
  autoClose: number;
  hideProgressBar: boolean;
  closeOnClick: boolean;
  pauseOnHover: boolean;
  draggable: boolean;
  progress: undefined;
  theme: "colored" | "light" | "dark";
  transition: typeof Bounce;
};

export const showToast = (
  message: string,
  status: ToastStatus,
  autoClose = 4000
): void => {
  const config: ToastConfig = {
    position: "top-right",
    autoClose: autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  };

  switch (status) {
    case "success":
      toast.success(message, config);
      break;
    case "error":
      toast.error(message, config);
      break;
    case "warning":
      toast.warn(message, config);
      break;
    case "info":
      toast.info(message, config);
      break;
    default:
      toast.info(message, config);
      break;
  }
};

export default showToast;
