import { Icons } from "./interface";

const Close = (
	{
		width = 24,
		height = 24,
		color = 'white',
		strokeWidth = 1.5
	}: Icons
) => {
	return (
		<svg
			width={ width }
			height={ height }
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16 8L8 16M16 16L8 8"
				stroke={ color }
				strokeWidth={ strokeWidth }
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default Close;
