import { Project } from "modules/Deal/type";
import { apiService } from "..";
import { MetaData } from "../exception";

export const addTagTypes = ["projects"] as const;

export const projectsApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      getProjects: build.query<MetaData<Project[]>, number | null>({
        query: (cityId) => {
          return {
            path: cityId ? `/projects?city_id=${cityId}` : "/projects",
          };
        },
        providesTags: ["projects"],
      }),
    }),
  });

export const { useGetProjectsQuery } = projectsApiHooks;
