import CoinOnHandIcon from "components/constant/icons/Analytics/CoinOnHandIcon";
import CoinsIcon from "components/constant/icons/Analytics/CoinsIcon";
import DealsWonIcon from "components/constant/icons/Analytics/DealsWonIcon";
import EventRegisterIcon from "components/constant/icons/Analytics/EventRegisterIcon";
import NumOfTransacIcon from "components/constant/icons/Analytics/NumOfTransacIcon";
import EyeIcon from "components/constant/icons/EyeIcon";
import LikeIcon from "components/constant/icons/LikeIcon";

export const data = {
  registeredAgentChart: {
    data: [
      {
        name: "9",
        uv: 4000,
        amt: 2400,
      },
      {
        name: "10",
        uv: 3000,
        amt: 2210,
      },
      {
        name: "11",
        uv: 2000,
        amt: 2290,
      },
      {
        name: "12",
        uv: 2780,
        amt: 20000,
      },
      {
        name: "13",
        uv: 1890,
        amt: 15000,
      },
      {
        name: "14",
        uv: 2390,
        amt: 10000,
      },
      {
        name: "15",
        uv: 3490,
        amt: 5000,
      },
      {
        name: "16",
        uv: 1800,
        amt: 5000,
      },
      {
        name: "17",
        uv: 490,
        amt: 5000,
      },
      {
        name: "18",
        uv: 2490,
        amt: 5000,
      },
      {
        name: "19",
        uv: 1490,
        amt: 5000,
      },
    ],
    title: "Зарегистрированные агенты",
    dropdown: false,
    field: "register",
  },
  activeAgentChart: {
    data: [
      {
        name: "9",
        uv: 4000,
        amt: 2400,
      },
      {
        name: "10",
        uv: 3000,
        amt: 2210,
      },
      {
        name: "11",
        uv: 2000,
        amt: 2290,
      },
      {
        name: "12",
        uv: 2780,
        amt: 20000,
      },
      {
        name: "13",
        uv: 1890,
        amt: 15000,
      },
      {
        name: "14",
        uv: 2390,
        amt: 10000,
      },
      {
        name: "15",
        uv: 3490,
        amt: 5000,
      },
      {
        name: "16",
        uv: 1800,
        amt: 5000,
      },
      {
        name: "17",
        uv: 490,
        amt: 5000,
      },
      {
        name: "18",
        uv: 2490,
        amt: 5000,
      },
      {
        name: "19",
        uv: 1490,
        amt: 5000,
      },
    ],
    title: "Активные пользователи",
    dropdown: true,
    field: "active",
  },
  cityChart: {
    data: [
      {
        name: "9",
        uv: 4000,
        amt: 2400,
      },
      {
        name: "10",
        uv: 3000,
        amt: 2210,
      },
      {
        name: "11",
        uv: 2000,
        amt: 2290,
      },
      {
        name: "12",
        uv: 2780,
        amt: 20000,
      },
      {
        name: "13",
        uv: 1890,
        amt: 15000,
      },
      {
        name: "14",
        uv: 2390,
        amt: 10000,
      },
      {
        name: "15",
        uv: 3490,
        amt: 5000,
      },
      {
        name: "16",
        uv: 1800,
        amt: 5000,
      },
      {
        name: "17",
        uv: 490,
        amt: 5000,
      },
      {
        name: "18",
        uv: 2490,
        amt: 5000,
      },
      {
        name: "19",
        uv: 1490,
        amt: 5000,
      },
    ],
    title: "Статистика по городам",
    dropdown: true,
    field: "byCity",
  },
};

export const infoCartData1 = [
  {
    id: 1,
    title: "Размер\nвознаграждений",
    icons: CoinOnHandIcon,
    count: "25 000 000 Руб",
    bottomLeft: "+452 000 руб",
    bottomRight: "+53%",
  },
  {
    id: 2,
    title: "Среднее\nвознаграждение",
    icons: CoinsIcon,
    count: "25 000 000 Руб",
    bottomLeft: "+452 000 руб",
    bottomRight: "+53%",
  },
];

export const infoCartData = [
  {
    id: 3,
    title: "Выиграно\nсделок",
    icons: DealsWonIcon,
    count: "53",
    bottomLeft: "+24%",
    bottomRight: "-15%",
  },
  {
    id: 4,
    title: "Кол-во\nсделок",
    icons: NumOfTransacIcon,
    count: "268",
    bottomLeft: "+42 m2",
    bottomRight: "+53%",
    dropdownd: "Показ",
  },
  {
    id: 5,
    title: "Просмотр\nновостей",
    icons: EyeIcon,
    count: "53",
    bottomLeft: "+24",
    bottomRight: "-15%",
  },
  {
    id: 6,
    title: "Лайки на\nновости",
    icons: LikeIcon,
    count: "25",
    bottomLeft: "+3",
    bottomRight: "+5%",
  },
  {
    id: 7,
    title: "Средний чек\nсделки",
    icons: NumOfTransacIcon,
    count: "25 000 000 Руб",
    bottomLeft: "+452 000 руб",
    bottomRight: "+53%",
  },
  {
    id: 8,
    title: "Средний чек\nсделки",
    icons: NumOfTransacIcon,
    count: "125,02 m2",
    bottomLeft: "+42 m2",
    bottomRight: "+53%",
  },
  {
    id: 9,
    title: "Кол-во сгенерированных\nподборок",
    icons: NumOfTransacIcon,
    count: "1 411",
    bottomLeft: "+24",
    bottomRight: "-15%",
  },
  {
    id: 9,
    title: "Записей на\nмероприятия",
    icons: EventRegisterIcon,
    count: "22 455",
    bottomLeft: "+315",
    bottomRight: "+2%",
  },
];

export const dealsInfoSteps = [
  { id: 1, color: "bg-[#36C979]", label: "Выиграна" },
  { id: 2, color: "bg-[#FF005C]", label: "Проиграна" },
  { id: 3, color: "bg-[#3B4A60]", label: "На паузе" },
  { id: 4, color: "bg-[#3B4A60]", label: "На паузе" },
  { id: 5, color: "bg-[#3B4A60]", label: "На паузе" },
  { id: 6, color: "bg-[#3B4A60]", label: "На паузе" },
  { id: 7, color: "bg-[#3B4A60]", label: "На паузе" },
  { id: 8, color: "bg-[#3B4A60]", label: "На паузе" },
  { id: 9, color: "bg-[#3B4A60]", label: "На паузе" },
];
