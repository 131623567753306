interface DateInfo {
  id: number;
  date: number;
  weekday: string;
  fullDate: string;
}

interface MonthInfo {
  month: string;
  dates: DateInfo[];
}

const getWeekdayName = (date: Date): string => {
  return date.toLocaleDateString("ru-RU", { weekday: "short" });
};

export const generateDateArray = (
  startMonth: number,
  startYear: number,
  endMonth: number,
  endYear: number
): MonthInfo[] => {
  const months: string[] = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];

  const result: MonthInfo[] = [];

  const today = new Date();
  const currentDay = today.getDate();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();

  for (let year = startYear; year <= endYear; year++) {
    for (
      let month = year === startYear ? startMonth - 1 : 0;
      month <= (year === endYear ? endMonth - 1 : 11);
      month++
    ) {
      const monthInfo: MonthInfo = {
        month: months[month],
        dates: [],
      };

      const daysInMonth = new Date(year, month + 1, 0).getDate();

      for (
        let day =
          year === startYear &&
          month === startMonth - 1 &&
          year === currentYear &&
          month === currentMonth
            ? currentDay
            : 1;
        day <= daysInMonth;
        day++
      ) {
        const date = new Date(year, month, day + 1);
        const fullDate = date.toISOString().split("T")[0];
        const dateInfo: DateInfo = {
          id: day,
          date: day,
          weekday: getWeekdayName(date),
          fullDate,
        };

        monthInfo.dates.push(dateInfo);
      }

      result.push(monthInfo);
    }
  }

  return result;
};
