import React, { useEffect, useState } from "react";

import Modal from "components/Modal";
import CircleXIcon from "components/constant/icons/CircleXIcon";
import Label from "components/Form/Label";
import Dropdown from "components/Form/Dropdown/Dropdown";
import ButtonForm from "components/Button/ButtonForm";
import { useFormatForDropDownOptions } from "hooks/useDropDownOption";
import {
  useBookingRequestMutation,
  useGetNotBookedPrimisesMutation,
} from "data/api/booking";
import showToast from "utils/showToast";
import { useCurrentTime } from "hooks/useCurrentDate";
import { useGetProjectsQuery } from "data/api/project";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useGetBuildingsQuery } from "data/api/building";
import { SearchField } from "components/Form/SearchField";
import { formatPhoneNumber } from "hooks/useFormatInput";
import { PuchaseOptions } from "enums/purchaseOption";
import { Primise } from "enums/primisesType";
import { primisesTypeData } from "modules/Apartment/data";
import { ResponseStatusType } from "data/api/exception";
import { Apartment } from "modules/Apartment/ApartmentList/dataTransformer";
import { useGetMeQuery } from "data/api/profile";

interface PropsType {
  isOpen: boolean;
  handleToggle: () => void;
  primise_id?: number;
  refetch?: () => void;
  client_info?: any;
}

type BookingForm = {
  client: number;
  project: number;
  building: number;
  type: Primise;
  primise: number;
  purchase_option: PuchaseOptions;
};

const BookingFrom = ({
  isOpen,
  handleToggle,
  primise_id,
  refetch,
  client_info,
}: PropsType) => {
  const { data: user, refetch: refetchUser } = useGetMeQuery();
  const [notBookedPrimisesdata, setNotBookedPrimisesData] =
    useState<Apartment[]>();
  const [bookingMutate] = useBookingRequestMutation();
  const [getNotBookedPromisesMutation] = useGetNotBookedPrimisesMutation();
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
    getValues,
    watch,
  } = useForm<BookingForm>();
  const projectSelection = useSelector((state: RootState) => state.header);

  const { data: projects } = useGetProjectsQuery(projectSelection.city_id);

  const { data: buildings } = useGetBuildingsQuery({
    projectId: watch("project"),
  });

  console.log("user", user);

  const optionsProject = useFormatForDropDownOptions(projects?.data ?? []);
  const optionsBuilding = useFormatForDropDownOptions(buildings?.data ?? []);
  const optionsPrimises = useFormatForDropDownOptions(
    notBookedPrimisesdata ?? []
  );

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "project") {
        reset(
          { building: undefined, type: undefined, primise: undefined },
          { keepValues: true }
        );
      } else if (name === "building") {
        reset({ type: undefined, primise: undefined }, { keepValues: true });
      } else if (name === "type") {
        reset({ primise: undefined }, { keepValues: true });
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, reset]);

  const purchaseOptions = [
    { label: "Ипотека", value: PuchaseOptions.MORTAGE },
    { label: "Рассрочка", value: PuchaseOptions.INSTALLMENT },
    { label: "Полная оплата", value: PuchaseOptions.FULL_PAYMENT },
    { label: "Счет", value: PuchaseOptions.BILL },
  ];

  const time = useCurrentTime();

  useEffect(() => {
    getNotBooketPrimises();
  }, [watch("building"), watch("type")]);

  async function getNotBooketPrimises() {
    const query =
      getValues("building") && getValues("type")
        ? `building_id=${getValues("building")}&type=${getValues("type")}`
        : "";

    if (getValues("type") && getValues("building")) {
      const { data } = await getNotBookedPromisesMutation(query);
      setNotBookedPrimisesData(data?.data);
    }
  }

  const getValue = (
    opts: { label: string; value: string | number }[],
    val: number | string | undefined
  ) => {
    if (val) {
      return opts.filter((o) => o.value == val);
    }
    return null;
  };

  const onSubmit: SubmitHandler<BookingForm> = async (data) => {
    const { data: res, error } = await bookingMutate({
      data: {
        premise_id: primise_id ? Number(primise_id) : data.primise,
        date: new Date(),
        time: time,
        client_id: client_info?.id
          ? Number(client_info?.id)
          : Number(data.client),
        purchase_option: data.purchase_option,
      },
    });
    if (res?.meta?.type === ResponseStatusType.SUCCESS) {
      handleToggle();
      reset();
      refetch?.();
      refetchUser();
      showToast("Запрос на бронирование принят", "success", 1000);
      return;
    }

    if (error?.message) {
      showToast(error.message, "error");
      return;
    }
  };

  const handleCancel = () => {
    handleToggle();
  };

  return (
    <Modal
      className=" w-full md:w-[500px]  rounded-t-[30px] md:rounded-[30px]"
      isOpen={isOpen}
    >
      <div className=" text-accent-default mb-[20px] flex items-center justify-between">
        <h1 className=" text-[25px] font-extrabold leading-[30px]">
          Бронирование
        </h1>
        <button onClick={handleCancel}>
          <CircleXIcon />
        </button>
      </div>
      {client_info && (
        <div className={"mb-[30px]"}>
          <p className={"text-sm mb-[10px]"}>
            <span className="mr-2"> Номер сделки:</span>

            <span className={"text-accent-default font-medium"}>
              {client_info?.node}
            </span>
          </p>

          <p className={"text-sm mb-[10px]"}>
            <span className="mr-2"> ФИО:</span>

            <span className={"text-accent-default font-medium"}>
              {client_info?.fullname}
            </span>
          </p>

          <p className={"text-sm mb-[10px]"}>
            <span className="mr-2">Номер:</span>
            <span className={"text-accent-default font-medium"}>
              +7 {formatPhoneNumber(client_info?.phone.slice(1))}
            </span>
          </p>
        </div>
      )}

      <form
        onSubmit={handleSubmit(onSubmit)}
        className={
          "flex flex-col gap-[20px] max-h-[70vh] pr-[10px] toolbar-scroll overflow-y-auto"
        }
      >
        {!client_info?.id && (
          <Label label="Клиент">
            <Controller
              name="client"
              control={control}
              rules={{
                required: {
                  value: !client_info?.id,
                  message: "Клиент обязателен для заполнения",
                },
              }}
              render={({ field: { onChange } }) => (
                <SearchField
                  onChange={(value) => onChange(value.id)}
                  className="rounded-[4px!important]"
                />
              )}
            />
          </Label>
        )}

        {!primise_id && (
          <>
            <Label
              label="Проект"
              labelLink={{ label: "Открыть проекты", to: "/projects" }}
            >
              <Controller
                name="project"
                control={control}
                rules={{
                  required: {
                    value: !primise_id,
                    message: "Проект обязателен для заполнения",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    name="project"
                    value={getValue(optionsProject, value)}
                    options={optionsProject}
                    onChange={(value) => onChange(value.value)}
                    placeholder="Проект не выбран"
                    errors={errors}
                  />
                )}
              />
            </Label>

            <Label label="Объект">
              <Controller
                name="building"
                control={control}
                rules={{
                  required: {
                    value: !primise_id,
                    message: "Объект обязателен для заполнения",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    name="building"
                    value={getValue(optionsBuilding, value)}
                    options={watch("project") ? optionsBuilding : []}
                    onChange={(value) => onChange(value.value)}
                    placeholder="Объект не выбран"
                    errors={errors}
                  />
                )}
              />
            </Label>

            <Label label="Вид помещения">
              <Controller
                name="type"
                control={control}
                rules={{
                  required: {
                    value: !primise_id,
                    message: "Вид помещения обязателен для заполнения",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    name="type"
                    value={getValue(primisesTypeData, value)}
                    options={primisesTypeData}
                    onChange={(value) => onChange(value.value)}
                    placeholder="Вид помещения не выбран"
                    errors={errors}
                  />
                )}
              />
            </Label>

            <Label label="Номер помещения">
              <Controller
                name="primise"
                control={control}
                rules={{
                  required: {
                    value: !primise_id,
                    message: "Номер помещения",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    name="type"
                    options={watch("type") && optionsPrimises}
                    value={getValue(optionsPrimises, value)}
                    onChange={(value) => onChange(value.value)}
                    placeholder="Номер помещения"
                    errors={errors}
                  />
                )}
              />
            </Label>
          </>
        )}

        <Label label="Способ покупки">
          <Controller
            name="purchase_option"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Вариант покупки обязателен для заполнения",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                name="purchase_options"
                options={purchaseOptions}
                value={getValue(purchaseOptions, value)}
                onChange={(value) => onChange(value.value)}
                placeholder="Способ покупки не выбран"
                errors={errors}
                menuPosition="fixed"
              />
            )}
          />
        </Label>
        <p className="text-accent-default text-sm text-center">
          Осталось бронирований:{" "}
          {user?.meta?.data?.remaining_user_creation_limit}
        </p>
        <div className="flex gap-[10px]">
          <ButtonForm
            disabled={!isValid}
            text="Забронировать"
            type="submit"
            className="py-3 px-6 
        bg-accent-default flex-1
        text-white-default flex justify-center
        hover:bg-accent-disabled
        hover:
        "
          />
          {/* <ButtonForm
            text="Удалить"
            className=" py-3 px-6 bg-white-default text-accent-default
            border border-accent-default
        hover:bg-accent-default flex-1
        hover:text-white-default flex justify-center
        "
          /> */}
        </div>
      </form>
    </Modal>
  );
};

export default BookingFrom;
