import { useState } from "react";
import Slider from "react-slick";

interface PropsType {
  list: string[];
  index: number;
}

const NewsBannerVertical = ({ list, index }: PropsType) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    vertical: true,
    verticalSwiping: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    draggable: false,
    adaptiveHeight: true,
    appendDots: (dots: React.ReactNode) => (
      <div
        style={{
          right: 0,
          top: "50%",
          transform: "translateY(-50%)",
          bottom: "auto",
          width: "auto",
          zIndex: "3",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i: number) => (
      <div
        style={
          i === currentIndex
            ? {}
            : {
                opacity: "0.6",
              }
        }
        className={`h-[8px] slick w-[8px] rounded-full bg-white-default custom-dot `}
      ></div>
    ),
    afterChange: (currentIndex: number) => {
      setCurrentIndex(currentIndex);
    },
  };

  return (
    <div className="slider-container relative">
      <Slider className="center" {...settings}>
        {list.map((img, idx) => (
          <div key={`slider-img-item-${index}${idx}`}>
            <img
              src={process.env.REACT_APP_FILES_URL + "/" + img}
              width={"100%"}
              className="md:h-[404px] h-[142px]"
              crossOrigin="anonymous"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default NewsBannerVertical;
