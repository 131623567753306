import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";

export interface SelectionState {
  isOpenCreateModal: boolean;
}
const initialState: SelectionState = {
  isOpenCreateModal: false,
};

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    handleOpenCreateNews: (state, action: PayloadAction<boolean>) => {
      state.isOpenCreateModal = action.payload;
    },
  },
});

export const {
	handleOpenCreateNews,
} = newsSlice.actions;

export const getIsOpenCreateNews = (state: RootState) =>
  state.news.isOpenCreateModal;

export default newsSlice.reducer;
