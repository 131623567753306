import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "data/api";
import { User } from "data/api/profile/type";

export const updateUserAction = createAsyncThunk("user/updateUser", () => {});

export const getUserAction = createAsyncThunk<
  User,
  void,
  { rejectValue: string }
>("user/getUser", async (_, { rejectWithValue }) => {
  try {
    const user = await api.fetch<User>({ path: "/users/me" });

    return user;
  } catch (err) {
    const error = err as Error;
    return rejectWithValue(error.message);
  }
});

export const logoutUserAction = createAsyncThunk("user/logoutUser", () => {});
