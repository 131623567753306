import React from "react";

const VKIcon = () => {
  return (
    <svg
      width="18"
      height="11"
      viewBox="0 0 18 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.80626 10.5293H9.88176C9.88176 10.5293 10.2069 10.4933 10.3723 10.3144C10.5253 10.1501 10.5196 9.84189 10.5196 9.84189C10.5196 9.84189 10.4983 8.39851 11.1688 8.18589C11.8291 7.97664 12.6774 9.58089 13.5763 10.1985C14.2558 10.6654 14.7721 10.563 14.7721 10.563L17.1763 10.5293C17.1763 10.5293 18.434 10.4516 17.8378 9.46276C17.7894 9.38176 17.4901 8.73151 16.0501 7.39501C14.5426 5.99551 14.7451 6.22276 16.5609 3.80289C17.6668 2.32914 18.1089 1.42914 17.9705 1.04439C17.8389 0.677638 17.0255 0.774388 17.0255 0.774388L14.321 0.790138C14.321 0.790138 14.1208 0.763138 13.9711 0.852013C13.826 0.939763 13.7326 1.14226 13.7326 1.14226C13.7326 1.14226 13.304 2.28301 12.7325 3.25276C11.5276 5.29914 11.045 5.40714 10.8481 5.28001C10.3903 4.98414 10.505 4.08976 10.505 3.45526C10.505 1.47189 10.8054 0.645013 9.91889 0.431263C9.62414 0.360388 9.40814 0.313138 8.65551 0.305263C7.69026 0.295138 6.87239 0.308638 6.41001 0.534763C6.10176 0.685513 5.86439 1.02189 6.00951 1.04101C6.18839 1.06464 6.59339 1.15014 6.80826 1.44264C7.08614 1.81951 7.07601 2.66776 7.07601 2.66776C7.07601 2.66776 7.23576 5.00214 6.70364 5.29239C6.33801 5.49151 5.83739 5.08539 4.76301 3.22801C4.21289 2.27739 3.79664 1.22551 3.79664 1.22551C3.79664 1.22551 3.71676 1.02976 3.57389 0.925138C3.40064 0.798013 3.15764 0.757513 3.15764 0.757513L0.585888 0.773263C0.585888 0.773263 0.200013 0.784513 0.0582629 0.952138C-0.0677371 1.10176 0.0481379 1.41001 0.0481379 1.41001C0.0481379 1.41001 2.06189 6.12039 4.34114 8.49526C6.43251 10.6721 8.80626 10.5293 8.80626 10.5293Z"
        fill="#3B4658"
      />
    </svg>
  );
};

export default VKIcon;
