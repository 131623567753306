import React from "react";

import { useSelector } from "react-redux";
import { RootState } from "store";

import ApartmentList from "../ApartmentList";
import ParkingOrStoreroom from "../ParkingStoreComercial";

import { useGetPremisesQuery } from "data/api/premises";
import { objectToQueryString } from "utils/objectToQuery";
import { Apartment } from "../ApartmentList/dataTransformer";
import { SelectionState } from "store/ApartmentReducer";
import { Primise } from "enums/primisesType";

interface propsType {
  children: React.ReactNode;
}

interface propsTypeRenderContent {
  selection: SelectionState;
}

const RenderContent = ({ selection }: propsTypeRenderContent) => {
  const filters = useSelector((state: RootState) => state.apartment);
  const { data, isLoading } = useGetPremisesQuery<{
    data: { data: Apartment[] };
    isLoading: boolean;
  }>(objectToQueryString(filters.filters));

  const roomlist = data?.data ?? [];

  switch (selection.filters.type) {
    case Primise.STOREROOM:
      return <ParkingOrStoreroom data={roomlist} isLoading={isLoading} />;
    case Primise.PARKING:
      return <ParkingOrStoreroom data={roomlist} isLoading={isLoading} />;
    default:
      return <ApartmentList data={roomlist} isLoading={isLoading} />;
  }
};
const ContentLayout = ({ children }: propsType) => {
  const selection = useSelector((state: RootState) => state.apartment);

  return (
    <div className="appartment-scroll pb-[50px] relative">
      {children}
      {<RenderContent selection={selection} />}
    </div>
  );
};

export default ContentLayout;
