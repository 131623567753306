import React, { Dispatch, SetStateAction, useState } from "react";
import ButtonForm from "components/Button/ButtonForm";
import Modal from "components/Modal";
import { images } from "utils/resource";
import CircleXIcon from "components/constant/icons/CircleXIcon";
import TextField from "components/Form/TextField/TextField";
import Label from "components/Form/Label";
import Dropdown from "components/Form/Dropdown/Dropdown";
import { useRoles } from "hooks/useRoles";
import { User, UserStatus } from "data/api/profile/type";
import { formatPhoneNumber } from "hooks/useFormatInput";
import { formatDate } from "@fullcalendar/core";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import showToast from "utils/showToast";
import ImgUpload from "components/Form/ImgUpload";
import { useUploadFileMutation } from "data/api/file";
import {
  useLoginAsUserMutation,
  useUpdateUserMutation,
} from "data/api/profile";
import { ResponseStatusType } from "data/api/exception";
import UserOutIcon from "components/constant/icons/UserOutIcon";
import { roleOptions, UserRole } from "enums/users";

interface Props {
  isVerifyModal: boolean;
  setIsVerifyModal: Dispatch<SetStateAction<boolean>>;
  isEditModal: boolean;
  setIsEditModal: Dispatch<SetStateAction<boolean>>;
  user?: User;
  refetch: () => void;
}

const AdminsModals = ({
  isVerifyModal,
  setIsVerifyModal,
  isEditModal,
  setIsEditModal,
  user,
  refetch,
}: Props) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid },
  } = useForm<User>(user ? { values: user } : {});
  const [fileMutate] = useUploadFileMutation();
  const [userUpdateMutate] = useUpdateUserMutation();
  const [isHover, setIsHovered] = useState(false);
  const [loginAsAdminModal, setloginAsAdminModal] = useState(false);
  const [loginAsUserMutate] = useLoginAsUserMutation();
  const roles = useRoles(null);

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("image", file);
      try {
        const response = await fileMutate(formData).unwrap();
        const fileName = response.data.filename;
        setValue("avatar", fileName);
      } catch (error) {
        console.error("File upload failed", error);
      }
    }
  };

  const onSubmit: SubmitHandler<User> = async (data) => {
    const { data: res, error } = await userUpdateMutate({
      data: {
        id: user?.id,
        role: data.role,
        firstName: data.firstName,
        lastName: data.lastName,
        avatar: data.avatar,
        status: data.status,
      },
    });
    if (res?.meta?.type === ResponseStatusType.SUCCESS) {
      showToast("Пользователь успешно обновлены!", "success", 2000);
      setIsVerifyModal(false);
      setIsEditModal(false);
      refetch();
      return;
    }
    if (error?.message) {
      showToast(error?.message, "error", 2000);
      return;
    }
  };

  const handleVerify = async () => {
    const { error } = await userUpdateMutate({
      data: {
        id: user?.id,
        is_verified: true,
      },
    });

    if (error?.message) {
      showToast(error?.message, "error", 2000);
      return;
    }
    refetch();
    setIsVerifyModal(false);
  };

  const getValue = (
    opts: { label: string; value: string | number }[],
    val: number | string | undefined
  ) => {
    if (val) {
      return opts.filter((o) => o.value == val);
    }
    return user ? opts?.[0] : null;
  };

  const statusOptions = [
    { value: UserStatus.ACTIVE, label: "Активный" },
    { value: UserStatus.BLOCKED, label: "Блокировать" },
  ];

  const hanldeLoginAdUser = async () => {
    const { data: res, error } = await loginAsUserMutate({
      data: { user_id: user?.id },
    });

    if (res?.data?.accessToken) {
      logIn(res.data.accessToken);
      return;
    }

    if (error?.message) {
      showToast(error?.message, "error", 2000);
      return;
    }
    setloginAsAdminModal(false);
  };

  function logIn(newToken: string) {
    if (newToken) {
      const oldToken = localStorage.getItem("token") as string;
      localStorage.setItem("old_token", oldToken);
      localStorage.setItem("token", newToken);
      window.location.reload();
    }
  }
  const roleUpdateByRolePermission = () => {
    switch (roles.currentRole) {
      case UserRole.HEAD_OF_AGENCY: {
        return roleOptions.filter(
          (item) =>
            item.value === UserRole.AGENT ||
            item.value === UserRole.HEAD_OF_AGENCY
        );
      }
      default:
        return roleOptions.slice(1);
    }
  };

  console.log("user", user);

  const imgUrl = process.env.REACT_APP_FILES_URL;

  return (
    <>
      <Modal
        className=" w-full md:w-[419px] rounded-t-[30px] md:rounded-lg"
        isOpen={isVerifyModal}
        onClose={() => setIsVerifyModal(false)}
      >
        <div className=" text-accent-default text-center">
          <h1 className=" text-[25px] font-dewi leading-[30px]">Верификация</h1>
          <p className="text-sm font-normal mt-1">
            Подтвердите регистрацию агента в вашем агентстве недвижимости
          </p>
        </div>
        <div className="flex gap-3 items-center py-[15px] my-5 px-5 bg-bg-default rounded-lg">
          <img
            src={user?.avatar ? `${imgUrl}/${user.avatar}` : images.defaultImg}
            alt="user image"
            width={50}
            height={50}
            className="rounded-full w-[50px] h-[50px]"
            crossOrigin="anonymous"
          />
          <div className="flex flex-col ">
            <p className="text-sm text-accent-default font-semibold">
              {user?.fullName}
            </p>
            <p className="text-[12px] text-accent-disabled font-medium">
              +7 {formatPhoneNumber(user?.phone)}
            </p>
          </div>
          <div className="flex- flex-col gap-[5px] text-end ml-auto text-sm text-accent-disabled font-normal">
            <p>{user?.role}</p>
            <p>{formatDate(user?.created_at || "")}</p>
          </div>
        </div>
        {user?.is_verified ? (
          <ButtonForm
            text="Уже проверено"
            onClick={() => setIsVerifyModal(false)}
            className=" py-3 px-6 
            bg-bg-default text-accent-default hover:bg-accent-default  hover:text-white-default w-full flex justify-center
        "
          />
        ) : (
          <div className="flex gap-[10px]">
            <ButtonForm
              text="Нет"
              onClick={() => setIsVerifyModal(false)}
              className=" py-3 px-6 
            bg-bg-default text-accent-default hover:bg-accent-default  hover:text-white-default flex-1 flex justify-center
        "
            />
            <ButtonForm
              text="Да"
              onClick={handleVerify}
              className=" py-3 px-6 bg-accent-default text-white-default flex-1 flex justify-center hover:bg-accent-disabled
        "
            />
          </div>
        )}
      </Modal>

      <Modal
        className=" w-full md:w-[419px] rounded-t-[30px] md:rounded-lg"
        isOpen={loginAsAdminModal}
      >
        <div className=" text-accent-default text-center">
          <h1 className=" text-[25px] font-dewi leading-[30px]">
            Вход от имени пользователя
          </h1>
          <p className="text-sm font-normal mt-1">
            Подтвердите аккаунт от лица которого хотите зайти
          </p>
        </div>
        <div className="flex gap-3 items-center py-[15px] my-5 px-5 bg-bg-default rounded-lg">
          <img
            src={user?.avatar ? `${imgUrl}/${user.avatar}` : images.defaultImg}
            alt="user image"
            width={50}
            height={50}
            className="rounded-full"
            crossOrigin="anonymous"
          />
          <div className="flex flex-col ">
            <p className="text-sm text-accent-default font-semibold">
              {user?.fullName}
            </p>
            <p className="text-[12px] text-accent-disabled font-medium">
              +7 {formatPhoneNumber(user?.phone)}
            </p>
          </div>
          <div className="flex- flex-col gap-[5px] text-end ml-auto text-sm text-accent-disabled font-normal">
            <p>{user?.role}</p>
            <p>{formatDate(user?.created_at || "")}</p>
          </div>
        </div>
        <div className="flex gap-[10px]">
          <ButtonForm
            text="Нет"
            onClick={() => setloginAsAdminModal(false)}
            className=" py-3 px-6 
            bg-bg-default text-accent-default hover:bg-accent-default  hover:text-white-default flex-1 flex justify-center
        "
          />
          <ButtonForm
            text="Да"
            onClick={hanldeLoginAdUser}
            className=" py-3 px-6 bg-accent-default text-white-default flex-1 flex justify-center hover:bg-accent-disabled
        "
          />
        </div>
      </Modal>

      <Modal
        className=" w-full md:w-[440px] rounded-t-[30px] md:rounded-lg "
        isOpen={isEditModal}
      >
        <div className=" text-accent-default mb-[30px] flex items-center justify-between">
          <h1 className=" text-[25px] font-extrabold leading-[30px]">
            Редактировать
          </h1>
          <button onClick={() => setIsEditModal(false)}>
            <CircleXIcon />
          </button>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-5 max-h-[80vh] overflow-y-auto pr-3 toolbar-scroll"
        >
          <div className="">
            <Controller
              name="avatar"
              control={control}
              render={({ field: { value } }) => (
                <ImgUpload
                  value={value ? `${imgUrl}/${value}` : ""}
                  handleImageUpload={handleImageUpload}
                  title="Фото профиля"
                  requirementText="Добавьте изображение с разрешением минимум 1080х1080 px"
                />
              )}
            />
          </div>
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value}
                onChange={onChange}
                label="Имя"
                placeholder="Имя"
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value}
                onChange={onChange}
                label="Фамилия"
                placeholder="Фамилия"
              />
            )}
          />
          <Controller
            name="role"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <Label label="Роль">
                <Dropdown
                  name="role"
                  options={roleUpdateByRolePermission()}
                  value={getValue(roleOptions, value)}
                  onChange={(value) => onChange(value.value)}
                  placeholder="Новый пользователь"
                />
              </Label>
            )}
          />
          {!roles.isAffiliate_manager && !roles.isAdmin ? (
            <Controller
              name="status"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <Label label="Статус">
                  <Dropdown
                    name="status"
                    options={statusOptions}
                    onChange={(value) => onChange(value.value)}
                    value={getValue(statusOptions, value)}
                    placeholder="Активный"
                  />
                </Label>
              )}
            />
          ) : null}
          <div className="flex flex-col gap-[10px] mt-5">
            <ButtonForm
              disabled={!isValid}
              text="Сохранить"
              type="submit"
              className=" py-3 px-6 hover:bg-accent-disabled text-white-default bg-accent-default hover:text-bg-defaultt flex justify-center"
            />
            {/* {!roles.isAffiliate_manager && !roles.isAdmin ? (
              <ButtonForm
                text={watch("is_blocked") ? "Разблокировать" : "Заблокировать"}
                onClick={handleToggleBlockTheUser}
                className={` py-3 px-6  border  flex justify-center hover:bg-red-default hover:text-white-default ${
                  watch("is_blocked")
                    ? "bg-red-default text-white-default"
                    : "border-red-default text-red-default "
                } `}
              />
            ) : null} */}
            {roles.isAdmin ? (
              <ButtonForm
                leftIcon={
                  <UserOutIcon color={isHover ? "#FFFFFF" : "#37465B"} />
                }
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={() => {
                  setIsEditModal(false);
                  setloginAsAdminModal(true);
                }}
                text={"Зайти от лица пользователя"}
                className={` py-3 px-6   flex justify-center bg-bg-default text-accent-default hover:bg-accent-default  hover:text-white-default `}
              />
            ) : null}
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AdminsModals;
