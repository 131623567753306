import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { RootState } from "store";
import { useDispatch, useSelector } from "react-redux";

import ButtonForm from "components/Button/ButtonForm";
import Dropdown from "components/Form/Dropdown/Dropdown";
import Label from "components/Form/Label";
import PageTitle from "components/PageTitle";
import {
  handleFilterData,
  resetFilter,
  selectType,
} from "store/ApartmentReducer";
import { Filters } from "store/ApartmentReducer/type";
import { useFilterOptions } from "hooks/useDropDownOption";
import { useWindowSize } from "../../../hooks/useWindowSize";

import { primisesTypeData, rangeData } from "../data";
import { handleOpenFilters } from "store/BaseClientsReducer";
import { Primise } from "enums/primisesType";
import { useGetPremisesSeasonsQuery } from "data/api/premises";
import { objectToQueryString } from "utils/objectToQuery";
import MultiRangeSlider from "components/Form/MultiRangeSlider";

interface propsType {
  title: string;
}

const Filter = ({ title }: propsType) => {
  const { isMd, isSm } = useWindowSize();
  const selection = useSelector((state: RootState) => state);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const [localFilters, setLocalFilters] = useState<Filters>({
    type: selection.apartment.filters.type,
  });
  const filterOptions = useFilterOptions(
    localFilters?.project_id as number,
    localFilters?.building_id as number
  );
  const primisesType = queryParams.get("primises_type");

  const { data: seasonData } = useGetPremisesSeasonsQuery(
    objectToQueryString({ ...localFilters, season_id: "" })
  );

  const resetQueryParams = () => {
    navigate({
      pathname: "/apartments",
    });
  };

  const seasonOptions = seasonData?.data.map((item) => {
    return {
      label: item.season_name,
      value: item.id,
    };
  });

  const isMobile = isMd || isSm;

  const updateLocalFilter = (key: keyof Filters, value: string) => {
    setLocalFilters((prevFilters) => ({
      ...((prevFilters as Filters) || null),
      [key]: value,
    }));
    dispatch(selectType(localFilters?.type as Primise));
  };

  const updateFilter = () => {
    dispatch(
      handleFilterData({ ...selection.apartment.filters, ...localFilters })
    );
  };

  useEffect(() => {
    dispatch(resetFilter());
    if (id) {
      if (selection.apartment.filters.city_id !== selection.header.city_id) {
        resetQueryParams();
      }
      setLocalFilters({
        type: (primisesType as Primise) || selection.apartment.filters.type,
        project_id: Number(id),
        city_id: selection.header.city_id,
        building_id: 0,
      });
      dispatch(
        handleFilterData({
          type: (primisesType as Primise) || selection.apartment.filters.type,
          city_id: selection.header.city_id,
          project_id: Number(id),
        })
      );
    } else {
      setLocalFilters({
        type: Primise.APARTMENT,
      });
      dispatch(
        handleFilterData({
          type: Primise.APARTMENT,
          city_id: selection.header.city_id,
        })
      );
    }
  }, [selection.header.city_id, id]);

  const handleTypeSelect = (e: { value: string }) => {
    updateLocalFilter("type", e.value);
    setLocalFilters((prev) => ({
      ...((prev as Filters) || null),
      rooms: "",
    }));
  };

  const handleToggleRooms = (item: string) => {
    if (item === localFilters?.rooms) {
      updateLocalFilter("rooms", "");
      return;
    }
    updateLocalFilter("rooms", item);
  };

  const handleProjectChange = (e: any) => {
    updateLocalFilter("project_id", e.value);
    updateLocalFilter("building_id", "");
    updateLocalFilter("section_id", "");
  };

  const getValue = (
    opts: { label: string; value: string | number }[],
    val: number | string | undefined
  ) => {
    if (val) {
      return opts.filter((o) => o.value == val);
    }
    return null;
  };

  return (
    <div
      className={`filter bg-white-default
        p-[20px] xl:p-[30px] self-start md:min-w-[346px]
        `}
    >
      <PageTitle text={title} />
      <div className="flex flex-col gap-[25px] mt-[30px]">
        <Label label="Тип помещения">
          <Dropdown
            onChange={handleTypeSelect}
            options={primisesTypeData}
            name="type"
            placeholder="Все"
            className="w-full"
            value={getValue(primisesTypeData, localFilters?.type)}
          />
        </Label>
        <Label label="Проект">
          <Dropdown
            onChange={handleProjectChange}
            options={[{ label: "всё", value: null }, ...filterOptions.projects]}
            value={getValue(filterOptions.projects, localFilters?.project_id)}
            placeholder="Все"
            className="w-full"
            name="project_id"
          />
        </Label>
        <Label label="Дом">
          <Dropdown
            onChange={(e) => {
              updateLocalFilter("building_id", e.value);
            }}
            options={[
              { label: "всё", value: null },
              ...filterOptions.buildings,
            ]}
            placeholder="Все"
            className="w-full"
            disabled={
              filterOptions.buildings.length === 0 || !localFilters?.project_id
            }
            name="building_id"
            value={getValue(
              [{ label: "всё", value: 0 }, ...filterOptions.buildings],
              localFilters?.building_id
            )}
          />
        </Label>
        <Label label="Секция">
          <Dropdown
            onChange={(e) => updateLocalFilter("section_id", e.value)}
            options={[{ label: "всё", value: "" }, , ...filterOptions.sections]}
            placeholder="Все"
            className="w-full"
            disabled={
              !localFilters?.building_id || filterOptions.sections.length === 0
            }
            name="section_id"
            value={getValue(filterOptions.sections, localFilters?.section_id)}
          />
        </Label>
        <Label label="Срок сдачи">
          <Dropdown
            onChange={(e) => updateLocalFilter("season_id", e.value)}
            options={[{ label: "всё", value: "" }, , ...(seasonOptions || [])]}
            placeholder="Все"
            className="w-full"
            name="season_id"
            // value={getValue(filterOptions.sections, localFilters?.season_id)}
          />
        </Label>
        {localFilters?.type === Primise.APARTMENT ? (
          <Label label="Комнат">
            <div className="flex gap-2 items-center mb-[10px]">
              <div
                onClick={() => handleToggleRooms("0")}
                className={`text-[12px] font-normal w-[70px] h-[45px] border border-light-blue-10 
               leading-[18.75px] rounded-full text-accent-default cursor-pointer
             flex items-center justify-center ${
               "0" == localFilters?.rooms &&
               "bg-accent-default text-white-default"
             } `}
              >
                Студия
              </div>
              {["1", "2", "3", "4+"].map((item) => (
                <div
                  onClick={() => handleToggleRooms(item)}
                  key={item}
                  className={`text-[16px] font-normal w-[46px] h-[45px] border border-light-blue-10 
               leading-[18.75px] rounded-full text-accent-default cursor-pointer
             flex items-center justify-center ${
               item == localFilters?.rooms &&
               "bg-accent-default text-white-default"
             } `}
                >
                  {item}
                </div>
              ))}
            </div>
          </Label>
        ) : (
          <div></div>
        )}

        {rangeData.map(
          ({
            label,
            min,
            max,
            step,
            filterKeys,
          }: {
            label: string;
            min: number;
            max: number;
            step: number;
            filterKeys: any;
          }) => (
            <Label label={label} key={label}>
              <MultiRangeSlider
                onChange={(value) => {
                  updateLocalFilter(filterKeys[0], value.min.toString());
                  updateLocalFilter(filterKeys[1], value.max.toString());
                }}
                max={max}
                min={min}
                step={step}
              />
            </Label>
          )
        )}
        {/* <button className="text-[14px] leading-[24px] font-medium text-blue-default hover:text-blue-focus">
            Больше фильтров
          </button> */}
        <div className="flex flex-col sm:gap-[10px]">
          <ButtonForm
            text="Применить"
            className={`border border-accent-default mt-[1px] py-3 px-6
             hover:bg-accent-default flex justify-center
              hover:text-white-default w-full
              ${isMobile ? "bg-accent-default text-white-default" : ""}
              `}
            onClick={(e) => {
              updateFilter();
              dispatch(handleOpenFilters(false));
            }}
          />
          {isMobile && (
            <ButtonForm
              text="Отмена"
              className="border border-accent-default py-3 px-6 flex justify-center w-full mt-[10px]"
              onClick={() => dispatch(handleOpenFilters(false))}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Filter;
