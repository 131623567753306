import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";

export interface SelectionState {
  isOpenFilters: boolean;
  isOpenSearch: boolean;
  isOpenModal: boolean;
  isOpenBaseClientModal: number | null;
  isOpenRecordForShow: boolean;
  isOpenBookingModal: boolean;
  isNotificationModalOpen: boolean;
  isDealsInfoModalOpen: number;
}
const initialState: SelectionState = {
  isOpenFilters: false,
  isOpenSearch: false,
  isOpenModal: false,
  isOpenBaseClientModal: null,
  isOpenRecordForShow: false,
  isOpenBookingModal: false,
  isNotificationModalOpen: false,
  isDealsInfoModalOpen: 0,
};

const baseClientsSlice = createSlice({
  name: "baseClients",
  initialState,
  reducers: {
    handleOpenFilters: (state, action: PayloadAction<boolean>) => {
      state.isOpenFilters = action.payload;
    },
    handleOpenSearch: (state, action: PayloadAction<boolean>) => {
      state.isOpenSearch = action.payload;
    },
    handleOpenModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenModal = action.payload;
    },
    toggleBaseClientInfoModal: (state, action: PayloadAction<number>) => {
      const cardId = action.payload;
      state.isOpenBaseClientModal =
        state.isOpenBaseClientModal == cardId ? null : cardId;
    },
    toggleRecordForShowModal: (state) => {
      state.isOpenRecordForShow = !state.isOpenRecordForShow;
    },
    toggleBookingModal: (state) => {
      state.isOpenBookingModal = !state.isOpenBookingModal;
    },
    toggleNotificationFormModal: (state) => {
      state.isNotificationModalOpen = !state.isNotificationModalOpen;
    },
    handleToggleDealsInfo: (state, action: PayloadAction<number>) => {
      state.isDealsInfoModalOpen = action.payload;
    },
  },
});

export const {
  handleOpenFilters,
  handleOpenSearch,
  handleOpenModal,
  toggleRecordForShowModal,
  toggleBaseClientInfoModal,
  toggleBookingModal,
  toggleNotificationFormModal,
  handleToggleDealsInfo,
} = baseClientsSlice.actions;

export const getIsOpenFilters = (state: RootState) =>
  state.baseClients.isOpenFilters;
export const getIsOpenSearch = (state: RootState) =>
  state.baseClients.isOpenSearch;
export const getIsOpenModal = (state: RootState) =>
  state.baseClients.isOpenModal;

export default baseClientsSlice.reducer;
