import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

interface DataItem {
  name: string;
  uv: number;
  amt: number;
}

interface Props {
  data: DataItem[];
  title: string;
}

const BarChartComponent = ({ data, title }: Props) => {
  return (
    <div className="p-[15px] bg-white-default rounded-lg flex-1">
      <h3 className="text-accent-default text-[16px] font-medium">{title}</h3>
      <div style={{ width: "100%", height: 230 }}>
        <ResponsiveContainer>
          <BarChart
            data={data}
            margin={{
              top: 30,
              bottom: 14
            }}
            barSize={10}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              label={{ value: "Апр", position: "left", offset: 0 }}
              dataKey="name"
            />
            <YAxis />
            <Tooltip />
            <Bar
              dataKey="uv"
              stackId="a"
              fill="#37465B"
              radius={[10, 10, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default BarChartComponent;
