import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";

export interface SelectionState {
  isOpenDatePicker: boolean;
}
const initialState: SelectionState = {
  isOpenDatePicker: false,
};

const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    handleOpenDatePicker: (state, action: PayloadAction<boolean>) => {
      state.isOpenDatePicker = action.payload;
    },
  },
});

export const {
  handleOpenDatePicker,
} = calendarSlice.actions;

export const getIsOpenDatePicker = (state: RootState) =>
  state.calendar.isOpenDatePicker;

export default calendarSlice.reducer;
