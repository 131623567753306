import React from "react";
import { Pie, PieChart, Cell } from "recharts";

const PieChartComponent = () => {
  const data = [
    { name: "Group A", value: 400 },
    { name: "Group B", value: 300 },
    { name: "Group C", value: 300 },
    { name: "Group D", value: 100 },
    { name: "Group D", value: 80 },
    { name: "Group D", value: 500 },
    { name: "Group D", value: 100 },
  ];
  const COLORS = [
    "#36C979",
    "#1400FF",
    "#FF005C",
    "#3B4A60",
    "#52FF01",
    "#7723FF",
    "#DEBE4E",
  ];

  return (
    <PieChart
      width={140}
      height={140}
    >
      <Pie
        data={data}
        cx={65}
        cy={70}
        innerRadius={45}
        outerRadius={60}
        fill="#8884d8"
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default PieChartComponent;
