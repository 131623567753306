import React from "react";

const ClockIcon = (
	{
		color = "#37465B"
	}
) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6378 12.9615C14.0308 13.0925 14.4555 12.8801 14.5865 12.4872C14.7175 12.0942 14.5051 11.6695 14.1122 11.5385L13.6378 12.9615ZM10.5 11.125H9.75C9.75 11.4478 9.95657 11.7344 10.2628 11.8365L10.5 11.125ZM11.25 6.42087C11.25 6.00666 10.9142 5.67087 10.5 5.67087C10.0858 5.67087 9.75 6.00666 9.75 6.42087H11.25ZM14.1122 11.5385L10.7372 10.4135L10.2628 11.8365L13.6378 12.9615L14.1122 11.5385ZM11.25 11.125V6.42087H9.75V11.125H11.25ZM18.75 10C18.75 14.5563 15.0563 18.25 10.5 18.25V19.75C15.8848 19.75 20.25 15.3848 20.25 10H18.75ZM10.5 18.25C5.94365 18.25 2.25 14.5563 2.25 10H0.75C0.75 15.3848 5.11522 19.75 10.5 19.75V18.25ZM2.25 10C2.25 5.44365 5.94365 1.75 10.5 1.75V0.25C5.11522 0.25 0.75 4.61522 0.75 10H2.25ZM10.5 1.75C15.0563 1.75 18.75 5.44365 18.75 10H20.25C20.25 4.61522 15.8848 0.25 10.5 0.25V1.75Z"
        fill={color}
      />
    </svg>
  );
};

export default ClockIcon;
