import React from "react";

import CancelIcon from "components/constant/icons/CancelIcon";
import { Apartment } from "../ApartmentList/dataTransformer";
import { useDispatch } from "react-redux";
import { handleDeteleteItemInStore } from "store/ApartmentReducer";
import { Primise } from "enums/primisesType";

interface propsType {
  item: Apartment;
  hanldeRemove: (arg: number) => void;
  actions: boolean;
}

const InfoParkingCard = ({ item, hanldeRemove, actions }: propsType) => {
  const { id, floor, number, size, section, type } = item;

  const dispatch = useDispatch();

  const handleDeleteData = (e: React.MouseEvent, id: number, type: Primise) => {
    e.stopPropagation();
    dispatch(handleDeteleteItemInStore({ type, id }));
  };
  const handleJumpToSeeProduct = (e: React.MouseEvent) => {
    e.stopPropagation();
    window.location.href = `https://ekaterinburg.brusnika.ru/flat`;
  };

  return (
    <div
      onClick={handleJumpToSeeProduct}
      className="py-[15px] px-[20px] bg-white-default rounded-lg flex flex-col gap-[20px] cursor-pointer"
    >
      <div className="flex gap-[30px] text-accent-default">
        <div className="flex flex-col gap-[6px] ">
          <h5 className="text-[14px] font-medium leading-[18px]">Этаж</h5>
          <h4 className="text-[14px] font-normal">{floor}</h4>
        </div>
        <div className="flex flex-col gap-[6px] ">
          <h5 className="text-[14px] font-medium leading-[18px]">Секция</h5>
          <h4 className="text-[14px] font-normal">{section?.name}</h4>
        </div>
        <div className="flex flex-col gap-[6px] ">
          <h5 className="text-[14px] font-medium leading-[18px]">Номер</h5>
          <h4 className="text-[14px] font-normal">{number}</h4>
        </div>
        {actions && (
          <button
            onClick={(e) => {
              hanldeRemove(id);
              handleDeleteData(e, id, type);
            }}
            className=" outline-none w-[8px] ml-auto h-[8px]"
          >
            <CancelIcon />
          </button>
        )}
      </div>
      <div className="flex gap-[30px] items-center text-accent-default">
        <div className="flex flex-col gap-[6px] ">
          <h5 className="text-[14px] font-medium leading-[18px]">Дом</h5>
          <h4 className="text-[14px] font-normal">Наземный паркинг</h4>
        </div>
        <div className="flex flex-col gap-[6px] ">
          <h5 className="text-[14px] font-medium leading-[18px]">Площадь</h5>
          <h4 className="text-[14px] font-normal">{size}</h4>
        </div>
      </div>
    </div>
  );
};

export default InfoParkingCard;
