import React from "react";

const CompassIcon = (
	{
		rotate = 0,
	}: { rotate: number }
) => {
	return (
		<svg
			width="68"
			height="68"
			viewBox="0 0 68 68"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_33_7)">
				<g className="rotate_zHRlo" style={{transform: `rotate(${rotate}deg)`, transformOrigin: "center"}}>
					<path
						d="M34.5118 8.35645C33.9959 8.35645 33.5188 8.27198 33.0806 8.10305C32.6455 7.93412 32.2709 7.68245 31.957 7.34804C31.6431 7.01363 31.3991 6.59648 31.2251 6.09659C31.0542 5.5967 30.9687 5.01923 30.9687 4.3642C30.9687 3.75399 31.0511 3.20066 31.2158 2.70422C31.3805 2.20777 31.6198 1.782 31.9337 1.4269C32.2352 1.0856 32.6082 0.821862 33.0526 0.635695C33.5002 0.449529 33.9881 0.356445 34.5165 0.356445C34.8086 0.356445 35.0713 0.375407 35.3044 0.41333C35.5406 0.447805 35.7581 0.494347 35.957 0.552955C36.1653 0.618458 36.3533 0.69258 36.5211 0.775321C36.6921 0.854614 36.8413 0.928736 36.9687 0.997686V2.86453H36.7636C36.6765 2.78178 36.5662 2.68353 36.4326 2.56976C36.302 2.45599 36.1528 2.34395 35.985 2.23363C35.8141 2.12331 35.6291 2.03022 35.4302 1.95438C35.2313 1.87853 35.0184 1.84061 34.7915 1.84061C34.5398 1.84061 34.3005 1.88543 34.0736 1.97506C33.8467 2.06125 33.6369 2.20605 33.4442 2.40945C33.2608 2.60596 33.1117 2.86625 32.9967 3.19032C32.8848 3.51439 32.8288 3.9074 32.8288 4.36937C32.8288 4.85203 32.8894 5.25539 33.0106 5.57946C33.135 5.90353 33.2904 6.15864 33.4768 6.34481C33.6664 6.53442 33.8778 6.6706 34.1109 6.75334C34.344 6.83264 34.574 6.87228 34.8009 6.87228C35.0184 6.87228 35.2329 6.83608 35.4442 6.76369C35.6587 6.69129 35.856 6.59303 36.0363 6.46892C36.1886 6.36894 36.33 6.26207 36.4605 6.1483C36.5911 6.03453 36.6983 5.93628 36.7822 5.85354H36.9687V7.69452C36.7946 7.78071 36.6284 7.86172 36.4699 7.93757C36.3113 8.01342 36.1451 8.07892 35.971 8.13408C35.7441 8.20648 35.5312 8.26164 35.3323 8.29956C35.1334 8.33748 34.8599 8.35645 34.5118 8.35645Z"
						fill="#E1E1E1"
						className="rotateLetter_x8625"
						style={{transform: `rotate(-${rotate}deg)`, transformOrigin: "center", transformBox: "fill-box"}}></path>
					<path
						d="M7.35547 36.6448C7.35547 37.0137 7.28404 37.3515 7.14118 37.6584C7.0015 37.9652 6.79198 38.222 6.51261 38.4289C6.22055 38.6461 5.87293 38.8116 5.46975 38.9253C5.06658 39.0357 4.57452 39.0908 3.99356 39.0908C3.48245 39.0908 3.00626 39.0443 2.56499 38.9512C2.1269 38.8616 1.72372 38.7409 1.35547 38.5892V36.8516H1.5269C1.61579 36.9068 1.74912 36.9861 1.9269 37.0895C2.10785 37.1895 2.2888 37.2757 2.46975 37.3481C2.69833 37.4412 2.90944 37.5153 3.10309 37.5705C3.29991 37.6222 3.5269 37.648 3.78404 37.648C4.03483 37.648 4.26341 37.6256 4.46975 37.5808C4.6761 37.536 4.84912 37.467 4.9888 37.3739C5.14436 37.274 5.26023 37.1585 5.33642 37.0275C5.41261 36.893 5.45071 36.7206 5.45071 36.5103C5.45071 36.3104 5.42214 36.1484 5.36499 36.0242C5.31102 35.9001 5.22531 35.7984 5.10785 35.7191C4.99039 35.6467 4.84277 35.5967 4.66499 35.5692C4.49039 35.5416 4.28404 35.5278 4.04594 35.5278H2.74118V34.1522H3.99833C4.23642 34.1522 4.44118 34.1333 4.61261 34.0953C4.78404 34.054 4.9142 33.9971 5.00309 33.9247C5.1015 33.842 5.16817 33.7506 5.20309 33.6506C5.24118 33.5506 5.26023 33.4248 5.26023 33.2731C5.26023 33.0145 5.14118 32.8267 4.90309 32.7094C4.66817 32.5922 4.34594 32.5336 3.93642 32.5336C3.69198 32.5336 3.46499 32.5595 3.25547 32.6112C3.04594 32.6629 2.84595 32.7232 2.65547 32.7922C2.44912 32.8715 2.28404 32.9456 2.16023 33.0145C2.03642 33.08 1.90626 33.1473 1.76975 33.2162H1.59356V31.5976C1.91737 31.47 2.31261 31.3546 2.77928 31.2511C3.24912 31.1443 3.73801 31.0908 4.24594 31.0908C5.21737 31.0908 5.94595 31.2563 6.43166 31.5873C6.92055 31.9148 7.16499 32.3716 7.16499 32.9577C7.16499 33.3955 7.03325 33.773 6.76975 34.0902C6.50944 34.4073 6.17134 34.6194 5.75547 34.7262V34.8038C5.9269 34.8348 6.10626 34.89 6.29356 34.9693C6.48404 35.0451 6.65706 35.1537 6.81261 35.2951C6.97134 35.4399 7.1015 35.6192 7.20309 35.8329C7.30468 36.0432 7.35547 36.3138 7.35547 36.6448Z"
						fill="#E1E1E1"
						className="rotateLetter_x8625"
						style={{transform: `rotate(-${rotate}deg)`, transformOrigin: "center", transformBox: "fill-box"}}></path>
					<path
						d="M67.4102 36.7854C67.4102 37.1722 67.3411 37.5179 67.2029 37.8223C67.0679 38.1268 66.8811 38.3793 66.6425 38.5799C66.3662 38.8163 66.0616 38.9846 65.7288 39.0849C65.3992 39.1852 64.98 39.2354 64.4714 39.2354H61.4102V31.2354H64.1323C64.6974 31.2354 65.1103 31.2568 65.3709 31.2998C65.6346 31.3428 65.8952 31.4377 66.1527 31.5846C66.4196 31.7386 66.6174 31.9463 66.7461 32.2078C66.878 32.4657 66.9439 32.7612 66.9439 33.0943C66.9439 33.4812 66.8544 33.8232 66.6755 34.1205C66.4965 34.4142 66.2438 34.6435 65.9172 34.8082V34.8512C66.3756 34.9551 66.7383 35.17 67.0051 35.4959C67.2751 35.8219 67.4102 36.2517 67.4102 36.7854ZM65.093 33.4973C65.093 33.3647 65.0632 33.2322 65.0036 33.0997C64.947 32.9672 64.845 32.8687 64.6974 32.8042C64.5656 32.7469 64.4007 32.7164 64.2029 32.7129C64.0083 32.7057 63.7335 32.7021 63.3788 32.7021H63.2092V34.3945H63.4918C63.7775 34.3945 64.0208 34.3891 64.2218 34.3784C64.4227 34.3677 64.5813 34.3318 64.6974 34.2709C64.8607 34.1886 64.9675 34.0829 65.0177 33.954C65.0679 33.8214 65.093 33.6692 65.093 33.4973ZM65.5357 36.7531C65.5357 36.4988 65.4918 36.3036 65.4039 36.1675C65.3191 36.0278 65.1731 35.924 64.9659 35.8559C64.8246 35.8093 64.6299 35.7843 64.3819 35.7807C64.1339 35.7771 63.8748 35.7753 63.6048 35.7753H63.2092V37.7686H63.3411C63.8497 37.7686 64.2139 37.7668 64.4337 37.7632C64.6535 37.7596 64.856 37.7131 65.0412 37.6235C65.2296 37.534 65.3584 37.4158 65.4274 37.2689C65.4996 37.1185 65.5357 36.9466 65.5357 36.7531Z"
						fill="#E1E1E1"
						className="rotateLetter_x8625"
						style={{transform: `rotate(-${rotate}deg)`, transformOrigin: "center", transformBox: "fill-box"}}></path>
				</g>
				<path
					d="M34.2343 21.5791L38.2342 33.0075H30.2344L34.2343 21.5791Z"
					fill="#767E8B"
					className="rotate_zHRlo"
					style={{transform: `rotate(${rotate}deg)`, transformOrigin: "center"}}
				></path>
				<path
					d="M34.5971 43.4022C34.4771 43.7453 33.9918 43.7453 33.8717 43.4022L30.2345 33.0101L38.2344 33.0101L34.5971 43.4022Z"
					fill="#E1E1E1"
					className="rotate_zHRlo"
					style={{transform: `rotate(${rotate}deg)`, transformOrigin: "center"}}
				></path>
				<path
					d="M7.43667 46.1083C7.09846 45.988 6.72672 46.1646 6.60637 46.5028L4.64506 52.0142C4.52471 52.3524 4.70131 52.7242 5.03952 52.8445C5.37773 52.9649 5.74947 52.7883 5.86982 52.4501L7.61321 47.551L12.5122 49.2944C12.8505 49.4148 13.2222 49.2382 13.3426 48.9C13.4629 48.5617 13.2863 48.19 12.9481 48.0697L7.43667 46.1083ZM6.63164 46.9996C9.70841 53.476 14.9826 58.6512 21.5161 61.6046L22.0516 60.42C15.7985 57.5933 10.7506 52.6403 7.80586 46.4418L6.63164 46.9996Z"
					fill="#ECC44F"
					className="rotate_zHRlo"
					style={{transform: `rotate(${rotate}deg)`, transformOrigin: "center"}}
				></path>
				<path
					d="M46.7162 60.774C53.0536 57.7633 58.1177 52.6023 61.0078 46.2089"
					stroke="#ECC44F"
					strokeWidth="1.3"
					className="rotate_zHRlo"
					style={{transform: `rotate(${rotate}deg)`, transformOrigin: "center"}}
				></path>
				<path
					d="M60.7695 21.2766C57.7588 14.9392 52.5978 9.87511 46.2044 6.98502"
					stroke="#E1E1E1"
					strokeWidth="1.3"
					className="rotate_zHRlo"
					style={{transform: `rotate(${rotate}deg)`, transformOrigin: "center"}}
				></path>
				<path
					d="M21.276 7.2233C14.9386 10.2341 9.87447 15.3951 6.98438 21.7884" stroke="#E1E1E1"
					strokeWidth="1.3"
					className="rotate_zHRlo"
					style={{transform: `rotate(${rotate}deg)`, transformOrigin: "center"}}
				></path>
				<path
					d="M34.2539 63.8795C35.6346 63.8795 36.7539 62.7602 36.7539 61.3795C36.7539 59.9988 35.6346 58.8795 34.2539 58.8795C32.8732 58.8795 31.7539 59.9988 31.7539 61.3795C31.7539 62.7602 32.8732 63.8795 34.2539 63.8795Z"
					stroke="#ECC44F"
					strokeWidth="1.33"
					strokeLinecap="round"
					strokeLinejoin="round"
					className="rotate_zHRlo"
					style={{transform: `rotate(${rotate}deg)`, transformOrigin: "center"}}
				></path>
				<path
					d="M34.2539 55.8795V56.8795"
					stroke="#ECC44F"
					strokeWidth="1.33"
					strokeLinecap="round"
					strokeLinejoin="round"
					className="rotate_zHRlo"
					style={{transform: `rotate(${rotate}deg)`, transformOrigin: "center"}}
				></path>
				<path
					d="M34.2539 65.8795V66.8795"
					stroke="#ECC44F"
					strokeWidth="1.33"
					strokeLinecap="round"
					strokeLinejoin="round"
					className="rotate_zHRlo"
					style={{transform: `rotate(${rotate}deg)`, transformOrigin: "center"}}
				></path>
				<path
					d="M30.3633 57.4889L31.0733 58.1989"
					stroke="#ECC44F"
					strokeWidth="1.33"
					strokeLinecap="round"
					strokeLinejoin="round"
					className="rotate_zHRlo"
					style={{transform: `rotate(${rotate}deg)`, transformOrigin: "center"}}
				></path>
				<path
					d="M37.4336 64.5592L38.1436 65.2692"
					stroke="#ECC44F"
					strokeWidth="1.33"
					strokeLinecap="round"
					strokeLinejoin="round"
					className="rotate_zHRlo"
					style={{transform: `rotate(${rotate}deg)`, transformOrigin: "center"}}
				></path>
				<path
					d="M28.754 61.3795H29.754"
					stroke="#ECC44F"
					strokeWidth="1.33"
					strokeLinecap="round"
					strokeLinejoin="round"
					className="rotate_zHRlo"
					style={{transform: `rotate(${rotate}deg)`, transformOrigin: "center"}}
				></path>
				<path
					d="M38.7539 61.3795H39.7539"
					stroke="#ECC44F"
					strokeWidth="1.33"
					strokeLinecap="round"
					strokeLinejoin="round"
					className="rotate_zHRlo"
					style={{transform: `rotate(${rotate}deg)`, transformOrigin: "center"}}
				></path>
				<path
					d="M30.3633 65.2692L31.0733 64.5592"
					stroke="#ECC44F"
					strokeWidth="1.33"
					strokeLinecap="round"
					strokeLinejoin="round"
					className="rotate_zHRlo"
					style={{transform: `rotate(${rotate}deg)`, transformOrigin: "center"}}
				></path>
				<path
					d="M37.4336 58.1989L38.1436 57.4889"
					stroke="#ECC44F"
					strokeWidth="1.33"
					strokeLinecap="round"
					strokeLinejoin="round"
					className="rotate_zHRlo"
					style={{transform: `rotate(${rotate}deg)`, transformOrigin: "center"}}
				></path>
			</g>
		</svg>
	);
};

export default CompassIcon;
