import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SelectionState {
  delete: number[];
}

const initialState: SelectionState = {
  delete: [],
};

const contactsSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    handleDeleteContact: (state, action: PayloadAction<number>) => {
      state.delete.push(action.payload);
    },
    handleBackToInitialContactsState: (state) => {
      state.delete = initialState.delete;
    },
  },
});

export const { handleDeleteContact, handleBackToInitialContactsState } =
  contactsSlice.actions;

export default contactsSlice.reducer;
