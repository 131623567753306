import React, { useEffect, useMemo, useRef, useState } from "react";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import timeGridPlugin from "@fullcalendar/timegrid";
import rrulePlugin from "@fullcalendar/rrule";
import { FullCalendarComponentEventItem } from "./FullCalendarComponentEventItem";
import Modal from "../../components/Modal";
import ButtonForm from "../../components/Button/ButtonForm";
import Close from "../../components/constant/icons/Close";
import CloseModals from "./CloseModals";
import SpinnerIcon from "../../components/constant/icons/SpinnerIcon";
import Dropdown from "../../components/Form/Dropdown/Dropdown";
import { useGetCalendarQuery } from "data/api/calendar";
import { formatPhoneNumber } from "hooks/useFormatInput";
import { useFormatForDropDownOptions } from "hooks/useDropDownOption";
import { useGetCitiesQuery } from "data/api/city";
import { VisitStatus } from "enums/visitStatus";
import { useGetMeQuery } from "../../data/api/profile";
import { dropDownOptionsType } from "../Apartment/Filter/type";
import { useDispatch, useSelector } from "react-redux";
import { handleSelectCity } from "store/HeaderReducer";
import CalendarDatePickerField from "../../components/Calendar/CalendarDatePickerField";
import { useWindowSize } from "../../hooks/useWindowSize";
import {
  getIsOpenDatePicker,
  handleOpenDatePicker,
} from "../../store/CalendarReducer";
import { useRoles } from "hooks/useRoles";
import FullCalendarMobile from "./FullCalendarMobile";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../components/constant/icons/BackIcon";

interface ContentModalData {
  key: string;
  title: string;
  text?: string;
}

const dayHeaderContent = (arg: any) => {
  const { text, date } = arg;

  return (
    <div className={"text-center bg-bg-default p-[6px]"}>
      <p
        className={
          "text-xs capitalize font-medium text-accent-focus opacity-50 pb-[2px] leading-[16px]"
        }
      >
        {text}
      </p>

      <p className="text-sm font-medium leading-[18px]">{date.getDate()}</p>
    </div>
  );
};
const CalendarPage = () => {
  const slotMinTime = "10:00:00";
  const slotMaxTime = "18:00:00";

  const role = useRoles(null);
	const navigate = useNavigate();

  const { isMd } = useWindowSize();

  const [selectedDate, setSelectedDate] = useState<Date | string>("");
  const [state, setState] = useState<any | null>(null);

  const { data, isLoading } = useGetCalendarQuery(state?.value);
  const res = useGetCitiesQuery();
  const options = useFormatForDropDownOptions(res?.data?.data ?? []);
  const { data: userData } = useGetMeQuery();
  const dispatch = useDispatch();

  const isOpenDatePicker = useSelector(getIsOpenDatePicker);

  const checkShowDatePicker = isMd ? isOpenDatePicker : true;

  const profileData = userData?.data;

	const formatText: any  = {
		online: "Онлайн",
		offline: "Оффлайн",
	}

  const [contentModal, setContentModal] = useState<any>(null);
  const [contentModalList, setContentModalList] = useState<ContentModalData[]>(
    []
  );

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenMobileModal, setIsOpenMobileModal] = useState(false);
  const [isCloseOpened, setIsCloseOpened] = useState(false);

  const [dateStart, setDateStart] = useState<Date | null>(null);

  const timeStringToMinutes = (timeString: string) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return hours * 60 + minutes + seconds / 60;
  };

  const minTimeInMinutes = timeStringToMinutes(slotMinTime);
  const maxTimeInMinutes = timeStringToMinutes(slotMaxTime);

  const differenceInMinutes = maxTimeInMinutes - minTimeInMinutes;

  const differenceInHours = differenceInMinutes / 60;

  const generateTimeSlots = () => {
    // Разбираем минимальное и максимальное время на часы, минуты и секунды
    const [minHour, minMinute, minSecond] = slotMinTime.split(":").map(Number);
    const [maxHour, maxMinute, maxSecond] = slotMaxTime.split(":").map(Number);

    // Создаем массив времени
    const timeSlots = [];
    let currentHour = minHour;

    while (currentHour <= maxHour) {
      // Формируем строку времени в формате HH:MM:SS
      const timeSlot = `${String(currentHour).padStart(2, "0")}:${String(
        minMinute
      ).padStart(2, "0")}`;
      timeSlots.push(timeSlot);
      currentHour++;
    }

    return timeSlots;
  };

  const formatDate = (date: Date, i = 0, hours = "") => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}`;
  };

  function formatModalDate(inputString = ""): string {
    const tIndex = inputString?.indexOf("T");
    const zIndex = inputString?.indexOf("Z");

    const datePart = inputString?.slice(0, tIndex);
    const timePart = inputString?.slice(zIndex + 2).trim();

    return `${datePart} ${timePart}`;
  }

  const listCalendar = useMemo(() => {
    const visitList = data?.data?.visits ?? [];
    const eventsList = data?.data?.events ?? [];

    const res = visitList.map((item, i) => {
      return {
        id: item.id,
        resourceId: item.project_id,
        title: "Показ",
        text: item.client?.fullname,
        start: formatDate(new Date(item.date), i, item.time),
        end: formatDate(new Date(item.date), i, item.time),
        date: item.date + " " + item.time?.slice(0, item.time.length - 3),
        name: item.client?.fullname,
        number: "+7 " + formatPhoneNumber(item.client?.phone_number?.slice(1)),
        project: item.project?.name,
        manager_name: item.manager?.fullname,
        manager_number: item.manager?.phone,
        backgroundColor: "#EBF3FF",
        address: item.project?.location,
        status: item.status,
      };
    });

    const events = eventsList.map((item: any, i) => {
      const time = item.time
        ? {
            date:
              item.date +
              (item.time
                ? " " + item.time?.slice(0, item.time.length - 3)
                : ""),
            start: formatDate(new Date(item.date), i, item.time),
            end: formatDate(new Date(item.date), i, item.time),
          }
        : {
            date: item.date,
            start: formatDate(new Date(item.date), i, item.start_time),
            end: formatDate(new Date(item.date), i, item.end_time),
          };

      return {
        ...time,
        id: item.id,
        resourceId: item.project_id,
        title: "Мероприятие",
        text: item.title,
        name: item.client?.fullname,
        number: "+7 " + formatPhoneNumber(item.client?.phone_number?.slice(1)),
        project: item.project?.name,
        manager_name: item.manager?.fullname,
        manager_number: item.manager?.phone,
        backgroundColor: "#F6FCF5",
        address: item.project?.location,
        status: item.status,
				is_signer: item?.user_invitation?.is_accepted || item?.is_owner,
				type: formatText[item.format] || ""
      };
    });

    const list = [...res, ...events].filter(
      (el) => el.status !== VisitStatus.FAIL
    );

    return list;
  }, [data?.data]);

  const handleCityChange = (city: dropDownOptionsType) => {
    setState(city);
    dispatch(handleSelectCity(Number(city.value)));
  };

	const isSelectEvent = (): boolean => {
		return contentModal?.title === "Мероприятие"
	}

  function searchData(id: number | string, key: string) {
    return listCalendar.find((i) => +i.id === id && key === i.title);
  }

  const calendarRef = useRef<any>(null);

  useEffect(() => {
    if (calendarRef.current && selectedDate) {
      calendarRef.current.getApi().gotoDate(selectedDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (!state && profileData?.city?.id) {
      const findCity = options.find(
        (item) => item.value === profileData?.city?.id
      );

      findCity && setState(findCity);
    }
  }, [profileData, state]);

	useEffect(() => {
		if (isOpenMobileModal) {
			setIsOpenMobileModal(false);
		}
	}, [isOpenMobileModal])

  function onClickCalendarNew(info: any) {}

  function onClickCalendar(item: any) {
    const keysToExtract: ContentModalData[] = [
      {
        key: "id",
        title: "Id",
      },
      {
        key: "date",
        title: "Дата показа",
      },
      {
        key: "name",
        title: "Клиент",
      },
      {
        key: "number",
        title: "телефон",
      },
      {
        key: "project",
        title: "Проект",
      },
      {
        key: "address",
        title: "Адрес",
      },
      {
        key: "manager_name",
        title: "Мененджер продаж",
      },
      {
        key: "manager_number",
        title: "Телефон мененджера",
      },
    ];

    const extractedArray = keysToExtract.map((i) => ({
      ...i,
      text: item[i.key],
    }));

		if (item.title === "Мероприятие") {
			return handleOpenEvent(item)
		}

    setContentModal(item);
    setContentModalList(extractedArray);
    setIsOpenModal(true);
  }

  const handleCancelVisit = () => {
    setIsOpenModal(false);
    setIsCloseOpened(true);
  };

  const handleOpenEvent = (select: any) => {
    navigate(`/events/view/${select.id}`)
  };

	function handleBackToModal() {
		setIsOpenModal(false);
		setIsOpenMobileModal(true);
	}

  if (isLoading)
    return (
      <div className="flex justify-center items-center h-[40vh] px-[20px] py-[10px] relative">
        <SpinnerIcon />
      </div>
    );

  return (
    <>
      <div className={"bg-bg-default min-h-[50vh]"}>
        <div className="br-container md:py-[20px] py-[10px] md:px-[20px]">
          <div className={"calendar-block relative"}>
            <div
              className={
                "absolute  md:right-[20px] md:top-[20px] right-2 top-2 z-[6] cursor-pointer md:max-w-[302px] max-w-[140px] w-full mb-0 flex gap-[12px] md:h-[48px] h-[24px]"
              }
            >
              <Dropdown
                value={state}
                onChange={handleCityChange}
                options={[{ label: "Все", value: "" }, ...options]}
                placeholder="Выберите город"
                className="w-full cursor-pointer"
              />

              {checkShowDatePicker && (
                <div className={"md:relative md:w-auto w-full absolute"}>
                  <CalendarDatePickerField
                    startDate={dateStart}
                    onChangeDate={(value) => {
                      setDateStart(value);

                      setSelectedDate(value);
                      dispatch(handleOpenDatePicker(false));
                    }}
                    className={"h-full min-h-[48px]"}
                    isMobile={isMd}
                  />
                </div>
              )}
            </div>

						{isMd ? (
							<FullCalendarMobile
								handleOpenModal={onClickCalendar}
								isOpenMobileModal={isOpenMobileModal}
								listCalendar={listCalendar}
							/>
						) : (
							<div
								className={"relative bg-white-default rounded-lg overflow-hidden"}
							>
								<div
									className={
										"absolute top-[142px] z-10 text-xs w-[42px] flex flex-col items-center justify-between"
									}
								>
									{generateTimeSlots().map((item, idx) => (
										<div
											key={`item-${idx}`}
											className={"h-[124px] text-[#00000080]"}
										>
											{item}
										</div>
									))}
								</div>

								<FullCalendar
									ref={calendarRef}
									plugins={[
										timeGridPlugin,
										resourceTimelinePlugin,
										interactionPlugin,
										dayGridPlugin,
										rrulePlugin,
									]}
									initialView={"timeGridFourDay"}
									views={{
									  timeGridFourDay: {
									    type: "timeGrid",
									    duration: { days: 30 },
									  },
									}}
									schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
									locale="ru"
									dayHeaderContent={dayHeaderContent}
									// headerToolbar={ false } // Скрыть шапку
									// displayEventTime // Показывать выбранное время
									// selectable // Подсветка выбранной даты
									height={`${145.5 * differenceInHours}px`}
									// hiddenDays={[0]}
									// slotEventOverlap={false}
									// weekends={false}
									// now={new Date().toISOString()}
									// nowIndicator
									allDaySlot={false}
									slotLabelFormat={{
										hour: "2-digit",
										minute: "2-digit",
										omitZeroMinute: false,
										meridiem: "short",
									}}
									eventDisplay="block"
									slotLabelInterval="01:00"
									slotDuration="00:30:00"
									snapDuration="00:30:00"
									slotMinTime={slotMinTime || "08:00:00"}
									slotMaxTime={slotMaxTime || "20:00:00"}
									slotLabelContent={({text}) => {
										return <span className="customTimeLine">{text}</span>;
									}}
									titleFormat={(arg) => {
										const month = new Date(arg.date.marker).toLocaleString(
											"default",
											{month: "long"}
										);
										const year = arg.date.year;

										return `${month} ${year}`;
									}}
									headerToolbar={{
										start: "prev,title,next", // will normally be on the left. if RTL, will be on the right
										center: "",
										end: "", // will normally be on the right. if RTL, will be on the left
									}}
									themeSystem="bootstrap5"
									firstDay={1} // Первый день понедельник, начинается с воскресенья
									eventContent={(info) => {
										return (
											<FullCalendarComponentEventItem
												info={info}
												item={searchData(Number(info.event.id), info.event.title)}
											/>
										);
									}}
									eventTextColor="#000"
									eventBorderColor="transparent"
									dateClick={(info) => {
										const date = new Date();
										const selectedDate = info.date;

										if (date > selectedDate) {
											return;
										}

										if (onClickCalendarNew) onClickCalendarNew(info);
									}}
									eventClick={(info) => {
										const item = listCalendar.find(
											(i) => i.id === Number(info.event.id)
										);

										if (onClickCalendar) onClickCalendar(item);
									}}
									// @ts-ignore
									events={listCalendar}
								/>
							</div>
						)}
					</div>
				</div>
			</div>

			<Modal
				isOpen={isOpenModal}
				className=" w-full  md:w-[500px] rounded-t-[30px] md:rounded-lg"
				onClose={() => setIsOpenModal(false)}
			>
				<div className={`flex justify-${isMd ? "center" : "between"}`}>
					{isMd && (
						<button
							className="border-none outline-none h-[37px] rounded-[4px] flex items-center justify-center gap-1 bg-white-default absolute left-[20px] text-sm
          "
							onClick={handleBackToModal}
						>
							<BackIcon size={16}/>
						</button>
					)}

					<h1 className={"font-extrabold text-[25px] font-dewi"}>{contentModal?.title || "Показ"}</h1>

					{!isMd && (
						<ButtonForm
							text={<Close color={"black"}/>}
							onClick={() => setIsOpenModal(false)}
						/>
					)}
				</div>

				<div className={"my-[20px]"}>
					{contentModalList &&
						contentModalList?.slice(1).map((item, idx) => {

							if (isSelectEvent()) {
								if (item.key === "date") return (
									<div className={"mb-[10px]"} key={`modal-content-item-${idx}`}>
										<p
											className={
												"text-sm font-medium text-accent-default mb-[10px]"
											}
										>
											{item.title}:
											<span className={"font-normal ml-[10px]"}>
                    {formatModalDate(item?.text)}
                  </span>
										</p>
									</div>
								)

								return null
							}

							return (
								<div className={"mb-[10px]"} key={`modal-content-item-${idx}`}>
									<p
										className={
											"text-sm font-medium text-accent-default mb-[10px]"
										}
									>
										{item.title}:
										<span className={"font-normal ml-[10px]"}>
                    {item.key === "date"
											? formatModalDate(item?.text)
											: item?.text}
                  </span>
									</p>
								</div>
							)
						})}
				</div>

				{!isSelectEvent() ? (
					<div className={"flex flex-col"}>
						{role.isManager ||
							role.isAdmin ||
							(role.isAffiliate_manager && (
								<ButtonForm
									text={"Перейти в задачу"}
									className={
										"p-3 bg-bg-default mb-[10px] justify-center font-medium"
									}
									onClick={() => {
									}}
								/>
							))}

						<ButtonForm
							text={"Отменить"}
							className={
								"p-3 bg-red-default text-white-default justify-center font-medium"
							}
							onClick={handleCancelVisit}
						/>
					</div>
				) : (
					<div className={"flex flex-col"}>
						<ButtonForm
							text={"Подробнее"}
							className={
								"p-3 bg-bg-default text-accent-default justify-center font-medium"
							}
							onClick={() => handleOpenEvent(contentModal)}
						/>
					</div>
				)}
			</Modal>

			<CloseModals
				isOpen={isCloseOpened}
				eventId={contentModalList?.[0]?.text}
				close={() => setIsCloseOpened(false)}
			/>
		</>
	);
};

export default CalendarPage;
