import { Icons } from "./interface";

const ListIcon = (
	{
		width = 21,
		height = 20,
		color = 'white',
		strokeWidth = 1.5
	}: Icons
) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.51667 5H18.25M7.51667 10.4H18.25M7.51667 15.8H18.25M3.25 5V5.01067M3.25 10.4V10.4107M3.25 15.8V15.8107"
				stroke={color}
				strokeWidth={strokeWidth}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default ListIcon;
