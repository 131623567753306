import store from "store";
import { apiService } from "..";
import { MetaData } from "../exception";
import { ContactRequestType, IContact } from "./type";

export const addTagTypes = ["contact"] as const;

export const contactsApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      getContacts: build.query<MetaData<IContact[]>, number>({
        query: (id) => ({
          path: `/contact?city_id=${id}`,
        }),
        providesTags: ["contact"],
      }),
      contactsBulk: build.mutation<
        MetaData<IContact[]>,
        MetaData<ContactRequestType>
      >({
        query: ({ data }) => ({
          path: `/contact/bulk`,
          body: { meta: {}, data: data },
          method: "POST",
        }),
      }),
    }),
  });

export const { useGetContactsQuery, useContactsBulkMutation } =
  contactsApiHooks;
