import React from "react";

const TelegramIcon = ({ color = "#3B4658" }) => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.06288 10.3857L6.76513 14.5737C7.19113 14.5737 7.37563 14.3907 7.59688 14.171L9.59413 12.2622L13.7326 15.293C14.4916 15.716 15.0264 15.4932 15.2311 14.5947L17.9476 1.86575L17.9484 1.865C18.1891 0.742999 17.5426 0.304249 16.8031 0.579499L0.835633 6.69275C-0.254117 7.11575 -0.237617 7.72325 0.650383 7.9985L4.73263 9.26825L14.2149 3.335C14.6611 3.0395 15.0669 3.203 14.7331 3.4985L7.06288 10.3857Z"
        fill={color}
      />
    </svg>
  );
};

export default TelegramIcon;
