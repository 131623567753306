import { apiService } from "..";
import { MetaData } from "../exception";
import { City, DaDataResponseItem } from "./type";

export const addTagTypes = ["cities"] as const;

export const citiesApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      getCities: build.query<MetaData<City[]>, void>({
        query: () => ({
          path: "/cities",
        }),
        // providesTags: ["cities"],
      }),
    }),
  });

export const { useGetCitiesQuery } = citiesApiHooks;

export const getCityByIp = async () => {
  const myIpResponse = await fetch("https://api.ipify.org?format=json");

  const ipData = (await myIpResponse.json()) as { ip: string };

  var url =
    "https://suggestions.dadata.ru/suggestions/api/4_1/rs/iplocate/address?ip=";
  var token = "462e202dd30c150f1f16a8e6414ab45e9d107c32";

  var options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Token " + token,
    },
  };

  const sugeestionsResponse = await fetch(url + ipData.ip, options);
  const suggestionsData = (await sugeestionsResponse.json()) as {
    location: null | { value: string };
  };

  return suggestionsData;
};

export const getCityByDaData = async (
  query: string
): Promise<DaDataResponseItem[]> => {
  const url =
    "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
  const token = "462e202dd30c150f1f16a8e6414ab45e9d107c32";

  const options: RequestInit = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify({ query: query }),
  };

  try {
    const suggestionsResponse = await fetch(url, options);

    if (!suggestionsResponse.ok) {
      throw new Error(`HTTP error! Status: ${suggestionsResponse.status}`);
    }

    const suggestionsData = await suggestionsResponse.json();

    return suggestionsData.suggestions || [];
  } catch (error) {
    console.error("Error fetching city data:", error);
    return [];
  }
};
