import { NotificationFormSubmit } from "modules/BaseClients/BaseClientFormNotification";
import { apiService } from "..";
import { MetaData } from "../exception";
import { Client, ClientSearch } from "./type";

export const addTagTypes = ["baseClients"] as const;

export const baseClientsApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      filterBaseClientsList: build.mutation<MetaData<Client[]>, string>({
        query: (query) => ({
          path: query ? `/client?${query}` : "/client",
        }),
      }),
      getAllBaseClientList: build.query<MetaData<Client[]>, string>({
        query: (query) => ({
          path: query ? `client?${query}` : "/client",
        }),
      }),
      searchClientsList: build.mutation<MetaData<ClientSearch[]>, string>({
        query: (query) => ({
          path: `/client/search?${query}`,
        }),
      }),
      deleteClient: build.mutation<MetaData<ClientSearch[]>, number>({
        query: (query) => ({
          path: `/client?client_id=${query}`,
          method: "DELETE",
        }),
      }),
      createClient: build.mutation<MetaData, NotificationFormSubmit>({
        query: (data) => ({
          path: `/client`,
          body: { meta: {}, data: data },
          method: "POST",
        }),
      }),
      checkingThefastening: build.mutation<MetaData<Client>, string>({
        query: (query) => ({
          path: `/client/search-from-bmpsoft?${query}`,
        }),
      }),
      clipThefastening: build.mutation<MetaData<Client>, string>({
        query: (query) => ({
          path: `/client/clip-from-bmpsoft?${query}`,
        }),
      }),
    }),
  });

export const {
  useFilterBaseClientsListMutation,
  useSearchClientsListMutation,
  useGetAllBaseClientListQuery,
  useCheckingThefasteningMutation,
  useClipThefasteningMutation,
  useDeleteClientMutation,
  useCreateClientMutation,
} = baseClientsApiHooks;
