import React, { useState } from "react";
import { RegisterFormState } from "../types";
import SelectRegisterType from "./SelectRegisterType";
import UserDataForm from "./UserDataForm";
import NewAgencyAgreementForm from "./NewAgreementForm";
import AttachToAgencyForm from "./AttachToAgencyForm";
import NoteExistInListForm from "./AttachToAgencyForm/NoteExistInListForm";
import SuccessfullySubmited from "./SuccessfulySubmited";
import AuthPageWraper from "../../../components/AuthPageWraper";
import {
  RegisterPhoneForm,
  RegisterSMSCodeVerification,
} from "./SMSVerificationForm";
import { Step } from "enums/authEnums";

const SignUp = () => {
  const [formState, setFormState] = useState<RegisterFormState>({
    step: Step.SELECT_REGISTER_TYPE,
  });

  switch (formState.step) {
    case Step.SELECT_REGISTER_TYPE:
      return (
        <AuthPageWraper isRegisterPage={true}>
          <SelectRegisterType setFormState={setFormState} />
        </AuthPageWraper>
      );
    case Step.PHONE_NUMBER_FORM: {
      return (
        <AuthPageWraper isRegisterPage={true}>
          <>
            <RegisterPhoneForm
              setFormState={setFormState}
              title=" Введите номер телефона"
            />
          </>
        </AuthPageWraper>
      );
    }
    case Step.SMS_VERIFICATION_FORM: {
      return (
        <AuthPageWraper isRegisterPage={true}>
          <>
            <RegisterSMSCodeVerification
              setFormState={setFormState}
              title="Введите код"
            />
          </>
        </AuthPageWraper>
      );
    }
    case Step.USER_INFO_FORM:
      return (
        <AuthPageWraper isRegisterPage={true}>
          <UserDataForm
            setFormState={setFormState}
            registerType={formState.registerType!}
            title={
              <>
                Данные <br /> пользователя
              </>
            }
          />
        </AuthPageWraper>
      );
    case Step.NEW_AGENCY_AGREEMENT_FORM:
      return (
        <AuthPageWraper isRegisterPage={true}>
          <NewAgencyAgreementForm
            title={
              <>
                Заключение <br /> договора
              </>
            }
            setFormState={setFormState}
          />
        </AuthPageWraper>
      );
    case Step.ATTACH_TO_AGENCY_FORM:
      return (
        <AuthPageWraper isRegisterPage={true}>
          <AttachToAgencyForm
            title={
              <>
                Присоединиться к <br /> существующему AN
              </>
            }
            setFormState={setFormState}
          />
        </AuthPageWraper>
      );
    case Step.ATTACHMENT_ISNOTEXIST_FORM:
      return (
        <AuthPageWraper isRegisterPage={true}>
          <NoteExistInListForm
            title={
              <>
                Присоединиться к <br /> существующему AN
              </>
            }
            setFormState={setFormState}
          />
        </AuthPageWraper>
      );
    case Step.SUCCESSFUL_REGISTRATION:
    case Step.SUCCESS_MESSAGE:
      return (
        <AuthPageWraper isRegisterPage={true}>
          <SuccessfullySubmited
            title="Заключение договора"
            message="Ваши данные отправлены"
            buttonText="Перейти в личный кабинет"
          />
        </AuthPageWraper>
      );
    default:
      return null;
  }
};

export default SignUp;
