import * as yup from "yup";
import {
  firstName,
  lastName,
  city_id,
  birthDate,
  email,
  phone,
  password,
  passwordConfirm,
  code,
  legalName,
  title,
  inn,
  entry_doc,
  company_card_doc,
  tax_registration_doc,
  authority_signatory_doc,
  isOwner,
  ownerFullName,
  ownerPhone,
  startWorkDate,
  agency_id,
} from "./common";

// New Agent Form Schema
export const schemaNewAgentForm = yup.object().shape({
  firstName,
  lastName,
  city_id,
  birthDate,
  email,
});

// Forgot Password Schema
export const schemaPhone = yup.object({
  phone,
});

export const schemaSMSCode = yup.object({
  code,
});

export const schemaPasswords = yup.object({
  password,
  passwordConfirm,
});

// Login Page Schema
export const schemaAgent = yup.object({
  phone,
});

export const schemaEmployee = yup.object({
  email,
  password,
}).required();

// New Agent Register Form Schema
export const newAgentRegisterForm = yup.object().shape({
  city_id,
  legalName,
  title,
  inn,
  phone,
  email,
  ownerFullName,
  ownerPhone,
  entry_doc,
  company_card_doc,
  tax_registration_doc,
  authority_signatory_doc,
  isOwner,
});

// Choose Agent Schema
export const schemaChooseAgent = yup.object({
  startWorkDate,
  agency_id,
});

// Request Agent Schema
export const schemaRequestAgent = yup.object({
  city_id,
  title,
  ownerFullName,
  ownerPhone,
});
