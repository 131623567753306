import TextField from "../../components/Form/TextField/TextField";
import ButtonForm from "../../components/Button/ButtonForm";
import TickIcon from "../../components/constant/icons/TickIcon";
import PencilIcon from "../../components/constant/icons/PencilIcon";
import Close from "../../components/constant/icons/Close";
import TrashIcon from "../../components/constant/icons/TrashIcon";
import React, { useEffect, useState } from "react";

interface NewsCategoryItemProps {
	text: string;
	index: number;
	onChangeValue: (value: string, index: number) => void
	handleDeleteBlock: () => void
}

const NewsCategoryItem = (
	{
		text,
		index,
		onChangeValue,
		handleDeleteBlock
	}: NewsCategoryItemProps
) => {

	const [isEditBlock, setIsEditBlock] = useState(false);
	const [value, setValue] = useState(text);

	useEffect(() => {
		!text && setIsEditBlock(true);
	}, []);

	function handleCloseBlock() {
		if (!value) handleDeleteBlock();

		setIsEditBlock(false);
	}

	function handleSave() {
		onChangeValue(value, index);
		setIsEditBlock(false);
	}

	return (
		<div className={"flex gap-[10px] py-[10px]"}>
			<div className={"w-full"}>
				{isEditBlock ? (
					<TextField
						value={value}
						onChangeValue={setValue}
						placeholder={"Введите название категории"}
						imgRight={<div onClick={() => setValue("")}><Close color={"#C5CAD1"}/></div>}
					/>
				) : (
					<div
						className={"flex items-center justify-center p-3 border border-accent-default w-full text-sm leading-[22px]"}>
						{text}
					</div>
				)}
			</div>

			<div className={"flex gap-[5px]"}>
				{isEditBlock ? (
					<ButtonForm
						text={<TickIcon/>}
						className={'bg-accent-default border border-accent-default rounded justify-center min-w-[48px]'}
						onClick={handleSave}
					/>
				) : (
					<ButtonForm
						text={<PencilIcon color={'#37465B'}/>}
						className={'bg-white-default border border-accent-default rounded justify-center min-w-[48px]'}
						onClick={() => setIsEditBlock(true)}
					/>
				)}

				{isEditBlock ? (
					<ButtonForm
						text={<Close color={'#EF3B24'}/>}
						className={'bg-white-default border border-red-default rounded justify-center min-w-[48px]'}
						onClick={handleCloseBlock}
					/>
				) : (
					<ButtonForm
						text={<TrashIcon color={'#EF3B24'}/>}
						className={'bg-white-default border border-red-default rounded justify-center min-w-[48px]'}
						onClick={handleDeleteBlock}
					/>
				)}
			</div>
		</div>
	)
}

export default NewsCategoryItem;