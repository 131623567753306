import { ApiResponse } from "data/interface/api_responce";
import { apiService } from "../index";

import {
  AgencyRegisterRequestParams,
  UserSignUpRequestParams,
  AgencyChooseRequestParams,
  AgencyRequestParams,
  SignInAgentResponseType,
} from "./type";
import { MetaData } from "../exception";
import { RegisterStatus } from "enums/registerStatus";

export const addTagTypes = ["auth"] as const;

export const authApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      signInAgent: build.mutation<
        MetaData<SignInAgentResponseType>,
        MetaData<{ phone: string }>
      >({
        query: ({ data }) => ({
          path: `/auth/agent`,
          body: { meta: {}, data: data },
          method: "POST",
        }),
      }),
      signInEmployee: build.mutation<
        MetaData<{ accessToken: string }>,
        MetaData<{ email: string; password: string }>
      >({
        query: ({ data }) => ({
          path: `/auth/login`,
          body: {
            meta: {},
            data: { email: data.email, password: data.password },
          },
          method: "POST",
        }),
      }),
      signInAgentVerify: build.mutation<
        MetaData<{
          accessToken: string;
          user_id: number;
          register_status: RegisterStatus;
        }>,
        MetaData<{ code: number; user_id: number }>
      >({
        query: ({ data }) => ({
          path: "/auth/agent/verify-code",
          body: { meta: {}, data: { code: data.code, user_id: data.user_id } },
          method: "POST",
        }),
      }),
      signInAgentResend: build.mutation<
        MetaData<{ user_id: number }>,
        MetaData<{ phone: string }>
      >({
        query: ({ data }) => ({
          path: "/auth/agent/resend-code",
          body: { meta: {}, data: { phone: data.phone } },
          method: "POST",
        }),
      }),
      signUpAgent: build.mutation<
        MetaData<SignInAgentResponseType>,
        MetaData<{ phone: string }>
      >({
        query: ({ data }) => ({
          path: "/auth/agent",
          body: { meta: {}, data: { phone: data.phone } },
          method: "POST",
        }),
      }),

      signUpUser: build.mutation<
        MetaData<{ message: string }>,
        MetaData<UserSignUpRequestParams>
      >({
        query: ({ data }) => ({
          path: "/auth/agent/register/fill_data",
          body: { meta: {}, data: data },
          method: "POST",
        }),
      }),
      registerAgency: build.mutation<
        MetaData<{ accessToken: string }>,
        MetaData<AgencyRegisterRequestParams>
      >({
        query: ({ data }) => ({
          path: "/auth/agent/register/register_agency",
          body: { meta: {}, data: data },
          method: "POST",
        }),
      }),
      chooseAgency: build.mutation<
        MetaData<{ accessToken: string }>,
        MetaData<AgencyChooseRequestParams>
      >({
        query: ({ data }) => ({
          path: "/auth/agent/register/choose_agency",
          body: { meta: {}, data: data },
          method: "POST",
        }),
      }),
      requestAgency: build.mutation<
        MetaData<{ accessToken: string }>,
        MetaData<AgencyRequestParams>
      >({
        query: ({ data }) => ({
          path: "/auth/agent/register/request_agency",
          body: { meta: {}, data: data },
          method: "POST",
        }),
      }),
    }),
  });

export const {
  useSignInAgentMutation,
  useSignInEmployeeMutation,
  useSignInAgentResendMutation,
  useChooseAgencyMutation,
  useSignInAgentVerifyMutation,
  useSignUpAgentMutation,
  useSignUpUserMutation,
  useRegisterAgencyMutation,
  useRequestAgencyMutation,
} = authApiHooks;

export const handleDownload = (url: string, fileName: string) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName || "downloaded-file";
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error("Error fetching the file:", error);
    });
};
