import React, { Dispatch, SetStateAction, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import CancelIcon from "components/constant/icons/CancelIcon";
import PencilIcon from "components/constant/icons/PencilIcon";
import PlusIcon from "components/constant/icons/PlusIcon";
import ToggleSwitcher from "components/ToggleSwitcher";
import { TrainingCategoryType, TrainingType } from "data/api/training/type";
import { useDispatch } from "react-redux";
import {
  handleDeleteTrainings,
  handleUpdateTrainingCategories,
  hanldeUpdateTraining,
} from "store/TrainingReducer";

import { CSS } from "@dnd-kit/utilities";
import DragDropIcon from "components/constant/icons/DragDropIcon";
import { useWindowSize } from "hooks/useWindowSize";

interface IProps {
  categ: TrainingCategoryType;
  setIsCreateQuestionModalOpen: Dispatch<SetStateAction<boolean>>;
  setUpdateTraining: Dispatch<SetStateAction<TrainingType | undefined>>;
  setCategoryId: Dispatch<
    SetStateAction<
      | {
          ref_id: string | undefined;
          id: number;
        }
      | undefined
    >
  >;
}

const TrainingAndCategoryListItems = ({
  categ,
  setIsCreateQuestionModalOpen,
  setUpdateTraining,
  setCategoryId,
}: IProps) => {
  const dispatch = useDispatch();
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: categ.id });

  const windowSize = useWindowSize();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    border: isDragging ? "2px solid #3583FF" : "none",
    boxShadow: isDragging ? "0 4px 8px rgba(0, 0, 0, 0.1)" : "none",
    backgroundColor: isDragging ? "#f0f8ff" : "transparent",
  };

  const handleToggleTrainings = (isToggle: boolean, quest: TrainingType) => {
    dispatch(hanldeUpdateTraining({ ...quest, is_active: isToggle }));
  };

  const handleToggleCategies = (
    isToggle: boolean,
    categ: TrainingCategoryType
  ) => {
    dispatch(handleUpdateTrainingCategories({ ...categ, is_active: isToggle }));
  };

  const handleEditTraining = (training: TrainingType) => {
    setIsCreateQuestionModalOpen(true);
    setUpdateTraining(training);
  };
  const handleDeleteTraining = (training: TrainingType) => {
    dispatch(handleDeleteTrainings(training));
  };

  return (
    <div
      key={categ.id}
      className={`text-accent-default font-semibold bg-white-default rounded-xl p-2`}
      ref={setNodeRef}
      style={style}
    >
      <div className="flex items-center gap-1 mb-3">
        <h2 className="text-[20px] sm:text-2xl">{categ.name}</h2>
        <ToggleSwitcher
          isToggle={categ.is_active}
          setIsToggle={(isToggle) => handleToggleCategies(isToggle, categ)}
        />
        {windowSize.isLg ? (
          <div
            {...attributes}
            {...listeners}
            className="cursor-grab ml-3 flex items-center"
          >
            <DragDropIcon />
          </div>
        ) : null}
      </div>
      <div className="flex flex-col gap-[10px]">
        {categ.training?.length
          ? categ.training.map((quest) => (
              <div
                key={quest.id}
                className="flex flex-col sm:flex-row gap-[10px] sm:gap-5"
              >
                <div className="p-[10px] pl-[15px] rounded-[12px] w-full border border-boder-default cursor-pointer flex items-center sm:items-start justify-between gap-[10px]">
                  <div className="flex flex-col">
                    <p className=" text-sm sm:text-[16px] font-normal">
                      {quest.title}
                    </p>
                  </div>

                  <div className=" sm:self-start">
                    <ToggleSwitcher
                      isToggle={quest.is_active}
                      setIsToggle={(isToggle) =>
                        handleToggleTrainings(isToggle, quest)
                      }
                    />
                  </div>
                </div>
                <div className="flex gap-[6px]">
                  <button
                    onClick={() => handleEditTraining(quest)}
                    className=" w-1/2 sm:w-[44px] h-[44px] outline-none rounded-lg sm:rounded-full border border-accent-default flex items-center justify-center"
                  >
                    <PencilIcon />
                  </button>
                  <button
                    onClick={() => handleDeleteTraining(quest)}
                    className="w-1/2 sm:w-[44px] h-[44px] outline-none rounded-lg sm:rounded-full border border-red-default flex items-center justify-center"
                  >
                    <CancelIcon color="#EF3B24" />
                  </button>
                </div>
              </div>
            ))
          : null}
        <button
          onClick={() => {
            setIsCreateQuestionModalOpen(true);
            setCategoryId({
              ref_id: categ.ref_id,
              id: categ.id,
            });
          }}
          className="h-[48px] w-full rounded-lg flex items-center justify-center border border-dashed border-blue-default outline-none"
        >
          <PlusIcon width={28} height={28} color="#3583FF" />
        </button>
      </div>
    </div>
  );
};

export default TrainingAndCategoryListItems;
