import { Apartment } from "modules/Apartment/ApartmentList/dataTransformer";
import { apiService } from "..";
import { MetaData } from "../exception";

interface Season {
  id: number;
  created_at: string;
  updated_at: string;
  season_name: number;
  year: string;
  date: string;
}

export const addTagTypes = ["premises"] as const;

export const primisesApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      getPremises: build.query<MetaData<Apartment[]>, string>({
        query: (query) => ({
          path: `/premises?${query}`,
        }),
        providesTags: ["premises"],
      }),
      getPremisesSeasons: build.query<MetaData<Season[]>, string>({
        query: (query) => ({
          path: `/premises/seasons?${query}`,
        }),
        providesTags: ["premises"],
      }),
      getOnePremise: build.query<MetaData<Apartment>, string | undefined>({
        query: (id) => ({
          path: `/premises/${id}`,
        }),
        providesTags: ["premises"],
      }),
      PickPremises: build.query<MetaData<Apartment[]>, number[]>({
        query: (query) => {
          const idQueryList = query.reduce((acc, curr, idx) => {
            acc += `${idx !== 0 ? "&" : ""}ids=${curr}`;

            return acc;
          }, "");

          return {
            path: `/premises/cherry-pick/{ids}?${idQueryList}`,
          };
        },
        providesTags: ["premises"],
      }),
    }),
  });

export const {
  useGetPremisesQuery,
  usePickPremisesQuery,
  useGetOnePremiseQuery,
  useGetPremisesSeasonsQuery,
} = primisesApiHooks;
