import React from "react";
import CoinOnHandIcon from "components/constant/icons/Analytics/CoinOnHandIcon";
import { useWindowSize } from "hooks/useWindowSize";

type DataItem = {
  id: number;
  title: string;
  icons: React.FC<React.SVGProps<SVGSVGElement>>;
  count: string;
  bottomLeft: string;
  bottomRight: string;
  dropdownd?: string;
};

interface Props {
  item: DataItem;
  isrewardInfo?: boolean;
}

const AnaliticsInfoCart = ({ item, isrewardInfo }: Props) => {
  const { isLg } = useWindowSize();
  return (
    <div
      className={`rounded-lg bg-white-default flex   gap-[10px] sm:gap-5 p-[10px] sm:p-[15px] ${
        isrewardInfo && !isLg ? "col-span-2 flex-row justify-between" : "col-span-1 flex-col"
      }`}
    >
      <div className="flex gap-2 sm:gap-3 items-center">
        <div className="w-[44px] h-[44px] rounded-lg bg-bg-default flex items-center justify-center">
          <item.icons color="#3583FF" />
        </div>
        <p className=" leading-[16px] sm:leading-[22px] w-[100px] sm:w-auto text-[12px] sm:text-[16px]">
          {item.title}
        </p>
      </div>
      <div
        className={`flex flex-col  sm:gap-5 ${
          isrewardInfo && !isLg ? "gap-0" : "gap-[10px]"
        } `}
      >
        <h2
          className={` text-accent-default font-dewi text-[14px] sm:text-[20px]`}
        >
          {item.count}
        </h2>
        <div className="flex justify-between items-center text-[12px] sm:text-sm font-medium text-[#42C34F]">
          <p>{item.bottomLeft}</p>
          <p>{item.bottomRight}</p>
        </div>
      </div>
    </div>
  );
};

export default AnaliticsInfoCart;
