import React from "react";

const NumOfTransacIcon = ({ color = "#3583FF" }) => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.55312 5.8H13.9531M5.55312 10.6H13.9531M3.51312 1H15.9931C16.8547 1 17.5531 1.80589 17.5531 2.8V19L14.9531 17.2L12.3531 19L9.75312 17.2L7.15312 19L4.55312 17.2L1.95312 19V2.8C1.95312 1.80589 2.65156 1 3.51312 1Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default NumOfTransacIcon;
