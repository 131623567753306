import React from "react";
import { Icons } from "./interface";

const PencilIcon = (
	{
		color = "currentColor",
		strokeWidth = "1.5",
		size = 24
	}: Icons
) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
				d="M12.9999 6.50325L16.5999 10.1033M4 19.1033L8.36598 18.2235C8.59776 18.1768 8.81058 18.0627 8.97772 17.8955L18.7514 8.11643C19.22 7.64758 19.2197 6.88759 18.7507 6.41913L16.6803 4.35105C16.2115 3.88279 15.4519 3.8831 14.9835 4.35177L5.20876 14.1318C5.04195 14.2987 4.92805 14.5111 4.8813 14.7424L4 19.1033Z"
				stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PencilIcon;
