import { ApiResponse } from "data/interface/api_responce";
import { apiService } from "..";
import { NewsResponseType } from "./type";
import { MetaData } from "../exception";

export const addTagTypes = ["news"] as const;

export const newsApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      getNews: build.query<MetaData<NewsResponseType>, void>({
        query: (param) => ({
          path: "/news",
        }),
        providesTags: ["news"],
      }),
      getOneNews: build.query<{ data: NewsResponseType }, number>({
        query: (id) => ({
          path: `/news/${id}`,
        }),
        providesTags: ["news"],
      }),
    }),
  });

export const { useGetNewsQuery, useGetOneNewsQuery } = newsApiHooks;
