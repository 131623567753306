import React from "react";
import { Icons } from "../interface";

const PlanSectionIcon = ({
  width = 20,
  height = 20,
  color = "black",
}: Icons) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.49936 11.0002H7.68686C8.41174 11.0002 8.99936 11.5878 8.99936 12.3127V17.5002M18 15L18 5C18 3.34315 16.6569 2 15 2L5 2C3.34315 2 2 3.34314 2 5L2 15C2 16.6569 3.34314 18 5 18H15C16.6569 18 18 16.6569 18 15Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default PlanSectionIcon;
