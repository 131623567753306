import React, { useState } from "react";
import HomePageCard from "../../components/Cards/HomePageCard";
import { images } from "utils/resource";
import ButtonForm from "../../components/Button/ButtonForm";
import { useNavigate, useParams } from "react-router-dom";
import ArrowDropdown from "../../components/constant/icons/ArrowDropdown";
import EyeIcon from "../../components/constant/icons/EyeIcon";
import LikeIcon from "../../components/constant/icons/LikeIcon";
import NewsPageCard from "../../components/Cards/NewsPageCard";
import PencilIcon from "../../components/constant/icons/PencilIcon";
import { useGetNewsQuery, useGetOneNewsQuery } from "data/api/news";

const NewsViewPage = () => {
  const navigate = useNavigate();
  const route = useParams();
  const { data: news } = useGetNewsQuery();

  const { data: newsOne } = useGetOneNewsQuery(Number(route.id));

  const hashtag = ["Брусника", "Мероприятие"];
  const title = "Скидка на квартиры сотрудникам";
  const date = "12.12.2023";
  const text =
    'Добро пожаловать в нашу дружную команду "Брусника"! Мы очень рады, что вы присоединились к нам в качестве риелтора, и уверены, что ваш опыт и профессионализм станут ценным вкладом в нашу компанию.\n' +
    "\n" +
    'Мы стремимся к высоким стандартам обслуживания клиентов и успешным риелторским практикам, и уверены, что ваше участие поможет нам еще больше укрепить нашу позицию на рынке недвижимости. Мы ценим каждого сотрудника "Брусники" и гордимся тем, что вместе мы создаем команду, способную достигать выдающихся результатов.\n' +
    "\n" +
    "Ваша энергия, профессионализм и преданность работе — это именно то, что делает нас успешными. Мы уверены, что совместные усилия и коллективный дух нашей команды приведут к общему процветанию и достижению новых высот.\n" +
    "\n" +
    'Если у вас есть какие-либо вопросы, предложения или идеи, не стесняйтесь делиться ими с нами. Мы ценим ваш вклад и стремимся создать условия для вашего успешного трудоустройства и карьерного роста в "Бруснике".\n' +
    "\n" +
    "Еще раз добро пожаловать в нашу команду! Мы с нетерпением ждем совместных достижений и успехов.\n" +
    "С наилучшими пожеланиями,";
  const watched = 1000;
  const likes = 42;

  const [otherNewsList, setOtherNewsList] = useState([
    {
      img: images.newsImage,
      title: "Скидка на квартиры сотрудникам",
      tags: ["Брусника", "Мероприятие"],
      views: 1000,
      likes: 42,
      date: "12.12.2024",
    },
    {
      img: images.newsImage,
      title: "Скидка на квартиры сотрудникам",
      tags: ["Брусника", "Мероприятие"],
      views: 1000,
      likes: 42,
      date: "12.12.2024",
    },
    {
      img: images.newsImage,
      title: "Скидка на квартиры сотрудникам",
      tags: ["Брусника", "Мероприятие"],
      views: 1000,
      likes: 42,
      date: "12.12.2024",
    },
    {
      img: images.newsImage,
      title: "Скидка на квартиры сотрудникам",
      tags: ["Брусника", "Мероприятие"],
      views: 1000,
      likes: 42,
      date: "12.12.2024",
    },
    {
      img: images.newsImage,
      title: "Скидка на квартиры сотрудникам",
      tags: ["Брусника", "Мероприятие"],
      views: 1000,
      likes: 42,
      date: "12.12.2024",
    },
    {
      img: images.newsImage,
      title: "Скидка на квартиры сотрудникам",
      tags: ["Брусника", "Мероприятие"],
      views: 1000,
      likes: 42,
      date: "12.12.2024",
    },
  ]);

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  return (
    <section className=" bg-bg-default relative">
      <div className="md:py-[30px] py-[10px] br-container flex gap-[20px] flex-col lg:flex-row">
        <div className="xl:w-[76%] lg:w-[70%] w-full">
          <div className={"p-[20px] relative bg-white-default rounded-lg"}>
            <div className={"hidden md:flex absolute left-[30px] top-[30px]"}>
              <ButtonForm
                leftIcon={
                  <div className={"rotate-90"}>
                    <ArrowDropdown color={"#37465B"} />
                  </div>
                }
                className="bg-white-default p-2 pr-[20px] text-accent-default flex gap-[0!important] rounded-full"
                text="Назад"
                onClick={() => navigate(-1)}
              />
            </div>

            <div>
              <img
                src={images.newsImage}
                width={"100%"}
                className={"rounded-sm"}
              />
            </div>

            <div className={"mt-[30px]"}>
              <div className="flex gap-[10px] text-[14px] leading-[18px] font-normal text-blue-default">
                {hashtag.map((item, i) => (
                  <div
                    key={i}
                    className="px-[20px] py-[6px] rounded-[100px] bg-light-blue"
                  >
                    {item}
                  </div>
                ))}
              </div>

              <div className={"flex justify-between pt-[15px]"}>
                <h3 className="text-[24px] leading-[28px] text-accent-default font-semibold">
                  {title}
                </h3>

                <p className={"md:block hidden text-sm text-accent-default"}>{date}</p>
              </div>

              <p className={"my-[30px] text-accent-default text-sm"}>{text}</p>

              <div className={"flex justify-between"}>
                <ButtonForm
                  leftIcon={<LikeIcon color={"#FFFFFF"} />}
                  text="Нравится"
                  onClick={() => {}}
                  className={
                    "hidden md:flex max-h-[48px] h-full max-w-[147px] px-6 py-3 w-full justify-center bg-accent-default text-white-default text-ellipsis whitespace-nowrap"
                  }
                />

                <div className="flex gap-[15px] items-center text-[12px] text-accent-disabled font-medium leading-[16px]">
                  {(watched || watched === 0) && (
                    <div className="flex items-center justify-center gap-[8px] cursor-pointer">
                      <EyeIcon color="#8E95A1" width="18" />
                      <span>{watched}</span>
                    </div>
                  )}
                  {(likes || likes === 0) && (
                    <div className="flex items-center justify-center gap-[8px] cursor-pointer">
                      <LikeIcon />
                      <span>{likes}</span>
                    </div>
                  )}
                </div>

								<div className={"md:hidden block"}>
									<p className={"text-sm text-accent-disabled"}>{date}</p>
								</div>
							</div>

							<div className={"md:hidden flex pt-[30px]"}>
								<ButtonForm
									leftIcon={<LikeIcon color={"#FFFFFF"} />}
									text="Нравится"
									onClick={() => {}}
									className={
										"max-h-[48px] h-full px-6 py-3 w-full justify-center bg-accent-default text-white-default text-ellipsis whitespace-nowrap"
									}
								/>
							</div>
            </div>
          </div>
        </div>

        <div className="xl:w-[24%] lg:w-[30%] w-full flex flex-col gap-[10px] self-start">
          <div className={"lg:block flex overflow-auto gap-[10px]"}>
            {otherNewsList.map((i, idx) => (
              <NewsPageCard
                key={`news-item-card-${idx}`}
                imageSrc={i.img}
                title={i.title}
                watched={i.views}
                likes={i.likes}
                date={i.date}
                category="Категория"
              />
            ))}
          </div>
        </div>
      </div>

      <div className="fixed right-[30px] bottom-[30px] flex items-center justify-center">
        <div
          className={
            "rounded-full bg-accent-default flex items-center justify-center p-3 cursor-pointer"
          }
          onClick={() => setIsOpenEditModal(true)}
        >
          <PencilIcon color={"#FFFFFF"} />
        </div>
      </div>
    </section>
  );
};

export default NewsViewPage;
