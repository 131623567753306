import React, { useEffect, useState } from "react";
import ContactEditForm from "./ContactEditForm";
import { useNavigate } from "react-router";
import { CloseIcon } from "yet-another-react-lightbox";
import ContactsMap from "../Map";
import { Office } from "..";
import PlusIcon from "components/constant/icons/PlusIcon";
import { IContact } from "data/api/contact/type";
import { useContactsBulkMutation, useGetContactsQuery } from "data/api/contact";
import ButtonForm from "components/Button/ButtonForm";
import showToast from "utils/showToast";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { handleBackToInitialContactsState } from "store/ContactReducer";

const ContactsEditPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialWorkSchedule = [
    {
      weekday: 0,
      start_time: undefined,
      end_time: undefined,
      contact_id: null,
    },
    {
      weekday: 1,
      start_time: undefined,
      end_time: undefined,
      contact_id: null,
    },
    {
      weekday: 2,
      start_time: undefined,
      end_time: undefined,
      contact_id: null,
    },
    {
      weekday: 3,
      start_time: undefined,
      end_time: undefined,
      contact_id: null,
    },
    {
      weekday: 4,
      start_time: undefined,
      end_time: undefined,
      contact_id: null,
    },
    {
      weekday: 5,
      start_time: undefined,
      end_time: undefined,
      contact_id: null,
    },
    {
      weekday: 6,
      start_time: undefined,
      end_time: undefined,
      contact_id: null,
    },
  ];
  const [inputsValue, setInputValue] = useState<IContact[]>([]);
  const selector = useSelector((state: RootState) => state);

  const [contactsMutate] = useContactsBulkMutation();
  const { data: contacts, refetch } = useGetContactsQuery(
    selector.header.city_id
  );

  useEffect(() => init(), [contacts]);

  console.log("contacts", contacts);

  function init() {
    if (contacts?.data) setInputValue(contacts?.data);
  }

  const office: Office[] = [
    {
      city: {
        id: 8,
        name: "Москва",
        lat: "55.7558",
        long: "37.6173",
      },
      id: 8,
      lat: "55.7558",
      link: "",
      location: "Пресненская набережная, Москва",
      long: "37.6173",
      name: "Москва Проект",
      photo: "project_default_image.jpg",
    },
  ];

  const addNewForm = () => {
    setInputValue((prev) => [
      ...prev,
      {
        id: Date.now(),
        ref_id: `new-${Date.now()}`,
        title: "",
        address: { title: "" },
        address_link: "",
        phone_number: [""],
        work_schedule: initialWorkSchedule,
        city_id: 0,
      },
    ]);
  };

  console.log("inputsValue", inputsValue);

  const handleSubmitBulk = async () => {
    const data = {
      create: inputsValue.filter((item) => item.ref_id?.startsWith("new-")),
      update: inputsValue.filter((item) => item.ref_id?.startsWith("old-")),
      delete: selector.contact.delete,
    };
    const { data: res, error } = await contactsMutate({ data: data });
    if (error?.message) {
      showToast(error.message, "error", 2000);
      return;
    }
    showToast("succesfully created", "success", 2000);
    dispatch(handleBackToInitialContactsState());
    navigate("/contacts");
    refetch();
  };

  return (
    <div className="bg-white-default">
      <div className="br-container min-h-[50vh] py-7 flex flex-wrap gap-6 ">
        {inputsValue.map((inputsValue, index) => (
          <ContactEditForm
            inputsValue={inputsValue}
            setInputValue={setInputValue}
            index={index}
          />
        ))}
      </div>
      <div className="br-container">
        <div className="flex flex-col md:flex-row w-full md:w-[70%] gap-5 mr-auto">
          <ButtonForm
            text="Отменить"
            className=" py-3 px-6   bg-white-default text-accent-default border border-accent-default hover:bg-accent-default  flex-1  hover:text-white-default flex justify-center"
          />
          <ButtonForm
            onClick={handleSubmitBulk}
            text="Сохранить"
            className=" py-3 px-6  bg-accent-default text-white-default   hover:bg-accent-focus  flex-1  hover:text-white-default flex justify-center"
          />
        </div>
      </div>

      <div className="mt-9">
        <ContactsMap contactsList={office} />
      </div>
      <div className=" hidden fixed right-[90px] bottom-[30px] lg:flex items-center justify-center">
        <button
          className={
            "rounded-full bg-accent-default flex items-center justify-center p-3 cursor-pointer"
          }
          onClick={addNewForm}
        >
          <PlusIcon color={"#FFFFFF"} />
        </button>
      </div>
      <div className=" hidden fixed right-[30px] bottom-[30px] lg:flex items-center justify-center">
        <div
          className={
            "rounded-full bg-red-default flex items-center justify-center p-3 cursor-pointer"
          }
          onClick={() => navigate("/contacts")}
        >
          <CloseIcon color={"#FFFFFF"} />
        </div>
      </div>
    </div>
  );
};

export default ContactsEditPage;
