import { Apartment } from "../ApartmentList/dataTransformer";

type TransformedData = {
  [projectName: string]: {
    [apartmentType: string]: Apartment[];
  };
};

export const transformDataForApartmentInfo = (
  data: Apartment[] | undefined
): TransformedData => {
  if (!data || data.length === 0) {
    return {};
  }
  return data.reduce((acc: TransformedData, apartment: Apartment) => {
    const projectName = apartment.project.name;
    const apartmentType = apartment.type;
    if (!acc[projectName]) {
      acc[projectName] = {};
    }
    if (!acc[projectName][apartmentType]) {
      acc[projectName][apartmentType] = [];
    }
    acc[projectName][apartmentType].push(apartment);
    return acc;
  }, {});
};
