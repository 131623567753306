import React from "react";

const CopyIcon = (
	{
		width = "18",
		height = "20",
		color = "#37465B"
	}
) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 11.1251L16.5 4.00003C16.5 2.34317 15.1568 1.00002 13.5 1.00003L6.375 1.00012M10.5 19.0001L3.75 19.0001C2.50736 19.0001 1.5 17.9928 1.5 16.7501L1.5 7.00012C1.5 5.75747 2.50736 4.75011 3.75 4.75011L10.5 4.75011C11.7426 4.75011 12.75 5.75747 12.75 7.00011L12.75 16.7501C12.75 17.9928 11.7426 19.0001 10.5 19.0001Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CopyIcon;
