import React from "react";

const ChessIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        y="0.75"
        width="7.5"
        height="7.5"
        rx="1.25"
        stroke="#37465B"
        strokeWidth="1.5"
      />
      <rect
        x="9.75"
        y="9.75"
        width="7.5"
        height="7.5"
        rx="1.25"
        stroke="#37465B"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default ChessIcon;
