import { useGetNewbieTrainingsQuery } from "data/api/training";
import Carousel from "./index";
import { truncateHtmlWords } from "utils/truncateHtmlWords";

interface HomePageRightAsideSlideProps {
  className?: string;
}

export const TrainingSider = ({ className }: HomePageRightAsideSlideProps) => {
  const { data } = useGetNewbieTrainingsQuery();

  const trainings = data?.data ?? [];
  if (trainings.length === 0) {
    return null;
  }
  return (
    <div
      className={`pb-[40px] bg-white-default rounded-lg overflow-hidden ${className}`}
    >
      <Carousel
        dots={false}
        arrowWraperStyles="left-[12px] xl:left-[20px] -bottom-[30px]"
        arrowStyle="border-[1px] border-[#9999]"
      >
        {trainings.map((item, i) => {
          return (
            <div key={i} className="xl:min-w-[300px] bg-white-default">
              <div className="flex flex-col justify-between p-[12px] xl:p-5">
                <h3 className=" text-[14px] xl:text-[16px] leading-[18.75px] text-blue-focus mb-[5px] xl:mb-[8px] font-medium">
                  {item.title}
                </h3>

                <div
                  dangerouslySetInnerHTML={{
                    __html: truncateHtmlWords(item.content, 15),
                  }}
                />
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};
