import React, { useEffect, useState } from "react";
import Dropdown from "components/Form/Dropdown/Dropdown";
import Modal from "components/Modal";
import CircleXIcon from "components/constant/icons/CircleXIcon";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import ButtonForm from "components/Button/ButtonForm";
import Label from "components/Form/Label";
import { useGetProjectsQuery } from "data/api/project";
import { useFormatForDropDownOptions } from "hooks/useDropDownOption";
import showToast from "utils/showToast";
import { ApiException, ResponseStatusType } from "data/api/exception";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { SearchField } from "components/Form/SearchField";
import { useRecordForShowMutation } from "data/api/calendar";
import { Client } from "modules/Deal/type";
import { formatPhoneNumber } from "hooks/useFormatInput";

interface RecordForShow {
  project: { label: string; value: number };
  date: { label: string; value: Date };
  time: string;
  client: number;
}
interface DateOption {
  label: string;
  value: Date;
}
interface propType {
  handleToggle: () => void;
  isOpenModal: boolean;
  project_id?: number;
  client_Id?: number;
  handleRecordBack?: (agr: boolean) => void;
  client?: Client;
}

const RecordForShow = ({
  isOpenModal,
  handleToggle,
  project_id,
  client_Id,
  handleRecordBack,
  client,
}: propType) => {
  const selection = useSelector((state: RootState) => state.header);

  const { data: projects } = useGetProjectsQuery<{
    data: { data: { id: number; name: string }[] };
  }>(selection.city_id);

  const [mutate] = useRecordForShowMutation();
  const optionsProject = useFormatForDropDownOptions(projects?.data ?? []);

  const timeOptions = [
    {
      label: "09:30",
      value: "09:30",
    },
    {
      label: "10:00",
      value: "10:00",
    },
    {
      label: "10:30",
      value: "10:30",
    },
    {
      label: "11:00",
      value: "11:00",
    },
    {
      label: "11:30",
      value: "11:30",
    },
    {
      label: "12:00",
      value: "12:00",
    },
    {
      label: "12:30",
      value: "12:30",
    },
    {
      label: "13:00",
      value: "13:00",
    },
    {
      label: "13:30",
      value: "13:30",
    },
    {
      label: "14:00",
      value: "14:00",
    },
    {
      label: "14:30",
      value: "14:30",
    },
    {
      label: "15:00",
      value: "15:00",
    },
    {
      label: "15:30",
      value: "15:30",
    },
    {
      label: "16:00",
      value: "16:00",
    },
    {
      label: "16:30",
      value: "16:30",
    },
  ];

  const dateOptions = (): DateOption[] => {
    const today = new Date();
    const dateOptions: DateOption[] = [];

    for (let i = 0; i < 7; i++) {
      const futureDate = new Date(today);
      futureDate.setDate(today.getDate() + i);
      const day = String(futureDate.getDate()).padStart(2, "0");
      const month = String(futureDate.getMonth() + 1).padStart(2, "0");
      const year = futureDate.getFullYear();
      const formattedDate = `${day}.${month}.${year}`;

      dateOptions.push({
        label: formattedDate,
        value: futureDate,
      });
    }

    return dateOptions;
  };

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<RecordForShow>();

  const onSubmit: SubmitHandler<RecordForShow> = async (data) => {
    try {
      const { data: res, error } = await mutate({
        data: {
          project_id: project_id ? project_id : data.project.value,
          date: data.date.value,
          time: data.time,
          client_id: client_Id ? client_Id : data.client,
        },
      });
      if (res?.meta?.type === ResponseStatusType.SUCCESS) {
        reset();
        handleToggle();
        showToast("Подтверждение заявки на показ!", "success", 1000);
        return;
      }

      if (error?.message) {
        showToast(error.message, "error");
        return;
      }
    } catch (error) {
      const err = error as ApiException;
      console.log(err);
    }
  };

  return (
    <Modal
      className=" w-full md:w-[500px] rounded-t-[30px] md:rounded-[20px]"
      isOpen={isOpenModal}
    >
      <div className=" text-accent-default mb-5  flex items-center justify-between">
        <h1 className=" text-[25px] font-extrabold leading-[30px]">
          Запись на показ
        </h1>
        <button onClick={handleToggle}>
          <CircleXIcon />
        </button>
      </div>
      {client && (
        <div className="text-accent-default text-sm flex flex-col gap-[6px]">
          <p>
            ФИО: <span className="ml-2">{client?.fullname}</span>
          </p>
          <p>
            Номер:{" "}
            <span className="ml-2">
              +7 {formatPhoneNumber(client?.phone_number)}
            </span>
          </p>
        </div>
      )}

      <form
        onSubmit={handleSubmit(onSubmit)}
        action=""
        className="flex flex-col gap-[20px] mt-7"
      >
        {!project_id && (
          <Label
            label="Проект"
            labelLink={{ label: "Открыть проекты", to: "/projects" }}
          >
            <Controller
              name="project"
              control={control}
              rules={{
                required: {
                  value: !project_id,
                  message: "Проект обязателен для заполнения",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  name="project"
                  options={optionsProject}
                  errors={errors}
                  value={value}
                  onChange={onChange}
                  placeholder="Проект не выбран"
                />
              )}
            />
          </Label>
        )}
        {!client_Id && (
          <Label label="Клиент">
            <Controller
              name="client"
              control={control}
              rules={{
                required: {
                  value: !client_Id,
                  message: "Проект обязателен для заполнения",
                },
              }}
              render={({ field: { onChange } }) => (
                <SearchField
                  onChange={(value) => onChange(value.id)}
                  className="rounded-[4px!important]"
                />
              )}
            />
          </Label>
        )}

        <Label label="Дата показа">
          <Controller
            name="date"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Дата обязателен для заполнения",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                name="date"
                options={dateOptions()}
                errors={errors}
                value={value}
                defaultValue={value}
                onChange={onChange}
                placeholder="Выберите день"
              />
            )}
          />
        </Label>
        <Label label="Время показа">
          <Controller
            name="time"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Время обязателен для заполнения",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                name="time"
                options={timeOptions}
                errors={errors}
                defaultValue={value}
                onChange={(data) => onChange(data.value)}
                placeholder="Время не выбран"
              />
            )}
          />
        </Label>

        <div className="flex flex-col-reverse sm:flex-row gap-[10px] mt-[10px]">
          <ButtonForm
            text="Назад"
            onClick={() => {
              handleToggle();
              reset();
              handleRecordBack?.(true);
            }}
            className=" bg-bg-default py-3 px-6 flex-1
             hover:bg-accent-default flex justify-center
              hover:text-white-default"
          />
          <ButtonForm
            text="Записать на показ"
            type="submit"
            className=" py-3 px-6 
            bg-accent-default flex-1
            text-white-default flex justify-center
            hover:bg-accent-disabled
            hover:
            "
          />
        </div>
      </form>
    </Modal>
  );
};

export default RecordForShow;
