import React from "react";

const HamburgerMenu = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <rect width="44" height="44" rx="8" fill="#F4F4F4" />
        <path
          d="M30 28H14M30 22H14M30 16H14"
          stroke="#37465B"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

export default HamburgerMenu;
