import React from "react";

import { TrainingCategoryType } from "data/api/training/type";
import PencilIcon from "components/constant/icons/PencilIcon";
import { useLocation } from "react-router";

interface Props {
  categories?: TrainingCategoryType[];
  category: number;
  handleSelectCategory: (arg: number) => void;
}

const TrainingCategories = ({
  categories,
  handleSelectCategory,
  category,
}: Props) => {
  const location = useLocation();

  return (
    <div className="flex items-center gap-[10px] no-scrollbar overflow-x-auto pb-2 mb-3 lg:mb-1 ">
      {!(location.pathname === "/training") ? (
        <button className=" w-[40px] h-[40px] outline-none rounded-full bg-accent-default flex items-center justify-center lg:hidden">
          <PencilIcon size={19} color="#ffffff" />
        </button>
      ) : null}

      {categories &&
        [{ id: 0, name: "Все" }, ...categories].map((item, i) => (
          <div
            key={item.id}
            onClick={() => handleSelectCategory(item.id)}
            className={`py-[9px] px-[24px] lg:py-[11px] lg:px-6 flex text-center rounded-[32px] text-sm font-normal flex-shrink-0  cursor-pointer ${
              category === item.id
                ? "bg-accent-default text-white-default"
                : "bg-white-default text-accent-default"
            }`}
          >
            {item.name}
          </div>
        ))}
    </div>
  );
};

export default TrainingCategories;
