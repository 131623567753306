import React, { FC, ReactNode, useState } from "react";
import ArrowTableIcon from "../../constant/icons/Table/ArrowTableIcon";
import SpinnerIcon from "../../constant/icons/SpinnerIcon";
import { formatPhoneNumber } from "hooks/useFormatInput";
import PencilIcon from "components/constant/icons/PencilIcon";
import { useRoles } from "hooks/useRoles";
import { User, UserStatus } from "data/api/profile/type";
import { images } from "utils/resource";
import { formatDate } from "@fullcalendar/core";

export interface DataItem {
  [key: string]: User;
}

export interface TableColumn {
  key: keyof User;
  title?: string;
  width?: string;
  sortable?: boolean;
}

interface TableContainerProps {
  header: TableColumn[];
  content: User[] | undefined;
  setData?: (list: User[] | undefined) => void;
  sortable?: boolean;
  textContentEmpty?: string | ReactNode;
  onRightClickTrContent?: (e: MouseEvent, item: User) => void;
  onLeftClickTrContent?: (e: User) => void;
  isLastRightText?: boolean;
  loading?: boolean;
  tableBodyUnderBlock?: any;
  doSelectData?: boolean;
  handleSelectTableData?: (id: number) => void;
  handleEditTableItem: (data: User) => void;
}

const AdminTableContainer: FC<TableContainerProps> = ({
  header,
  content,
  setData,
  sortable = true,
  textContentEmpty = "Пусто",
  onRightClickTrContent,
  onLeftClickTrContent,
  isLastRightText = true,
  loading = false,
  tableBodyUnderBlock,
  handleEditTableItem,
}) => {
  const [sortConfig, setSortConfig] = useState<{
    key: keyof User;
    direction: "asc" | "desc";
  } | null>(null);

  const sortedItem = (item: User, key: keyof User) => {
    const value = item[key];
    if (typeof value === "object" && value !== null) {
      return value?.id;
    }
    return value;
  };

  const handleSort = (column: TableColumn) => {
    if (!column.sortable && !sortable) return;

    let direction: "asc" | "desc" = "asc";
    if (
      sortConfig &&
      sortConfig.key === column.key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }

    setSortConfig({ key: column.key, direction });

    const sortedData = [...(content || [])].sort((a: User, b: User) => {
      const aValue = sortedItem(a, column.key);
      const bValue = sortedItem(b, column.key);

      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });

    setData && setData(sortedData);
  };

  const statuses = {
    active: "Активный",
    blocked: "Блокировать",
    registered: "Зарегистрирован",
  };

  const imgUrl = process.env.REACT_APP_FILES_URL;

  return (
    <table className="table-auto border-collapse w-full bg-white-default rounded-lg ">
      <thead className="table__header">
        <tr className={"border-b-[1px] border-boder-default"}>
          {header.map((column, idx) => {
            const isDesc =
              sortConfig?.key === column.key && sortConfig.direction === "desc";

            return (
              <th
                key={column.key}
                style={{ width: column.width }}
                className={
                  idx === header.length - 1 && isLastRightText
                    ? "py-4 px-[15px] 2xl:px-5"
                    : "py-4 px-[15px] 2xl:px-5"
                }
              >
                <div className={"table__header__th flex"}>
                  <p className={"mr-2 text-[14px] 2xl:text-base font-semibold"}>
                    {column.title}
                  </p>

                  {column.sortable && sortable && (
                    <button
                      onClick={() => handleSort(column)}
                      className={`transition-all ${isDesc ? "rotate-180" : ""}`}
                    >
                      {sortConfig?.direction &&
                      sortConfig.direction === "asc" ? (
                        <ArrowTableIcon />
                      ) : (
                        <ArrowTableIcon />
                      )}
                    </button>
                  )}
                </div>
              </th>
            );
          })}
        </tr>
      </thead>

      <tbody
        className={`table__content ${loading ? "table__content-loading" : ""}`}
      >
        {content?.length ? (
          content?.map((item, index) => (
            <React.Fragment key={`table-content-item-${index}`}>
              <tr
                key={index}
                className={`cursor-pointer w-full hover:bg-bg-default m-1 ${
                  item.status === UserStatus.BLOCKED ? "opacity-50" : ""
                }`}
              >
                {header.map((column, idx) => {
                  return (
                    <td
                      onContextMenu={(e: any) => {
                        onRightClickTrContent && onRightClickTrContent(e, item);
                      }}
                      onClick={() => {
                        onLeftClickTrContent && onLeftClickTrContent(item);
                      }}
                      key={column.key}
                      colSpan={idx === header.length - 1 ? header.length : 1}
                      className={`py-4 px-3 text-[14px] 2xl:text-[16px] 2xl:px-7 truncate ${
                        idx === header.length - 1 && isLastRightText
                          ? "last"
                          : ""
                      }`}
                    >
                      {column.key === "fullName" ? (
                        <div className="flex gap-3 items-center">
                          <img
                            src={
                              item.avatar
                                ? `${imgUrl}/${item.avatar}`
                                : images.defaultImg
                            }
                            alt="user image"
                            width={50}
                            height={50}
                            className="rounded-full w-[50px] h-[50px]"
                            crossOrigin="anonymous"
                          />
                          <div className="flex flex-col ">
                            <p className="text-[16px] text-accent-default font-semibold">
                              {item?.fullName}
                            </p>
                            <p className="text-sm text-accent-disabled font-medium">
                              +7 {formatPhoneNumber(item.phone)}
                            </p>
                          </div>
                        </div>
                      ) : typeof item[column.key] === "object" ? (
                        <>
                          {column.key === "agency"
                            ? item[column.key]?.legalName
                            : column.key === "city"
                            ? item[column.key]?.name
                            : null}
                        </>
                      ) : (
                        <>
                          {column.key === "created_at"
                            ? formatDate(item[column.key])
                            : column.key === "status"
                            ? statuses[item.status]
                            : item[column.key]}
                        </>
                      )}
                    </td>
                  );
                })}
                <td className="flex gap-5 items-center py-6 px-3  2xl:pl-9 ">
                  <div
                    onClick={() => handleEditTableItem(item)}
                    className="h-[34px] w-[34px] rounded flex items-center justify-center border border-accent-default"
                  >
                    <PencilIcon />
                  </div>
                </td>
              </tr>

              {tableBodyUnderBlock && tableBodyUnderBlock(item)}
            </React.Fragment>
          ))
        ) : (
          <tr>
            <td colSpan={header.length}>
              <div className={"flex justify-center items-center"}>
                {loading ? (
                  <>
                    <div
                      className="flex justify-center items-center h-full px-[20px] py-[10px]"
                      style={{ position: "relative" }}
                    >
                      <SpinnerIcon />
                    </div>
                  </>
                ) : (
                  <p className={"m-6"}>{textContentEmpty}</p>
                )}
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default AdminTableContainer;
