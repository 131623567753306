import React from "react";

const AddIconInCircle = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.375 9.99945H10M10 9.99945H6.625M10 9.99945V13.3745M10 9.99945L10 6.62445M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
        stroke="#37465B"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default AddIconInCircle;
