import React, { Dispatch, SetStateAction, useState } from "react";
import Modal from "components/Modal";
import CircleXIcon from "components/constant/icons/CircleXIcon";
import Dropdown from "components/Form/Dropdown/Dropdown";
import ToggleSwitcher from "components/ToggleSwitcher";
import ButtonForm from "components/Button/ButtonForm";
import { images } from "utils/resource";
import TickInCircleIcon from "components/constant/icons/TickInCircleIcon";
import ImageUploadIcon from "components/constant/icons/ImageUploadIcon";
import TegField from "components/Form/TegField";
import { PuchaseOptions } from "enums/purchaseOption";

type RadioBtn = {
  id: number;
  label: string;
  value: boolean;
};

export type Tags = string[];

export interface State {
  tags: Tags;
  selectedImg?: string | undefined;
  purchaseoptions?: string;
  logo?: string;
  radioBtnData?: RadioBtn[];
}
interface PropsType {
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  imgList: { src: string }[];
  stateAddForm: State;
  setStateAddForm: Dispatch<SetStateAction<State>>;
  setGenirateModal: Dispatch<SetStateAction<boolean>>;
}
const AdvertisementForm = ({
  isOpen,
  setIsOpen,
  setGenirateModal,
  stateAddForm,
  setStateAddForm,
}: PropsType) => {
  const [photoSelectModal, setPhotoSelectModal] = useState(false);
  const [selectedImgId, setSelectedImgId] = useState<string>();

  const purchaseOptions = [
    { label: "Ипотека", value: "Ипотека" },
    { label: "Рассрочка", value: "Рассрочка" },
    { label: "Полная оплата", value: "Полная оплата" },
    { label: "Счет", value: "Счет" },
  ];

  const logoToggleOptions = [
    { label: "Нет", value: "false" },
    { label: "Логотип", value: "true" },
  ];

  const bgMaket = [
    { id: 1, img: images.Maketbg1 },
    { id: 2, img: images.Maketbg2 },
    { id: 3, img: images.Maketbg3 },
    { id: 4, img: images.Maketbg4 },
  ];

  const toggleRadioValue = (id: number) => {
    setStateAddForm((prevState: State) => ({
      ...prevState,
      radioBtnData:
        prevState.radioBtnData &&
        prevState.radioBtnData.map((item) =>
          item.id === id ? { ...item, value: !item.value } : item
        ),
    }));
  };

  return (
    <Modal className="w-full md:w-[500px] rounded-[8px]" isOpen={isOpen}>
      <div className="overflow-y-auto max-h-[90vh] pr-3 toolbar-scroll">
        <div className=" text-accent-default mb-[20px] flex items-center justify-between">
          <h1 className="font-dewi text-[25px] font-extrabold leading-[30px]">
            Рекламный макет
          </h1>
          <button onClick={() => setIsOpen(false)}>
            <CircleXIcon />
          </button>
        </div>
        <div className="flex flex-col gap-[20px]">
          <div
            className="cursor-pointer"
            onClick={() => setPhotoSelectModal(true)}
          >
            <div className="flex gap-[20px] items-center">
              <div className="flex flex-col justify-center border-2 border-dashed border-[#D7DCE4] rounded-md w-[136px] h-[90px] cursor-pointer">
                {stateAddForm.selectedImg ? (
                  <img
                    src={stateAddForm.selectedImg}
                    alt="Uploaded"
                    className="w-full h-auto"
                  />
                ) : (
                  <div className="flex flex-col items-center">
                    <ImageUploadIcon width="34" height="33" color="#D7DCE4" />
                  </div>
                )}
              </div>
              <div className="text-accent-default flex flex-col gap-[10px]">
                <p className="text-[14px] font-semibold leading-4">Фон</p>
                <p className="text-[12px] font-normal leading-[14px]">
                  Выберите изображение
                </p>
              </div>
            </div>
          </div>
          <div className=" flex flex-col gap-[10px]">
            <h3 className=" text-accent-default text-sm font-semibold">
              Вариант покупки
            </h3>
            <Dropdown
              name="purchase_options"
              options={purchaseOptions}
              value={
                purchaseOptions.find(
                  (item) => item.value === stateAddForm.purchaseoptions
                ) || null
              }
              onChange={(value) => {
                setStateAddForm((prev: State) => ({
                  ...prev,
                  purchaseoptions: value.value,
                }));
              }}
              placeholder="Вариант покупки не выбран"
            />
          </div>
          <div className=" flex flex-col gap-[10px]">
            <h3 className=" text-accent-default text-sm font-semibold">
              Застройщик
            </h3>
            <Dropdown
              name="logo"
              value={
                logoToggleOptions.find(
                  (item) => item.value === stateAddForm.logo
                ) || null
              }
              options={logoToggleOptions}
              onChange={(value) => {
                setStateAddForm((prev: State) => ({
                  ...prev,
                  logo: value.value,
                }));
              }}
              placeholder="Логотип"
            />
          </div>

          <div className="flex flex-col gap-[10px]">
            <h3 className=" text-accent-default text-sm font-semibold">
              Данные для выведения на макете
            </h3>
            {stateAddForm.radioBtnData &&
              stateAddForm.radioBtnData.map((item) => (
                <div key={item.id} className="flex flex-col gap-[10px]">
                  <ToggleSwitcher
                    label={item.label}
                    isToggle={item.value}
                    setIsToggle={() => toggleRadioValue(item.id)}
                  />
                  <hr className=" bg-boder-default opacity-20" />
                </div>
              ))}
          </div>
          <TegField
            tegsStateForm={stateAddForm.tags}
            setTegsStateForm={setStateAddForm}
            boldLabel="Уникальные параметры"
            placeholder="Параметры помещения"
          />
        </div>

        <div className="flex flex-col gap-[10px] mt-[20px]">
          <ButtonForm
            text=" Сгенерировать макет"
            onClick={() => {
              setIsOpen(false);
              setGenirateModal(true);
            }}
            type="button"
            className=" py-3 px-6 
            bg-accent-default flex-1
            text-white-default flex justify-center
            hover:bg-accent-disabled
            hover:
            "
          />
          <ButtonForm
            text="Отмена"
            onClick={() => setIsOpen(false)}
            className=" bg-bg-default py-3 px-6 flex-1
             hover:bg-accent-default flex justify-center
              hover:text-white-default"
          />
        </div>

        {/* Image Selection Modal */}
        <Modal className="w-[500px] rounded-[8px]" isOpen={photoSelectModal}>
          <div className=" text-accent-default mb-[20px] flex items-center justify-between">
            <h1 className="font-dewi text-[25px] font-extrabold leading-[30px]">
              Выбор фона
            </h1>
            <button onClick={() => setPhotoSelectModal(false)}>
              <CircleXIcon />
            </button>
          </div>

          <div className="flex gap-[10px] flex-wrap items-center">
            {bgMaket.map((img, i) => (
              <div
                onClick={() => setSelectedImgId(img.img)}
                key={img.id}
                className="rounded-lg overflow-hidden  w-[31.8%] cursor-pointer relative"
              >
                <img
                  src={img.img}
                  width={140}
                  height={90}
                  className="h-full"
                  alt="apartment maket img"
                />
                {selectedImgId === img.img && (
                  <div className="absolute top-0 left-0 bottom-0 right-0 bg-accent-default opacity-50 flex items-center justify-center"></div>
                )}
                {selectedImgId === img.img && (
                  <div className="absolute top-0 left-0 bottom-0 right-0 flex items-center justify-center">
                    <TickInCircleIcon />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="flex gap-[10px] mt-[20px]">
            <ButtonForm
              onClick={() => {
                setPhotoSelectModal(false);
                setSelectedImgId("");
              }}
              text="Отмена"
              type="button"
              className="border border-accent-default py-3 px-6 flex-1
              hover:bg-accent-default flex justify-center
               hover:text-white-default"
            />
            <ButtonForm
              text="Сохранить"
              onClick={() => {
                if (selectedImgId)
                  setStateAddForm((prevState: State) => ({
                    ...prevState,
                    selectedImg: selectedImgId,
                  }));
                setSelectedImgId("");
                setPhotoSelectModal(false);
              }}
              className=" py-3 px-6 
              bg-accent-default flex-1
              text-white-default flex justify-center
              hover:bg-accent-disabled
              hover:
              "
            />
          </div>
        </Modal>
      </div>
    </Modal>
  );
};

export default AdvertisementForm;
