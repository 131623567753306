import React from "react";

const DateIcon = ({ color = "#3B4658" }) => {
  return (
    <svg
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.73512 0C4.14933 0 4.48512 0.335786 4.48512 0.75V1.63928H13.8125V0.75C13.8125 0.335786 14.1483 0 14.5625 0C14.9767 0 15.3125 0.335786 15.3125 0.75V1.68118C17.1167 1.95258 18.5 3.50939 18.5 5.38928L18.5 16.875C18.5 18.9461 16.8211 20.625 14.75 20.625H3.75C1.67893 20.625 0 18.9461 0 16.875V5.38928C0 3.58032 1.28086 2.07052 2.98512 1.71734V0.75C2.98512 0.335786 3.32091 0 3.73512 0ZM3.7251 3.13941C2.49393 3.15276 1.5 4.15495 1.5 5.38928V16.875C1.5 18.1176 2.50736 19.125 3.75 19.125H14.75C15.9926 19.125 17 18.1176 17 16.875L17 5.38928C17 4.14664 15.9926 3.13928 14.75 3.13928H3.75264C3.74681 3.13941 3.74097 3.13948 3.73512 3.13948C3.73177 3.13948 3.72843 3.13946 3.7251 3.13941ZM3.64286 6.21426C3.64286 5.80004 3.97864 5.46426 4.39286 5.46426H14.0312C14.4455 5.46426 14.7812 5.80004 14.7812 6.21426C14.7812 6.62847 14.4455 6.96426 14.0312 6.96426H4.39286C3.97864 6.96426 3.64286 6.62847 3.64286 6.21426Z"
        fill={color}
      />
    </svg>
  );
};

export default DateIcon;
