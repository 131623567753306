import { apiService } from "..";
import { BookingRequestType } from "./type";
import { MetaData } from "../exception";
import { Apartment } from "modules/Apartment/ApartmentList/dataTransformer";

export const addTagTypes = ["booking"] as const;

export const bookingApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      bookingRequest: build.mutation<
        MetaData<{ statusCode: number }>,
        MetaData<BookingRequestType>
      >({
        query: (param) => ({
          path: `/bookings`,
          body: param,
          method: "POST",
        }),
      }),
      getNotBookedPrimises: build.mutation<MetaData<Apartment[]>, string>({
        query: (query) => ({
          path: `/bookings/not-booked-premises?${query}`,
          method: "GET",
        }),
      }),
    }),
  });
export const { useBookingRequestMutation, useGetNotBookedPrimisesMutation } =
  bookingApiHooks;
